import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlSegment } from '@angular/router';
import { Subscription } from 'rxjs';
import { Breadcrumbs } from '@shared/models/breadcrumbs';
import { BaseComponent } from '@shared/components/base/base.component';
import { takeUntil } from 'rxjs/operators';
import { EventsState } from '@modules/events/states/events.state';
import { UserSessionService } from '@core/services/user-session/user-session.service';
import { EventsService } from '@modules/events/services/events.service';
import { Store } from '@ngrx/store';
import { UserSessionState } from '@core/stores/user-session/states/user-session.states';
import { Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

@Component({
  providers: [UserSessionService, EventsService],
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent extends BaseComponent
  implements OnInit, OnDestroy {
  private eventSubs: Subscription;
  public breadcrumbs: Breadcrumbs[] = [];
  private updateBreadcrumbSubscription: Subscription;

  constructor(
    protected idle: Idle,
    protected keepalive: Keepalive,
    protected eventsService: EventsService,
    protected eventStore: Store<{ nf: EventsState }>,
    protected sessionStore: Store<{ uss: UserSessionState }>,
    protected router: Router,
    protected userSessionServive: UserSessionService
  ) {
    super(idle, keepalive, eventsService, sessionStore, router);
    this.router.events.pipe(takeUntil(this.componentDestroyed)).subscribe(_ => {
      this.breadcrumbs = [];
      this.parseRoute(this.router.routerState.snapshot.root);
    });

    this.eventSubs = this.eventStore.select('nf').subscribe(event => {
      if (event.items.id === 'updateBreadCrumb') {
        for (const crumb of this.breadcrumbs) {
          if (crumb.name === event.items.payload.key) {
            crumb.name = event.items.payload.value;
          }
        }
      }
    });
  }

  ngOnInit() {}

  parseRoute(node: ActivatedRouteSnapshot) {
    if (node.url.length > 0) {
      const breadCrumbName = node.data.title || node.url[0].path;
      let urlSegments: UrlSegment[] = [];
      node.pathFromRoot.forEach(routerState => {
        urlSegments = urlSegments.concat(routerState.url);
      });

      this.breadcrumbs.push({
        name: breadCrumbName,
        url:
          '/' +
          urlSegments
            .map(urlSegment => {
              return urlSegment.path;
            })
            .join('/')
      });
    }

    if (node.firstChild) {
      this.parseRoute(node.firstChild);
    }
  }

  ngOnDestroy(): void {
    this.eventSubs.unsubscribe();
  }

  format(name): string {
    let str = name.replace(/-/g, ' ');
    let names = str.split(' ');
    const uppercased = names.map(name => name.toUpperCase());
    return uppercased.join(' ');
  }
}
