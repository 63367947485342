import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  QueryList,
  ViewChildren,
  Input,
  Output,
  ElementRef,
  EventEmitter,
  ViewChild,
  ComponentRef
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { EventsService } from '@modules/events/services/events.service';
import { Store } from '@ngrx/store';
import { EventsState } from '@modules/events/states/events.state';

@Component({
  selector: 'app-view-port',
  templateUrl: './view-port.component.html',
  styleUrls: ['./view-port.component.scss']
})
export class ViewPortComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input('item') item?: any;
  @Input('chartType') chartType?: number;
  @Output('data') data: any = new EventEmitter(true);
  @ViewChild('viewportBody', { static: false }) viewportBody: ElementRef;
  protected eventSubs: Subscription;
  public height: number = 0;
  public dimensions: any;
  public canvasDimensions: any = { width: 800, height: 100 };
  public data_view_id: number = 0;

  public items: any[] = [];
  public sceneWidth: number = 700;
  public sceneHeight: number = 500;

  public viewportForm = new FormGroup({
    width: new FormControl('', []),
    height: new FormControl('', []),
    scale: new FormControl('', [])
  });

  constructor(
    public eventStore: Store<{ nf: EventsState }>,
    protected eventsService: EventsService,
    protected route: ActivatedRoute,
    protected router: Router
  ) {
    this.sceneWidth = 700;
    this.sceneHeight = 500;
    this.dimensions = {
      minY: 0,
      maxY: 10,
      minX: 0,
      maxX: 10,
      minZ: 0,
      maxZ: 10
    };
  }

  ngOnInit(): void {
    this.items = [
      { id: 1, name: 'Axis x', start: 0, end: 10 },
      { id: 2, name: 'Axis y', start: 0, end: 10 },
      { id: 3, name: 'Axis z', start: 0, end: 20 }
    ];
    if (typeof this.chartType === 'undefined' || this.chartType === null) {
      this.chartType = 1;
    }
    if (
      typeof this.item.options.cols !== 'undefined' &&
      typeof this.item.options.rows !== 'undefined'
    ) {
      this.canvasDimensions = {
        width: 220 * parseInt(this.item.options.cols),
        height: 160 * parseInt(this.item.options.rows)
      };

      if (typeof this.item !== 'undefined' && this.item !== null) {
        this.height = parseInt(this.item.options.rows) * 10.75;
      }
    }
    if (typeof this.item !== 'undefined' && this.item !== null) {
      this.data_view_id = this.item.data_view_id;
    }
    this.subscribeEvents();
  }

  subscribeEvents(): void {
    this.eventSubs = this.eventStore.select('nf').subscribe(event => {
      if (event.items.id === 'postAxisValues') {
        this.items = event.items.payload.items;
        //  this.data_view_id = event.items.payload.data_view_id;
      }
    });
  }

  ngOnDestroy(): void {
    if (typeof this.eventSubs !== 'undefined' && this.eventSubs !== null) {
      this.eventSubs.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    let elem = document.querySelectorAll<HTMLElement>('.viewport-body');

    if (typeof elem !== 'undefined' && elem !== null) {
      if (elem.length > 0) {
        //    elem[0].style.minHeight = `${this.height}em`;
      }
    }
  }

  processAction(action: any): void {
    this.data.emit('testit');
  }

  onAxisUpdated(e: any) {}

  scaleCoord(c: any): void {}
}
