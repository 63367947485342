import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import * as fromComponents from './components';
import { ClarityModule } from '@clr/angular';
import { BaseComponent } from './components/base/base.component';
import { RouterModule } from '@angular/router';
import { EventService } from '@core/services/event/event.service';
import { EventsService } from '@modules/events/services/events.service';
import { UserProfileService } from '@modules/users/services/user-profile.service';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { DpDatePickerModule } from 'ng2-date-picker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { DataCommanderChartWrapperModule } from '@shared/modules/data-commander-chart-wrapper.module';
import { DragulaService, DragulaModule } from 'ng2-dragula';
import { DndListModule } from 'ngx-drag-and-drop-lists';
import { UserSessionService } from '@core/services/user-session/user-session.service';
import { CreateFolderComponent } from './components/create-folder/create-folder.component';
//import { DraggableItemsComponent } from './components/draggable-items/draggable-items.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ThreedControlsComponent } from './components/threed-controls/threed-controls.component';
import { AngularSlickgridModule } from 'angular-slickgrid';

@NgModule({
  declarations: [...fromComponents.components],
  imports: [
    CommonModule,
    MatFormFieldModule,
    NgxMaterialTimepickerModule,
    DataCommanderChartWrapperModule,
    DpDatePickerModule,
    ClarityModule,
    DndListModule,
    DragulaModule,
    FormsModule,
    FontAwesomeModule,
    SelectDropDownModule,
    ReactiveFormsModule,
    AngularSlickgridModule.forRoot({
      // add any Global Grid Options/Config you might want
      // to avoid passing the same options over and over in each grids of your App
      enableExcelExport: true, // Excel Export is the new default,
      enableExport: false, // CSV/Text with Tab Delimited
      enableAutoResize: true,
      autoResize: {
        container: '#demo-container',
        rightPadding: 10
      } as any
    }),
    RouterModule
  ],
  providers: [
    DragulaService,
    EventService,
    UserSessionService,
    UserProfileService,
    EventsService
  ],
  exports: [...fromComponents.components]
})
export class SharedModule {}
