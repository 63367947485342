import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyRoutingModule } from './company-routing.module';
import { CompanyListComponent } from './company-list/company-list.component';
import { CompanyDetailsComponent } from './company-details/company-details.component';
import { CustomPeriodDetailsComponent } from './custom-periods/custom-period-details/custom-period-details.component';
import { CustomPeriodListComponent } from './custom-periods/custom-period-list/custom-period-list.component';
import { ClarityModule } from '@clr/angular';
import { DpDatePickerModule } from 'ng2-date-picker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [
    CompanyListComponent,
    CompanyDetailsComponent,
    CustomPeriodDetailsComponent,
    CustomPeriodListComponent
  ],
  imports: [
    CommonModule,
    CompanyRoutingModule,
    ClarityModule,
    DpDatePickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    NgxMaterialTimepickerModule,
    SelectDropDownModule,
    SharedModule
  ]
})
export class CompanyModule {}
