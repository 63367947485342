<div class="alarm-menu" [@slideInOut]="alarmContactMenuState">
    <div class="alarm-control">
        <button class="btn btn-link nb-button alarm-button" (click)=toggleAlarmContactMenu()>
          <clr-icon shape="cog" size="24"></clr-icon>
        </button>
        
        <span *ngIf="alarmContactMenuData">{{alarmContactMenuData.alarmName}}</span>
    </div>



    <div class="alarm-container">


        <div class="alarm-name">Applied Alarm Contacts</div>
   <div class="alarm-alarms"
      [dndList]
      [dndModel]="appliedAlarmContacts">
      <div *ngFor="let item of appliedAlarmContacts"
        class="alarm" 
        (dndMoved)="removeOneItem(item, appliedAlarmContacts)"
        (dndDragEnd)="onDragEnded(item, 'applied')"
        [dndType]="'item'"
        [dndDraggable]
        [dndObject]="item">{{item.name}}</div>
    </div>


        <div class="alarm-name">Available Alarm Contacts</div>
        <div class="clr-row search-box">
           <div class="search-box-left">&nbsp;</div>
           <div class="search-box-right">
                <label for="allAlarmContactsSearch">
                    <input id="allAlarmCotnactSearch" [(ngModel)]="searchText" (ngModelChange)="searchContacts($event)" placeholder="Search Contact">
                </label>
           </div>
        </div>

 <div class="global-alarms" 
      [dndList]
      [dndModel]="allContacts"
      >
      <div *ngFor="let item of allContacts"
        class="alarm"
        (dndMoved)="removeOneItem(item, allContacts)"
        (dndDragEnd)="onDragEnded(item, 'all')"
        [dndType]="'item'"
        [dndDraggable]
        [dndObject]="item">{{item.name}}</div>
    </div>
    
</div>


</div>
