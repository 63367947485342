import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { BaseComponent } from '@shared/components/base/base.component';
import { EventsService } from '@modules/events/services/events.service';
import { EventsState } from '@modules/events/states/events.state';
import { ITicket } from '@modules/ticketing/models/ticket.model';
import { TicketService } from '@modules/ticketing/services/ticket.service';
import { UserProfileService } from '@modules/users/services/user-profile.service';
import { UserSessionState } from '@core/stores/user-session/states/user-session.states';

@Component({
  providers: [TicketService, EventsService],
  selector: 'app-ticket-list',
  templateUrl: './ticket-list.component.html',
  styleUrls: ['./ticket-list.component.scss']
})
export class TicketListComponent extends BaseComponent
  implements OnInit, OnDestroy, AfterViewInit {
  private className = 'TicketListComponent';
  private eventSubs: Subscription;
  listHeader = 'Tickets';
  detailUrl = '';
  baseUrl = '/admin/tickets';
  baseRoute = '';
  items: ITicket[];
  loading = true;
  selected = [];
  itemIds = [];
  private deleteModalTitle = 'Delete Tickets';
  private deleteEvent = 'deleteTicketItems';
  noDataText = 'No tickets to display.';
  itemCount = 0;
  limit;
  rowLimitLocalStorageKey = this.className + '-row-limit';
  errorMessage: string;
  private refreshListSubscription: Subscription;
  private deleteItemsSubscription: Subscription;
  private filters: any;
  private successToast = {
    title: 'Success!',
    text: '',
    type: 'success'
  };
  private errorToast = {
    title: 'Error!',
    text: '',
    type: 'error'
  };
  private itemNameOne = 'Ticket';
  private itemNameMany = 'Tickets';

  constructor(
    protected idle: Idle,
    protected keepalive: Keepalive,
    protected eventsService: EventsService,
    protected eventStore: Store<{ nf: EventsState }>,
    protected sessionStore: Store<{ uss: UserSessionState }>,
    private ticketService: TicketService,
    private userProfileService: UserProfileService,
    protected route: ActivatedRoute,
    protected router: Router
  ) {
    super(idle, keepalive, eventsService, sessionStore, router);

    this.limit = this.userProfileService.getStorageVal(
      this.rowLimitLocalStorageKey
    );
    if (!this.limit) {
      this.limit = 25;
    }
  }

  ngOnInit() {
    this.baseRoute = this.userProfileService.settings['route'];
    this.detailUrl = `/tickets`;
    this.subscribeEvents();
  }

  ngOnDestroy(): void {
    if (typeof this.eventSubs !== 'undefined' && this.eventSubs !== null) {
      this.eventSubs.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    this.saveRoute();
  }

  subscribeEvents() {
    this.eventSubs = this.eventStore.select('nf').subscribe(event => {
      if (event.items.id === 'deleteTickets') {
        this.deleteItems();
      }
    });
  }

  addItem() {
    this.router.navigate([`${this.baseUrl}/0`]);
  }

  deleteDialog() {
    const itemNames = this.selected.map(function(d) {
      return d['name'];
    });

    let id = 'openTicketDeleteModal';

    this.eventsService.publish(id, 'openTicketDeleteModal', {
      module: 'tickets',
      action: 'open',
      deleteModalTitle: this.deleteModalTitle,
      itemNames: itemNames,
      event: this.deleteEvent,
      additionalInfo: '',
      name: ''
    });
  }

  refresh(filters) {
    this.userProfileService.setStorageVal(
      this.rowLimitLocalStorageKey,
      this.limit
    );
    this.filters = filters;
    // get around clarity 'expression has changed after it was checked' bug.
    setTimeout(() => {
      this.loading = true;
    }, 100);
    this.selected = [];
    this.ticketService.getTickets(filters).subscribe(
      items => this.onItemsRetrieved(items),
      error => (this.errorMessage = <any>error)
    );
  }

  onItemsRetrieved(data: any): void {
    if (data.is_error) {
      this.router.navigate([this.detailUrl]);
    } else {
      this.items = data.item_list;
      this.itemCount = data.item_count;
      // get around clarity 'expression has changed after it was checked' bug.
      setTimeout(() => {
        this.loading = false;
      }, 200);
    }
  }

  deleteItems() {
    this.itemIds = this.selected.map(function(d) {
      return d['id'];
    });
    for (const itemId of this.itemIds) {
      this.ticketService.deleteTicket(itemId).subscribe(
        item => this.onItemDeleted(item, itemId),
        error => (this.errorMessage = <any>error)
      );
    }
  }

  onItemDeleted(data: any, itemId): void {
    this.itemIds = this.itemIds.filter(function(e) {
      return e !== itemId;
    });
    if (data.is_error) {
      this.errorToast.text = `Failed to delete ${
        this.selected.length > 1 ? this.itemNameMany : this.itemNameOne
      }!`;
      this.eventsService.showToast(this.errorToast);
    } else {
      if (this.itemIds.length === 0) {
        this.successToast.text = `${
          this.selected.length > 1 ? this.itemNameMany : this.itemNameOne
        } deleted successfully!`;
        this.eventsService.showToast(this.successToast);
        this.refresh(this.filters);
      }
    }
  }

  toggleItemActive(active, id) {
    this.ticketService.saveTicket({ id: id, active: active }).subscribe(
      item => this.onItemUpdated(item),
      error => (this.errorMessage = <any>error)
    );
  }

  onItemUpdated(data: any): void {
    if (data.is_error) {
      this.errorToast.text = data.message;
      this.eventsService.showToast(this.errorToast);
    } else {
      // console.log(`${this.className} onItemUpdated`);
      this.successToast.text = `${this.itemNameOne} updated successfully!`;
      this.eventsService.showToast(this.successToast);
    }
  }

  readUrl(id) {
    return `${this.baseUrl}/${id}`;
  }
}
