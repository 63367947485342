<div class="child-container child-container-wrapper">
<div class="flex-outer-container"> 

    <div class="flex-outer-left-item">
         <div class="flex-inner-left-container">
                   <div class="flex-inner-left-left-item">  
                      <h2>{{listHeader}}</h2>
                   </div>
         </div>
    </div>
    <div class="flex-outer-right-item">
         <div class="flex-inner-right-container">
                    <div class="flex-inner-right-right-item">
                                <button class="btn btn-primary add-button" (click)=addItem()>
                                  Add
                                </button>&nbsp;
                                <button class="btn btn-primary delete-button" [disabled]="!(selected.length > 0)" (click)=deleteDialog()>
                                 Delete
                               </button>&nbsp;
                    </div> 

                    <div class="flex-inner-right-left-item">

                                <div class="toggle-container">
                                    <div class="toggle-left-item ">
                                    </div>
                                    <div class="toggle-right-item">
                                    </div>

                                </div>



                            </div> 
                      <div class="flex-inner-right-last-item">

                               <div class="clr-row">

                               </div>
                      </div>
                    </div>
         </div> 
    </div>


<div class="limit-height">

    <clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading" [(clrDgSelected)]="selected" class="grid-sizing fl-table">
        <clr-dg-column [clrDgField]="'name'">
            Name
            <clr-dg-string-filter [clrDgStringFilter]="'name'"></clr-dg-string-filter>
        </clr-dg-column>
        <clr-dg-column *ngIf="showCompany">Company</clr-dg-column>
        <!--<clr-dg-column>Streaming</clr-dg-column>-->
        <!--<clr-dg-column>Update Interval</clr-dg-column>-->
        <clr-dg-column>Active</clr-dg-column>

        <clr-dg-placeholder>{{noDataText}}</clr-dg-placeholder>
        <!-- Do not use *clrDgItems, use *ngFor. https://github.com/vmware/clarity/issues/928 -->
        <clr-dg-row *ngFor="let item of items" [clrDgItem]="item">
            <clr-dg-cell class="col-top">
                <a [routerLink]="readUrl(item.id)">{{item.name}}</a>
            </clr-dg-cell>
            <clr-dg-cell class="col-top" *ngIf="showCompany">{{item.company.name}}</clr-dg-cell>
            <!--<clr-dg-cell>{{item.is_streaming}}</clr-dg-cell>-->
            <!--<clr-dg-cell class="item-active">
                <clr-checkbox [clrDisabled]="true" [clrChecked]="item.stream_interval"></clr-checkbox>
            </clr-dg-cell>-->
            <clr-dg-cell class="item-active col-top-checkbox">
                <input clrCheckbox type="checkbox" class="item-active-checkbox" [checked]="item.active" [(ngModel)]="item.active" (ngModelChange)="toggleItemActive(item)" />
            </clr-dg-cell>
        </clr-dg-row>



        <clr-dg-footer>

            <clr-dropdown [clrCloseMenuOnItemClick]="true" (clrMenuPosition)="'top-left'">
                <clr-dropdown-menu>
                    <h4 class="dropdown-header">Select Row Count</h4>
                    <button type="button" class="dropdown-item" [ngClass]="{'active': limit == 25}" (click)="limit = 25"
                            clrDropdownItem>25
                    </button>
                    <button type="button" class="dropdown-item" [ngClass]="{'active': limit == 50}" (click)="limit = 50"
                            clrDropdownItem>50
                    </button>
                    <button type="button" class="dropdown-item" [ngClass]="{'active': limit == 100}"
                            (click)="limit = 100" clrDropdownItem>100
                    </button>
                </clr-dropdown-menu>
            </clr-dropdown>





            <clr-dg-pagination #pagination [clrDgPageSize]="25" [clrDgTotalItems]="itemCount">
                <clr-dg-page-size [clrPageSizeOptions]="[25, 50, 100]">
                    Custom Periods per page
                </clr-dg-page-size>
                {{ pagination.firstItem + 1 }}
                -
                {{ pagination.lastItem + 1 }}
                of
                {{ pagination.totalItems }}
                custom periods
            </clr-dg-pagination>

        </clr-dg-footer>


    </clr-datagrid>
</div>
</div>

