export const constants = Object.freeze({
  app: {
    regex: '',
    email_regex:
      '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
    latitude_regex: '^[+-]?((90.?0*$)|(([0-8]?[0-9]).?[0-9]*$))',
    longitude_regex:
      '^[+-]?((180.?0*$)|(((1[0-7][0-9])|([0-9]{0,2})).?[0-9]*$))',
    rounding_regex:
      '^[+-]?((180.?0*$)|(((1[0-7][0-9])|([0-9]{0,2})).?[0-9]*$))',
    cell_regex: '^\\d{10}$',
    data_point_expression_regex: '^(?!.*([x])\\1{1})[x0-9+*-/()., ]+$',
    user_password_regex: '^(?=.*?[0-9]).{2,}$',
    company: {
      modules: {
        dashboard: 'Dashboard',
        data_analysis: 'Data Analysis',
        companies: 'Companies',
        users: 'Users',
        alarms: 'Alarms',
        things: 'Things',
        reports: 'Reports'
      }
    }
  },
  api: {
    base_api: '',
    base: 'https://stage.legendanalytics.com',
    auth: {
      authenticate: '/authenticate', //'/api/v2/authenticate',
      logout: 'logout' //'/api/v2/logout'
    },
    data: {
      data_interval_api_url: '/api/data/custom_periods',
      data_view: {
        copy_to_user_api_url: '/api/data/copy_data_view',
        api_url: '/api/data/data_views',
        data_point: {
          add_api_url: '/api/data/add_data_point',
          remove_api_url: '/api/data/remove_data_point'
        },
        date_range_filter: {
          add_api_url: '/api/data/add_date_range_filter',
          remove_api_url: '/api/data/remove_date_range_filter'
        }
      },
      dashboard: {
        copy_to_user_api_url: 'api/data/copy_dashboard',
        update_dashboard_url: 'api/data/update_dashboard'
      },
      data_point: {
        data_point_thing_order: '/api/data/get_data_point_thing_order',
        reset_computed_datapoint: '/api/data/reset_computed_data_point',
        copy_to_user_api_url: '/api/data/copy_data_point',
        add_api_url: '/api/data/add_data_point',
        remove_api_url: '/api/data/remove_data_point'
      },

      data_range_filter: {
        add_api_url: '/api/data/add_date_range_filter',
        remove_api_url: '/api/data/remove_date_range_filter'
      },

      data_get_csv_api_url: '/api/data/get_csv'
    },
    location: {
      location_api: '/api/locations',
      asset_customer_location: '/api/asset_customer_location',
      asset_customer_locations: '/api/asset_customer_locations'
    },
    user_history: {
      user_history_url: '/api/user_history'
    },
    user_session: {
      url: '/user_session', //'/api/v2/user_session',
      cache_busting_interval: 100000,
      v2_url: '/api/v2/user_session'
    },
    field: {
      field_api: '/api/data/fields'
    },
    thing: {
      thing_type_api: '/apiapi/thing_type',
      add_thing_acl_api: '/api/add_thing_acl',
      remove_thing_acl_api: '/api/remove_thing_acl',
      add_thing_tag_api: '/api/add_thing_tag',
      remove_thing_tag_api: '/api/remove_thing_tag',
      get_thing_acl_api: '/api/get_thing_acls',
      get_thing_tags_api: '/api/get_thing_tags',
      get_thing_data_api: '/api/get_thing_data',
      insert_thing_data_api: '/api/task/insert_thing_data',
      thing_api: '/api/things',
      thing_data_api: '/api/get_thing_data',
      thing_lat_regex: '^[+-]?((90.?0*$)|(([0-8]?[0-9]).?[0-9]*$))',
      thing_lon_regex:
        '^[+-]?((180.?0*$)|(((1[0-7][0-9])|([0-9]{0,2})).?[0-9]*$))'
    },
    company: {
      company_api: '/api/companies',
      role: {
        api: '/api/company_roles',
        thing: {
          api: '/api/get_company_role_things',
          add_api: '/api/add_company_role_thing',
          update_api: '/api/remove_company_role_thing',
          remove_api: '/api/update_company_role_thing'
        }
      }
    },
    customPeriod: {
      custom_period_api: '/api/data/custom_periods'
    },
    dataPoint: {
      data_point_api: '/api/data/data_points',
      add_thing_api: '/api/data/add_thing',
      remove_thing_api: '/api/data/remove_thing',
      add_computed_thing_api: '/api/data/add_computed_thing',
      remove_computed_thing_api: '/api/data/remove_computed_thing',
      update_computed_thing_api: '/api/data/update_computed_thing',
      copy_to_user_api: '/api/data/copy_data_point'
    },
    dataView: {
      data_view_api: '/api/data/data_views',
      data_view_full_api: '/data'
    },
    dashboard: {
      dashboard_api: '/api/data/dashboards',
      user_dashboard_api: '/api/data/user_dashboards',
      stream_update_interval: 900 * 1000,
      dataView: {
        add: '/api/data/add_data_view',
        remove: '/api/data/remove_data_view'
      }
    },
    event: {
      event_url: '/api/events',
      event_type_url: '/api/event_types',
      event_contact_url: '/api/event_contacts'
    },
    outage: {
      outage_url: '/api/outages',
      outage_type_url: '/api/outage_types'
    },
    asset: {
      url: '/api/assets',
      customer: {
        url: '/api/asset_customers',
        asset_customer_contact: {
          add_api_url: '/api/add_asset_customer_contact',
          remove_api_url: '/api/remove_asset_customer_contact'
        }
      },
      data_point: {
        add_api_url: '/api/add_asset_data_point',
        remove_api_url: '/api/remove_asset_data_point'
      },
      trigger_point: {
        add_api_url: '/api/add_asset_trigger_point',
        remove_api_url: '/api/remove_asset_trigger_point'
      },
      location: {
        locations_api: '/api/asset_customer_locations',
        location_api: '/api/asset_customer_location'
      },
      type_url: '/api/asset_types'
    },

    trigger_point: {
      url: '/api/trigger_points',
      contact: {
        add_api_url: '/api/add_trigger_point_contact',
        remove_api_url: '/api/remove_trigger_point_contact'
      },
      data_view: {
        add_api_url: '/api/add_trigger_point_data_view',
        remove_api_url: '/api/remove_trigger_point_data_view'
      },
      profile: {
        url: '/api/trigger_point_profiles',
        add_api_url: '/api/add_trigger_point_profile',
        remove_api_url: '/api/remove_trigger_point_profile',
        action: {
          url: '/api/trigger_point_profile_actions',
          add_api_url: '/api/add_trigger_point_profile_action',
          remove_api_url: '/api/remove_trigger_point_profile_action'
        }
      }
    },
    user: {
      user_api_check_name: '/api/check_name',
      user_api: '/api/users',
      login_as: '/api/v2/login_as'
    },
    // Report Template Api
    reports: {
      report_api: '/api/reports',
      template_api: '/api/report_templates',
      create_report_api: '/api/task/create_report',
      events: {
        api_url: '/api/scheduled_events',
        type_api_url: '/api/scheduled_event_types',
        contact_api_url: '/api/scheduled_event_contacts',
        contact: {
          add_api_url: '/api/add_scheduled_event_contact',
          remove_api_url: '/api/remove_scheduled_event_contact'
        }
      }
    },
    tasks: {
      task_status_api_url: '/api/task/task_status'
    },
    alarm: {
      alarm_api: '/api/alarms',
      alarm_type_api: '/api/alarm_types',
      alarm_template_api: '/api/alarm_templates',
      alarm_contact_api: '/api/alarm_contacts',
      alarm_history_api: '/api/alarm_histories',
      alarm_alarm_contact_add_api: '/api/add_alarm_contact',
      alarm_alarm_contact_remove_api: '/api/remove_alarm_contact'
    },
    tickets: {
      ticket_url: '/api/tickets',
      ticket_type_url: '/api/ticket_types',
      ticket_add_note_url: '/api/add_ticket_note',
      ticket_priority_url: '/api/ticket_priority',
      ticket_status_url: '/api/ticket_status',
      ticket_ticket_user_add_api_url: '/api/add_ticket_user',
      ticket_ticket_user_remove_api_url: '/api/remove_ticket_user'
    },
    login: {
      recover: '/api/v2/recover',
      welcome: '/api/v2/welcome',
      reset: '/api/v2/reset'
    }
  },
  states: [
    {
      name: 'Alabama',
      id: 'AL'
    },
    {
      name: 'Alaska',
      id: 'AK'
    },
    {
      name: 'Arizona',
      id: 'AZ'
    },
    {
      name: 'Arkansas',
      id: 'AR'
    },
    {
      name: 'California',
      id: 'CA'
    },
    {
      name: 'Colorado',
      id: 'CO'
    },
    {
      name: 'Connecticut',
      id: 'CT'
    },
    {
      name: 'Delaware',
      id: 'DE'
    },
    {
      name: 'District Of Columbia',
      id: 'DC'
    },
    {
      name: 'Florida',
      id: 'FL'
    },
    {
      name: 'Georgia',
      id: 'GA'
    },
    {
      name: 'Hawaii',
      id: 'HI'
    },
    {
      name: 'Idaho',
      id: 'ID'
    },
    {
      name: 'Illinois',
      id: 'IL'
    },
    {
      name: 'Indiana',
      id: 'IN'
    },
    {
      name: 'Iowa',
      id: 'IA'
    },
    {
      name: 'Kansas',
      id: 'KS'
    },
    {
      name: 'Kentucky',
      id: 'KY'
    },
    {
      name: 'Louisiana',
      id: 'LA'
    },
    {
      name: 'Maine',
      id: 'ME'
    },
    {
      name: 'Maryland',
      id: 'MD'
    },
    {
      name: 'Massachusetts',
      id: 'MA'
    },
    {
      name: 'Michigan',
      id: 'MI'
    },
    {
      name: 'Minnesota',
      id: 'MN'
    },
    {
      name: 'Mississippi',
      id: 'MS'
    },
    {
      name: 'Missouri',
      id: 'MO'
    },
    {
      name: 'Montana',
      id: 'MT'
    },
    {
      name: 'Nebraska',
      id: 'NE'
    },
    {
      name: 'Nevada',
      id: 'NV'
    },
    {
      name: 'New Hampshire',
      id: 'NH'
    },
    {
      name: 'New Jersey',
      id: 'NJ'
    },
    {
      name: 'New Mexico',
      id: 'NM'
    },
    {
      name: 'New York',
      id: 'NY'
    },
    {
      name: 'North Carolina',
      id: 'NC'
    },
    {
      name: 'North Dakota',
      id: 'ND'
    },
    {
      name: 'Ohio',
      id: 'OH'
    },
    {
      name: 'Oklahoma',
      id: 'OK'
    },
    {
      name: 'Oregon',
      id: 'OR'
    },
    {
      name: 'Pennsylvania',
      id: 'PA'
    },
    {
      name: 'Rhode Island',
      id: 'RI'
    },
    {
      name: 'South Carolina',
      id: 'SC'
    },
    {
      name: 'South Dakota',
      id: 'SD'
    },
    {
      name: 'Tennessee',
      id: 'TN'
    },
    {
      name: 'Texas',
      id: 'TX'
    },
    {
      name: 'Utah',
      id: 'UT'
    },
    {
      name: 'Vermont',
      id: 'VT'
    },
    {
      name: 'Virginia',
      id: 'VA'
    },
    {
      name: 'Washington',
      id: 'WA'
    },
    {
      name: 'West Virginia',
      id: 'WV'
    },
    {
      name: 'Wisconsin',
      id: 'WI'
    },
    {
      name: 'Wyoming',
      id: 'WY'
    }
  ],
  months: [
    {
      name: 'January',
      id: 1
    },
    {
      name: 'February',
      id: 2
    },
    {
      name: 'March',
      id: 3
    },
    {
      name: 'April',
      id: 4
    },
    {
      name: 'May',
      id: 5
    },
    {
      name: 'June',
      id: 6
    },
    {
      name: 'July',
      id: 7
    },
    {
      name: 'August',
      id: 8
    },
    {
      name: 'September',
      id: 9
    },
    {
      name: 'October',
      id: 10
    },
    {
      name: 'November',
      id: 11
    },
    {
      name: 'December',
      id: 12
    },
    {
      name: 'Last Month',
      id: 13
    },
    {
      name: 'This Month',
      id: 14
    }
  ],
  years: [
    {
      name: '2017',
      id: 1
    },
    {
      name: '2018',
      id: 2
    },
    {
      name: '2019',
      id: 3
    },
    {
      name: '2020',
      id: 4
    },
    {
      name: '2021',
      id: 5
    },
    {
      name: '2022',
      id: 6
    },
    {
      name: '2023',
      id: 7
    },
    {
      name: '2024',
      id: 8
    },
    {
      name: '2025',
      id: 9
    },
    {
      name: '2026',
      id: 10
    },
    {
      name: 'Last Year',
      id: 99
    },
    {
      name: 'This Year',
      id: 100
    }
  ]
});
