import { BaseComponent } from '@shared/components/base/base.component';
import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { FieldService } from '@core/services/field/field.service';
import { DataViewService } from '@core/services/data-view/data-view.service';
import { UserProfileService } from '@modules/users/services/user-profile.service';
import { UserService } from '@core/services/user/user.service';
import { CompanyService } from '@core/services/company/company.service';
import { TimeIntervalConfig } from '@shared/utils/date-picker-options';
import { ICustomPeriod } from '@modules/companies/models/data-interval/data-inteval.model';
import { get_item } from '@shared/utils/storage/encrypted_storage';
import { CustomPeriodService } from '@core/services/custom-period/custom-period.service';
import { Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { EventsService } from '@modules/events/services/events.service';
import { Store } from '@ngrx/store';
import { UserSessionState } from '@core/stores/user-session/states/user-session.states';
import { UserSessionService } from '@core/services/user-session/user-session.service';
import * as moment from 'moment';

@Component({
  providers: [
    EventsService,
    UserProfileService,
    UserSessionService,
    CompanyService,
    DataViewService,
    CustomPeriodService,
    UserService
  ],
  selector: 'app-custom-period-details',
  templateUrl: './custom-period-details.component.html',
  styleUrls: ['./custom-period-details.component.scss']
})
export class CustomPeriodDetailsComponent extends BaseComponent
  implements OnInit, OnDestroy, AfterViewInit {
  header: string;
  loading = true;
  routeSubs: Subscription;
  public userSession: any;
  private role: string;
  private baseRoute;
  private baseUrl = '/admin/companies/custom-periods';
  private returnUrl = '/companies/custom-periods/list';
  private newItemUrl = '/companies/custom-periods/';
  public name;
  fields;
  users;
  companies;
  public user_selected;
  private dataPoints;
  dataViewMenuData = {};
  private className = 'CustomPeriodDetailsComponent';
  showCompanies = true;
  public period_id: number;
  public item: ICustomPeriod;
  private _item: ICustomPeriod;
  timeIntervalConfig = TimeIntervalConfig;
  public active: boolean;
  public company_id: number;
  public user_id: number;
  public mon: boolean = false;
  public mon_from: any;
  public mon_to: any;
  public tue: boolean = false;
  public tue_from: any;
  public tue_to: any;
  public wed: boolean = false;
  public wed_from: any;
  public wed_to: any;
  public thu: boolean = false;
  public thu_from: any;
  public thu_to: any;
  public fri: boolean = false;
  public fri_from: any;
  public fri_to: any;
  public sat: boolean = false;
  public sat_from: any;
  public sat_to: any;
  public sun: boolean = false;
  public sun_from: any;
  public sun_to: any;

  startTime = new Date('1900-01-01T07:00:00');
  endTime = new Date('1900-01-01T17:00:00');
  itemForm = this.formBuilder.group({
    name: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(50)
    ]),
    active: new FormControl(),
    company_id: new FormControl('', Validators.required),
    mon: false,
    mon_from: moment(this.startTime),
    mon_to: moment(this.endTime),
    tue: false,
    tue_from: moment(this.startTime),
    tue_to: moment(this.endTime),
    wed: false,
    wed_from: moment(this.startTime),
    wed_to: moment(this.endTime),
    thu: false,
    thu_from: moment(this.startTime),
    thu_to: moment(this.endTime),
    fri: false,
    fri_from: moment(this.startTime),
    fri_to: moment(this.endTime),
    sat: false,
    sat_from: moment(this.startTime),
    sat_to: moment(this.endTime),
    sun: false,
    sun_from: moment(this.startTime),
    sun_to: moment(this.endTime)
  });
  configItemArray = [
    'mon',
    'mon_from',
    'mon_to',
    'tue',
    'tue_from',
    'tue_to',
    'wed',
    'wed_from',
    'wed_to',
    'thu',
    'thu_from',
    'thu_to',
    'fri',
    'fri_from',
    'fri_to',
    'sat',
    'sat_from',
    'sat_to',
    'sun',
    'sun_from',
    'sun_to'
  ];
  private errorMessage: string;
  private successToast = {
    title: 'Success!',
    text: 'Custom Period updated successfully!',
    type: 'success'
  };
  private errorToast = {
    title: 'Error!',
    text: 'Failed to update Custom Period!',
    type: 'error'
  };

  constructor(
    protected idle: Idle,
    protected keepalive: Keepalive,
    protected eventsService: EventsService,
    protected sessionStore: Store<{ uss: UserSessionState }>,
    private customPeriodService: CustomPeriodService,
    protected userProfileService: UserProfileService,
    protected userSessionService: UserSessionService,
    protected fieldService: FieldService,
    private companyService: CompanyService,
    protected userService: UserService,
    protected route: ActivatedRoute,
    protected router: Router,
    private formBuilder: FormBuilder
  ) {
    super(idle, keepalive, eventsService, sessionStore, router);
    this.userSession = this.userSessionService.getUserSession(true);
  }

  ngOnInit() {
    this.readParams();
    let session = get_item('session');
    this.company_id = session.company.id;
    this.role = session.role;
    this.userSessionService.advanceSession(session);
  }

  readParams() {
    this.routeSubs = this.route.params.subscribe(params => {
      this.period_id = params['id'];
      this.header =
        this.period_id === 0
          ? 'Add Custom Period'
          : 'Custom Period Detail - Edit';
      if (this.period_id < 1) {
        this.item = this.getEmptyPeriod() as ICustomPeriod;
        this.readFields();
      } else {
        this.customPeriodService
          .getCustomPeriod(this.period_id)
          .subscribe(d => {
            this.item = d['item'];
            this.name = d['name'];
            this.readFields();
          });
      }
    });
  }

  readFields() {
    this.fieldService.getFields().subscribe(data => {
      this.fields = data;
      this.readCompanies();
    });
  }

  readCompanies() {
    this.companyService.getAllCompanies().subscribe(data => {
      this.companies = data['item_list'];
      this.companies.reverse();
      this.readUsers();
    });
  }

  readUsers() {
    this.userService.getAllUsers().subscribe(data => {
      this.users = data['item_list'];
      this.users.reverse();
      this.patchForm();
    });
  }

  ngOnDestroy(): void {
    if (typeof this.routeSubs !== 'undefined' && this.routeSubs !== null) {
      this.routeSubs.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    this.saveRoute();
  }

  patchForm() {
    for (let user of this.users) {
      user['name'] = user['name'] + ' (' + user['company'] + ')';
    }
    this.itemForm.patchValue(this.item);

    if (this.role == 'master') {
      this.disableCompanies();
      this.showCompanies = false;
    }

    if (this.role == 'admin') {
      this.disableCompanies();
      this.showCompanies = false;
    }

    if (this.role == 'standard') {
      this.disableCompanies();
      this.showCompanies = false;
    }

    if (this.item.config) {
      for (let item of this.configItemArray) {
        if (this.itemForm.controls && this.itemForm.controls[item]) {
          this.itemForm.controls[item].setValue(this.item.config[item]);
        }
      }
      delete this.item['config'];
    }
  }

  disableCompanies() {
    if (this.itemForm.controls && this.itemForm.controls['company_id']) {
      this.itemForm.controls['company_id'].setValue(
        this.userProfileService.company['id']
      );
      this.itemForm.controls['company_id'].markAsPristine();
      this.itemForm.controls['company_id'].disable();
    }
  }

  /*updateDataViewMenu(event?) {
        const _data_points = this.item['data_points'] ? this.item['data_points'] : [];
        this.dataViewMenuData = {
            dataViewName: event ? event : this.item.name,
            dataViewId: this.item.id,
            dataViewDataPoints: _data_points,
            allDataPoints: this.dataPoints,
        };
    }*/

  update() {
    const updateItem = Object.assign({}, this.item, this.itemForm.value);

    var configObj = {};
    for (let obj of this.configItemArray) {
      configObj[obj] = updateItem[obj];
      delete updateItem[obj];
    }
    console.log(configObj);

    updateItem['config'] = configObj;

    if (this.role == 'master') {
      updateItem['company_id'] = this.itemForm.get('company_id').value;
    }

    if (this.role == 'admin') {
      updateItem['company_id'] = this.itemForm.get('company_id').value;
    }

    if (this.role == 'standard') {
      updateItem['company_id'] = this.itemForm.get('company_id').value;
      updateItem['user_id'] = this.itemForm.get('user_id').value;
    }

    this.customPeriodService.saveCustomPeriod(updateItem).subscribe(
      item => this.onItemUpdated(item),
      error => (this.errorMessage = <any>error)
    );
  }

  onItemUpdated(data: any): void {
    if (data.is_error) {
      console.log(`Error in onItemUpdated ${data.message}`);
      this.errorToast.text = data.message;
      this.eventsService.showToast(this.errorToast);
    } else {
      // console.log(`${this.className} onItemUpdated`);
      this.eventsService.showToast(this.successToast);
      if (this.item.id === 0) {
        this.header = 'Data View Detail - Edit';
        this.item = data.item;
      } else {
        // this.router.navigate([`${this.baseRoute}${this.returnUrl}`]);
      }
      this.router.navigate([`${this.baseUrl}`]);
    }
  }

  checkDirty(control) {
    if (this._item && this._item[control]) {
      if (
        this.itemForm.controls[control].value !==
        this._item[control].substring(11)
      ) {
        this.itemForm.controls[control].markAsDirty();
      } else {
        this.itemForm.controls[control].markAsPristine();
      }
    }
  }

  dayChanged(day) {
    let val = this.itemForm.controls[day].value;
    this[day] = !val;

    if (this.itemForm.controls && this.itemForm.controls[day]) {
      this.itemForm.controls[day].setValue(this[day]);
    }

    if (!this[day]) {
      this[`${day}_from`] = null;
      this[`${day}_to`] = null;
      this.itemForm.controls[`${day}_from`].setValue(null);
      this.itemForm.controls[`${day}_to`].setValue(null);
    }
  }

  getEmptyPeriod() {
    return {
      id: 0,
      name: '',
      company_id: 0,
      user_id: 0,
      config: this.configItemArray,
      active: true
    };
  }

  userChanged(e) {
    let event = parseInt(e);
    if (event > 0) {
    }
  }
  isActive(day) {
    return this.itemForm.controls[day].value;
  }
  companyChanged(e) {}
}
