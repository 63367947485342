import { Injectable } from '@angular/core';
import { BaseService } from '@core/services/base.service';
import { constants } from '@core/constants';
import { HttpClient } from '@angular/common/http';
import { EventsService } from '@modules/events/services/events.service';

@Injectable({
  providedIn: 'root'
})
export class EventManagementService extends BaseService {
  private serviceApiUrl = constants.api.event;

  constructor(
    protected eventsService: EventsService,
    protected http: HttpClient
  ) {
    super(eventsService, http);
  }

  getAllEvents() {
    return this.getAll(this.serviceApiUrl.event_url);
  }

  getEvent(id: number) {
    return this.get(`${constants.api.event.event_url}/${id}`);
  }

  getEvents(filters) {
    return this.getMany(this.serviceApiUrl.event_url, filters);
  }

  saveEvent(event) {
    return this.saveOne(this.serviceApiUrl.event_url, event);
  }

  deleteEvent(id: number) {
    return this.deleteOne(this.serviceApiUrl.event_url, id);
  }

  getEventType(id: number) {
    return this.getOne(this.serviceApiUrl.event_type_url, id);
  }

  getEventTypes(filters) {
    return this.getMany(this.serviceApiUrl.event_type_url, filters);
  }

  deleteEventType(id: number) {
    return this.deleteOne(this.serviceApiUrl.event_type_url, id);
  }

  saveEventType(eventType) {
    return this.saveOne(this.serviceApiUrl.event_type_url, eventType);
  }

  getAllEventTypesAsOptions() {
    return this.getAllAsOptions(this.serviceApiUrl.event_type_url, {
      name: 'name',
      id: 'id'
    });
  }

  getEventContacts(filters) {
    return this.getMany(this.serviceApiUrl.event_contact_url, filters);
  }

  deleteEventContact(id: number) {
    return this.deleteOne(this.serviceApiUrl.event_contact_url, id);
  }

  saveEventContact(contact) {
    return this.saveOne(this.serviceApiUrl.event_contact_url, contact);
  }

  getAssetCustomer(id: number) {
    return this.getOne(constants.api.asset.customer.url, id);
  }
}
