import { Component, OnDestroy, OnInit, ElementRef } from '@angular/core';
import { BaseComponent } from '@shared/components/base/base.component';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '../../../store/state/nav.state';
import { Observable } from 'rxjs';
import { UserSessionService } from '@core/services/user-session/user-session.service';
import { NavService } from '../../../store/services/nav.service';
import { EventsService } from '@modules/events/services/events.service';
import { UserSessionState } from '@core/stores/user-session/states/user-session.states';
import { Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Router } from '@angular/router';

@Component({
  providers: [UserSessionService, EventsService, NavService],
  selector: 'app-nav-toggle',
  templateUrl: './nav-toggle.component.html',
  styleUrls: ['./nav-toggle.component.scss']
})
export class NavToggleComponent extends BaseComponent
  implements OnInit, OnDestroy {
  public _navState = new BehaviorSubject<boolean>(true);
  public navState: Observable<boolean> = this._navState.asObservable();

  public showNav;

  constructor(
    protected idle: Idle,
    protected keepalive: Keepalive,
    protected element: ElementRef,
    protected eventsService: EventsService,
    protected sessionStore: Store<{ uss: UserSessionState }>,
    protected router: Router,
    protected userSessionService: UserSessionService,
    protected uiService: NavService,
    protected store: Store<{ ui: State }>
  ) {
    super(idle, keepalive, eventsService, sessionStore, router);
    this.userSessionService.sessionStorage
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((data: any) => {
        this.showNav = data.showNav;
      });
  }

  ngOnInit() {
    //      this.toggleNav(true);
  }

  toggleNav(val): void {
    this.uiService.updateNav(val);
    this.userSessionService.setLocalStorage('showNav', val);
    this._navState.next(val);

    let id = 'toggle_navigation';
    this.eventsService.publish(id, 'toggle_navigation', {
      item: { nav: val },
      module: 'toggle',
      action: 'toggle_nav'
    });
  }

  ngOnDestroy(): void {}

  toggleState() {
    return this.navState;
  }
}
