import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataCommanderChartComponent } from '@shared/components/data-commander-chart/data-commander-chart.component';
import { ClarityModule } from '@clr/angular';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DpDatePickerModule } from 'ng2-date-picker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

@NgModule({
  imports: [
    CommonModule,
    ClarityModule,
    FormsModule,
    SelectDropDownModule,
    MatFormFieldModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    NgxMaterialTimepickerModule,
    ReactiveFormsModule,
    DpDatePickerModule
  ],
  declarations: [DataCommanderChartComponent],
  exports: [DataCommanderChartComponent]
})
export class DataCommanderChartWrapperModule {}
