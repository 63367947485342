import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  Input
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  FormControl,
  FormGroup,
  Validators,
  FormArray,
  FormBuilder
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { EventsState } from '@modules/events/states/events.state';
import { EventsService } from '@modules/events/services/events.service';
import { constants } from '@core/constants';
import { BaseComponent } from '@shared/components/base/base.component';
import { UserSessionState } from '@core/stores/user-session/states/user-session.states';
import { Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { IConfig } from '@shared/models/config.model';

@Component({
  selector: 'app-grid-config-details',
  templateUrl: './grid-config-details.component.html',
  styleUrls: ['./grid-config-details.component.scss']
})
export class GridConfigDetailsComponent extends BaseComponent
  implements OnInit, OnDestroy, AfterViewInit {
  @Input('item') item: any;
  public eventSubs: Subscription;
  public itemForm: FormGroup;
  public rowSelected?: any;
  public name: string;
  public minId: number = 0;
  public computation_type: any;
  public computation_types: any[] = [
    { id: 1, name: 'Avg' },
    { id: 2, name: 'Sum' },
    { id: 3, name: 'Medium' },
    { id: 4, name: 'Min' },
    { id: 5, name: 'Max' }
  ];
  public computation_items: IConfig[] = [];

  constructor(
    protected idle: Idle,
    protected keepalive: Keepalive,
    protected sessionStore: Store<{ uss: UserSessionState }>,
    protected eventsService: EventsService,
    protected eventStore: Store<{ nf: EventsState }>,
    protected route: ActivatedRoute,
    protected router: Router,
    private formBuilder: FormBuilder
  ) {
    super(idle, keepalive, eventsService, sessionStore, router);
    this.name = '';
    this.computation_type = 1;
    this.itemForm = this.formBuilder.group({
      name: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50)
      ]),
      computation_type: new FormControl('', [])
    });
  }

  getNextId(): number {
    let maxId = 0;
    for (let i = 0; i < this.computation_items.length; i++) {
      if (maxId <= this.computation_items[i].id) {
        maxId = this.computation_items[i].id;
      }
    }
    maxId = maxId + 1;
    return maxId;
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {}

  patchForm(): void {}

  updateName(e: any): void {}

  add(): void {
    let item = {
      id: this.getNextId(),
      property: this.name,
      value: this.computation_type
    };
    this.computation_items.push(item);
  }

  deleteItem(item: any): void {
    for (let i = 0; i < this.computation_types.length; i++) {
      if (
        typeof this.computation_items[i] !== 'undefined' &&
        this.computation_items[i] !== null
      ) {
        if (item.id == this.computation_items[i].id) {
          this.computation_items.splice(i, 1);
        }
      }
    }
    this.minId = 0;
  }

  editItem(item: any): void {}

  readConfig(item: any) {
    for (let i = 0; i < this.computation_types.length; i++) {
      if (item == this.computation_types[i].id) {
        return this.computation_types[i].name;
      }
    }
    return item;
  }

  noRecords(): boolean {
    if (
      typeof this.computation_items === 'undefined' ||
      this.computation_items === null
    ) {
      return true;
    } else if (this.computation_items.length === 0) {
      return true;
    }
    return false;
  }

  update(): void {}
}
