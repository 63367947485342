<div *ngIf="itemNotEmpty(data)"  class="header">{{ data.item.name }}</div>
<!--
<div id="tdchart_tooltip" class="tooltip" style="display: none;"></div>
-->
<!--
<div id="tdchart" class="container bars-chart">
</div>
-->
<div id="clustered{{data_view_id}}{{hash}}" class="container bars-chart">
</div>
