import { highChartsExport } from './highcharts-export';

export const highChartsBase = {
    exporting: highChartsExport,
    credits: {
        enabled: false
    },
    time: {
        timezoneOffset: new Date().getTimezoneOffset()
    }
};
