import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseService } from '@core/services/base.service';
import { HttpClient } from '@angular/common/http';
import { constants } from '@core/constants';
import { catchError, map } from 'rxjs/operators';
import {
  IThing,
  IThings,
  IThingData,
  IThingDatas,
  IThingTag
} from '@shared/interfaces/things';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { UserProfileService } from '@modules/users/services/user-profile.service';
import { EventsService } from '@modules/events/services/events.service';
const initialThingsData = [] as IThing[];
const initialThingData = {} as IThing;
const initialThingDataData = [] as IThingData[];
interface Result {
  results: [];
}
@Injectable({
  providedIn: 'any'
})
export class ThingService extends BaseService {
  private thingsDataSource = new BehaviorSubject<IThing[]>(initialThingsData);
  private thingCountSource = new BehaviorSubject<number>(null);
  private thingDataSource = new BehaviorSubject<IThing>(initialThingData);
  private result = new BehaviorSubject<Result>();
  private _result = this.result.asObservable();
  private thingDataDataSource = new BehaviorSubject<IThingData[]>(
    initialThingDataData
  );
  public things = this.thingsDataSource.asObservable();
  public thingCount = this.thingCountSource.asObservable();
  public thing = this.thingDataSource.asObservable();
  public thingData = this.thingDataDataSource.asObservable();
  private company_specific;

  constructor(
    protected eventsService: EventsService,
    protected userProfileService: UserProfileService,
    protected http: HttpClient
  ) {
    super(eventsService, http);
    this.company_specific = userProfileService.settings['company_specific'];
  }

  getThings(filters: any) {
    return this.getMany(constants.api.thing.thing_api, filters).pipe(
      map((data: IThings) => {
        this.thingsDataSource.next(data.item_list);
        this.thingCountSource.next(data.item_count);
        return data;
      }),
      catchError(error => this.handleError(error))
    );
  }

  getAllThings() {
    return this.getAll(constants.api.thing.thing_api).pipe(
      map((data: IThings) => {
        this.thingsDataSource.next(data.item_list);
        this.thingCountSource.next(data.item_count);

        const items = data.item_list;
        const itemCount = data.item_count;
        return { items, itemCount };
      }),
      catchError(error => this.handleError(error))
    );
  }

  clearThings() {
    this.thingsDataSource.next(null);
    this.thingCountSource.next(null);
  }

  getThing(id: number) {
    if (id === 0) {
      const data = { id: 0 } as IThing;
      this.thingDataSource.next(data);
      return data;
    }
    return this.getOne(constants.api.thing.thing_api, id).pipe(
      map(
        (data: { item: IThing }) => {
          this.thingDataSource.next(data.item);
          return data;
        },
        catchError(error => this.handleError(error))
      )
    );
  }

  getThingData(id: number) {
    return this.getOne(constants.api.thing.thing_data_api, id).pipe(
      map(
        (data: IThingDatas) => {
          this.thingDataDataSource.next(data.item_list);
        },
        catchError(error => this.handleError(error))
      )
    );
  }

  updateThing(thing: IThing) {
    return this.updateOne(constants.api.thing.thing_api, thing).pipe(
      map(data => {
        this.handleSuccess(data);

        return data;
      }),
      catchError(error => this.handleError(error))
    );
  }

  deleteThing(id: number) {
    return this.deleteOne(constants.api.thing.thing_api, id).pipe(
      map(data => this.handleSuccess(data)),
      catchError(error => this.handleError(error))
    );
  }

  addThingAcl(thing) {
    return this.saveOne(constants.api.thing.add_thing_acl_api, thing).pipe(
      map(data => {
        this.handleSuccess(data);
        return data;
      }),
      catchError(error => this.handleError(error))
    );
  }

  deleteThingAcl(thing) {
    return this.saveOne(constants.api.thing.remove_thing_acl_api, thing);
  }

  getAllThingsAsOptions() {
    if (this.company_specific == 'englewood') {
      return this.getAllAsOptions(constants.api.thing.thing_api, {
        name: 'name',
        id: 'id',
        thing_type: 'thing_type'
      });
    } else {
      return this.getAllAsOptions(constants.api.thing.thing_api, {
        name: 'name',
        id: 'id'
      });
    }
  }

  getThingACLs(companyFilter): Observable<any[]> {
    return this.getMany(
      constants.api.thing.get_thing_acl_api,
      companyFilter
    ).pipe(
      map((data: IThings) => {
        this.thingsDataSource.next(data.item_list);
        this.thingCountSource.next(data.item_count);
        return data;
      }),
      catchError(error => this.handleError(error))
    );
  }

  getThingTags(tagFilter): Observable<any[]> {
    return this.getMany(constants.api.thing.get_thing_tags_api, tagFilter).pipe(
      map((data: IThingTag) => {
        return data;
      }),
      catchError(error => this.handleError(error))
    );
  }

  addThingTag(item): Observable<any> {
    return this.saveOne(constants.api.thing.add_thing_tag_api, item);
  }

  removeThingTag(item): Observable<any> {
    return this.saveOne(constants.api.thing.remove_thing_tag_api, item);
  }
}

@Injectable({
  providedIn: 'root'
})
export class ThingResolverService implements Resolve<any> {
  constructor(private thingService: ThingService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.thingService.getThing(+route.params.id);
  }
}

@Injectable({
  providedIn: 'root'
})
export class ThingsResolverService implements Resolve<any> {
  constructor(private thingService: ThingService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.thingService.getAllThings();
  }
}
