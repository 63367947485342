import * as hightCharts from 'highcharts';
import { highChartsBase } from './highcharts-base';

export const highChartsPieChart = function(data) {
    return Object.assign(
        {
            title: { text: data.name, style: { fontWeight: 'bold' } },
            boost: {
              enabled: true,
              useGPUTranslations: true,
            },
            chart: {
                spacingTop: 20,
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                style: {
                    fontFamily:
                        'Metropolis,"Avenir Next","Helvetica Neue",Arial,sans-serif'
                },
                events: {
                    afterPrint: function() {
                        window.dispatchEvent(new Event('resize'));
                    }
                }
            },
            tooltip: {
                pointFormat: '<b>{point.y}:</b> {point.percentage:.1f}%'
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    animation: false,
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        style: { fontWeight: 'normal' },
                        enabled: true,
                        format: '{point.name}: {point.percentage:.1f} %'
                    },
                    colors: [
                        '#2b908f',
                        '#90ee7e',
                        '#f45b5b',
                        '#7798BF',
                        '#aaeeee',
                        '#ff0066',
                        '#eeaaee',
                        '#55BF3B',
                        '#DF5353',
                        '#7798BF',
                        '#aaeeee'
                    ],
                    showInLegend: true
                }
            },
            legend: {
                enabled: true,
                squareSymbol: true,
                symbolRadius: 1,
                itemMarginBottom: 24,
                symbolPadding: 10,
                itemStyle: {
                    fontFamily:
                        'Metropolis, "Avenir Next", "Helvetica Neue", Arial, sans-serif'
                },
                labelFormat: '{name}: {y:.0f}'
            },
            series: [
            ]
        },
        highChartsBase
    );
};
