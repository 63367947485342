import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CompanyListComponent } from '@modules/companies/company-list/company-list.component';
import { CompanyDetailsComponent } from '@modules/companies/company-details/company-details.component';
import { CustomPeriodDetailsComponent } from './custom-periods/custom-period-details/custom-period-details.component';
import { CustomPeriodListComponent } from './custom-periods/custom-period-list/custom-period-list.component';

const routes: Routes = [
  {
    path: 'custom-periods/list',
    redirectTo: 'custom-periods'
  },
  {
    path: '',
    data: { title: 'Companies' },
    children: [
      {
        path: '',
        data: { title: 'Companies' },
        children: [
          {
            path: 'list',
            redirectTo: ''
          },
          {
            data: { title: 'Custom Periods' },
            path: 'custom-periods',
            children: [
              {
                path: '',
                data: { title: 'Custom Periods' },
                component: CustomPeriodListComponent,
                children: []
              },
              {
                path: ':id',
                component: CustomPeriodDetailsComponent,
                data: { title: 'Custom Period Details' }
              }
            ]
          },
          {
            data: { title: 'Companies' },
            path: 'companies',
            children: [
              {
                path: 'list',
                redirectTo: ''
              },
              {
                path: '',
                data: { title: 'Companies' },
                component: CompanyListComponent,
                children: []
              },
              {
                path: ':id',
                component: CompanyDetailsComponent,
                data: { title: 'Company Details' }
              }
            ]
          },
          {
            path: ':id',
            component: CompanyDetailsComponent,
            data: { title: 'Company Details' }
          },
          {
            path: '',
            component: CompanyListComponent,
            data: { title: 'Companies' }
          }
        ]
      }
    ]
  },
  {
    path: 'companies',
    data: { title: 'Companies' },
    children: [
      {
        path: '',
        data: { title: 'Companies' },
        children: [
          {
            data: { title: 'Custom Periods' },
            path: 'custom-periods',
            children: [
              {
                path: '',
                data: { title: 'Custom Periods' },
                component: CustomPeriodListComponent,
                children: []
              },
              {
                path: ':id',
                component: CustomPeriodDetailsComponent,
                data: { title: 'Custom Periood Details' }
              },
              {
                path: 'list',
                redirectTo: ''
              }
            ]
          },
          {
            path: ':id',
            component: CompanyDetailsComponent,
            data: { title: 'Company Details' }
          },
          {
            path: '',
            component: CompanyListComponent,
            data: { title: 'Companies' },
            children: [
              {
                path: 'list',
                redirectTo: ''
              },
              {
                path: ':id',
                component: CompanyDetailsComponent,
                data: { title: 'Company Details' }
              }
            ]
          },
          {
            path: 'list',
            redirectTo: ''
          }
        ]
      },
      {
        path: ':id',
        component: CompanyDetailsComponent,
        data: { title: 'Company Details' }
      }
    ]
  },
  {
    path: 'custom-periods',
    data: { title: 'Custom Periods' },
    children: [
      {
        path: '',
        data: { title: 'Custom Periods' },
        children: [
          {
            path: ':id',
            component: CustomPeriodDetailsComponent,
            data: { title: 'Custom Period Details' }
          },
          {
            path: '',
            component: CustomPeriodListComponent,
            data: { title: 'Custom Periods' }
          }
        ]
      }
    ]
  },
  {
    path: 'list',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CompanyRoutingModule {}
