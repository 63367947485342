<div class="child-container">
    <form [formGroup]="itemForm" class="pad-r24">
        <section class="form-block offset-top">
            <h4>{{header}}</h4>
            <br/>

            <div class="form-group clr-row">
                <div class="clr-col-2 col-title">
                    <label for="name">Name</label>
                </div>
                <div class="clr-col-9">






                        <clr-input-container *ngIf="item">
                            <input clrInput type="text" size="56" class="form-input" [formControl]="itemForm.controls.name" name="name" [value]="name" class="common-input"  (ngModel)="name" />
                            <clr-control-error *clrIfError="'minlength'">Must be at least 3 characters</clr-control-error>
                            <clr-control-error *clrIfError="'required'">Name Is Required</clr-control-error>
                        </clr-input-container>


                </div>
            </div>

            <div class="form-group clr-row" *ngIf="showCompanies">
                <div class="clr-col-2 col-title">
                    <label for="company">Company</label>
                </div>
                <div class="clr-col-9">
                    <div class="select">

                        <clr-select-container>
                            <select clrSelect id="company" class="form-select" [formControl]="itemForm.controls.company_id" name="company_id" *ngIf="companies" (change)="companyChanged($event)">
                                <option *ngFor="let company of companies" [value]="company.id">
                                {{ company.name }}</option>
                            </select>
                        </clr-select-container>
                    </div>
                </div>
            </div>
<!--
            <div class="form-group clr-row" *ngIf="showUsers">
                <div class="clr-col-2 col-title">
                    <label for="user">User</label>
                </div>
                <div class="clr-col-9">
                    <div class="select">
                        <clr-select-container>
                            <select clrSelect id="user" class="form-select" [formControl]="itemForm.controls.user_id" name="user_id" (ngModel)="item.user_id" *ngIf="users" (ngModelChange)="userChanged($event)">
                                <option *ngFor="let user of users" [value]="user.id">
                                 {{user.name}}
                                </option>
                            </select>
                        </clr-select-container>
                    </div>
                </div>
            </div>
-->
            <div class="form-group clr-row">
                <div class="clr-col-2 col-title">
                </div>
                <div class="clr-col-9 col-title">
                    <div class="checkbox-inline">
                          <clr-checkbox-wrapper>
                             <input id="period_active" clrCheckbox type="checkbox" [formControl]="itemForm.controls.active" name="active" (ngModel)="active">
                             <label for="period_active">Active ({{itemForm.controls.active.value ? 'True' :
                                'False'}})</label>
                          </clr-checkbox-wrapper>

                    </div>
                </div>
            </div>
            <hr>
                <div class="form-group clr-row week">
                        <div class="clr-col-2 col-title">
                        </div>
                        <div class="clr-col-2">
                           <clr-checkbox-wrapper>
                                <input id="mon" clrCheckbox type="checkbox" (click)="dayChanged('mon')" [formControl]="itemForm.controls.mon" name="mon" (ngModel)="mon">
                                <label>Monday</label>
                           </clr-checkbox-wrapper>
                        </div>
                        <div class="clr-col-1">
                            <label for="mon_from">From</label>
                        </div>
                        <div class="clr-col-2">

                        <input id="mon_from"
                        [disabled]="!isActive('mon')"
                        size="8"
                        type="text"
                        [value]="itemForm.controls.mon_from"
                        [formControl]="itemForm.controls.mon_from"
                        mode="time"
                        [theme]="'dp-material'"
                        [dpDayPicker]="timeIntervalConfig"
                        (ngModelChange)="checkDirty('mon_from')"/>

                        </div>

                        <div class="clr-col-1">
                            <label for="mon_to">To</label>
                        </div>
                        <div class="clr-col-2">

                    <input id="mon_to"
                        [disabled]="!isActive('mon')"
                        size="8"
                        type="text"
                        [value]="itemForm.controls.mon_to"
                        [formControl]="itemForm.controls.mon_to"
                        mode="time"
                        [theme]="'dp-material'"
                        [dpDayPicker]="timeIntervalConfig"
                        (ngModelChange)="checkDirty('mon_to')"/>
                        </div>
                    </div>
                <div class="form-group clr-row week">
                        <div class="clr-col-2 col-title">
                        </div>
                        <div class="clr-col-2">
                         <clr-checkbox-wrapper>
                                <input id="tue" clrCheckbox type="checkbox" (click)="dayChanged('tue')" [formControl]="itemForm.controls.tue" name="tue" (ngModel)="tue">
                                <label>Tuesday</label>
                         </clr-checkbox-wrapper>
                    </div>
                    <div class="clr-col-1">
                        <label for="tue_from">From</label>
                    </div>
                    <div class="clr-col-2">
                        <input id="tue_from"
                               type="text"
                               formControlName="tue_from"
                               mode="time"
                               [theme]="'dp-material'"
                               [dpDayPicker]="timeIntervalConfig"
                               size="8">
                    </div>
                    <div class="clr-col-1">
                        <label for="tue_to">To</label>
                    </div>
                    <div class="clr-col-2">
                        <input id="tue_to"
                               type="text"
                               formControlName="tue_to"
                               mode="time"
                               [theme]="'dp-material'"
                               [dpDayPicker]="timeIntervalConfig"
                               size="8">
                    </div>
                </div>
                <div class="form-group clr-row week">
                    <div class="clr-col-2 col-title">
                    </div>
                    <div class="clr-col-2">
                        <clr-checkbox-wrapper>
                                <input id="wed" clrCheckbox type="checkbox" (click)="dayChanged('wed')" [formControl]="itemForm.controls.wed" name="wed" (ngModel)="wed">
                                <label>Wednesday</label>
                        </clr-checkbox-wrapper>
                    </div>
                    <div class="clr-col-1">
                        <label for="wed_from">From</label>
                    </div>
                    <div class="clr-col-2">
                        <input id="wed_from"
                               type="text"
                               formControlName="wed_from"
                               mode="time"
                               [theme]="'dp-material'"
                               [dpDayPicker]="timeIntervalConfig"
                               size="8">
                    </div>
                    <div class="clr-col-1">
                        <label for="wed_to">To</label>
                    </div>
                    <div class="clr-col-2">
                        <input id="wed_to"
                               type="text"
                               formControlName="wed_to"
                               mode="time"
                               [theme]="'dp-material'"
                               [dpDayPicker]="timeIntervalConfig"
                               size="8">
                    </div>
                </div>
                <div class="form-group clr-row week">
                    <div class="clr-col-2 col-title">
                    </div>
                    <div class="clr-col-2">
                        <clr-checkbox-wrapper>
                                <input id="thu" clrCheckbox type="checkbox" (click)="dayChanged('thu')" [formControl]="itemForm.controls.thu" name="thu" (ngModel)="thu">
                                <label>Thursday</label>
                        </clr-checkbox-wrapper>
                    </div>
                    <div class="clr-col-1">
                        <label for="thu_from">From</label>
                    </div>
                    <div class="clr-col-2">
                        <input id="thu_from"
                               type="text"
                               formControlName="thu_from"
                               mode="time"
                               [theme]="'dp-material'"
                               [dpDayPicker]="timeIntervalConfig"
                               size="8">
                    </div>
                    <div class="clr-col-1">
                        <label for="thu_to">To</label>
                    </div>
                    <div class="clr-col-2">
                        <input id="thu_to"
                               type="text"
                               formControlName="thu_to"
                               mode="time"
                               [theme]="'dp-material'"
                               [dpDayPicker]="timeIntervalConfig"
                               size="8">
                    </div>
                </div>
                <div class="form-group clr-row week">
                    <div class="clr-col-2 col-title">
                    </div>
                    <div class="clr-col-2">
                        <clr-checkbox-wrapper>
                                <input id="fri" clrCheckbox type="checkbox" (click)="dayChanged('fri')" [formControl]="itemForm.controls.fri" name="fri" (ngModel)="fri">
                                <label>Friday</label>
                        </clr-checkbox-wrapper>
                    </div>
                    <div class="clr-col-1">
                        <label for="fri_from">From</label>
                    </div>
                    <div class="clr-col-2">
                        <input id="fri_from"
                               type="text"
                               formControlName="fri_from"
                               mode="time"
                               [theme]="'dp-material'"
                               [dpDayPicker]="timeIntervalConfig"
                               size="8">
                    </div>
                    <div class="clr-col-1">
                        <label for="fri_to">To</label>
                    </div>
                    <div class="clr-col-2">
                        <input id="fri_to"
                               type="text"
                               formControlName="fri_to"
                               mode="time"
                               [theme]="'dp-material'"
                               [dpDayPicker]="timeIntervalConfig"
                               size="8">
                    </div>
                </div>
                <div class="form-group clr-row week">
                    <div class="clr-col-2 col-title">
                    </div>
                    <div class="clr-col-2">
                        <clr-checkbox-wrapper>
                                <input id="sat" clrCheckbox type="checkbox" (click)="dayChanged('sat')" [formControl]="itemForm.controls.sat" name="sat" (ngModel)="sat">
                                <label>Saturday</label>
                        </clr-checkbox-wrapper>
                    </div>
                    <div class="clr-col-1">
                        <label for="sat_from">From</label>
                    </div>
                    <div class="clr-col-2">
                        <input id="sat_from"
                               type="text"
                               formControlName="sat_from"
                               mode="time"
                               [theme]="'dp-material'"
                               [dpDayPicker]="timeIntervalConfig"
                               size="8">
                    </div>
                    <div class="clr-col-1">
                        <label for="sat_to">To</label>
                    </div>
                    <div class="clr-col-2">
                        <input id="sat_to"
                               type="text"
                               formControlName="sat_to"
                               mode="time"
                               [theme]="'dp-material'"
                               [dpDayPicker]="timeIntervalConfig"
                               size="8">
                    </div>
                </div>
                <div class="form-group clr-row week">
                    <div class="clr-col-2 col-title">
                    </div>
                    <div class="clr-col-2">
                        <clr-checkbox-wrapper>
                                <input id="sun" clrCheckbox type="checkbox" (click)="dayChanged('sun')" [formControl]="itemForm.controls.sun" name="sun" (ngModel)="sun">
                                <label>Sunday</label>
                        </clr-checkbox-wrapper>
                    </div>
                    <div class="clr-col-1">
                        <label for="sun_from">From</label>
                    </div>
                    <div class="clr-col-2">
                        <input id="sun_from"
                               type="text"
                               formControlName="sun_from"
                               mode="time"
                               [theme]="'dp-material'"
                               [dpDayPicker]="timeIntervalConfig"
                               size="8">
                    </div>
                    <div class="clr-col-1">
                        <label for="sun_to">To</label>
                    </div>
                    <div class="clr-col-2">
                        <input id="sun_to"
                               type="text"
                               formControlName="sun_to"
                               mode="time"
                               [theme]="'dp-material'"
                               [dpDayPicker]="timeIntervalConfig"
                               size="8">
                    </div>
                </div>

               <div class="clr-col-row">
                    <div class="clr-col-1 clr-offset-9 button-position">
                            <button *ngIf="item"
                               class="btn btn-primary update-button"
                               [disabled]="itemForm.pristine || itemForm.invalid"
                                (click)="update()"
                                >
                                {{ item.id > 0 ? 'Update' : 'Save' }}
                            </button>
                            <button *ngIf="!item"
                               class="btn btn-primary update-button"
                               [disabled]="itemForm.pristine || itemForm.invalid"
                                (click)="update()"
                                >
                                Save
                            </button>
                    </div>
               </div>


        </section>
    </form>
</div>

