import { ActivatedRoute, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { BaseService } from '@core/services/base.service';
import { constants } from '@core/constants';
import { HttpClient } from '@angular/common/http';
import { EventsService } from '@modules/events/services/events.service';
import { UserSessionState } from '@core/stores/user-session/states/user-session.states';
import { UserSessionActionTypes } from '@core/stores/user-session/actions/user-session.actions';
import { UserSessionService } from '@core/services/user-session/user-session.service';
import { remove, set_item } from '@shared/utils/storage/encrypted_storage';

const initialData = { authenticated: true } as IAuth;

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseService {
  public authenticated: boolean;
  protected authSource = new BehaviorSubject<IAuth>(initialData);
  public data = this.authSource.asObservable();

  constructor(
    public userSessionService: UserSessionService,
    protected eventsService: EventsService,
    protected http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    protected sessionStore: Store<{ uss: UserSessionState }>
  ) {
    super(eventsService, http);
  }

  login(username: string, password: string) {
    let payld = { authenticated: false, session: {}, action: 'start_login' };
    this.sessionStore.dispatch({
      type: UserSessionActionTypes.StartLogin,
      payload: payld
    });

    return this.post(constants.api.auth.authenticate, {
      username,
      password
    })
      .pipe(
        mergeMap((res: IAuthResponse) =>
          this.get(constants.api.user_session.v2_url)
        )
      )
      .subscribe(response => {
        if (response.is_authenticated) {
          console.log(`Successfully authenticated user ${username}`);
          response['action'] = 'user_session';
          response['last_route'] = localStorage.getItem('backto');
          this.userSessionService._userSession.next(response);
          this.sessionStore.dispatch({
            type: UserSessionActionTypes.CreateSession,
            payload: response
          });
          set_item('session', response);
          this.authSource.next({ authenticated: true });
          if (response['last_route']) {
            this.router.navigate([response['last_route']]);
          } else {
            this.router.navigate([response.settings.route]);
          }
        } else {
          console.log(
            `Failed validating user ${username} - ${JSON.stringify(response)}`
          );
          let payload = {
            authenticated: false,
            session: response,
            action: 'failed_login'
          };
          this.userSessionService._userSession.next(response);
          this.sessionStore.dispatch({
            type: UserSessionActionTypes.FailedLogin,
            payload: payload
          });
          localStorage.setItem('session', null);
          this.authSource.next({ authenticated: false });
          this.router.navigate(['/login']);
        }
      });
  }

  logout() {
    return this.get(constants.api.auth.logout).pipe(
      finalize(() => {
        set_item('backto', null);
        set_item('session', null);
        remove('backto');
        remove('session');
        this.authSource.next({ authenticated: false });
      })
    );
  }

  outputError(error): Observable<any> {
    console.log(`Failed calling API - ${JSON.stringify(error)}`);
    return throwError(error);
  }

  sendRecoveryLink(email: string) {
    return this.http
      .post(constants.api.login.recover, JSON.stringify({ email }))
      .pipe(
        map((res: any) => <any>res),
        catchError(error => this.outputError(error))
      );
  }

  validateRecoveryLink(token: string) {
    return this.http.get(`${constants.api.login.recover}/${token}`).pipe(
      map((res: any) => <any>res),
      catchError(error => this.outputError(error))
    );
  }

  sendWelcomeRecoveryLink(email: string) {
    return this.http
      .post(constants.api.login.welcome, JSON.stringify({ email }))
      .pipe(
        map((res: any) => <any>res),
        catchError(error => this.outputError(error))
      );
  }

  validateWelcomeLink(token: string) {
    return this.http.get(`${constants.api.login.welcome}/${token}`).pipe(
      map((res: any) => <any>res),
      catchError(error => this.outputError(error))
    );
  }

  resetPassword(password: string, token: string) {
    return this.http.post(constants.api.login.reset, { password, token }).pipe(
      map(
        (res: any) => this.handleResult(res),
        catchError(error => this.outputError(error))
      )
    );
  }

  handleResult(res) {
    console.log('Upon return from user recovery ' + `${JSON.stringify(res)}`);

    if (!res.is_error) {
      this.authenticated = true;
      set_item('backto', 'admin/data-analysis/control-center');
    }
  }
}

export interface IAuth {
  authenticated: boolean;
}

export interface IAuthResponse {
  is_error: boolean;
}
