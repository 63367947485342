import { HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import {
  IUserSession,
  UserSessionService
} from '@core/services/user-session/user-session.service';
import { EventsService } from '@modules/events/services/events.service';
import { AuthService } from '@core/services/auth/auth.service';
import { Store } from '@ngrx/store';
import { UserSessionState } from '@core/stores/user-session/states/user-session.states';
import { set_item, get_item } from '@shared/utils/storage/encrypted_storage';

@Component({
  providers: [UserSessionService, EventsService, AuthService],
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  protected idleState = 'Not started.';
  protected notified: boolean;
  protected timedOut = false;
  protected lastPing?: Date = null;
  protected title = 'angular-idle-timeout';
  public componentDestroyed: Subject<any> = new Subject();
  public userSession: IUserSession;
  public loading = true;
  public refreshComponentSubscription: Subscription;
  public deleteEventSubscription: Subscription;
  public session_expired: boolean = false;

  protected logoutWarning = {
    title: 'Exiting!',
    text: 'Your session has expired!',
    type: 'error'
  };

  protected expiringWarning = {
    title: 'Warning!',
    text: 'You will be logged out soon!',
    type: 'warning'
  };

  protected countdownWarning = {
    title: 'Warning!',
    text: 'You will be logged out soon!',
    type: 'warning'
  };

  protected resumedSession = {
    title: 'Success',
    text: 'Welcome back!',
    type: 'success'
  };

  @HostListener('click') onClick() {
    this.idleState = 'No longer idle.';
    this.notified = false;
    console.log(this.idleState);
    this.reset();
    console.log('User Click using Host Listener');
  }

  @HostListener('change') onChange() {
    this.idleState = 'No longer idle.';
    this.notified = false;
    console.log(this.idleState);
    this.reset();
    console.log('User change using Host Listener');
  }

  constructor(
    protected idle: Idle,
    protected keepalive: Keepalive,
    protected eventsService?: EventsService,
    protected sessionStore?: Store<{ uss: UserSessionState }>,
    protected router?: Router,
    protected route?: ActivatedRoute
  ) {
    this.notified = false;
    this.session_expired = false;
  }

  monitorSession() {
    this.notified = false;
    this.sessionStore.select('uss').subscribe(event => {
      if (event['payload']['action'] === 'user_session') {
        this.userSession = event['payload'];
        console.log(
          `In Admin Component session was ${JSON.stringify(this.userSession)}`
        );
        /*
                let  last_route = localStorage.getItem('backto');

                if (last_route) {
                    this.router.navigate([last_route]);
                }
*/
        let timeout = parseInt(`${this.userSession.timeout}`);

        // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
        this.idle.setIdle(timeout);
        this.idle.setTimeout(10);

        console.log(
          `If you go idle this session will expire in ${this.userSession.timeout} seconds`
        );
        // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        this.idle.onIdleEnd.subscribe(() => {
          this.idleState = 'No longer idle.';
          this.notified = false;
          console.log(this.idleState);
          this.reset();
          this.eventsService.showToast(this.resumedSession);
        });

        this.idle.onTimeout.subscribe(() => {
          this.idleState = 'You are timed out!';
          this.timedOut = true;
          console.log(this.idleState);
          set_item('session', null);
          set_item('backto', null);
          this.eventsService.forceLogout();
          //this.eventsService.authservice().logout().subscribe();
          this.session_expired = true;
        });

        this.idle.onIdleStart.subscribe(() => {
          this.idleState = "You've gone idle!";
          this.notified = false;
          //this.eventsService.showToast(this.expiringWarning);
          console.log(this.idleState);
        });

        this.idle.onTimeoutWarning.subscribe(countdown => {
          this.idleState = 'You will time out in ' + countdown + ' seconds!';
          console.log(this.idleState);

          if (!this.notified) {
            this.countdownWarning.text =
              'You will be logged out in ' + countdown + ' seconds!';
            this.eventsService.showToast(this.countdownWarning);
            this.notified = true;
          }
          if (countdown === 1) {
            this.eventsService.showToast(this.logoutWarning);
          }
        });
        // sets the ping interval to 15 seconds
        this.keepalive.interval(15);
        this.keepalive.onPing.subscribe(() => (this.lastPing = new Date()));
        this.reset();
      }
    });
  }

  ngOnInit() {
    let route_back = localStorage.getItem('backto');
    if (route_back) {
      if (
        !route_back.includes('recover') &&
        !route_back.includes('reset') &&
        !route_back.includes('welcome') &&
        !route_back.includes('login')
      ) {
        console.log(`Trying to redirect to ${route_back}`);
        this.router.navigate([route_back]);
      }
    }
  }

  ngAfterViewInit() {}

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
    console.log('BaseComponent Destroy');
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  wait(ms) {
    var start = new Date().getTime();
    var end = start;
    while (end < start + ms) {
      end = new Date().getTime();
    }
  }

  notEmpty(e) {
    if (e === null) {
      return false;
    }

    if (typeof e === 'undefined') {
      return false;
    }

    return true;
  }

  saveRoute(): void {
    set_item('last_visited', this.router.url);
  }
}
