import Moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { BaseComponent } from '@shared/components/base/base.component';
import { ITicketType } from '@modules/ticketing/models/ticket-type.model';
import { ITicketPriority } from '@modules/ticketing/models/ticket-priority.model';
import { ITicketStatus } from '@modules/ticketing/models/ticket-status.model';
import { IUser } from '@shared/interfaces/users';
import { ITicket } from '@modules/ticketing/models/ticket.model';
import { UserProfileService } from '@modules/users/services/user-profile.service';
import { UserService } from '@core/services/user/user.service';
import {
  ITicketNote,
  TicketNoteDefault
} from '@modules/ticketing/models/ticket-note.model';
import { TicketService } from '@modules/ticketing/services/ticket.service';
import { EventsService } from '@modules/events/services/events.service';
import { FieldService } from '@core/services/field/field.service';
import { UserSessionState } from '@core/stores/user-session/states/user-session.states';
import { get_item, set_item } from '@shared/utils/storage/encrypted_storage';

@Component({
  providers: [EventsService, TicketService, FieldService, UserService],
  selector: 'app-ticket-details',
  templateUrl: './ticket-details.component.html',
  styleUrls: ['./ticket-details.component.scss']
})
export class TicketDetailsComponent extends BaseComponent
  implements OnInit, OnDestroy, AfterViewInit {
  header = 'Ticket Detail - Edit';
  public statuses = [
    { id: 1, name: 'open' },
    { id: 2, name: 'archived' },
    { id: 3, name: 'closed' }
  ];
  public types = [
    { id: 1, name: 'Maintenance Repair' },
    { id: 2, name: 'Vendor Repair' },
    { id: 3, name: 'Notice' }
  ];
  public description: string;
  private baseRoute;
  private returnUrl = '/admin/tickets/list';
  private newItemUrl = '/admin/tickets/';
  private routeSub: Subscription;
  private item_id: number = 0;
  private is_new: boolean = false;
  public author: string;
  public created: string;
  public username: string;
  public item: ITicket;
  noteItem: ITicketNote;
  fields: any;
  users: IUser[];
  ticketTypes: ITicketType[];
  ticketPriorities: ITicketPriority[];
  ticketStatuses: ITicketStatus[];
  itemForm = new FormGroup({
    event: new FormControl(),
    author: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(50)
    ]),
    status_id: new FormControl('', Validators.required),
    priority_id: new FormControl('', Validators.required),
    type_id: new FormControl('', Validators.required),
    description: new FormControl(),
    subject: new FormControl(),
    active: new FormControl()
  });
  noteForm = new FormGroup({
    subject: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(255)
    ]),
    description: new FormControl('', Validators.required)
  });
  private errorMessage: string;
  private noteSuccessToast = {
    title: 'Success!',
    text: 'Note saved successfully!',
    type: 'success'
  };
  private noteErrorToast = {
    title: 'Error!',
    text: 'Failed to save Note!',
    type: 'error'
  };
  private successToast = {
    title: 'Success!',
    text: 'Ticket updated successfully!',
    type: 'success'
  };
  private errorToast = {
    title: 'Error!',
    text: 'Failed to update Ticket!',
    type: 'error'
  };

  constructor(
    protected idle: Idle,
    protected keepalive: Keepalive,
    protected sessionStore: Store<{ uss: UserSessionState }>,
    protected route: ActivatedRoute,
    protected fieldService: FieldService,
    protected ticketService: TicketService,
    protected eventsService: EventsService,
    protected userService: UserService,
    protected userProfileService: UserProfileService,
    protected router: Router
  ) {
    super(idle, keepalive, eventsService, sessionStore, router);
  }

  ngOnDestroy(): void {
    if (typeof this.routeSub !== 'undefined' && this.routeSub !== null) {
      this.routeSub.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    this.saveRoute();
  }

  ngOnInit(): void {
    this.baseRoute = this.userProfileService.settings['route'];

    this.reloadPage();
    this.routeSub = this.route.params.subscribe(params => {
      this.item_id = params['id'];
      if (this.item_id < 1) {
        this.item = this.getBlankItem();
        this.header = 'Add Ticket';
        this.is_new = true;
      } else {
        this.ticketService.getTicket(this.item_id).subscribe(item => {
          this.item = item['item'];
          this.header = 'Ticket Detail - Edit';
        });
      }

      this.readProfile();
    });

    /*

        this.fields = this.route.snapshot.data['fields'];
        this.ticketTypes = this.route.snapshot.data['ticketTypes'];
        this.ticketPriorities = this.route.snapshot.data['ticketPriorities'];
        this.ticketStatuses = this.route.snapshot.data['ticketStatuses'];
        this.users = this.route.snapshot.data['users'];
        if (this.item.id === 0) {
            this.header = 'Add Ticket';
            this.item.author = this.userProfileService.username;
            this.itemForm.controls['subject'].setValidators([Validators.required, Validators.minLength(3), Validators.maxLength(255)]);
            this.itemForm.controls['description'].setValidators([Validators.required]);

        } else {
            if (this.item.event_id) {
                this.itemForm.controls['subject'].disable();
                this.itemForm.controls['description'].disable();
            }
            this.setupNewNote();
        }
        this.itemForm.patchValue(this.item);
*/
  }

  readProfile() {
    this.userProfileService.getUserProfile().subscribe(userProfile => {
      this.item.created = this.formatDate(new Date());
      if (this.item_id < 1) {
        this.item.author = userProfile.username;
      }
      this.username = userProfile.username;
      this.item.notes.sort((a, b) => a.id - b.id);
      this.noteItem = JSON.parse(JSON.stringify(TicketNoteDefault.item));
      this.readFields();
    });
  }

  readFields() {
    this.fieldService.getFields().subscribe(data => {
      this.fields = data;
      this.readTicketStatuses();
    });
  }
  readTicketTypes() {
    this.ticketService.getAllTicketTypesAsOptions().subscribe(response => {
      if (response.is_error) {
        this.ticketTypes = this.types;
      } else {
        this.ticketTypes = response.item_list;
      }

      this.readUsers();
    });
  }

  readPriorities() {
    this.ticketService.getAllTicketPrioritiesAsOptions().subscribe(data => {
      this.ticketPriorities = data;
    });
    this.readTicketTypes();
  }

  readTicketStatuses() {
    this.ticketService.getAllTicketStatusesAsOptions().subscribe(response => {
      if (response.is_error) {
        this.ticketStatuses = this.statuses;
      } else {
        this.ticketStatuses = response.item_list;
      }
    });
    this.readPriorities();
  }

  readUsers() {
    this.userService.getAllUsers().subscribe(data => {
      this.users = data['item_list'];
      this.finalizeData();
    });
  }

  finalizeData() {
    if (this.item.id === 0) {
      this.header = 'Add Ticket';
      this.itemForm.controls['subject'].setValidators([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(255)
      ]);
      this.itemForm.controls['description'].setValidators([
        Validators.required
      ]);
    } else {
      if (this.item.event_id) {
        this.itemForm.controls['subject'].disable();
        this.itemForm.controls['description'].disable();
      }
      this.setupNewNote();
    }
    this.item.created = Moment().format('MM/DD/YYYY hh:mm:ss');
    this.item.updated = Moment().format('MM/DD/YYYY hh:mm:ss');
    this.created = this.item.created;
    this.author = this.item.author;
    this.patchForm(this.item);
  }

  patchForm(item) {
    this.itemForm.patchValue(item);
    this.noteForm.controls['description'].setValue('');
    this.itemForm.controls['priority_id'].setValue(
      this.getByName(this.ticketPriorities, 'Low')
    );
    this.itemForm.controls['status_id'].setValue(
      this.getByName(this.ticketStatuses, 'open')
    );
    this.itemForm.controls['type_id'].setValue(
      this.getByName(this.ticketTypes, 'Notice')
    );
  }

  getByName(collection, key) {
    for (let i = 0; i < collection.length; i++) {
      if (collection[i].name.toLowerCase() === key.toLowerCase()) {
        return collection[i].id;
      }
    }
    return collection[0].id;
  }

  update() {
    if (this.item.id < 1) {
      this.item.event = null;
      delete this.item.event_id;
    }
    const updateItem = Object.assign({}, this.item, this.itemForm.value);
    this.ticketService.saveTicket(updateItem).subscribe(
      item => this.onItemUpdated(item),
      error => (this.errorMessage = <any>error)
    );
  }

  onItemUpdated(data: any): void {
    if (data.is_error) {
      this.errorToast.text = data.message;
      this.eventsService.showToast(this.errorToast);
    } else {
      this.eventsService.showToast(this.successToast);
      if (this.is_new) {
        this.header = 'Ticket Detail - Edit';
        this.item = data.item;

        this.router.navigate([`${this.newItemUrl}/${this.item.id}`]);
      } else {
        this.router.navigate([`${this.returnUrl}`]);
      }
    }
  }

  updateItem(item: ITicket) {
    this.item = item;
  }

  assignedUsers() {
    let usersString = '';
    if (this.item.assigned_users) {
      this.item.assigned_users.forEach((user, idx) => {
        usersString += user.name;
        if (idx + 1 < this.item.assigned_users.length) {
          usersString += ', ';
        }
      });
    }
    return usersString;
  }

  sendNote() {
    this.noteItem.sent_user_ids = this.item.assigned_users.map(u => u.id);
    this.noteItem.ticket_id = this.item.id;
    const noteUpdateItem = Object.assign(
      {},
      this.noteItem,
      this.noteForm.value
    );

    console.log(noteUpdateItem);
    this.ticketService.addTicketNote(noteUpdateItem).subscribe(
      item => this.onNoteAdded(item),
      error => (this.errorMessage = <any>error)
    );
  }

  onNoteAdded(data: any): void {
    if (data.is_error) {
      this.noteErrorToast.text = data.message;
      this.eventsService.showToast(this.noteErrorToast);
    } else {
      this.eventsService.showToast(this.noteSuccessToast);
      this.updateItemViaAux();
    }
  }

  toggleUserMenu() {
    let id = 'openTicketUserMenu';
    this.eventsService.publish(id, 'openTicketUserMenu', {
      module: 'ticketing',
      action: 'open'
    });
  }

  updateItemViaAux() {
    this.readProfile();
    this.ticketService.getTicket(this.item.id).subscribe(
      res => {
        this.item = res['item'];
        this.item.notes.sort((a, b) => a.id - b.id);
        this.setupNewNote();
      },
      error => (this.errorMessage = <any>error)
    );
  }

  setupNewNote() {
    let newSubject = this.item.subject;
    if (this.item.notes) {
      const lastNote = this.item.notes[this.item.notes.length - 1];
      if (lastNote) {
        newSubject = lastNote.subject;
      }
    }

    this.noteForm.controls['subject'].setValue(`Re: ${newSubject}`);
    this.noteForm.controls['description'].setValue('');
  }

  formatDate(date) {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  getBlankItem() {
    return {
      id: 0,
      description: '',
      subject: '',
      status_id: 1,
      priority_id: 1,
      priority: '',
      event: null,
      type_id: 1,
      assigned_users: [],
      notes: []
    };
  }

  reloadPage(): void {
    let visited3d = get_item('visited3d');
    if (typeof visited3d !== 'undefined' && visited3d !== null) {
      window.location.reload(true);
      set_item('visited3d', null);
    }
  }
}
