import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '@core/services/base.service';
import { constants } from '@core/constants';
import { TriggerPoint } from '@modules/eventmanagement/models/trigger-point.model';
import { TriggerPointProfile } from '@modules/eventmanagement/models/trigger-point-profile.model';
import { EventsService } from '@modules/events/services/events.service';

@Injectable()
export class TriggerPointService extends BaseService {
  private serviceApiUrl = constants.api.trigger_point;

  constructor(
    protected eventsService: EventsService,
    protected http: HttpClient
  ) {
    super(eventsService, http);
  }

  getAllTriggerPoints() {
    return this.getAll(this.serviceApiUrl.url);
  }

  getTriggerPoint(id: number) {
    return this.getOne(this.serviceApiUrl.url, id);
  }

  getTriggerPoints(filters) {
    return this.getMany(this.serviceApiUrl.url, filters);
  }

  saveTriggerPoint(triggerPoint: TriggerPoint) {
    return this.saveOne(this.serviceApiUrl.url, triggerPoint);
  }

  deleteTriggerPoint(id: number) {
    return this.deleteOne(this.serviceApiUrl.url, id);
  }

  getTriggerPointProfile(id: number) {
    return this.getOne(this.serviceApiUrl.profile.url, id);
  }

  getTriggerPointProfiles(filters) {
    return this.getMany(this.serviceApiUrl.profile.url, filters);
  }

  saveTriggerPointProfile(triggerPointProfile: TriggerPointProfile) {
    return this.saveOne(this.serviceApiUrl.profile.url, triggerPointProfile);
  }

  deleteTriggerPointProfile(id: number) {
    return this.deleteOne(this.serviceApiUrl.profile.url, id);
  }

  addTriggerPointTriggerPointProfile(payload) {
    return this.saveOne(this.serviceApiUrl.profile.add_api_url, payload);
  }

  deleteTriggerPointTriggerPointProfile(payload) {
    return this.saveOne(this.serviceApiUrl.profile.remove_api_url, payload);
  }

  addTriggerPointContact(payload) {
    return this.saveOne(this.serviceApiUrl.contact.add_api_url, payload);
  }
  deleteTriggerPointContact(payload) {
    return this.saveOne(this.serviceApiUrl.contact.remove_api_url, payload);
  }

  getTriggerPointProfileActions(filters) {
    return this.getMany(this.serviceApiUrl.profile.action.url, filters);
  }

  addTriggerPointProfileAction(payload) {
    return this.saveOne(this.serviceApiUrl.profile.action.add_api_url, payload);
  }

  removeTriggerPointProfileAction(payload) {
    return this.saveOne(
      this.serviceApiUrl.profile.action.remove_api_url,
      payload
    );
  }

  addTriggerPointDataView(payload) {
    return this.saveOne(this.serviceApiUrl.data_view.add_api_url, payload);
  }
  deleteTriggerPointDataView(payload) {
    return this.saveOne(this.serviceApiUrl.data_view.remove_api_url, payload);
  }
}
