import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '@shared/components/base/base.component';
import { takeUntil } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { IUserSession } from '@core/services/user-session/user-session.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '@core/services/auth/auth.service';
import { UserSessionService } from '@core/services/user-session/user-session.service';
import { EventsService } from '@modules/events/services/events.service';
import { Store } from '@ngrx/store';
import { EventsState } from '@modules/events/states/events.state';
import { UserSessionState } from '@core/stores/user-session/states/user-session.states';
import { Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Router } from '@angular/router';
import { set_item, get_item } from '@shared/utils/storage/encrypted_storage';

@Component({
  providers: [UserSessionService, EventsService],
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent extends BaseComponent
  implements OnInit, OnDestroy {
  public avatarImage: any;
  public userSession: any;
  public full_name?: string;
  public isOpen: boolean = false;
  public openUserPreferences: boolean = false;
  private eventSubs: Subscription;

  constructor(
    protected idle: Idle,
    protected keepalive: Keepalive,
    protected eventsService: EventsService,
    protected eventStore: Store<{ nf: EventsState }>,
    protected sessionStore: Store<{ uss: UserSessionState }>,
    protected router: Router,
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    protected userSessionService: UserSessionService
  ) {
    super(idle, keepalive, eventsService, sessionStore, router);

    this.sessionStore.select('uss').subscribe(event => {
      if (
        event['payload']['action'] === 'user_session' &&
        event['payload'].is_authenticated
      ) {
        this.userSession = event['payload'];
      }
    });

    this.userSessionService.data
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((userSession: IUserSession) => {
        if (userSession.avatarUrl) {
          this.avatarImage = this.sanitizer.bypassSecurityTrustStyle(
            `background-image: url(${userSession.avatarUrl})`
          );
        }
      });
  }

  ngOnInit(): void {
    this.sessionStore.select('uss').subscribe(event => {
      if (
        event['payload']['action'] === 'user_session' &&
        event['payload'].is_authenticated
      ) {
        this.userSession = event['payload'];
      }
    });

    if (this.userSession.name && !this.userSession.is_authenticated) {
      console.log('It is time to refresh session');

      let session = get_item('session');

      if (session && session.is_authenticated) {
        this.userSession = session;
        this.full_name = this.userSession.name;
      }
    } else if (this.userSession.name) {
      this.full_name = this.userSession.name;
    }
    this.subscribeEvents();
  }

  subscribeEvents() {
    this.eventSubs = this.eventStore.select('nf').subscribe(event => {
      if (event.items.id === 'closeUserPreferencesModal') {
        this.openUserPreferences = false;
      }
      if (event.items.id === 'updateFullName') {
        this.full_name = event.items.payload.full_name;
        let session = get_item('session');
        session.name = this.full_name;
        this.userSession = session;
        set_item('session', session);
        let id = 'redirectToDefault';
        this.eventsService.publish(id, 'redirectToDefault', {
          session: session
        });
      }
    });
  }

  showUserPreferences() {
    this.openUserPreferences = true;
    let id = 'openUserPreferencesModal';
    this.eventsService.publish(id, 'openUserPreferencesModal', {
      module: 'shared',
      userSession: this.userSession,
      action: 'openUserPreferencesModal',
      additionalInfo: ''
    });
  }

  logout(): void {
    console.log('Logging out ===> ');
    this.authService.logout().subscribe(_ => {
      console.log('Will try logging out ...===>');
      this.router.navigate(['/login']);
    });
  }

  checkOpen(e) {}

  hideBreadcrumb() {
    this.isOpen = true;
    let elements = document.querySelectorAll<HTMLElement>('.header-actions')!;
    if (elements && elements[0]) {
      elements[0].style.zIndex = '10000000';
    }
    //        let elements1 = document.querySelectorAll<HTMLElement>('.subheader-wrapper')!;
    //        if (elements1 && elements1[0]) {
    //             elements1[0].style.zIndex = '-1';
    //       }

    let elements2 = document.querySelectorAll<HTMLElement>('.add-folder')!;
    if (elements2 && elements2[0]) {
      elements2[0].style.zIndex = '-1';
    }
  }

  close(event) {}

  onChange() {
    this.isOpen = !this.isOpen;
  }

  ngOnDestroy(): void {
    if (typeof this.eventSubs !== 'undefined' && this.eventSubs !== null) {
      this.eventSubs.unsubscribe();
    }
  }
}
