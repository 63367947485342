<form *ngIf="item" [formGroup]="itemForm" class="pad-r24">
<!--
<div class="clr-row config-wrapper">
   <div class="clr-col-2">&nbsp;&nbsp;
      Calculation Name
   </div>
   <div class="clr-col-2"></div>
   <div class="clr-col-2">&nbsp;&nbsp;
      Overall Calculations 
   </div>
</div>
-->
<div class="clr-row config-wrapper">


   <div class="clr-col-2 adjust-top adjust-left">&nbsp;&nbsp;
      Calc.Name
   </div>
   <div class="clr-col-2 adjust-top">
        <clr-input-container>
            <input clrInput type="text" [(ngModel)]="name" formControlName="name" name="name" size="10"  (ngModelChange)="updateName($event)"/>
            <clr-control-error *clrIfError="'minlength'">Must be at least 3 characters</clr-control-error>
            <clr-control-error *clrIfError="'required'">Name Is Required</clr-control-error>
        </clr-input-container>
   </div>
   <div class="clr-col-2 adjust-top">&nbsp;&nbsp;
       Calculation
   </div>
   <div class="clr-col-2 adjust-top">
        <clr-input-container>
            <input clrInput type="text" [(ngModel)]="computation_type" formControlName="computation_type" name="computation_type" size="10"/>
            <clr-control-error *clrIfError="'minlength'">Must be at least 3 characters</clr-control-error>
            <clr-control-error *clrIfError="'required'">Name Is Required</clr-control-error>
        </clr-input-container>
   </div>
   <div class="clr-col-2">
      <button type="button" class="btn btn-sm modbus-btn" (click)="add()">Add</button>
   </div>

   <div class="dg-wrapper">
    <clr-datagrid [(clrDgSelected)]="rowSelected">
      <clr-dg-column>Name</clr-dg-column>
      <clr-dg-column>Type</clr-dg-column>
      <clr-dg-column></clr-dg-column>
      <clr-dg-row *clrDgItems="let item of computation_items" [clrDgItem]="item" class="row">
              <clr-dg-cell>{{ item.property }}</clr-dg-cell>
              <clr-dg-cell>{{ readConfig(item.value) }}</clr-dg-cell>
              <clr-dg-cell>
                  <button type="button" class="btn btn-sm config-btn" (click)="deleteItem(item)">Delete</button>
                  <button type="button" class="btn btn-sm config-last-btn" (click)="editItem(item)">Edit</button>
              </clr-dg-cell>
      </clr-dg-row>
    </clr-datagrid>
    </div>
</div>
<div class="clr-row">
    <div class="clr-col-9">

    </div>
    <div class="clr-col-2 align-update">
                   <button class="btn btn-primary update-button" [disabled]="noRecords()"
                        (click)=update()>Update 
                   </button>
    </div>
</div>

</form>


