import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@core/guards/auth.guard';
import { AssetCustomerDetailsComponent } from '@modules/eventmanagement/asset-customer-details/asset-customer-details.component';
import { TriggerPointProfileDetailsComponent } from '@modules/eventmanagement/trigger-point-profile-details/trigger-point-profile-details.component';
import { LoginComponent } from '@modules/login/login.component';
import { AlarmDetailsComponent } from '@modules/alarms/alarm-details/alarm-details.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'admin/data-analysis',
    pathMatch: 'full'
  },
  {
    path: 'app',
    redirectTo: 'admin',
    pathMatch: 'full'
  },
  {
    path: 'tickets',
    loadChildren: () =>
      import('@modules/ticketing/ticketing.module').then(
        m => m.TicketingModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'things',
    loadChildren: () =>
      import('@modules/things/thing.module').then(m => m.ThingModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'alarms',
    loadChildren: () =>
      import('@modules/alarms/alarm.module').then(m => m.AlarmModule)
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('@modules/admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/users',
    loadChildren: () =>
      import('@modules/users/user.module').then(m => m.UserModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/alarms',
    loadChildren: () =>
      import('@modules/alarms/alarm.module').then(m => m.AlarmModule)
  },
  {
    path: 'admin/tickets',
    loadChildren: () =>
      import('@modules/ticketing/ticketing.module').then(
        m => m.TicketingModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/reports',
    loadChildren: () =>
      import('@modules/reports/report.module').then(m => m.ReportModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/event-management',
    loadChildren: () =>
      import('@modules/eventmanagement/eventmanagement.module').then(
        m => m.EventManagementModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'app/event-management',
    loadChildren: () =>
      import('@modules/eventmanagement/eventmanagement.module').then(
        m => m.EventManagementModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'app/reports',
    loadChildren: () =>
      import('@modules/reports/report.module').then(m => m.ReportModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'app/tickets',
    loadChildren: () =>
      import('@modules/ticketing/ticketing.module').then(
        m => m.TicketingModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/companies',
    loadChildren: () =>
      import('@modules/companies/company.module').then(m => m.CompanyModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/users',
    loadChildren: () =>
      import('@modules/users/user.module').then(m => m.UserModule)
  },
  {
    path: 'admin/event-management/asset-customers/list/:id',
    component: AssetCustomerDetailsComponent
  },
  {
    path: 'admin/event-management/asset-customers/:id',
    component: AssetCustomerDetailsComponent
  },
  {
    path: 'admin/event-management/profiles/list/:id',
    component: TriggerPointProfileDetailsComponent
  },
  {
    path: 'admin/event-management/profiles/:id',
    component: TriggerPointProfileDetailsComponent
  },
  {
    path: 'admin/alarms/:id',
    component: AlarmDetailsComponent
  },
  {
    path: 'companies',
    loadChildren: () =>
      import('@modules/companies/company.module').then(m => m.CompanyModule)
  },
  {
    path: 'login',
    loadChildren: () =>
      import('@modules/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'recover',
    component: LoginComponent
  },
  {
    path: 'reset',
    component: LoginComponent
  },
  {
    path: 'welcome',
    component: LoginComponent
  },
  {
    path: 'recover/:token',
    component: LoginComponent
  },
  {
    path: 'welcome/:token',
    component: LoginComponent
  },
  { path: '**', redirectTo: 'admin/data-analysis/control-center' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
