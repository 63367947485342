import { Router } from '@angular/router';
import {
  Component,
  OnInit,
  OnDestroy,
  OnChanges,
  AfterViewInit,
  HostListener,
  ElementRef,
  Input,
  SimpleChanges,
  ViewChild,
  Renderer2,
  EventEmitter,
  ViewEncapsulation
} from '@angular/core';
import { Store } from '@ngrx/store';
import { EventsState } from '@modules/events/states/events.state';
import { EventsService } from '@modules/events/services/events.service';
import { constants } from '@core/constants';
import { BaseComponent } from '@shared/components/base/base.component';
import { UserSessionState } from '@core/stores/user-session/states/user-session.states';
import { Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { TranslateService } from '@ngx-translate/core';
import { ComponentRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { faker } from '@faker-js/faker';
import sparkline from '@fnando/sparkline';
import { ExcelExportService } from '@slickgrid-universal/excel-export';
import { GridChartService } from '@shared/services/grid-chart/grid-chart.service';
import { get_item, set_item } from '@shared/utils/storage/encrypted_storage';
import { Slick } from 'slickgrid';
import {
  faEllipsisV,
  faBars,
  faCheck
} from '@fortawesome/free-solid-svg-icons';
import 'jquery-ui-dist/jquery-ui';
import 'jquery-ui';
/*
import { AngularGridInstance, Column, Editors, FieldType, Formatters, GridOption, GridService, OnEventArgs, SlickDataView, SlickGrid } from 'angular-slickgrid';
import { AutoResizeOption } from 'angular-slickgrid';
**/
import {
  AngularGridInstance,
  Column,
  Editors,
  FieldType,
  FileType,
  Formatters,
  GridOption,
  GridService,
  OnEventArgs,
  SlickDataView,
  SlickGrid,
  Aggregators,
  deepCopy,
  Filters,
  Formatter,
  GroupTotalFormatters
} from 'angular-slickgrid';
// using external non-typed js libraries
let filterElements: {} = {};
let menuElements: {} = {};
@Component({
  selector: 'app-grid-chart-slick',
  templateUrl: './grid-chart-slick.component.html',
  styleUrls: ['./grid-chart-slick.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GridChartSlickComponent extends BaseComponent
  implements AfterViewInit, OnInit, OnDestroy, OnChanges {
  @Input('item_id') item_id: number;
  @Input('data_view_name') data_view_name: string;
  @Input('data_view_time_domain') data_view_time_domain: string;
  @Input('data_view_id') data_view_id: number;
  @Input('cols') cols: number;
  @Input('rows') rows: number;
  @ViewChild('wrapper') wrapper: ElementRef;
  @ViewChild('dropdown') dropdown: ElementRef;
  public random_id: any = '';
  public showTitle: boolean = false;
  public initialized: boolean[] = [];
  public grid_id: any = '';
  public filterFixed: boolean = false;
  public resizerPaused = false;
  public time_domains = {
    time_domain_1: 'Today',
    time_domain_2: 'Yesterday',
    time_domain_3: 'This Week',
    time_domain_4: 'Last Week',
    time_domain_5: 'This Month',
    time_domain_6: 'Last Month',
    time_domain_7: 'This Year',
    time_domain_8: 'Last Year'
  };
  public time_domain_id: number = 1;
  public load: boolean = false;
  public loaded: boolean = false;
  public currentClass: any = '';
  public menus: {} = {};
  public filters: {} = {};
  public menuElements: {} = {};
  public filterElements: {} = {};
  public datasets: {} = {};
  public uniqueId: string = '0';
  public columnsOnOff: any = {};
  public columns: any[] = [];
  public faBarsIcon: any;
  public faCheckIcon: any;
  public faEllipsisVIcon: any;
  private eventSubs: Subscription;
  private pageRatio: number = 1;
  private excelExportService: any;
  public processing: boolean = false;
  public menu: boolean = false;
  public filterRow: boolean = true;
  private click: boolean = false;
  title = 'Example 11: Add / Update / Highlight a Datagrid Item';

  angularGrid!: AngularGridInstance;
  grid!: SlickGrid;
  gridService!: GridService;
  dataView!: SlickDataView;
  columnDefinitions: Column[] = [];
  origColumnDefinitions: Column[] = [];
  origDataSet: any[] = [];
  gridOptions!: GridOption;
  public dataset: any[];
  updatedObject: any;
  gridId: number = 0;
  moveRowsPlugin: any;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    let gridsterItem = document.getElementById(`gridster-item-${this.item_id}`);
    //this.angularGrid.resizerService.resizeGrid();
    /*  
          if (
              typeof this.angularGrid !== 'undefined' &&
              this.angularGrid !== null
            ) {
              if (
                typeof this.angularGrid.resizerService !== 'undefined' &&
                this.angularGrid.resizerService !== null
              ) {
                let gridsterItem = document.getElementById(`gridster-item-${this.item_id}`)
                let gridItem = document.getElementById(`wrapper-${this.currentClass}`);
                let child = document.querySelectorAll<HTMLElement>(
                    `.slickgrid-container`
                );
                let wd = window.innerWidth - 739;


                if (typeof child!== 'undefined' && child!==null) {
                   for (let i = 0; i < child.length; i++) {
                     if (this.isDescendant(gridItem, child[i])) {
                          //let w = gridsterItem.offsetWidth - 430;
                          //let h = gridsterItem.offsetHeight - 430;
                          let w = 200;
                          child[i].style.width = `${w}px`;
                          this.renderer.setStyle(child[i], 'width', `${w}px`);             
           
                     }
                   }
                }   

                let w = 200;//gridsterItem.offsetWidth - 430;
                let h = gridsterItem.offsetHeight - 430;
                gridItem.style.width = `${w}px`;
                this.renderer.setStyle(gridItem, 'width', `${w}px`);

  
              }
            }
*/
    this.cols = Math.floor(gridsterItem.offsetWidth / 220);

    this.fixChart(false);
    this.showHeader();
    this.fixHeader();
    this.updatePaginator();
    this.angularGrid.resizerService.resizeGrid();
    this.togglePauseResizer();
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (typeof this.dropdown !== 'undefined' && this.dropdown !== null) {
      if (this.dropdown.nativeElement.contains(event.target)) {
        this.menu = true;
        this.click = true;
      } else {
        this.menu = false;
        this.click = false;
      }
    }
  }

  constructor(
    protected idle: Idle,
    protected keepalive: Keepalive,
    protected sessionStore: Store<{ uss: UserSessionState }>,
    protected router: Router,
    protected gridChartService: GridChartService,
    protected eventsService: EventsService,
    protected eventStore: Store<{ nf: EventsState }>,
    protected renderer: Renderer2
  ) {
    super(idle, keepalive, eventsService, sessionStore, router);
    //    this.dataset = this.mockDataset(20);
    for (let i = 0; i < 100; i++) {
      this.initialized.push(false);
    }
    this.excelExportService = new ExcelExportService();
    let den: number = this.rows / 2;
    this.pageRatio = 5 * den;
    this.faBarsIcon = faBars;
    this.faCheckIcon = faCheck;
    this.faEllipsisVIcon = faEllipsisV;
  }

  clickHeader(): void {
    setTimeout(() => {
      let hds = document.querySelectorAll<HTMLElement>(
        `.${this.currentClass} .slick-header-column`
      );
      if (typeof hds !== 'undefined') {
        for (let i = 0; i < hds.length; i++) {
          this.fixHeader();
          this.fixGrid();
          hds[i].click();
        }
      }
    }, 350);

    let hds = document.querySelectorAll<HTMLElement>(
      `.${this.currentClass} .slick-header`
    );
    if (typeof hds !== 'undefined' && hds !== null) {
      for (let i = 0; i < hds.length; i++) {
        hds[i].click();
      }
    }
  }
  ngAfterViewInit(): void {
    this.fixChart(true);
    this.fixPagination();
    this.fixHeader();
    this.setDataView();
    this.updatePaginator();
    setTimeout(() => {
      let hds = document.querySelectorAll<HTMLElement>(
        `.${this.currentClass} .slick-header-column`
      );
      if (typeof hds !== 'undefined') {
        for (let i = 0; i < hds.length; i++) {
          this.fixHeader();
          this.fixGrid();
          hds[i].click();
        }
      }
    }, 150);

    let hds = document.querySelectorAll<HTMLElement>('.slick-header');
    if (typeof hds !== 'undefined' && hds !== null) {
      for (let i = 0; i < hds.length; i++) {
        hds[i].click();
      }
    }
  }

  fixAll(): void {
    this.fixChart(true);
    this.fixPagination();
    this.fixHeader();
    this.setDataView();
    setTimeout(() => {
      let hds = document.querySelectorAll<HTMLElement>(
        `.${this.currentClass} .slick-header-column`
      );
      if (typeof hds !== 'undefined') {
        for (let i = 0; i < hds.length; i++) {
          this.fixHeader();
          this.fixGrid();
          hds[i].click();
        }
      }
    }, 150);

    let hds = document.querySelectorAll<HTMLElement>('.slick-header');
    if (typeof hds !== 'undefined' && hds !== null) {
      for (let i = 0; i < hds.length; i++) {
        hds[i].click();
      }
    }
  }
  getFirst(con, tag) {
    return con.getElementsByTagName(tag)[0];
  }

  restoreHeader(): void {
    let header = document.querySelectorAll<HTMLElement>('.slick-header');

    if (typeof header !== 'undefined' && header !== null) {
      for (let i = 0; i < header.length; i++) {
        if (typeof header[i] !== 'undefined' && header[i] !== null) {
          header[i].style.display = 'block';
          header[i].style.paddingLeft = '2.00em';
        }
      }
    }
  }

  fixEmpty(): void {
    let header = document.querySelectorAll<HTMLElement>('.slick-header');
    let empty = document.querySelectorAll<HTMLElement>(
      '.slick-empty-data-warning'
    );
    if (typeof empty !== 'undefined' && empty !== null) {
      for (let i = 0; i < empty.length; i++) {
        if (typeof empty[i] !== 'undefined' && empty[i] !== null) {
          empty[i].style.display = 'none';
        }
      }
    }

    if (typeof header !== 'undefined' && header !== null) {
      for (let i = 0; i < header.length; i++) {
        if (typeof header[i] !== 'undefined' && header[i] !== null) {
          header[i].style.display = 'none';
        }
      }
    }
  }

  fixChart(adjust: boolean): void {
    let rowsNum: number = this.rows * 9.6;
    let colsNum: number = this.cols * 16.2;

    if (this.cols === 2) {
      colsNum = 31;
    }
    if (this.cols === 1) {
      colsNum = 13.5;
    }
    if (this.rows === 2) {
      rowsNum = 17.5;
    }
    let settings = document.querySelectorAll<HTMLElement>(
      '.slick-pagination-settings'
    );

    let empty = document.querySelectorAll<HTMLElement>(
      '.slick-empty-data-warning'
    );

    if (typeof empty !== 'undefined' && empty !== null) {
      for (let i = 0; i < empty.length; i++) {}
    }
    let selectControls = document.getElementById('items-per-page-label');
    let formControls = document.querySelectorAll<HTMLElement>('.form-control');
    let id = this.getId();
    if (adjust) {
      //    this.currentClass = `grid-${id}`;
    }
    let wrapper = document.getElementById(
      `slickGridContainer-${this.data_view_id}-${this.gridId}`
    );
    if (typeof wrapper !== 'undefined' && wrapper !== null) {
      wrapper.style.height = `${rowsNum}em`;
      wrapper.style.width = `${colsNum}em`;
      wrapper.classList.add(this.currentClass);
      wrapper.style.display = 'hidden';
    }

    if (typeof settings !== 'undefined' && settings !== null) {
      for (let i = 0; i < settings.length; i++) {
        let select = this.getFirst(settings[i], 'select');
        if (typeof select !== 'undefined' && select !== null) {
          select.style.width = '2.0em';
          select.style.marginRight = '1.0em';
          settings[i].style.width = '20em';
          settings[i].style.display = 'flex';
          settings[i].style.fontSize = '1.0em';
        }
      }
    }

    if (typeof selectControls !== 'undefined' && selectControls !== null) {
      //   selectControls.style.width='1.7em';
    }

    if (!this.filterFixed) {
      this.filterFixed = true;
      if (typeof formControls !== 'undefined' && formControls !== null) {
        for (let i = 0; i < formControls.length; i++) {
          //      formControls[i].style.width = '1.7em';
          formControls[i].style.color = '#8B8B8B';
          formControls[i].style.fontFamily =
            'var(--slick-multiselect-input-filter-font-family, "Helvetica Neue", Helvetica, Arial)';
          formControls[i].style.fontSize =
            'var(--slick-multiselect-input-filter-font-size, 12px)';
        }
      }
    }
    let elements = document.querySelectorAll<HTMLElement>(
      `.${this.currentClass} .slick-sort-indicator`
    );
    if (typeof elements !== 'undefined' && elements !== null) {
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.marginTop = '0.6em';
      }
    }

    let headers = document.querySelectorAll<HTMLElement>(
      `.${this.currentClass} .slick-header`
    );
    if (typeof headers !== 'undefined' && headers !== null) {
      for (let i = 0; i < headers.length; i++) {
        headers[i].style.marginTop = '-1.0em';
        headers[i].style.width = '101%';
        headers[i].style.paddingLeft = '2.00em';
      }
    }

    if (adjust) {
      let headerCols = document.querySelectorAll<HTMLElement>(
        `.${this.currentClass} .slick-header-columns`
      );
      if (typeof headers !== 'undefined' && headerCols !== null) {
        for (let i = 0; i < headerCols.length; i++) {
          headerCols[i].style.background = '#FAFAFA';
          headerCols[i].style.zIndex = '10000';
          headerCols[i].style.marginTop = '-0.5em';
        }
      }
    } else {
      let headerCols = document.querySelectorAll<HTMLElement>(
        `.${this.currentClass} .slick-header-columns`
      );
      if (typeof headers !== 'undefined' && headerCols !== null) {
        for (let i = 0; i < headerCols.length; i++) {
          headerCols[i].style.background = '#FAFAFA';
          headerCols[i].style.zIndex = '10000';
          headerCols[i].style.marginTop = '0.5em';
        }
      }
    }
    let wrappers = document.querySelectorAll<HTMLElement>(
      '.slickgrid-container'
    );
    let parnt = document.getElementById(`${this.data_view_id}-${this.gridId}`);

    if (typeof wrappers !== 'undefined' && wrappers !== null) {
      for (let i = 0; i < wrappers.length; i++) {
        if (typeof parnt !== 'undefined' && parnt !== null) {
          if (parnt.contains(wrappers[i])) {
            wrappers[i].style.height = `${rowsNum}em`;
            wrappers[i].style.width = `${colsNum}em`;
            wrappers[i].style.overflow = 'scroll';
            wrappers[i].style.overflowY = 'scroll';
            wrappers[i].style.borderBottom = 'none';
            wrappers[i].style.marginBottom = '-2.0em';
            wrappers[i].style.zIndex = '0';
          }
        }
      }
    }

    let containers = document.querySelectorAll<HTMLElement>('.gridPane');
    if (typeof containers !== 'undefined' && containers !== null) {
      for (let i = 0; i < containers.length; i++) {
        if (typeof parnt !== 'undefined' && parnt !== null) {
          if (parnt.contains(containers[i])) {
            containers[i].style.height = `${rowsNum}em`;
            containers[i].style.width = `${colsNum}em`;
            containers[i].style.overflow = 'scroll';
            containers[i].style.overflowY = 'scroll';
            containers[i].style.zIndex = '0';
          }
        }
      }
    }
    let left = document.querySelectorAll<HTMLElement>('.l0');
    if (typeof left !== 'undefined' && left !== null) {
      for (let i = 0; i < left.length; i++) {
        left[i].innerHTML = ' ';
      }
    }
  }

  fixGrid(): void {
    let cls = document.querySelectorAll<HTMLElement>('.flatpickr .input-group');
    if (typeof cls !== 'undefined' && cls !== null) {
      for (let i = 0; i < cls.length; i++) {
        cls[i].addEventListener('click', function(e: any) {
          let innerHidden = cls[i].firstChild as HTMLElement;
          let textInput = innerHidden.nextElementSibling as HTMLInputElement;
          let spanClose = textInput.nextElementSibling as HTMLElement;
        });
      }
    }

    let cells = document.querySelectorAll<HTMLElement>('.slick-cell');
    let self = this;

    if (typeof cells !== 'undefined' && cells !== null) {
      for (let i = 0; i < cells.length; i++) {
        cells[i].addEventListener('click', function(e: any) {
          /*
                  let innerHidden = cells[i].firstChild as HTMLElement;
                  let textInput = innerHidden.nextElementSibling as HTMLInputElement;
                  let spanClose = textInput.nextElementSibling as HTMLElement;
*/
          //                self.fixCell();
          //            cells[i].style.background = 'red';
          /*
              cells[i].childNodes[0].style.border = 'none';
              cells[i].childNodes[0].style.border = 'none';
              cells[i].childNodes[0].boxShadow = 'none';
              cells[i].childNodes[0].background = 'none';
              cells[i].childNodes[0].borderRadius = 'none';
*/
          let input = document.querySelectorAll<HTMLElement>('.editor-text');
          if (typeof input !== 'undefined' && input !== null) {
            let inp = input[0];
            if (typeof inp !== 'undefined' && inp !== null) {
              inp.style.border = 'none';
              inp.style.boxShadow = 'none';
              inp.style.background = 'none';
              inp.style.borderRadius = 'none';
            }
          }

          let inpt = document.querySelectorAll<HTMLElement>('.editor-duration');
          if (typeof inpt !== 'undefined' && inpt !== null) {
            let inp = inpt[0];
            if (typeof inp !== 'undefined' && inp !== null) {
              inp.style.border = 'none';
              inp.style.boxShadow = 'none';
              inp.style.background = 'none';
              inp.style.borderRadius = 'none';
            }
          }

          let btns = document.querySelectorAll<HTMLElement>('.icon-clear');
          if (typeof btns !== 'undefined' && btns !== null) {
            let btn = btns[0];
            if (typeof btn !== 'undefined' && btn !== null) {
              btn.style.marginTop = '0.0em';
              btn.style.color = '#8F8F8F';
            }
          }
        });
      }
    }
    let left = document.querySelectorAll<HTMLElement>('.l0');
    if (typeof left !== 'undefined' && left !== null) {
      for (let i = 0; i < left.length; i++) {
        left[i].innerHTML = ' ';
      }
    }
  }
  fixCell(): void {
    /*
     let cells = document.querySelectorAll<HTMLElement>('.flatpickr');
     if (typeof cells !== 'undefined' && cells!==null) {
        for (let i = 0; i < cells.length; i++) {
           let cell = cells[i];
           cell.style.background = '#cccccc';
        }
     }
*/
  }

  angularGridReady(angularGrid: AngularGridInstance) {
    this.angularGrid = angularGrid;
    this.dataView = angularGrid.dataView;
    this.grid = angularGrid.slickGrid as SlickGrid;
    this.gridService = angularGrid.gridService;
    /*

    this.grid.setSelectionModel(new Slick.RowSelectionModel());
    this.moveRowsPlugin = new Slick.RowMoveManager({
      cancelEditOnDrag: true
    });
    this.grid.registerPlugin(this.moveRowsPlugin);
*/
    this.fixHeader();
  }

  ngOnInit(): void {
    this.gridChartService.setUniqueId(this.item_id);
    this.currentClass = `grid-${this.getId()}`;
    this.grid_id = this.randomId();
    this.loaded = false;
    this.gridChartService.setDataViewId(this.data_view_id);
    this.uniqueId = this.readId(this.data_view_id);
    this.gridChartService.uniqueId = this.uniqueId;
    this.drawChart();
    this.eventsService.publish('startGridSpinner', 'startGridSpinner', {
      data_view_id: this.data_view_id
    });
    this.fixEmpty();
    this.readChart();
    this.fixHeader();
    this.subscribeEvents();
  }

  ngOnDestroy(): void {
    if (typeof this.eventSubs !== 'undefined' && this.eventSubs !== null) {
      this.eventSubs.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    setTimeout(() => {
      let hds = document.querySelectorAll<HTMLElement>('.slick-header-column');
      if (typeof hds !== 'undefined') {
        for (let i = 0; i < hds.length; i++) {
          hds[i].click();
          this.fixHeader();
        }
      }
    }, 150);
  }

  angularGridReady1(angularGrid: AngularGridInstance) {
    this.angularGrid = angularGrid;
  }

  readChart(): void {
    this.gridChartService.readView(this.data_view_id).subscribe((item: any) => {
      if (typeof item !== 'undefined' && item !== null) {
        let id = 'updateChartItems';
        /*
        this.eventsService.publish('updateChartItems', 'updateChartItems', {
           data_view_id:this.data_view_id,
           columnDefinitions:true
        });
*/
        this.time_domain_id = item.time_domain_id;
        this.data_view_time_domain = item.time_domain_id;
        this.dataset = deepCopy(item.data);
        if (
          typeof this.angularGrid !== 'undefined' &&
          this.angularGrid !== null
        ) {
          if (
            typeof this.angularGrid.gridService !== 'undefined' &&
            this.angularGrid.gridService !== null
          ) {
            this.angularGrid.gridService.init(
              this.angularGrid.slickGrid,
              this.dataset
            );
          }
        }
        this.fixEmpty();
        this.origDataSet = deepCopy(this.dataset);
        this.origColumnDefinitions = deepCopy(item.columnDefinitions);
        this.columnDefinitions = deepCopy(item.columnDefinitions);
        this.columns = this.columnDefinitions.slice(
          1,
          this.columnDefinitions.length
        );
        this.gridOptions = deepCopy(item.gridOptions);
        this.initColumns();
        this.loaded = true;
        this.eventsService.publish(
          'refreshGridChartFinished',
          'refreshGridChartFinished',
          { data_view_id: this.data_view_id, size: item.data.length }
        );
        this.restoreHeader();
        this.clickHeader();
        //  this.angularGrid.filterService.setSortColumnIcons([{ columnId: 'value', sortAsc: false }]);
      }
    });
  }

  setGridDefaultVisibleColumns() {
    const visibleColumns = this.columnDefinitions.filter(c => {
      return c['visible'] !== false;
    });
    if (typeof this.angularGrid !== 'undefined' && this.angularGrid !== null) {
      this.angularGrid.slickGrid.setColumns(visibleColumns);
    }
  }

  /*
  drawChart(): void {
    this.columnDefinitions = [
      {
        id: '1',
        sortable: true,
        field: 'id',
        width: 0, 
        minWidth: 0, 
        maxWidth: 0, 
        cssClass: "reallyHidden", 
        headerCssClass: "reallyHidden"
 
     },
      {
        id: '2',
        name: '',
        field: '',
        type: FieldType.string,
        width: 0, 
        minWidth: 0, 
        maxWidth: 0, 
        cssClass: "reallyHidden", 
        headerCssClass: "reallyHidden"
      },
      {
        id: '3',
        name: '',
        field: '',
        width: 0, 
        minWidth: 0, 
        maxWidth: 0, 
        cssClass: "reallyHidden", 
        headerCssClass: "reallyHidden"
      },
      { 
        id: '4',
        name: '',
        field: '',
        width: 0, 
        minWidth: 0, 
        maxWidth: 0, 
        cssClass: "reallyHidden", 
        headerCssClass: "reallyHidden"
      },
      { 
        id: '5',
        name: '',
        field: '',
        width: 0, 
        minWidth: 0, 
        maxWidth: 0, 
        cssClass: "reallyHidden", 
        headerCssClass: "reallyHidden" 
      },
    ];
    this.gridOptions = {
      enableExcelExport: true,
      asyncEditorLoading: true,
      autoHeight: true,
      gridWidth: 400,
      rowHeight: 0,
      autoResize: {
        container: `#demo-container-${this.uniqueId}`,
        rightPadding: 10
      } as any,
      enableFiltering: true,
      //    showHeaderRow: false,
      enableGridMenu: true,
      editable: true,
      enableColumnPicker: true,
      enableRowSelection: true,
      forceFitColumns: false,
      topPanelHeight: 25,
      enableAddRow: true,
      enableCellNavigation: true,
      autoEdit: true,
      enableCheckboxSelector: true,
      enablePagination: true,
      pagination: {
        pageSizes: [5, 10, 15, 20, 25, 50, 75, 100],
        pageSize: 10 // this.pageRatio
      },
      excelExportOptions: {
        sanitizeDataExport: true
      } as any,
      gridMenu: {
        hideExportExcelCommand: false // false by default, so it's optional
      },
      registerExternalResources: [new ExcelExportService()]
    } as any;
  }
*/

  drawChart(): void {
    this.columnDefinitions = [
      {
        id: 'delete',
        sortable: true,
        field: 'id',
        width: 0,
        minWidth: 0,
        maxWidth: 0,
        cssClass: 'reallyHidden',
        headerCssClass: 'reallyHidden',

        excludeFromHeaderMenu: true,
        formatter: Formatters.deleteIcon,
        //minWidth: 30,
        //maxWidth: 30,
        // use onCellClick OR grid.onClick.subscribe which you can see down below
        onCellClick: (e: Event, args: OnEventArgs) => {
          console.log(args);
          if (confirm('1.Are you sure?' + args.dataContext.id)) {
            this.angularGrid.gridService.deleteItemById(args.dataContext.id);
          }
        }
      },
      {
        id: 'title',
        name: 'Title',
        field: 'title',
        sortable: true,
        width: 0,
        minWidth: 0,
        maxWidth: 0,
        cssClass: 'reallyHidden',
        headerCssClass: 'reallyHidden',
        type: FieldType.string,
        editor: {
          model: Editors.longText
        }
      },
      {
        id: 'duration',
        name: 'Duration (days)',
        field: 'duration',
        sortable: true,
        width: 0,
        minWidth: 0,
        maxWidth: 0,
        cssClass: 'reallyHidden',
        headerCssClass: 'reallyHidden',
        type: FieldType.number,
        editor: {
          model: Editors.text
        },
        onCellChange: (e: Event, args: OnEventArgs) => {
          console.log(args);
        }
      },
      {
        sortable: true,
        id: 'complete',
        name: '% Complete',
        field: 'percentComplete',
        width: 0,
        minWidth: 0,
        maxWidth: 0,
        cssClass: 'reallyHidden',
        headerCssClass: 'reallyHidden',
        formatter: Formatters.percentCompleteBar,
        type: FieldType.number,
        editor: {
          model: Editors.integer
        }
      },
      {
        id: 'start',
        name: 'Start',
        field: 'start',
        width: 0,
        minWidth: 0,
        maxWidth: 0,
        cssClass: 'reallyHidden',
        headerCssClass: 'reallyHidden',
        formatter: Formatters.dateIso,
        sortable: true,
        type: FieldType.date,
        filterable: true,
        exportWithFormatter: true,
        filter: { model: Filters.compoundDate },
        editor: {
          model: Editors.date
        }
      },
      {
        id: 'finish',
        name: 'Finish',
        field: 'finish',
        width: 0,
        minWidth: 0,
        maxWidth: 0,
        cssClass: 'reallyHidden',
        headerCssClass: 'reallyHidden',
        //formatter: Formatters.dateIso, sortable: true,
        //type: FieldType.date
        formatter: Formatters.dateIso,
        exportWithFormatter: true,
        type: FieldType.date
      },
      {
        id: 'effort-driven',
        name: 'Effort Driven',
        field: 'effortDriven',
        width: 0,
        minWidth: 0,
        maxWidth: 0,
        cssClass: 'reallyHidden',
        headerCssClass: 'reallyHidden',
        formatter: Formatters.checkmark,
        type: FieldType.boolean,
        sortable: true,
        filterable: true
      }
    ];
    this.gridOptions = {
      enableExcelExport: true,
      asyncEditorLoading: true,
      autoHeight: true,
      gridWidth: 400,
      rowHeight: 33,
      autoResize: {
        container: `#demo-container-${this.uniqueId}`,
        rightPadding: 10
      } as any,
      enableFiltering: true,
      //    showHeaderRow: false,
      enableGridMenu: true,
      editable: true,
      enableColumnPicker: true,
      enableRowSelection: true,
      forceFitColumns: false,
      topPanelHeight: 25,
      enableAddRow: true,
      enableCellNavigation: true,
      autoEdit: true,
      enableCheckboxSelector: true,
      enablePagination: true,
      pagination: {
        pageSizes: [5, 10, 15, 20, 25, 50, 75, 100],
        pageSize: 10 // this.pageRatio
      },
      excelExportOptions: {
        sanitizeDataExport: true
      } as any,
      gridMenu: {
        hideExportExcelCommand: false // false by default, so it's optional
      },
      registerExternalResources: [new ExcelExportService()]
    } as any;
  }

  onBeforeMoveRow(e: Event, data: { rows: number[]; insertBefore: number }) {
    for (const rowIdx of data.rows) {
      // no point in moving before or after itself
      if (
        rowIdx === data.insertBefore ||
        (rowIdx === data.insertBefore - 1 &&
          data.insertBefore - 1 !== this.angularGrid.dataView.getItemCount())
      ) {
        e.stopPropagation();
        return false;
      }
    }
    return true;
  }

  onMoveRows(_e: Event, args: any) {
    // rows and insertBefore references,
    // note that these references are assuming that the dataset isn't filtered at all
    // which is not always the case so we will recalcualte them and we won't use these reference afterward
    const rows = args.rows as number[];
    const insertBefore = args.insertBefore;
    const extractedRows = [];

    // when moving rows, we need to cancel any sorting that might happen
    // we can do this by providing an undefined sort comparer
    // which basically destroys the current sort comparer without resorting the dataset, it basically keeps the previous sorting
    this.angularGrid.dataView.sort(undefined as any, true);

    // the dataset might be filtered/sorted,
    // so we need to get the same dataset as the one that the SlickGrid DataView uses
    const tmpDataset = this.angularGrid.dataView.getItems();
    const filteredItems = this.angularGrid.dataView.getFilteredItems();

    const itemOnRight = this.angularGrid.dataView.getItem(insertBefore);
    const insertBeforeFilteredIdx = itemOnRight
      ? this.angularGrid.dataView.getIdxById(itemOnRight.id)
      : this.angularGrid.dataView.getItemCount();

    const filteredRowItems: any[] = [];
    rows.forEach(row => filteredRowItems.push(filteredItems[row]));
    const filteredRows = filteredRowItems.map(item =>
      this.angularGrid.dataView.getIdxById(item.id)
    );

    const left = tmpDataset.slice(0, insertBeforeFilteredIdx);
    const right = tmpDataset.slice(insertBeforeFilteredIdx, tmpDataset.length);

    // convert into a final new dataset that has the new order
    // we need to resort with
    rows.sort((a: number, b: number) => a - b);
    for (const filteredRow of filteredRows) {
      if (filteredRow !== undefined) {
        extractedRows.push(tmpDataset[filteredRow]);
      }
    }
    filteredRows.reverse();
    for (const row of filteredRows) {
      if (row !== undefined && insertBeforeFilteredIdx !== undefined) {
        if (row < insertBeforeFilteredIdx) {
          left.splice(row, 1);
        } else {
          right.splice(row - insertBeforeFilteredIdx, 1);
        }
      }
    }

    // final updated dataset, we need to overwrite the DataView dataset (and our local one) with this new dataset that has a new order
    const finalDataset = left.concat(extractedRows.concat(right));
    this.dataset = finalDataset; // update dataset and re-render the grid
  }

  addNewItem(insertPosition?: 'top' | 'bottom') {
    const newItem1 = this.createNewItem(1);
    const newItem2 = this.createNewItem(2);

    // single insert
    this.angularGrid.gridService.addItem(newItem1, {
      position: insertPosition
    });

    // OR multiple inserts
    // this.angularGrid.gridService.addItems([newItem1, newItem2], { position: insertPosition });
  }

  createNewItem(incrementIdByHowMany = 1) {
    const dataset = this.angularGrid.dataView.getItems();
    let highestId = 0;
    dataset.forEach((item: any) => {
      if (item.id > highestId) {
        highestId = item.id;
      }
    });
    const newId = highestId + incrementIdByHowMany;
    const randomYear = 2000 + Math.floor(Math.random() * 10);
    const randomMonth = Math.floor(Math.random() * 11);
    const randomDay = Math.floor(Math.random() * 29);
    const randomPercent = Math.round(Math.random() * 100);

    return {
      id: newId,
      title: 'Task ' + newId,
      duration: Math.round(Math.random() * 100) + '',
      percentComplete: randomPercent,
      percentCompleteNumber: randomPercent,
      start: new Date(randomYear, randomMonth, randomDay),
      finish: new Date(randomYear, randomMonth + 2, randomDay),
      effortDriven: true
    };
  }

  highlighFifthRow() {
    this.scrollGridTop();
    this.angularGrid.gridService.highlightRow(4, 1500);
  }

  /** Change the Duration Rows Background Color */
  changeDurationBackgroundColor() {
    this.dataView.getItemMetadata = this.updateItemMetadataForDurationOver40(
      this.dataView.getItemMetadata
    );

    // also re-render the grid for the styling to be applied right away
    this.grid.invalidate();
    this.grid.render();

    // or use the Angular-SlickGrid GridService
    // this.gridService.renderGrid();
  }

  /**
   * Change the SlickGrid Item Metadata, we will add a CSS class on all rows with a Duration over 50
   * For more info, you can see this SO https://stackoverflow.com/a/19985148/1212166
   */
  updateItemMetadataForDurationOver40(previousItemMetadata: any) {
    const newCssClass = 'duration-bg';

    return (rowNumber: number) => {
      const item = this.dataView.getItem(rowNumber);
      let meta = {
        cssClasses: ''
      };
      if (typeof previousItemMetadata === 'object') {
        meta = previousItemMetadata(rowNumber);
      }

      if (meta && item && item.duration) {
        const duration = +item.duration; // convert to number
        if (duration > 40) {
          meta.cssClasses = (meta.cssClasses || '') + ' ' + newCssClass;
        }
      }

      return meta;
    };
  }

  updateSecondItem() {
    this.scrollGridTop();
    const updatedItem = this.angularGrid.gridService.getDataItemByRowNumber(1);
    updatedItem.duration = Math.round(Math.random() * 100);
    this.angularGrid.gridService.updateItem(updatedItem);

    // OR by id
    // this.angularGrid.gridService.updateItemById(updatedItem.id, updatedItem);

    // OR multiple changes
    /*
    const updatedItem1 = this.angularGrid.gridService.getDataItemByRowNumber(1);
    const updatedItem2 = this.angularGrid.gridService.getDataItemByRowNumber(2);
    updatedItem1.duration = Math.round(Math.random() * 100);
    updatedItem2.duration = Math.round(Math.random() * 100);
    this.angularGrid.gridService.updateItems([updatedItem1, updatedItem2], { highlightRow: true });
    */
  }

  scrollGridBottom() {
    this.angularGrid.slickGrid.navigateBottom();
  }

  scrollGridTop() {
    this.angularGrid.slickGrid.navigateTop();
  }

  randomId(): string {
    if (typeof this.gridId === 'undefined') {
      this.gridId = Math.floor(Math.random() * 1000);
    }
    return `${this.data_view_id}-${this.gridId}`;
  }

  exportToExcel() {
    if (typeof this.angularGrid !== 'undefined' && this.angularGrid !== null) {
      this.angularGrid.excelExportService.exportToExcel({
        filename: 'export',
        format: FileType.xlsx
      });
    }
  }

  subscribeEvents(): void {
    this.eventSubs = this.eventStore.select('nf').subscribe(event => {
      console.log(`EVENT PROCESSED ${event.items.id}`);
      if (event.items.id === 'updateGridChart') {
        if (event.items.payload.data_view_id === this.data_view_id) {
          this.time_domain_id = event.items.payload.time_domain_id;
        }
      }
      if (event.items.id === 'gridsterItemChanged') {
        if (event.items.payload.data_view_id === this.data_view_id) {
          if (
            event.items.payload.item !== 'undefined' &&
            event.items.payload.item !== null
          ) {
            if (
              typeof event.items.payload.item !== 'undefined' &&
              event.items.payload.items !== null
            ) {
              this.rows = deepCopy(event.items.payload.item.rows);
              this.cols = deepCopy(event.items.payload.item.cols);
              this.fixChart(false);
              this.fixHeader();
              this.readChart();
              this.updatePaginator();
              if (
                typeof this.angularGrid !== 'undefined' &&
                this.angularGrid !== null
              ) {
                if (
                  typeof this.angularGrid.resizerService !== 'undefined' &&
                  this.angularGrid.resizerService !== null
                ) {
                  this.angularGrid.resizerService.resizeGrid();
                }
              }
            }
          }
        }
      }

      if (event.items.id === 'gridsterItemResizeStopped') {
        if (event.items.payload.data_view_id === this.data_view_id) {
          this.fixChart(false);
          this.showHeader();
          this.fixHeader();
          this.updatePaginator();
          this.angularGrid.resizerService.resizeGrid();
          if (
            typeof event.items.payload.orig_item.settings !== 'undefined' &&
            event.items.payload.orig_item.settings !== null
          ) {
          }
        }
      }

      if (event.items.id === 'deleteChartItem') {
        let curr_data_view_id: number = get_item('data_view_id') as number;
        let payload_data_view_id: number = event.items.payload.updPayload
          .dataViewId as number;
        if (curr_data_view_id === this.data_view_id) {
          let itemId: number = event.items.payload.updPayload.fullItem
            .id as number;
          let updated_dataset: any[] = [];
          for (let i = 0; i < this.dataset.length; i++) {
            if (this.dataset[i].id !== itemId) {
              updated_dataset.push(this.dataset[i]);
            } else {
            }
          }
          this.dataset = updated_dataset;
          if (
            typeof this.angularGrid !== 'undefined' &&
            this.angularGrid !== null
          ) {
            if (
              typeof this.angularGrid.gridService !== 'undefined' &&
              this.angularGrid.gridService !== null
            ) {
              //          this.angularGrid.gridService.init(this.angularGrid.slickGrid, this.dataset);
            }
          }
        }
      }

      if (event.items.id === 'startGridSpinner') {
        this.filterFixed = false;
        if (event.items.payload.data_view_id !== 'undefined') {
          if (event.items.payload.data_view_id == this.data_view_id) {
            this.hideHeader();
            this.hideGrid();
            this.updatePaginator();
          }
        }
      }

      if (event.items.id === 'refreshGridChartFinished') {
        if (this.data_view_id === event.items.payload.data_view_id) {
          this.showHeader();
          this.fixChart(false);
          if (
            typeof this.angularGrid !== 'undefined' &&
            this.angularGrid !== null
          ) {
            this.angularGrid.resizerService.resizeGrid();
          }
        }
        if (
          typeof this.angularGrid !== 'undefined' &&
          this.angularGrid !== null
        ) {
          console.log(`Finish and show the grid`);
          this.angularGrid.resizerService.resizeGrid();
        }
      }

      if (event.items.id === 'refreshGridChartHeader') {
        this.fixChart(false);
        this.fixHeader();
        this.updatePaginator();
        this.angularGrid.resizerService.resizeGrid();
      }
      if (event.items.id === 'startGridSpinner') {
        let data_view_id = event.items.payload.data_view_id;
        if (data_view_id === this.data_view_id) {
          this.hideHeader();
        }
      }
      if (event.items.id === 'stopGridSpinner') {
        let data_view_id = event.items.payload.data_view_id;
        if (data_view_id === this.data_view_id) {
          this.showHeader();
        }
      }
      if (event.items.id === 'refreshGridChart') {
        if (this.data_view_id === event.items.payload.item.data_view_id) {
          this.rows = event.items.payload.item.settings.rows;
          this.cols = event.items.payload.item.settings.cols;
          //      this.data_view_id = event.items.payload.item.data_view_id;
          this.fixChart(false);
          this.fixHeader();
          this.readChart();
          this.updatePaginator();
          if (
            typeof this.angularGrid !== 'undefined' &&
            this.angularGrid !== null
          ) {
            if (
              typeof this.angularGrid.resizerService !== 'undefined' &&
              this.angularGrid.resizerService !== null
            ) {
              this.angularGrid.resizerService.resizeGrid();
            }
          }
        }
      }
    });
  }

  dropDownToggle() {
    this.menu = !this.menu;
  }

  clearFilters(): void {
    this.angularGrid.filterService.clearFilters();
    this.angularGrid.resizerService.resizeGrid();
  }

  clearSorting(): void {
    this.angularGrid.sortService.clearSorting();
    this.angularGrid.resizerService.resizeGrid();
  }

  toggleFilterRow(): void {
    let prent = document.getElementById(this.uniqueId);
    let elements = document.querySelectorAll<HTMLElement>('.slick-headerrow');
    if (typeof elements !== 'undefined') {
      for (let i = 0; i < elements.length; i++) {
        if (this.isDescendant(prent, elements[i])) {
          if (!this.filterRow) {
            elements[i].style.display = 'block';
          } else {
            elements[i].style.display = 'none';
          }
        }
      }
    }
    this.filterRow = !this.filterRow;
  }

  closeHeaderMenu(i: number): void {
    let el = document.querySelectorAll<HTMLElement>('.slick-header-menu');
    // let el = document.getElementById(`header-menu-${i}`);
    if (typeof el !== 'undefined' && el !== null) {
      if (el.length > 0) {
        el[0].style.display = 'none';
      }
    }
    this.angularGrid.resizerService.resizeGrid();
  }

  closeMenu(): void {
    let el = document.getElementById('hamburger');
    el.click();
    this.angularGrid.resizerService.resizeGrid();
  }

  initColumns() {
    for (let i = 0; i < this.columns.length; i++) {
      this.columnsOnOff[this.columns[i].id] = true;
    }
  }

  toggleColumn(column: any) {
    let updatedColumnDefinitions: Column[] = [];
    this.columnsOnOff[column.id] = !this.columnsOnOff[column.id];
    for (let i = 0; i < this.origColumnDefinitions.length; i++) {
      let colm = this.origColumnDefinitions[i];
      if (this.columnsOnOff[colm.id]) {
        updatedColumnDefinitions.push(colm);
      }
    }
    this.columnDefinitions = updatedColumnDefinitions;
    this.angularGrid.resizerService.resizeGrid();
  }

  columnOn(column: any): boolean {
    return this.columnsOnOff[column.id];
  }

  fixDelete(): void {
    let deletes = document.querySelectorAll<HTMLElement>('.delete-icon');
    if (typeof deletes !== 'undefined' && deletes !== null) {
      for (let i = 0; i < deletes.length; i++) {
        deletes[i].addEventListener('click', this.preprocessDelete);
      }
    }
  }

  preprocessDelete(): void {
    this.gridChartService.dataViewDataSource.next(this.data_view_id);
    this.gridChartService.setDataViewId(this.data_view_id);
  }

  fixPagination(): void {
    let elements = document.querySelectorAll<HTMLElement>('.page-link');
    if (typeof elements !== 'undefined' && elements !== null) {
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.border = 'none';
        elements[i].style.background = 'none';
        elements[i].style.borderRadius = 'none';
        elements[i].style.color = '#808080';
        elements[i].style.fontSize = '1.6em';
        elements[i].style.marginTop = '0.1em';
      }
    }

    let back = document.querySelectorAll<HTMLElement>('.fa-angle-double-left');
    let forward = document.querySelectorAll<HTMLElement>(
      '.fa-angle-double-right'
    );

    let sback = document.querySelectorAll<HTMLElement>('.fa-angle-left');
    let sforward = document.querySelectorAll<HTMLElement>('.fa-angle-right');

    if (typeof back !== 'undefined') {
      for (let i = 0; i < back.length; i++) {
        back[i].classList.remove('icon-seek-first');
        back[i].classList.remove('fa-angle-double-left');
        back[i].innerHTML =
          '<clr-icon shape="step-forward-2 down" role="none"><svg version="1.1" viewBox="0 0 36 36" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" focusable="false" role="img"><path d="M7.08,6.52a1.68,1.68,0,0,0,0,2.4L16.51,18,7.12,27.08a1.7,1.7,0,0,0,2.36,2.44h0L21.4,18,9.48,6.47A1.69,1.69,0,0,0,7.08,6.52Z" class="clr-i-outline clr-i-outline-path-1"></path><path d="M26.49,5a1.7,1.7,0,0,0-1.7,1.7V29.3a1.7,1.7,0,0,0,3.4,0V6.7A1.7,1.7,0,0,0,26.49,5Z" class="clr-i-outline clr-i-outline-path-2"></path></svg></clr-icon>';
      }
    }

    if (typeof forward !== 'undefined') {
      for (let i = 0; i < forward.length; i++) {
        forward[i].classList.remove('icon-seek-end');
        forward[i].classList.remove('fa-angle-double-right');
        forward[i].innerHTML =
          '<clr-icon shape="step-forward-2 up" role="none"><svg version="1.1" viewBox="0 0 36 36" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" focusable="false" role="img"><path d="M7.08,6.52a1.68,1.68,0,0,0,0,2.4L16.51,18,7.12,27.08a1.7,1.7,0,0,0,2.36,2.44h0L21.4,18,9.48,6.47A1.69,1.69,0,0,0,7.08,6.52Z" class="clr-i-outline clr-i-outline-path-1"></path><path d="M26.49,5a1.7,1.7,0,0,0-1.7,1.7V29.3a1.7,1.7,0,0,0,3.4,0V6.7A1.7,1.7,0,0,0,26.49,5Z" class="clr-i-outline clr-i-outline-path-2"></path></svg></clr-icon>';
      }
    }

    if (typeof sback !== 'undefined') {
      for (let i = 0; i < sback.length; i++) {
        sback[i].classList.remove('icon-seek-prev');
        sback[i].classList.remove('fa-angle-left');
        sback[i].innerHTML =
          '<clr-icon shape="angle left" role="none"><svg version="1.1" viewBox="0 0 36 36" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" focusable="false" role="img"><path class="clr-i-outline clr-i-outline-path-1" d="M29.52,22.52,18,10.6,6.48,22.52a1.7,1.7,0,0,0,2.45,2.36L18,15.49l9.08,9.39a1.7,1.7,0,0,0,2.45-2.36Z"></path></svg></clr-icon>';
      }
    }

    if (typeof sforward !== 'undefined') {
      for (let i = 0; i < sforward.length; i++) {
        sforward[i].classList.remove('icon-seek-next');
        sforward[i].classList.remove('fa-angle-right');
        sforward[i].innerHTML =
          '<clr-icon shape="angle right" role="none"><svg version="1.1" viewBox="0 0 36 36" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" focusable="false" role="img"><path class="clr-i-outline clr-i-outline-path-1" d="M29.52,22.52,18,10.6,6.48,22.52a1.7,1.7,0,0,0,2.45,2.36L18,15.49l9.08,9.39a1.7,1.7,0,0,0,2.45-2.36Z"></path></svg></clr-icon>';
      }
    }

    let ulelem = document.querySelectorAll<HTMLElement>('.pagination');
    if (typeof ulelem !== 'undefined') {
      for (let i = 0; i < ulelem.length; i++) {
        ulelem[i].classList.add('page-ul-list');
      }
    }

    let ilelem = document.querySelectorAll<HTMLElement>('.page-item');

    if (typeof ilelem !== 'undefined') {
      for (let i = 0; i < ilelem.length; i++) {
        ilelem[i].classList.add('page-list-item');
      }
    }

    let elm = document.querySelectorAll<HTMLElement>('.form-control');
    if (typeof elm !== 'undefined' && elm !== null) {
      for (let i = 0; i < elm.length; i++) {
        elm[i].classList.add('fix-pagination');
      }
    }

    let inputs = document.querySelectorAll<HTMLElement>('input');

    if (typeof inputs !== 'undefined') {
      for (let i = 0; i < inputs.length; i++) {
        inputs[i].style.background = 'transparent';
        inputs[i].style.boxShadow = 'none';
        inputs[i].style.borderRadius = '1px';
      }
    }
    /*
    let span = document.querySelectorAll<HTMLElement>('.slick-pagination-settings');
    if (typeof span !== 'undefined') {
       for (let i = 0; i < span.length; i++) {
         span[i].style.paddingTop = '0.7em';
       }
    }
*/
    let settings = document.querySelectorAll<HTMLElement>(
      '.slick-pagination-settings'
    );

    if (typeof settings !== 'undefined') {
      for (let i = 0; i < settings.length; i++) {
        settings[i].style.paddingTop = '0.7em';
        settings[i].style.width = '22em';
      }
    }

    let sp = document.querySelectorAll<HTMLElement>('.slick-pagination');

    if (typeof sp !== 'undefined') {
      for (let i = 0; i < sp.length; i++) {
        sp[i].style.marginTop = '2.0em';
      }
    }

    let elems = document.querySelectorAll<HTMLElement>('select');
    if (typeof elems !== 'undefined') {
      for (let i = 0; i < elems.length; i++) {
        elems[i].style.width = '4.0em';
        elems[i].style.marginTop = '-0.3em';
        elems[i].style.color = '#808080';
        elems[i].style.outline = '0';
        elems[i].style.boxShadow = 'none';
        elems[i].style.borderRadius = '0';
        elems[i].style.borderLeft = 'none';
        elems[i].style.borderRight = 'none';
        elems[i].style.borderTop = 'none';
        elems[i].style.marginRight = '1.0em';
      }
    }
  }

  isDescendant(parent: any, child: any) {
    let node = child.parentNode;
    while (node) {
      if (node === parent) {
        return true;
      }
      node = node.parentNode;
    }

    return false;
  }

  readId(id: number): string {
    let hash: any = Math.random()
      .toString(36)
      .slice(2, 7);
    return `${id}-${hash}`;
  }

  readDataSet(): any {
    return this.datasets[this.uniqueId];
  }

  setDataView(): void {
    set_item('data_view_id', this.data_view_id);
    this.fixHeader();
  }

  resetColumns(): void {
    this.dataset = this.origDataSet;
    this.columnDefinitions = this.origColumnDefinitions;
    for (let i = 0; i < this.columns.length; i++) {
      let column = this.columns[i];
      this.columnsOnOff[column.id] = true;
    }
    this.angularGrid.resizerService.resizeGrid();
  }

  fixHeader(): void {
    let height = 0;

    let names = document.querySelectorAll<HTMLElement>('.slick-column-name');
    if (typeof names !== 'undefined') {
      for (let i = 0; i < names.length; i++) {
        names[i].style.cssText =
          'margin-top: -0.7em !important;min-width: 9em !important;';
      }
    }

    let headers = document.querySelectorAll<HTMLElement>(
      '.ui-state-default .slick-header-column'
    );
    if (typeof headers !== 'undefined') {
      for (let i = 0; i < headers.length; i++) {
        //            headers[i].style.zIndex = '1';
        headers[i].style.marginLeft = '-1.5em';
        headers[i].style.marginTop = '0.0em';
        headers[i].style.background = '#FAFAFA';
        headers[i].style.borderTop = '1px solid #cccccc';
        headers[i].style.borderRight = '1px solid #cccccc';
        headers[i].style.marginRight = '1.5em';
        headers[i].style.borderBottom = '1px solid #cccccc';
        headers[i].style.paddingTop = '1.5em';
        headers[i].style.paddingBottom = '0.5em';
        headers[i].style.marginBottom = '-1.0em';
        headers[i].style.fontSize = '0.9em';
        headers[i].style.textAlign = 'center';
        headers[i].style.display = 'table-cell';
        headers[i].style.verticalAlign = 'middle';
        headers[i].style.color = '#8B8B8B';
        headers[i].style.height = '3.0em';
        headers[i].classList.add('header-item');
        headers[i].addEventListener('mouseover', function(event) {
          headers[i].classList.add('header-item-over');
          headers[i].style.background = '#E8E8E8';
        });

        headers[i].addEventListener('mouseout', function(event) {
          if (typeof hds[i] !== 'undefined' && hds[i] !== null) {
            hds[i].style.background = '#FAFAFA';
          }
        });
        headers[i].addEventListener('keyup', function(event) {});
      }
    }
    let head = document.querySelectorAll<HTMLElement>(
      `.${this.currentClass} .slick-header-columns`
    );

    if (typeof head !== 'undefined' && head !== null) {
      for (let i = 0; i < head.length; i++) {
        head[i].style.marginLeft = '-1.7em';
      }
    }

    let hdl = document.querySelectorAll<HTMLElement>(
      `.${this.currentClass} .slick-header-left`
    );
    if (typeof hdl !== 'undefined') {
      for (let i = 0; i < hdl.length; i++) {
        hdl[i].style.paddingLeft = '2.0.5em';
        hdl[i].style.height = '3.7em';
        hdl[i].style.marginTop = '-0.5em';
        hdl[i].style.marginLeft = '-0.2em';
      }
    }

    let hds = document.querySelectorAll<HTMLElement>(
      `.${this.currentClass} .slick-header-column`
    );
    if (typeof hds !== 'undefined') {
      for (let i = 0; i < hds.length; i++) {
        //            headers[i].style.zIndex = '1';
        hds[i].style.marginLeft = '-1.5em';
        hds[i].style.marginTop = '0.0em';
        hds[i].style.background = '#FAFAFA';
        hds[i].style.borderTop = '1px solid #cccccc';
        hds[i].style.borderRight = '1px solid #cccccc';
        hds[i].style.borderBottom = '1px solid #cccccc';
        hds[i].style.color = '#8B8B8B';
        hds[i].style.fontSize = '0.9em';
        hds[i].style.textAlign = 'center';
        hds[i].style.display = 'table-cell';
        hds[i].style.verticalAlign = 'middle';
        hds[i].style.marginRight = '1.5em';
        hds[i].style.paddingTop = '1.5em';
        hds[i].style.marginBottom = '-1.0em';
        hds[i].style.paddingBottom = '0.5em';
        hds[i].style.height = '3.0em';
        hds[i].classList.add('header-item');
        hds[i].addEventListener('mouseover', function(event) {
          hds[i].style.background = '#E8E8E8';
        });
        hds[i].addEventListener('mouseout', function(event) {
          hds[i].style.background = '#FAFAFA';
        });
        hds[i].addEventListener('keyup', function(event) {});
      }
    }

    let sps = document.querySelectorAll<HTMLElement>(
      `.${this.currentClass} .slick-pane`
    );
    if (typeof sps !== 'undefined' && sps !== null) {
      for (let i = 0; i < sps.length; i++) {
        //   sps[i].style.position = 'relative';
      }
    }

    let slc = document.querySelectorAll<HTMLElement>(
      `.${this.currentClass} .slick-custom`
    );

    if (typeof slc !== 'undefined') {
      for (let i = 0; i < slc.length; i++) {
        slc[i].style.position = 'absolute';
        slc[i].style.background = '#FAFAFA';
        slc[i].style.paddingLeft = '2.0.5em';
        slc[i].style.marginTop = '0.01em';
        slc[i].style.height = '3.15em';
      }
    }

    let sorts = document.querySelectorAll<HTMLElement>(
      `.${this.currentClass} .slick-sort-indicator`
    );
    if (typeof sorts !== 'undefined' && sorts !== null) {
      for (let i = 0; i < sorts.length; i++) {
        sorts[i].style.color = '#8B8B8B';
        sorts[i].style.marginTop = '0.6em';
      }
    }
    let menubuttons = document.querySelectorAll<HTMLElement>(
      `.${this.currentClass} .slick-header-menubutton`
    );
    let self = this;
    if (typeof menubuttons !== 'undefined' && menubuttons !== null) {
      for (let i = 0; i < menubuttons.length; i++) {
        menubuttons[i].style.marginTop = '-0.8em';
        menubuttons[i].style.height = '1.6em';
        menubuttons[i].style.width = '0.6em';
        menubuttons[i].style.textAlign = 'right';
        menubuttons[i].style.float = 'right';
        menubuttons[i].style.position = 'relative';
        menubuttons[i].style.marginRight = '0.2em';
        menubuttons[i].style.background = 'transparent';
        menubuttons[i].style.color = '#8B8B8B';
        menubuttons[i].id = `${this.currentClass}-menu-${i}`;
        menubuttons[i].style.display = 'block';
        menubuttons[
          i
        ].innerHTML = `<i class="fas fa-ellipsis-v ellipsis-spacer" id="header-menu-${i}"></i>`; //<fa-icon [icon]="faEllipsisVIcon"  aria="Menu"></fa-icon>';
        menubuttons[i].addEventListener('click', function(e: any) {
          self.menus[`menu-${i}`] = !self.menus[`menu-${i}`];
          self.preprocessMenuButtons(i);
          //                      if (self.menus[`menu-${i}`]) {
          //                    } else {
          //                    self.closeHeaderMenu(i);
          //   }
        });
        //  let close = document.getElementById(`header-menu-${i}`);
        // close.addEventListener('click', function(e:any) {
        //   /  self.preprocessMenuButtons(i);
        //  });
      }
    }
    let searches = document.querySelectorAll<HTMLElement>('.search-filter');

    let fls = document.querySelectorAll<HTMLElement>('.flatpickr');
    if (typeof fls !== 'undefined' && fls !== null) {
      for (let i = 0; i < fls.length; i++) {
        //   fls[i].style.fontFamily = 'var(--slick-multiselect-input-filter-font-family, "Helvetica Neue", Helvetica, Arial)';
        //   fls[i].style.fontSize = 'var(--slick-multiselect-input-filter-font-size, 12px)';
        //    fls[i].style.color = '#8B8B8B';
        //   fls[i].style.fontWeight = 'bold';
        //       fls[i].style.marginLeft = '-0.5em';
      }
    }
    let left = document.querySelectorAll<HTMLElement>('.l0');
    if (typeof left !== 'undefined' && left !== null) {
      for (let i = 0; i < left.length; i++) {
        left[i].innerHTML = ' ';
      }
    }
    let hdrs = document.querySelectorAll<HTMLElement>('.slick-header');
    if (typeof hdrs !== 'undefined' && hdrs !== null) {
      for (let i = 0; i < hdrs.length; i++) {
        hdrs[i].style.paddingLeft = '2.0.5em';
        hdrs[i].style.marginLeft = '-0.2em';
      }
    }

    let actives = document.querySelectorAll<HTMLElement>(
      '.form-control .input'
    );
    if (typeof actives !== 'undefined' && actives !== null) {
      for (let i = 0; i < actives.length; i++) {
        actives[i].style.fontFamily =
          'var(--slick-multiselect-input-filter-font-family, "Helvetica Neue", Helvetica, Arial)';
        actives[i].style.fontSize =
          'var(--slick-multiselect-input-filter-font-size, 12px)';
        actives[i].style.color = '#8B8B8B';
        actives[i].style.fontWeight = 'bold';
      }
    }

    let selects = document.querySelectorAll<HTMLElement>('.ms-filter');

    if (typeof selects !== 'undefined' && selects !== null) {
      for (let i = 0; i < selects.length; i++) {
        //        selects[i].classList.remove('search-filter');
        selects[i].classList.remove('filter-effort-driven');
        selects[i].classList.remove('filled');
        selects[i].style.color = '#8B8B8B';
        selects[i].style.fontWeight = 'bold';
      }
    }

    let buttons = document.querySelectorAll<HTMLElement>('.ms-choice');
    if (typeof buttons !== 'undefined' && buttons !== null) {
      for (let i = 0; i < buttons.length; i++) {
        buttons[i].style.borderRadius = 'none';
        buttons[i].style.border = 'none';
        buttons[i].style.borderTopLeftRadius = '0';
        buttons[i].style.borderTopRightRadius = '0';
        buttons[i].style.borderBottomLeftRadius = '0';
        buttons[i].style.borderBottomRightRadius = '0';
        buttons[i].style.outline = 'none';
        buttons[i].style.borderBottom = '1px solid #8B8B8B';
      }
    }

    let checks = document.querySelectorAll<HTMLSelectElement>(
      `.${this.currentClass} .input-group-addon`
    );

    if (typeof checks !== 'undefined' && checks !== null) {
      for (let i = 0; i < checks.length; i++) {
        checks[i].style.border = 'none';
        checks[i].style.width = '1.6em';
      }
    }

    let menuSelects = document.querySelectorAll<HTMLSelectElement>(
      `.${this.currentClass} .input-group-addon select`
    );
    if (typeof menuSelects !== 'undefined') {
      for (let i = 0; i < menuSelects.length; i++) {
        if (
          !menuSelects[i].classList.toString().includes('filter-select-button')
        ) {
          menuSelects[i].classList.add('filter-select-button');
        }
      }
    }

    let filterColumns = document.querySelectorAll<HTMLSelectElement>(
      `.${this.currentClass} .ui-state-default .slick-headerrow-column`
    );

    for (let i = 0; i < filterColumns.length; i++) {
      filterColumns[i].id = `${this.currentClass}-filter-column-${i}`;
      filterColumns[i].style.marginLeft = '-1.45em';
      filterColumns[i].style.marginRight = '1.0em';
      filterColumns[i].style.paddingLeft = '0.0em';
      /* pay attention */
      //        filterColumns[i].style.width = '8.0em';
      filterColumns[i].style.paddingRight = '0.5em';
      filterColumns[i].classList.add('filter-hidden');
      if (
        filterColumns[i].innerHTML.toString().includes('form-group') ||
        filterColumns[i].innerHTML.toString().includes('ms-filter') ||
        filterColumns[i].innerHTML.toString().includes('search-filter')
      ) {
        filterElements[`filter-${i}`] = filterColumns[i].innerHTML;
      } else {
        filterElements[`filter-${i}`] = null;
      }
    }

    let filters = document.querySelectorAll<HTMLSelectElement>(
      `.${this.currentClass} .input-group-prepend .form-control`
    );

    if (typeof filters !== 'undefined' && filters !== null) {
      for (let i = 0; i < filters.length; i++) {
        //     filters[i].classList.remove('form-control');
        //    filters[i].classList.add('form-control-updated');

        filters[i].style.border = 'none';
        filters[i].style.borderRadius = 'none';
        filters[i].style.borderBottomLeftRadius = '0';
        filters[i].style.borderBottomRightRadius = '0';
        filters[i].style.boxShadow = 'none';
        filters[i].style.borderBottom = '1px solid #8B8B8B';
        let elem = filters[i];
        //  elem.parentElement.parentElement.parentElement.classList.remove('search-filter');

        filters[i].addEventListener('change', function(e: any) {
          if (filters[i] instanceof HTMLSelectElement) {
            if (elem.value.length == 0) {
              elem.parentElement.parentElement.parentElement.classList.remove(
                'search-filter'
              );
            } else {
              elem.parentElement.parentElement.parentElement.classList.add(
                'search-filter'
              );
            }
          }
        });
      }
      let srchs = document.querySelectorAll<HTMLSelectElement>('.filter-start');
      if (typeof srchs !== 'undefined') {
        for (let i = 0; i < srchs.length; i++) {
          //     srchs[i].classList.remove('search-filter');
        }
      }
    }

    /*
     let updatedFilters = document.querySelectorAll<HTMLElement>('.slick-headerrow-column');
     let headermenus = document.querySelectorAll<HTMLElement>('.slick-header-menu');
     if (typeof headermenus !== 'undefined' && headermenus !== null) {
         console.log(`TOTAL HEADER MENUS ${headermenus.length}`);
         for (let i = 0; i < headermenus.length; i++) {
             let inner = headermenus[i].innerHTML;
             let append = '<div class="slick-header-menuitem"><div class="slick-header-menuicon fa fa-filter"></div><span class="slick-header-menucontent">Apply Filter</span></div>';
             let appendNew = append+inner;
             headermenus[i].innerHTML = appendNew;
             this.menuElements[`menu-${i}`] = headermenus[i];
         }
     }
  */
    /*
     let filters = document.querySelectorAll<HTMLElement>('.input-group-prepend');
     if (typeof filters !== 'undefined' && filters !== null) {
        for (let i = 0; i < filters.length; i++) {
          let append = '<i class="fas fa-caret-down" style="background:none;width:1.6em;height:1.6em;"></i>';
          filters[i].style.background = 'none';
          filters[i].style.borderRadius = 'none';
          filters[i].style.border = 'none';
          filters[i].style.borderBottomLeftRadius = '0';
          filters[i].style.borderBottomRightRadius = '0';

          if (!filters[i].innerHTML.toString().includes('caret')) {
              let inner = filters[i].innerHTML;

              let wrapper_open = '<div class="filter-box">';
              let control = '';//`<div class="filter-box-control">${append}</div>`;
              let picker = `<div class="filter-box-picker">${inner}</div>`;
              let wrapper_close = '</div>';


            //  let total = wrapper_open+control+picker+wrapper_close;
              //filters[i].innerHTML = total;

          }
        }     
     
     }

*/
  }
  hideHeader(): void {
    this.showTitle = false;
    let headers = document.querySelectorAll<HTMLElement>(`.slick-pane-header`);
    let parents = document.querySelectorAll<HTMLElement>(
      `.${this.currentClass}`
    );

    if (typeof headers !== 'undefined' && headers !== null) {
      for (let i = 0; i < headers.length; i++) {
        if (typeof parents !== 'undefined' && parents !== null) {
          if (parents.length > 0) {
            if (this.isDescendant(parents[0], headers[i])) {
              headers[i].style.display = 'hidden';
            }
          }
        }
      }
    }
    this.hideGrid();
  }

  showHeader(): void {
    this.showTitle = true;

    let headers = document.querySelectorAll<HTMLElement>(
      `.${this.currentClass} .slick-pane-header`
    );
    if (typeof headers !== 'undefined' && headers !== null) {
      for (let i = 0; i < headers.length; i++) {
        headers[i].style.display = 'block';
      }
    }
    this.showGrid();
  }

  preprocessMenuButtons(i: any) {
    let menus = document.querySelectorAll<HTMLElement>('.slick-header-menu');
    if (typeof menus !== 'undefined') {
      if (
        !menus[0].innerHTML.includes('added') &&
        filterElements[`filter-${i + 1}`]
      ) {
        let self = this;
        let menu = menus[0];

        // menu.style.right = '1.3em';
        let inner = menu.innerHTML;
        //    let append = `<div class="slick-header-menuitem added">${filterElements[`filter-${i}`]}</div>`;
        let append =
          `<div class="slick-header-menuitem added" id="submenu-${i +
            1}"><div id="submenu-filter-${i + 1}"` +
          `  class="slick-header-menuicon fa fa-filter" >` +
          `</div><span  id="submenu-word-filter-${i +
            1}" class="slick-header-menucontent">Apply Filter</span></div>`;
        let appendNew = append + inner;
        menu.innerHTML = appendNew;
        let hideCol = document.querySelectorAll<HTMLElement>('.fa-times');
        if (typeof hideCol !== 'undefined' && hideCol !== null) {
          let sort = hideCol[0];

          sort.addEventListener('click', function(e: any) {
            self.hideColumn(i);
          });

          sort.nextElementSibling.addEventListener('click', function(e: any) {
            self.hideColumn(i);
          });
        }

        let unSort = document.querySelectorAll<HTMLElement>('.fa-unsorted');
        if (typeof unSort !== 'undefined' && unSort !== null) {
          let sort = unSort[0];

          sort.addEventListener('click', function(e: any) {
            self.applyUnSort(i);
          });

          sort.nextElementSibling.addEventListener('click', function(e: any) {
            self.applyUnSort(i);
          });
        }

        let ascSort = document.querySelectorAll<HTMLElement>(
          '.fa-sort-amount-asc'
        );

        if (typeof ascSort !== 'undefined' && ascSort !== null) {
          let sort = ascSort[0];

          sort.addEventListener('click', function(e: any) {
            self.applySortAsc(i);
          });

          sort.nextElementSibling.addEventListener('click', function(e: any) {
            self.applySortAsc(i);
          });
        }

        let descSort = document.querySelectorAll<HTMLElement>(
          '.fa-sort-amount-desc'
        );
        if (typeof descSort !== 'undefined' && descSort !== null) {
          let sort = descSort[0];

          sort.addEventListener('click', function(e: any) {
            self.applySortDesc(i);
          });

          sort.nextElementSibling.addEventListener('click', function(e: any) {
            self.applySortDesc(i);
          });
        }

        let hideFilters = document.querySelectorAll<HTMLElement>(
          '.text-danger'
        );

        if (typeof hideFilters !== 'undefined' && hideFilters !== null) {
          for (let j = 0; j < hideFilters.length; j++) {
            hideFilters[j].addEventListener('click', function(e: any) {
              self.removeFilter(i);
            });
            hideFilters[j].nextElementSibling.addEventListener(
              'click',
              function(e: any) {
                self.removeFilter(i);
              }
            );
          }
        }
        //   this.filters[`filter-${i+1}`]=false;
        let subm = document.getElementById(`submenu-filter-${i + 1}`);
        if (typeof subm !== 'undefined' && subm !== null) {
          subm.addEventListener('click', function(e: any) {
            self.toggleFilter(i);
          });
        }

        let submw = document.getElementById(`submenu-word-filter-${i + 1}`);
        if (typeof submw !== 'undefined' && submw !== null) {
          submw.addEventListener('click', function(e: any) {
            self.toggleFilter(i);
          });
        }
      }
    }

    /*
     let headermenus = document.querySelectorAll<HTMLElement>('.slick-header-menu');

     if (typeof headermenus !== 'undefined' && headermenus !== null) {
         console.log(`TOTAL HEADER MENUS ${headermenus.length}`);
         for (let i = 0; i < headermenus.length; i++) {
             let inner = headermenus[i].innerHTML;
             let append = '<div class="slick-header-menuitem added"><div class="slick-header-menuicon fa fa-filter"></div><span class="slick-header-menucontent">Apply Filter</span></div>';
             if (filterElements[`filter-${i}`] !== null) {
                 append = filterElements[`filter-${i}`]; //'<div class="slick-header-menuitem"><div class="slick-header-menuicon fa fa-filter"></div><span class="slick-header-menucontent">Apply Filter</span></div>';
             }
             let appendNew = append+inner;
             headermenus[i].innerHTML = appendNew; 
         }
     }
*/
  }

  onSelectedRowsChanged(e: any, a: any) {
    this.fixChart(false);
    this.fixPagination();
    this.setDataView();
    this.fixHeader();
  }

  onCellClicked(e: any, a: any) {
    this.fixChart(false);
    this.fixPagination();
    this.setDataView();
    this.fixHeader();
  }

  onDrag(e: any, a: any) {
    this.fixChart(false);
    this.fixPagination();
    this.setDataView();
    this.fixHeader();
  }

  onDragEnd(e: any, a: any) {
    this.fixChart(false);
    this.fixPagination();
    this.setDataView();
    this.fixHeader();
  }

  onHeaderClick(e: any, a: any) {
    this.fixChart(false);
    this.fixPagination();
    this.setDataView();
    this.fixHeader();
  }

  onColumnsReordered(e: any, a: any) {
    setTimeout(() => {
      let hds = document.querySelectorAll<HTMLElement>('.slick-header-column');
      if (typeof hds !== 'undefined') {
        for (let i = 0; i < hds.length; i++) {
          hds[i].click();
          this.fixHeader();
        }
      }
    }, 100);
  }

  toggleFilter(i: any) {
    let filter = document.getElementById(
      `${this.currentClass}-filter-column-${i + 1}`
    );
    filter.classList.remove('filter-hidden');
    filter.classList.add('filter-active');
    let added: boolean = false;
    let fl = document.querySelectorAll<HTMLElement>('.filter-open');
    if (typeof fl !== 'undefined') {
      for (let i = 0; i < fl.length; i++) {
        fl[i].classList.remove('filter-open');
      }
    }

    let select = document.querySelectorAll<HTMLSelectElement>(
      '.input-group-addon .form-control'
    );
    if (typeof select !== 'undefined' && select !== null) {
      for (let i = 0; i < select.length; i++) {
        let borderRadius =
          'border-radius: 0 !important; -webkit-border-radius: 0 !important; -moz-border-radius: 0 !important';

        select[i].style.cssText = `${borderRadius}`;
        //    select[i].style.cssText = 'margin-left: 0.7em !important;margin-right: -0.7em !important;';
      }
    }

    let filters = document.querySelectorAll<HTMLElement>('.compound-input');
    if (typeof filters !== 'undefined') {
      for (let i = 0; i < filters.length; i++) {
        if (!filters[i].classList.toString().includes('filter-open')) {
          filters[i].classList.add('filter-open');
          filters[i].classList.add('updated');
          filters[i].style.border = 'none !important';
          filters[i].style.marginLeft = '0em !important';
          filters[i].style.borderRadius = '0 !important';
        }
      }
    }

    let addons = document.querySelectorAll<HTMLElement>('.input-group-addon');
    if (typeof addons !== 'undefined' && addons !== null) {
      for (let i = 0; i < addons.length; i++) {
        let addonNext = addons[i].nextSibling as HTMLElement;
        let addon = addons[i];
        addon.classList.remove('operator');
        addon.style.border = 'none';
        addon.style.width = '1.5em';
        //addonNext.style.marginLeft = '-0.31em';
        let select = addon.firstChild as HTMLSelectElement;
        select.addEventListener('change', function(e: any) {
          if (!added) {
            addon.classList.add('operator');
            added = true;
          }
          /*
          if (select.value == '') {
            addonNext.style.marginLeft = '-0.31em';
          } else if (select.value.length === 1) {
            addonNext.style.marginLeft = '-0.70em';
          } else if (select.value.length > 1) {
            addonNext.style.marginLeft = '-0.4em';
          }
*/
        });

        let addonNextSib = addon.nextSibling as HTMLElement;
        let hidden = addonNextSib.firstChild as HTMLInputElement;
        if (typeof hidden !== 'undefined' && hidden !== null) {
          hidden.addEventListener('change', function(e) {
            let hiddenNext = hidden.nextSibling as HTMLInputElement;
            hiddenNext.style.color = '#8B8B8B';
            hiddenNext.style.fontFamily =
              'var(--clr-font, Metropolis, "Avenir Next", "Helvetica Neue", Arial, sans-serif)';
          });
        }
      }
    }
    /* 
     let parentControls = document.querySelectorAll<HTMLSelectElement>('.input-group .flatpickr');

     if (typeof parentControls !== 'undefined') {
         const xDiv = document.createElement("div");
         xDiv.classList.add('close-filter');
         xDiv.innerHTML='x';
         xDiv.style.width='1.5em'; 
         xDiv.style.height='1.5em';
         parentControls[0].appendChild(xDiv);
      } 
*/
    let formControls = document.querySelectorAll<HTMLSelectElement>(
      '.input-group-addon .form-control'
    );
    if (typeof formControls !== 'undefined' && formControls !== null) {
      for (let i = 0; i < formControls.length; i++) {
        formControls[i].style.color = '#8B8B8B';

        formControls[i].style.fontFamily =
          'var(--slick-multiselect-input-filter-font-family, "Helvetica Neue", Helvetica, Arial)';
        formControls[i].style.fontSize =
          'var(--slick-multiselect-input-filter-font-size, 12px)';
        formControls[i].addEventListener('change', function(e: any) {
          formControls[i].style.color = '#8B8B8B';
          formControls[i].style.fontFamily =
            'var(--clr-font, Metropolis, "Avenir Next", "Helvetica Neue", Arial, sans-serif)';
        });
        formControls[i].addEventListener('click', function(e: any) {
          formControls[i].style.color = '#8B8B8B';
          formControls[i].style.fontFamily =
            'var(--clr-font, Metropolis, "Avenir Next", "Helvetica Neue", Arial, sans-serif)';
        });
      }
    }
    formControls = document.querySelectorAll<HTMLSelectElement>(
      '.form-control .input'
    );
    if (typeof formControls !== 'undefined' && formControls !== null) {
      for (let i = 0; i < formControls.length; i++) {
        formControls[i].style.color = '#8B8B8B';
        formControls[i].style.fontFamily =
          'var(--slick-multiselect-input-filter-font-family, "Helvetica Neue", Helvetica, Arial)';
        formControls[i].style.fontSize =
          'var(--slick-multiselect-input-filter-font-size, 12px)';
        formControls[i].addEventListener('change', function(e: any) {
          formControls[i].style.color = '#8B8B8B';
        });
        formControls[i].addEventListener('click', function(e: any) {
          formControls[i].style.color = '#8B8B8B';
        });
      }
    }
    let filterRoom = document.querySelectorAll<HTMLSelectElement>(
      '.filter-room'
    );

    if (typeof filterRoom !== 'undefined' && filterRoom !== null) {
      for (let i = 0; i < filterRoom.length; i++) {
        let border = 'border-bottom: 1px solid #cccccc !important;';
        let color = 'color: #8B8B8B;';
        let colorVar = 'color: var(--slick-menu-close-btn-color, #8B8B8B);';
        let fontFamily =
          'font-family: "Calibri Light", "Helvetica Neue", Arial, sans-serif;';
        let fontFamilyVar =
          'font-family: var(--slick-menu-close-btn-font-family, "Calibri Light", "Helvetica Neue", Arial, sans-serif);';
        filterRoom[i].style.cssText =
          filterRoom[i].style.cssText +
          `${border}${color}${colorVar}${fontFamily}${fontFamilyVar}`;
      }
    }

    let filt = document.querySelectorAll<HTMLSelectElement>(
      '.filter-active .filter-open'
    );
    for (let i = 0; i < filt.length; i++) {
      if (!filt[i].classList.toString().includes('filter-radius')) {
        filt[i].classList.add('filter-radius');
        filt[i].style.borderRadius = '0';
        filt[i].style.cssText =
          'border-radius:0 !important; background:transparent;';

        let color = 'color: #8B8B8B;';
        let colorVar = 'color: var(--slick-menu-close-btn-color, #8B8B8B);';
        let fontFamily =
          'font-family: "Calibri Light", "Helvetica Neue", Arial, sans-serif;';
        let fontFamilyVar =
          'font-family: var(--slick-menu-close-btn-font-family, "Calibri Light", "Helvetica Neue", Arial, sans-serif);';

        filt[i].style.cssText =
          filt[i].style.cssText +
          `${color}${colorVar}${fontFamily}${fontFamilyVar}`;
      }
    }

    this.closeHeaderMenu(i);
  }

  removeFilter(i: any) {
    let v: number = eval(i) + 1;
    let filter = document.getElementById(
      `${this.currentClass}-filter-column-${v}`
    );
    filter.classList.remove('filter-active');
    filter.classList.add('filter-hidden');
    this.closeHeaderMenu(i);
    let column = this.columns[i];
    this.angularGrid.filterService.clearFilterByColumnId(
      'click' as any,
      column.id
    );
    this.angularGrid.resizerService.resizeGrid();
    this.closeHeaderMenu(i);
  }

  applySortAsc(i: number) {
    let column = this.columns[i];
    if (typeof column !== 'undefined' && column !== null) {
      if (
        typeof this.angularGrid !== 'undefined' &&
        this.angularGrid !== null
      ) {
        this.angularGrid.sortService.updateSorting([
          { columnId: column.id, direction: 'ASC' }
        ]);
      }
    }
    this.closeHeaderMenu(i);
  }

  applySortDesc(i: number) {
    let column = this.columns[i];
    if (typeof column !== 'undefined' && column !== null) {
      if (
        typeof this.angularGrid !== 'undefined' &&
        this.angularGrid !== null
      ) {
        this.angularGrid.sortService.updateSorting([
          { columnId: column.id, direction: 'DESC' }
        ]);
      }
    }
    this.closeHeaderMenu(i);
  }

  applyUnSort(i: number) {
    let column = this.columns[i];
    this.angularGrid.sortService.clearSortByColumnId('click' as any, column.id);
    this.closeHeaderMenu(i);
  }

  hideColumn(i: number): void {
    let column = this.columns[i];
    this.toggleColumn(column);
  }

  clearSelected(select: any) {
    let elements = select.selectedOptions;

    for (var i = 0; i < elements.length; i++) {
      elements[i].selected = false;
    }
  }

  getId(): any {
    return Math.floor(Math.random() * 100);
  }

  readTimeDomain(time_domain_id: number): string {
    return this.time_domains[`time_domain_${time_domain_id}`];
  }

  initHeader(): void {}

  hideGrid(): void {
    this.showTitle = false;
    let panes = document.querySelectorAll<HTMLElement>(
      `.slick-wrapper-${this.currentClass} .slick-pane-header`
    );
    if (typeof panes !== 'undefined' && panes !== null) {
      for (let i = 0; i < panes.length; i++) {
        panes[i].style.cssText = 'display:none !important;';
      }
    }
  }

  showGrid(): void {
    let grids = document.querySelectorAll<HTMLElement>(
      `.slick-wrapper-${this.currentClass} .slick-pane-header`
    );
    if (typeof grids !== 'undefined' && grids !== null) {
      if (grids.length > 0) {
        for (let i = 0; i < grids.length; i++) {
          grids[i].style.cssText = 'display:block !important;';
        }
      }
    }
  }

  togglePauseResizer() {
    this.resizerPaused = !this.resizerPaused;
    this.angularGrid.resizerService.pauseResizer(this.resizerPaused);
  }

  updatePaginator() {
    let container = document.querySelectorAll<HTMLElement>(
      `.wrapper-${this.currentClass} slick-pagination`
    );
    let child = document.querySelectorAll<HTMLElement>(
      `.wrapper-${this.currentClass} .slick-pagination-nav`
    );
    let settings = document.querySelectorAll<HTMLElement>(
      `.wrapper-${this.currentClass} .slick-pagination-settings`
    );
    if (
      typeof child !== 'undefined' &&
      child !== null &&
      typeof settings !== 'undefined' &&
      settings !== null
    ) {
      if (child.length > 0 && settings.length > 0) {
        if (this.cols < 3) {
          //child[0].classList.remove('slick-pagination-nav');
          child[0].classList.add('slick-pagination-nav-updated');
          settings[0].classList.add('slick-pagination-settings-updated');
        } else {
          child[0].classList.remove('slick-pagination-nav-updated');
          settings[0].classList.remove('slick-pagination-settings-updated');
        }
      }
    }
  }
}
