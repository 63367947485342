<clr-modal [(clrModalOpen)]="openDataCommanderLiteModal" [clrModalSize]="'lg'" [clrModalClosable]="false">
    <h3 class="modal-title">Data Commander</h3>
    <div class="modal-body">
        <div class="fl-chart">
             <span class="spinner spinner-lg loading" [hidden]="!loadingCommander()">
                Refreshing...
             </span>
            <app-data-commander-chart #dataCommanderChart *ngIf="dataViewId" id="chart_dc-{{dataViewId}}"
                                  [dataviewID]="dataViewId"></app-data-commander-chart>
                          <div class="data-commander-settings">
                  <clr-dropdown (clrMenuPosition)="'bottom-right'">
                      <button clrDropdownToggle>
                          <clr-icon shape="settings" size="24"></clr-icon>

                      </button>
                      <clr-dropdown-menu>
                          <a (click)="downloadDataView()" clrDropdownItem>Download Data</a>
                      </clr-dropdown-menu>
                  </clr-dropdown>
              </div>
        </div>

    </div>
    <div class="modal-footer">
        <div class="dateranges clr-row">

                      <input id="fromPeriod"
                        size="8"
                        type="text"
                        [ngModel]="fromPeriod"
                        placeholder="From Date"
                        mode="daytime"
                        [theme]="'dp-material'"
                        [dpDayPicker]="timeIntervalConfig"
                        (ngModelChange)="updateFrom($event)"
                        /> &nbsp; &nbsp;


                      <input id="toPeriod"
                        size="8"
                        type="text"
                        [ngModel]="toPeriod"
                        placeholder="To Date"
                        mode="daytime"
                        [theme]="'dp-material'"
                        [dpDayPicker]="timeIntervalConfig"
                        (ngModelChange)="updateTo($event)"
                        /> &nbsp; &nbsp;
            <button type="button" class="btn btn-primary" (click)=getDateRangeData()>Run</button>
            <button type="button" class="btn btn-outline" (click)=cancel()>Exit</button>
        </div>
    </div>
</clr-modal>

