import { Action } from '@ngrx/store';

export enum UserSessionActionTypes {
  Authenticate = '[UserSession] Authenticate',
  CreateSession = '[UserSession] New data view',
  ReadSession = '[UserSession] New dashboard',
  UpdateSession = '[UserSession] Prime one event',
  DeleteSession = '[UserSession] Prime all events',
  RefreshSession = '[UserSession] Refresh Session',
  RecoverSession = '[UserSession] Recover Session',
  ExpireSession = '[UserSession] Expire Session',
  StartLogin = '[UserSession] Start Login',
  EndLogin = '[UserSession] EndLogin',
  FailedLogin = '[UserSession] FailedLogin',
  ForcedLogout = '[UserSession] ForceLogout',
  Authenticated = '[UserSession] Authenticated',
  Unauthenticated = '[UserSession] Unauthenticated'
}

export class AuthenticateAction implements Action {
  readonly type = UserSessionActionTypes.Authenticate;
  constructor(public payload: any) {}
}

export class CreateSessionAction implements Action {
  readonly type = UserSessionActionTypes.CreateSession;
  constructor(public payload: any) {}
}

export class ReadSessionAction implements Action {
  readonly type = UserSessionActionTypes.ReadSession;
  constructor(public payload: any) {}
}

export class UpdateSessionAction implements Action {
  readonly type = UserSessionActionTypes.UpdateSession;
  constructor(public payload: any) {}
}

export class DeleteSessionAction implements Action {
  readonly type = UserSessionActionTypes.DeleteSession;
  constructor(public payload: any) {}
}

export class RefreshSessionAction implements Action {
  readonly type = UserSessionActionTypes.RefreshSession;
  constructor(public payload: any) {}
}

export class RecoverSessionAction implements Action {
  readonly type = UserSessionActionTypes.RecoverSession;
  constructor(public payload: any) {}
}

export class ExpireSessionAction implements Action {
  readonly type = UserSessionActionTypes.ExpireSession;
  constructor(public payload: any) {}
}

export class StartLoginAction implements Action {
  readonly type = UserSessionActionTypes.StartLogin;
  constructor(public payload: any) {}
}

export class EndLoginAction implements Action {
  readonly type = UserSessionActionTypes.EndLogin;
  constructor(public payload: any) {}
}

export class FailedLoginAction implements Action {
  readonly type = UserSessionActionTypes.FailedLogin;
  constructor(public payload: any) {}
}

export class ForcedLogoutAction implements Action {
  readonly type = UserSessionActionTypes.ForcedLogout;
  constructor(public payload: any) {}
}
export type UserSessionActions =
  | AuthenticateAction
  | RecoverSessionAction
  | CreateSessionAction
  | FailedLoginAction
  | ForcedLogoutAction
  | ReadSessionAction
  | UpdateSessionAction
  | DeleteSessionAction
  | RefreshSessionAction
  | ExpireSessionAction
  | StartLoginAction
  | EndLoginAction;
