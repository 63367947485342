import * as highCharts from 'highcharts';
import { highChartsBase } from './highcharts-base';

export const highChartsDualAxes = function(name, series, labels, xAxis, yAxis) {
    let threshold_guide_items = [];
    let event_period = null;
    let start_yaxis_on_min = 0;
    let last_calculated_yaxis_min = null;
    let first_reset_triggered = false;

    if (typeof labels=== 'undefined' || labels === null || labels.length <=0) {

    return Object.assign(
        {
     chart: {
            spacingTop: 20,
            zoomType: 'xy',
            renderTo:'container',
            defaultSeriesType:'column',
            borderWidth:0,
            backgroundColor:'#FFF',
            borderWidth:1,
            borderColor:'#FFF',
            plotBackgroundColor:'#fff',
            plotBorderWidth:1,
            plotBorderColor:'#FFF'
        },
        credits:{enabled:false},
    exporting:{enabled:false},
    title: { text: name, style: { fontWeight: 'bold' } },
            boost: {
              enabled: true,
              useGPUTranslations: true,
    },
   
    xAxis: xAxis,
    yAxis: yAxis,
    tooltip: {
        shared: true
    },
    legend: {
                enabled: true,
                symbolRadius: 1
    },
/*
    rangeSelector: {
                enabled: true
    },
*/
    navigator: {
         enabled: true,
         adaptToUpdatedData:true,
         xAxis: {
          labels: {
            style: { 
            },
            formatter: function() {
              return labels[this.value];
            }
          },
         }
    },
/*
    plotOptions: {
                series: {
                    marker: {
                        symbol: 'circle',
                        enabledThreshold: 5
                    }
                }
    },
    scrollbar: {
                height: 24,
                buttonArrowColor: 'gray',
                buttonBorderRadius: 1,
                rifleColor: 'gray',
                trackBackgroundColor: 'white',
                trackBorderWidth: 1,
                trackBorderColor: 'silver',
                trackBorderRadius: 1
    },
*/
    series: series
    },
        highChartsBase
    );

    } else {
    return Object.assign(
        {
     chart: {
            spacingTop: 20,
            zoomType: 'xy',
            renderTo:'container',
            defaultSeriesType:'column',
            borderWidth:0,
            backgroundColor:'#FFF',
            borderWidth:1,
            borderColor:'#FFF',
            plotBackgroundColor:'#fff',
            plotBorderWidth:1,
            plotBorderColor:'#FFF'
        },
        credits:{enabled:false},
    exporting:{enabled:false},
    title: { text: name, style: { fontWeight: 'bold' } },
            boost: {
              enabled: true,
              useGPUTranslations: true,
    },
   
    xAxis: xAxis,
    yAxis: yAxis,
/*
    plotOptions: {
                series: {
                    marker: {
                        symbol: 'circle',
                        enabledThreshold: 5
                    }
                }
    },
    scrollbar: {
                height: 24,
                buttonArrowColor: 'gray',
                buttonBorderRadius: 1,
                rifleColor: 'gray',
                trackBackgroundColor: 'white',
                trackBorderWidth: 1,
                trackBorderColor: 'silver',
                trackBorderRadius: 1
    },
*/
/*
    rangeSelector: {
                enabled: true
    },
*/
    navigator: {
         enabled: true,
         adaptToUpdatedData:true,
         xAxis: {
          labels: {
            style: {
            },
            formatter: function() {
              return labels[this.value];
            }
          },
         }
    },


    tooltip: {
        shared: true
    },
            legend: {
                enabled: true,
                symbolRadius: 1
            },
    series: series
    },
        highChartsBase
    );

    }
};



