import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { UserProfile } from '@modules/users/models/user-profile.model';
import { LocalService } from '../../../store/services/local.service';
import { isBoolean } from 'util';
import { BaseService } from '@core/services/base.service';
import { EventsService } from '@modules/events/services/events.service';

@Injectable()
export class UserProfileService extends BaseService {
  isAuthenticated = false;
  avatarUrl = '';
  name = '';
  id;
  company = {};
  username = '';
  settings = {};
  role;
  ws_token = '';
  localStorageSettings = {};
  localStorageDefaults = { showNav: true };

  constructor(
    protected local: LocalService,
    protected eventsService: EventsService,
    protected http: HttpClient
  ) {
    super(eventsService, http);
  }

  initWebStorageSettings(data) {
    this.localStorageSettings = this.local.readUserSettings(`${data.username}`);
    if (!this.localStorageSettings) {
      this.local.storeUserSettings(this.username);
      this.localStorageSettings = this.localStorageDefaults;
    }
  }

  getSettings() {
    return this.settings;
  }

  setStorageVal(key: string, val: any): void {
    this.localStorageSettings[key] = val;
    this.local.storeUserSettings(this.username);
  }

  getStorageVal(key: any) {
    return this.localStorageSettings[key];
  }

  showNav(val?: boolean) {
    if (isBoolean(val)) {
      this.setStorageVal('showNav', val);
    } else {
      return this.getStorageVal('showNav');
    }
  }

  getUserProfile(): Observable<UserProfile> {
    return this.http.get('/user_session').pipe(
      map((data: any) => {
        return data;
      }),
      catchError(error => this.handleError(error))
    );
  }

  mapRes(data) {
    this.isAuthenticated = data.isAuthenticated;
    this.avatarUrl = data.avatarUrl;
    this.name = data.name;
    this.id = data.id;
    this.username = data.username;
    this.settings = data.settings;
    this.company = data.company;
    this.role = data.role;
    this.ws_token = data.ws_token;
    this.initWebStorageSettings(data);
  }
}
