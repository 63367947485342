import { Component, OnDestroy, OnInit } from '@angular/core';
import Subscription from 'rxjs';
import { EventsService } from '@modules/events/services/events.service';
import { EventsState } from '@modules/events/states/events.state';
import { Store } from '@ngrx/store';

@Component({
  providers: [EventsService],
  selector: 'app-item-associate',
  templateUrl: './item-associate.component.html',
  styleUrls: ['./item-associate.component.css']
})
export class ItemAssociateComponent implements OnInit, OnDestroy {
  public eventSubs: Subscription;
  public openPitAssociateModal: boolean;
  public message;
  public title;
  private className = 'ItemAssociateComponent';
  private associateEvent;
  public doButton;
  public hideCancel = false;
  constructor(
    protected eventStore: Store<{ nf: EventsState }>,
    protected eventsService: EventsService
  ) {
    this.subscribeEvents();
  }

  ngOnInit() {
    console.log(`${this.className} init`);
  }

  ngOnDestroy(): void {
    this.eventSubs.unsubscribe();
  }

  subscribeEvents() {
    this.eventSubs = this.eventStore.select('nf').subscribe(event => {
      if (event.items.id === 'openPitAssociateModal') {
        this.message = event.items.payload.message;
        this.title = event.items.payload.title;
        this.associateEvent = event.items.payload.associateEvent;
        this.openPitAssociateModal = true;
        if ('doButton' in event.items.payload) {
          this.doButton = event.items.payload['doButton'];
        } else {
          this.doButton = null;
        }
        if ('hideCancel' in event.items.payload) {
          this.hideCancel = event.items.payload['hideCancel'];
        }
      }
    });
  }

  associateItem() {
    this.eventsService.publish(this.associateEvent, this.associateEvent, {});
    this.close();
  }

  cancel() {
    this.close();
  }

  close() {
    this.openPitAssociateModal = false;
  }
}
