import {
  Component,
  AfterViewInit,
  OnInit,
  OnDestroy,
  Renderer2
} from '@angular/core';
import { BaseComponent } from '@shared/components/base/base.component';
import { EventsService } from '@modules/events/services/events.service';
import { EventsState } from '@modules/events/states/events.state';
import { Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { AuthService } from '@core/services/auth/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserProfileService } from '@modules/users/services/user-profile.service';
import { UserSessionState } from '@core/stores/user-session/states/user-session.states';
import { Store } from '@ngrx/store';
import { get_item, set_item } from '@shared/utils/storage/encrypted_storage';
import { constants } from '@core/constants';

import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';

const enterTransition = transition(':enter', [
  style({
    opacity: 0,
    marginTop: '-1.5em'
  }),
  animate(
    '1s ease-in',
    style({
      opacity: 1,
      marginTop: '-1.5em'
    })
  )
]);

const leaveTrans = transition(':leave', [
  style({
    opacity: 1,
    marginTop: '-1.5em'
  }),
  animate(
    '1s ease-out',
    style({
      opacity: 0,
      marginTop: '-1.5em'
    })
  )
]);

const fadeIn = trigger('fadeIn', [enterTransition]);

const fadeOut = trigger('fadeOut', [leaveTrans]);

@Component({
  providers: [AuthService, UserProfileService],
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    //    fadeIn,
    fadeOut,
    trigger('simpleFadeAnimation', [
      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1, marginTop: '-1.5em' })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0, marginTop: '-1.5em' }),
        animate(2400)
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave', animate(2400, style({ opacity: 0 })))
    ]),
    // the animation for the message
    trigger('messageAnimation', [
      // this defines the "resting" styles for the "visible" state
      // (i.e., what styles the message element has when visible)
      state(
        'visible',
        style({
          opacity: 0.9,
          display: 'block'
        })
      ),

      // this defines the "resting" styles for the "hidden" state.
      // (i.e., what styles the message element has when hidden)
      state(
        'hidden',
        style({
          opacity: 0,
          display: 'none'
        })
      ),

      // transition from "hidden" to "visible" states using an animation
      transition('hidden => visible', animate('300ms ease-in')),

      // transition from "visible" to "hidden" similarly
      transition('visible => hidden', animate('300ms ease-out'))
    ])
  ]
})
export class LoginComponent extends BaseComponent
  implements OnInit, OnDestroy, AfterViewInit {
  public show = false;
  public loginForm: FormGroup;
  public loginMessage = 'Logging in...';
  public loggingIn = false;
  public error;
  public failedLogin = false;
  public failedMessage = '';
  private returnUrl: string;
  public errorState = 'out';
  public messageState = 'visible';
  public corp_name = 'Legend Analytics';
  public loginModel: any = {};
  public recoverModel: any = {};
  public resetModel: any = {};
  public loading = false;
  public loginError: boolean;
  public showForm;
  public hasReset = false;
  public userToken = null;
  public tokenLinkInvalid;
  public emailRegex = constants.app.email_regex;
  public passwordRegex = constants.app.user_password_regex;
  public showBookmarkBanner = false;
  public newUrl = '';

  get lForm() {
    return this.loginForm.controls;
  }

  constructor(
    protected idle: Idle,
    protected keepalive: Keepalive,
    protected eventsService: EventsService,
    protected eventStore: Store<{ nf: EventsState }>,
    protected route: ActivatedRoute,
    protected router: Router,
    private userProfileService: UserProfileService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private renderer: Renderer2,
    protected sessionStore: Store<{ uss: UserSessionState }>
  ) {
    super(idle, keepalive, eventsService, sessionStore, router);
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.authService.logout().subscribe();
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
    const hostname = window.location.host;
    const subdomain = hostname.split('.')[0];
    if (
      [
        'pilgrims',
        'pilgrims-stage',
        'novipax',
        'novipax-stage',
        '28lib',
        '28lib-stage'
      ].includes(subdomain)
    ) {
      this.newUrl = `https://${subdomain}.legendanalytics.com`;
      if (window.location.search.includes('fromLegacy=1')) {
        this.showBookmarkBanner = true;
      }
    }
    const route = this.router.url;
    this.userToken = this.route.snapshot.paramMap.get('token');
    this.authService.logout().subscribe();
    if (route.includes('login')) {
      this.showForm = 'login';
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }
    if (route.includes('/recover')) {
      if (this.userToken) {
        this.validateToken('reset');
      } else {
        this.showForm = 'recover';
      }
    }
    if (route.includes('/reset')) {
      this.showForm = 'reset';
    }
    if (route.includes('/welcome')) {
      console.log('Welcome screen...');
      if (this.userToken) {
        this.validateToken('welcome');
      } else {
        this.showForm = 'recover';
      }
    }
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    let elem = document.querySelectorAll<HTMLElement>('.clr-input-group');
    elem.forEach(function(e: any) {
      if (e && e.style) {
      }
    });
    let user = document.querySelectorAll<HTMLElement>('.clr-input-wrapper');
    user.forEach(function(e) {
      if (e && e.style) {
      }
    });
    let icon = document.querySelectorAll<HTMLElement>(
      '.clr-input-group-icon-action'
    );

    icon.forEach(function(e) {
      if (e && e.style) {
        e.style.marginLeft = '-2.5em';
      }
    });
    this.saveRoute();
  }

  submit(): void {
    //this.loggingIn = true;

    if (this.loginForm.invalid) {
      this.loggingIn = true;
      this.messageState = 'visible';
      this.loginForm.markAsTouched();
      //this.loggingIn = false;
      set_item('session', null);
      console.log(`Failed login ${JSON.stringify(event)}`);
      this.loggingIn = false;
      this.failedLogin = true;
      this.failedMessage = 'Invalid username or password';
      setTimeout(() => {
        this.messageState = 'hidden';
        this.failedLogin = false;
        this.loggingIn = false;
      }, 600);
      return;
    }
    let login = this.authService.login(
      this.lForm.username.value,
      this.lForm.password.value
    );
    /*
    this.sessionStore.select('uss').subscribe(event => {
            if (event['payload']['action']==='recover_session') {
                 console.log(`Restoring existing session - redirecting to ${event['payload']['backto']}`);
                 this.router.navigate([event['payload']['backto']]);
            }
    });
*/
    this.sessionStore.select('uss').subscribe(event => {
      if (event['session']['action'] === 'start_login') {
        this.loggingIn = true;
        this.messageState = 'visible';
      }

      if (event['session']['action'] === 'failed_login') {
        set_item('session', null);
        console.log(`Failed login ${JSON.stringify(event)}`);
        this.loggingIn = false;
        this.failedLogin = true;
        this.failedMessage = 'Invalid username or password';
        setTimeout(() => {
          this.messageState = 'hidden';
          this.failedLogin = false;
          this.loggingIn = false;
        }, 600);
      }
    });
    /*
    if (login) {
          login.subscribe(
               () => {
                   this.router.navigate([this.returnUrl]).then(null);
               },
               error => {
                   this.error = error.statusText;
                   this.loggingIn = false;
               }
          );
    }
*/
  }

  validateToken(form): void {
    const token = this.route.snapshot.paramMap.get('token');
    if (token) {
      if (form === 'reset') {
        this.authService.validateRecoveryLink(token).subscribe(
          data => {
            this.tokenLinkInvalid = data.is_error;
            if (!this.tokenLinkInvalid) {
              this.showForm = 'reset';
            }
          },
          error => {
            this.tokenLinkInvalid = true;
            this.showForm = 'recover';
          }
        );
      } else if (form === 'welcome') {
        this.authService.validateWelcomeLink(token).subscribe(
          data => {
            this.tokenLinkInvalid = data.is_error;
            if (!this.tokenLinkInvalid) {
              this.showForm = 'welcome';
            }
          },
          error => {
            this.router.navigate(['/login']);
          }
        );
      }
    }
  }

  loadingSpinner(): void {
    this.loading = false;
    this.loginMessage = 'Login in...';
  }

  sendRecoveryLink() {
    this.hasReset = true;
    this.authService.sendRecoveryLink(this.recoverModel.username).subscribe();
  }

  setPassword() {
    this.loading = true;
    this.loginMessage = 'Login in...';
    this.authService
      .resetPassword(this.resetModel.password, this.userToken)
      .subscribe(
        data => {
          if (this.authService.authenticated) {
            this.userProfileService.getUserProfile().subscribe(userProfile => {
              if (!userProfile.is_authenticated) {
                console.log(
                  `The user profile is ${JSON.stringify(userProfile)}}`
                );
                this.router.navigate(['/login']);
              } else {
                console.log(
                  `The authenticatd user profile is ${JSON.stringify(
                    userProfile
                  )}}`
                );
                this.authService.login(
                  userProfile.username.value,
                  this.resetModel.password
                );
                console.log(`Redirecting...`);
                //this.router.navigate(['/admin']);
              }
            });
          } else {
            this.loginError = true;
            this.loadingSpinner();
          }
        },
        error => {
          if (this.showForm === 'reset') {
            this.loginMessage = 'Reset failed!';
          }
          if (this.showForm === 'welcome') {
            this.loginMessage = 'Setting password failed!';
          }
          setTimeout(() => {
            this.loadingSpinner();
          }, 2000);
          console.log(error);
        }
      );
  }
}
