import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ViewChildren,
  QueryList
} from '@angular/core';
import { BaseComponent } from '@shared/components/base/base.component';
import { Subscription } from 'rxjs';
import { EventsState } from '@modules/events/states/events.state';
import { Store } from '@ngrx/store';
import { EventsService } from '@modules/events/services/events.service';
import { HttpClient } from '@angular/common/http';
import { UserSessionState } from '@core/stores/user-session/states/user-session.states';
import { TimeIntervalDateConfig } from '@shared/utils/date-picker-options';
import { Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Router } from '@angular/router';
import { DataCommanderChartComponent } from '@shared/components/data-commander-chart/data-commander-chart.component';

@Component({
  providers: [EventsService],
  selector: 'app-data-commander-lite-modal',
  templateUrl: './data-commander-lite.component.html',
  styleUrls: ['./data-commander-lite.component.scss']
})
export class DataCommanderLiteModalComponent extends BaseComponent
  implements OnInit, OnDestroy {
  public eventSubs: Subscription;
  public openDataCommanderLiteModal: boolean;
  public dataViewId: number;
  public fromPeriod: any = '';
  public toPeriod: any = '';
  public timeIntervalConfig = TimeIntervalDateConfig;

  @ViewChildren('dataCommanderChart')
  dataCommanderCharts: QueryList<DataCommanderChartComponent>;

  constructor(
    protected idle: Idle,
    protected keepalive: Keepalive,
    protected eventsService: EventsService,
    protected sessionStore: Store<{ uss: UserSessionState }>,
    protected eventStore: Store<{ nf: EventsState }>,
    protected router: Router,
    protected http: HttpClient
  ) {
    super(idle, keepalive, eventsService, sessionStore, router);
  }

  ngOnInit() {
    this.subscribeEvents();
  }

  ngOnDestroy(): void {
    this.eventSubs.unsubscribe();
  }

  subscribeEvents() {
    this.eventSubs = this.eventStore.select('nf').subscribe(event => {
      if (event.items.id === 'openDataCommanderLiteModal') {
        this.dataViewId = event.items.payload.dataViewId;
        this.fromPeriod = '';
        this.toPeriod = '';
        this.openDataCommanderLiteModal = true;
        const dataCommanderChartExist = setInterval(() => {
          if (
            typeof this.dataCommanderCharts !== 'undefined' &&
            this.dataCommanderCharts !== null
          ) {
            clearInterval(dataCommanderChartExist);
            this.dataCommanderCharts.forEach((chart: any) => {
              chart.getDataViewData();
            });
          }
        }, 200);
      }
    });
  }

  getDateRangeData() {
    if (typeof this.fromPeriod === 'undefined' || this.fromPeriod === null) {
      return false;
    }

    if (typeof this.toPeriod === 'undefined' || this.toPeriod === null) {
      return false;
    }
    this.dataCommanderCharts.forEach((chart: any) => {
      chart.getDateRangeData(this.dataViewId, this.fromPeriod, this.toPeriod);
    });
  }

  cancel() {
    this.close();
  }

  close() {
    this.dataCommanderCharts.forEach((chart: any) => {
      if (typeof chart !== 'undefined' && chart !== null) {
        if (typeof chart.chart !== 'undefined' && chart.chart !== null) {
          chart.chart.destroy();
        }
        chart.loading = false;
      }
    });
    this.openDataCommanderLiteModal = false;
  }

  loadingCommander() {
    if (
      typeof this.dataCommanderCharts !== 'undefined' &&
      this.dataCommanderCharts !== null
    ) {
      this.dataCommanderCharts.forEach((chart: any) => {
        if (typeof chart !== 'undefined' && chart !== null) {
          return chart.loading;
        } else {
          return true;
        }
      });
    }
    return false;
  }

  downloadDataView() {
    let csvLink;
    if (typeof this.fromPeriod !== 'undefined' && this.fromPeriod !== null) {
      if (typeof this.toPeriod !== 'undefined' && this.toPeriod !== null) {
        if (
          (this.toPeriod !== '' && this.fromPeriod !== '') ||
          (this.fromPeriod.trim().length !== 0 &&
            this.toPeriod.trim().length !== 0)
        ) {
          csvLink = `/api/data/get_csv/${this.dataViewId}/${this.fromPeriod}/${this.toPeriod}`;
        } else {
          csvLink = `/api/data/get_csv/${this.dataViewId}`;
        }
      } else {
        csvLink = `/api/data/get_csv/${this.dataViewId}`;
      }
    } else {
      csvLink = `/api/data/get_csv/${this.dataViewId}`;
    }
    window.location.href = csvLink;

    this.dataCommanderCharts.forEach((chart: any) => {
      chart.loading = true;
    });

    const responseType = 'arraybuffer';
    // @ts-ignore
    if (typeof this.http !== 'undefined' && this.http !== null) {
      this.http.get(csvLink, { responseType }).subscribe(
        (response: any) => {
          if (typeof response !== 'undefined' && response !== null) {
            if (
              typeof response.headers !== 'undefined' &&
              response.headers !== null
            ) {
              this.dataCommanderCharts.forEach((chart: any) => {
                chart.loading = false;
              });

              const fileName = response.headers
                .get('content-disposition')
                .split(' ')[1]
                .split('=')[1];
              const downloadLink = document.createElement('a');

              const blob = new Blob([response.arrayBuffer()], {
                type: 'application/octet-stream'
              });
              downloadLink.href = window.URL.createObjectURL(blob);
              downloadLink.setAttribute('download', fileName);
              document.body.appendChild(downloadLink);
              downloadLink.click();
              downloadLink.parentNode.removeChild(downloadLink);

              this.dataCommanderCharts.forEach((chart: any) => {
                chart.loading = false;
              });
            }
          }
          this.dataCommanderCharts.forEach((chart: any) => {
            chart.loading = false;
          });
        },
        error => {
          this.dataCommanderCharts.forEach((chart: any) => {
            chart.loading = false;
          });
        }
      );
    }
  }

  updateTo(e) {
    this.toPeriod = e;
  }

  updateFrom(e) {
    this.fromPeriod = e;
  }
}
