import { Injectable } from '@angular/core';
import { BaseService } from '@core/services/base.service';
import { HttpClient } from '@angular/common/http';
import { EventsService } from '@modules/events/services/events.service';
import { constants } from '@core/constants';

@Injectable({
  providedIn: 'root'
})
export class CustomPeriodService extends BaseService {
  private serviceApiUrl = constants.api.customPeriod.custom_period_api;
  //private addDataPointApiUrl = AppVariable.DATA_DATA_VIEW_DATA_POINT_ADD_API_URL;
  //private removeDataPointApiUrl = AppVariable.DATA_DATA_VIEW_DATA_POINT_REMOVE_API_URL;

  constructor(
    protected eventsService: EventsService,
    protected http: HttpClient
  ) {
    super(eventsService, http);
  }

  getAllCustomPeriods() {
    return this.getAll(this.serviceApiUrl);
  }

  getCustomPeriod(id: number) {
    return this.getOne(this.serviceApiUrl, id);
  }

  getCustomPeriods(filters) {
    return this.getMany(this.serviceApiUrl, filters);
  }

  saveCustomPeriod(dataPoint) {
    return this.saveOne(this.serviceApiUrl, dataPoint);
  }

  deleteCustomPeriod(id: number) {
    return this.deleteOne(this.serviceApiUrl, id);
  }

  /*saveDataPointToCustomPeriod(thing): Observable<Response> {
        return this.saveOne(this.addDataPointApiUrl, thing);
    }*/

  /*deleteDataPointFromCustomPeriod(thing): Observable<Response> {
        return this.saveOne(this.removeDataPointApiUrl, thing);
    }*/

  // Service with custom transform
  getAllCustomPeriodsAsOptions() {
    return this.getAllAsOptions(this.serviceApiUrl, {
      name: 'name',
      id: 'id',
      user_name: 'user_name'
    });
  }
}
