<div class="viewport-container">
    <div class="viewport-header">
        <div class="viewport-header-item-left">
<!--
             <app-axis-selector #axisSelector [items]="items" (axis)="onAxisUpdated($event)"></app-axis-selector>
-->
       </div>
    </div>
    <div class="viewport-body" #viewportBody>
        <div class="viewport-header-item">
            <app-axis-selector #axisSelector [data_view_id]="data_view_id" [item]="item" [items]="items" (axis)="onAxisUpdated($event)"></app-axis-selector>
        </div>
        <app-three-chart [canvasDimensions]="canvasDimensions" [item]="item" [data_view_id]="item.data_view_id"></app-three-chart>
     </div>
    <div class="viewport-footer">
        <app-threed-controls [item]="item"></app-threed-controls>
    </div>
</div>

