import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { eventsReducer } from './reducers/events.reducer';
import { ToastrService } from 'ngx-toastr';

@NgModule({
  imports: [StoreModule.forFeature('events', eventsReducer)],
  providers: [ToastrService],
  exports: []
})
export class EventsModule {}
