import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import Settings from '../models/settings.model';

@Injectable()
export class LocalService {
  private _local: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public readonly local: Observable<boolean> = this._local.asObservable();
  public current: BehaviorSubject<boolean>;

  constructor() {}

  readUserSettings(username: string): Settings {
    var s = new Settings();
    return s;
  }

  storeUserSettings(username: string): void {}
}
