import {
  Component,
  Input,
  Output,
  OnInit,
  OnDestroy,
  EventEmitter
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { EventsService } from '@modules/events/services/events.service';
import { Store } from '@ngrx/store';
import { EventsState } from '@modules/events/states/events.state';
import {
  FormControl,
  FormGroup,
  Validators,
  FormArray,
  FormBuilder
} from '@angular/forms';
import { Axis } from '@shared/models/d3-data/d3-data.model';

@Component({
  selector: 'app-axis-selector',
  templateUrl: './axis-selector.component.html',
  styleUrls: ['./axis-selector.component.scss']
})
export class AxisSelectorComponent implements OnInit, OnDestroy {
  @Input('items') items?: any;
  @Input('item') item: any;
  @Input('from') fromCoord?: any;
  @Input('to') toCoord?: any;
  @Input('data_view_id') data_view_id?: number;
  @Input('dimensions') dimensions: any;
  @Output('axis') currentAxis = new EventEmitter<Axis>();

  public total_items: any[] = [];
  public current_data_view_id: number = -1;
  public hideHeaderLabels: boolean = false;
  protected eventSubs: Subscription;
  public hideHeader: boolean = false;
  public currentId: any;
  public axis: any;
  public start: any;
  public end: any;
  public axisForm: FormGroup;
  public x: any[] = [];
  public y: any[] = [];
  public z: any[] = [];

  constructor(
    public eventStore: Store<{ nf: EventsState }>,
    protected eventsService: EventsService,
    protected route: ActivatedRoute,
    protected router: Router,
    private formBuilder: FormBuilder
  ) {
    this.axisForm = this.formBuilder.group({
      axis: new FormControl('', [Validators.required]),
      start: new FormControl('', [Validators.required]),
      end: new FormControl('', [Validators.required])
    });
  }

  patchForm(): void {
    this.axisForm.controls['axis'].setValue(0);
    this.setSelectedIndex(document.getElementById('axis-select'), '0');
    this.axis = 0;
  }

  ngOnInit(): void {
    if (this.item.settings.cols < 2) {
      this.hideHeader = true;
    } else {
      this.hideHeader = false;
    }
    if (this.item.settings.cols < 3) {
      this.hideHeaderLabels = true;
    } else {
      this.hideHeaderLabels = false;
    }
    this.subscribeEvents();
  }

  subscribeEvents(): void {
    this.eventSubs = this.eventStore.select('nf').subscribe(event => {
      if (event.items.id === 'resizeCanvasOnResizeStop') {
        let data_view_id = event.items.payload.item.data_view_id;
        this.current_data_view_id = data_view_id;
        if (data_view_id === this.data_view_id) {
          if (typeof event.items.payload.item.settings.cols !== 'undefined') {
            if (event.items.payload.item.settings.cols < 3) {
              this.hideHeaderLabels = true;
            } else {
              this.hideHeaderLabels = false;
            }
            if (event.items.payload.item.settings.cols < 2) {
              this.hideHeader = true;
            } else {
              this.hideHeader = false;
            }
          }
        }
      }
      if (event.items.id === 'hideHeaderLabels') {
        //  this.hideHeaderLabels = event.items.payload.hideHeaderLabels;
      }
      if (event.items.id === 'postAxisValues') {
        //     this.data_view_id = event.items.payload.data_view_id;
      }
      if (event.items.id === 'postAxes') {
        let data_view_id = event.items.payload.data_view_id;
        //     if (data_view_id === this.data_view_id) {

        this.current_data_view_id = data_view_id;
        let items = event.items.payload.items.slice(0, 2);

        if (data_view_id === this.data_view_id) {
          this.x = [];
          this.y = [];
          this.z = [];
          let z = [];

          items.forEach((item: any) => {
            let key =
              item.name[0].toLowerCase() + item.name.slice(1, item.name.length);

            let values = event.items.payload[key];

            if (typeof values === 'undefined') {
              values = event.items.payload[item.name];
            }

            if (item.id === 1) {
              if (typeof values !== 'undefined' && values !== null) {
                for (let i = 0; i < values.length; i++) {
                  this.x.push({ id: i, value: values[i] });
                }
              }
            } else if (item.id === 2) {
              if (typeof values !== 'undefined' && values !== null) {
                for (let i = 0; i < values.length; i++) {
                  this.y.push({ id: i, value: values[i] });
                }
              }
            } else if (item.id === 3) {
              if (typeof values !== 'undefined' && values !== null) {
                for (let i = 0; i < values.length; i++) {
                  z.push({ id: i, value: values[i] });
                }
              }
            }
          });

          let axisZClear: any[] = [{ id: 0, value: 0 }];
          let counter = 0;
          z.forEach((item: any) => {
            let v = parseInt(item.value);
            if (v !== 0) {
              axisZClear.push({ id: counter, value: item.value });
              counter++;
            }
          });

          axisZClear.sort(function(a, b) {
            return a.value - b.value;
          });
          this.z = axisZClear;

          if (this.total_items.length <= 0) {
            items.forEach((item: any) => {
              let start = 0;
              let end = 0;
              if (item.id === 0) {
                end = this.x.length - 1;
              }
              if (item.id === 1) {
                end = this.y.length - 1;
              }
              if (item.id === 2) {
                end = this.z.length - 1;
              }
              let it = {
                id: parseInt(item.id) - 1,
                start: start,
                end: end,
                name: item.name
              };
              this.total_items.push(it);
            });
          }

          if (
            typeof this.currentId === 'undefined' ||
            this.currentId === null
          ) {
            this.currentId = 0;
            //   this.axis = 0;
            this.start = 0;
            this.end = 10;
            this.items = this.total_items;
            this.axisForm.controls['start'].setValue(0);
            this.axisForm.controls['end'].setValue(0);
            if (this.total_items.length > 0) {
              this.updateAxis();
              this.axis = 0;
              this.axisForm.controls['axis'].setValue(0);
            }
          } else {
            this.axisForm.controls['axis'].setValue(this.currentId);
            this.axisForm.controls['start'].setValue(this.start);
            this.axisForm.controls['end'].setValue(this.end);
            return;
          }
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (typeof this.eventSubs !== 'undefined' && this.eventSubs !== null) {
      this.eventSubs.unsubscribe();
    }
  }

  processSelection(): void {}

  resizeWorld(): void {}

  updateStart(e: any): void {
    this.start = e;
  }

  updateEnd(e: any): void {
    this.end = e;
  }

  updateAxis(): void {
    //    if (typeof this.axis === 'undefined' || this.axis === null) {
    //    this.axis = 0;
    //   this.axisForm.controls['axis'].setValue(0);
    //   } else {
    if (typeof this.total_items !== 'undefined' && this.total_items !== null) {
      for (let i = 0; i < this.total_items.length; i++) {
        if (this.total_items[i].id == this.axisForm.controls['axis'].value) {
          this.currentId = this.total_items[i].id;
          this.axis = this.total_items[i].id;
          this.start = this.total_items[i].start;
          this.end = this.total_items[i].end;
          this.axisForm.controls['start'].setValue(this.start);
          //this.axisForm.controls['end'].setValue(this.end);
        }
      }
    }
    //  }
  }

  update(): void {
    this.end = parseInt(this.end);
    this.start = parseInt(this.start);
    this.items = this.total_items;
    for (let i = 0; i < this.items.length; i++) {
      if (this.items[i].id == this.currentId) {
        let start_item = null;
        let end_item = null;

        if (this.items[i].id === 0) {
          start_item = {
            index: this.axisForm.controls['start'].value,
            value: this.x[this.axisForm.controls['start'].value].value
          };
          end_item = {
            index: this.axisForm.controls['end'].value,
            value: this.x[this.end].value
          };
        }
        if (this.items[i].id === 1) {
          start_item = {
            index: this.axisForm.controls['start'].value,
            value: this.y[this.axisForm.controls['start'].value]
          };
          let end_index = 0;
          let end_value = 0;

          if (
            typeof this.axisForm.controls['end'].value !== 'undefined' &&
            this.y[this.end].value !== 'undefined'
          ) {
            end_item = {
              index: this.axisForm.controls['end'].value,
              value: this.y[this.end].value
            };
          } else {
            end_item = {
              index: end_index,
              value: this.y[0].value
            };
          }
        }
        if (this.items[i].id === 2) {
          start_item = {
            index: this.axisForm.controls['start'].value,
            value: this.z[this.axisForm.controls['start'].value]
          };
          end_item = {
            index: this.axisForm.controls['end'].value,
            value: this.z[this.axisForm.controls['end'].value].value
          };
        }
        let axis = {
          id: this.currentId + 1,
          name: this.items[i].name,
          start: start_item,
          end: end_item
        };
        this.currentAxis.emit(axis);
        let payload = { axis: axis, data_view_id: this.data_view_id };
        this.eventsService.publish('sliceChart', 'sliceChart', payload);
      }
    }
  }

  axes(): any {
    if (typeof this.axis === 'undefined' || this.axis === null) {
      return [];
    } else if (this.axis.id === 1) {
      return this.x;
    } else if (this.axis.id === 2) {
      return this.y;
    } else {
      return this.z;
    }
  }

  axisUpdate(event: any) {
    //     this.items[i].id==this.axisForm.controls['axis'].setValue(id);
    //    this.axis = id;
    this.axisForm.controls['axis'].setValue(this.axis);
    document.getElementById('axis-select').click();
  }

  setSelectedIndex(s: any, v: any) {
    for (var i = 0; i < s.options.length; i++) {
      if (s.options[i].text == v) {
        s.options[i].selected = true;

        return;
      }
    }
  }

  getAxisName(id: number): string {
    for (let i = 0; i < this.total_items.length; i++) {
      if (this.total_items[i].id == id) {
        return this.total_items[i].name;
      }
    }
    return '';
  }
  setEnd(v: any) {
    this.end = v;
  }

  setStart(v: any) {
    this.start = v;
  }

  updateAxisStart(e: any) {
    this.start = parseInt(e);
  }

  updateAxisEnd(e: any) {
    this.end = parseInt(e);
  }

  resetEnd() {
    this.axisForm.controls['end'] = this.end;
  }

  getId(id: number): any {
    let val =
      Math.random()
        .toString(36)
        .substring(2) + new Date().getTime().toString(36);
    return `${id}${val}`;
  }
}
