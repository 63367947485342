<app-alarm-contact-menu #alarm_contact_menu [alarmContactMenuData]='alarmContactMenuData' (toggle)="toggleChanged($event)"></app-alarm-contact-menu>
<div class="child-container alarm-details-container"  [@slideInOut]="alarmFormState">
	<form [formGroup]="itemForm" class="offset-top pad-r24" novalidate >
		<h4>{{header}}</h4> 
		<br/>
		<div class="clr-row form-group col-md-12">
			<div class="clr-col-sm-12 clr-col-md-6 half-form"> <!-- left 6 grid starts -->
				<div class="clr-row form-group row form-row">
					<div class="clr-col-4 naming-cell col-title">
							<label>Name:</label>
					</div>
					<div class="clr-col-8 content-cell">
						<clr-input-container *ngIf="item">
							<input clrInput type="text" size="54" class="form-input" [formControl]="itemForm.controls.name" name="name" ngDefaultControl id="name" class="common-input"  [(ngModel)]="name" (ngModelChange)="updateAlarmContactMenu($event)" />
							<clr-control-error *clrIfError="'minlength'">Must be at least 3 characters</clr-control-error>
							<clr-control-error *clrIfError="'required'">Name Is Required</clr-control-error>
						</clr-input-container>
					</div> <!-- Name row -->
				</div>

				<div class="clr-row form-group row form-row">
					<div class="clr-col-4 naming-cell col-title">
							<label for="thing">Thing:</label>
					</div>
					<div class="clr-col-8 content-cell thing-cell"> <!-- Thing row -->

                        <app-search-select id="things" #thingsDropdown [tag]="things" [width]="'20em'" name="thing" [records]="things" [ngModel]="thing" (selected)="setThing($event)"  (click)="reReadThings()" ngDefaultControl [formControl]="itemForm.controls.thing"></app-search-select>


					</div>
				</div>

				<div class="clr-row form-group row form-row">
					<div class="clr-col-4 naming-cell col-title">
							<label for="thing">Alarm Type:</label>
					</div>
					<div class="clr-col-8 content-cell alarm-type-cell">


						<clr-select-container class="alarm-select">
							<select class="form-input" clrSelect id="alarm_type_id" *ngIf="alarmTypes" [formControl]="itemForm.controls.alarm_type_id" name="alarm_type_id"  ngDefaultControl (change)="alarmTypeChanged($event)">
								<option *ngFor="let alarm_type of alarmTypes" [value]="alarm_type.id">
									{{alarm_type.name}}
								</option>
							</select>
						</clr-select-container>


					</div>
				</div>

				<div class="clr-row form-group row form-row">


					<div class="clr-col-4 naming-cell">
					</div>             


					<div class="clr-col-8 form-row spacer content-cell">
						<clr-checkbox-wrapper class="t-4">
							<input id="isAccumulator" clrCheckbox type="checkbox" [formControl]="itemForm.controls.is_accumulator" name="is_accumulator" ngDefaultControl (click)="toggleAccumulator()" [value]="is_accumulator" />
							&nbsp;<label for="isAccumulator">Is Accumulator ({{itemForm.controls.is_accumulator.value ? 'True' :
							'False'}})</label>
						</clr-checkbox-wrapper>

					</div>
				</div>

                <div class="clr-row form-group row form-row"> <!-- Time zone row starts -->
                    <div class="clr-col-4 col-title">
                            <label for="timezone">Period</label>
                    </div>
                    <div class="clr-col-8 content-cell active-from-cell">
                        <input id="period"
                        size="8"
                        type="text"
                        [value]="itemForm.controls.period.value"
                        [formControl]="itemForm.controls.period"
                        mode="time"
                        [theme]="'dp-material'"
                        [dpDayPicker]="timeIntervalConfig"
                        (ngModelChange)="checkDirty('period')"
                        >
                    </div>
                </div>

                <div class="clr-row form-group row form-row"> <!-- Active from row starts -->
                    <div class="clr-col-4 naming-cell col-title">
                            <label for="noticeinterval">Notice Interval</label>
                    </div>
                    <div class="clr-col-8 content-cell active-from-cell">
                        <input id="noticeinterval"
                        size="8"
                        class="alarm-notice-interval"
                        type="text"
                        [value]="itemForm.controls.notice_interval.value"
                        [formControl]="itemForm.controls.notice_interval"
                        mode="time"
                        [theme]="'dp-material'"
                        [dpDayPicker]="timeIntervalConfig"
                        (ngModelChange)="checkDirty('notice_interval')"
                        >
                    </div>
                </div> 

				<div class="clr-row form-group row form-row"> <!-- Time zone row starts -->
					<div class="clr-col-4 col-title">
							<label for="timezone">Time Zone</label>
					</div>
					<div class="clr-col-8 content-cell">

                        <app-search-select id="timezones" #alarmTimeZonesDropdown [tag]="'time_zones'" [width]="'20em'" name="timezone" [records]="time_zones" (click)="reReadTimezones()" [ngModel]="timezone"  ngDefaultControl [formControl]="itemForm.controls.timezone"></app-search-select>


					</div>

				</div> <!-- time zone row ends -->

				<div class="clr-row form-group row form-row"> <!-- Active from row starts -->
					<div class="clr-col-4 naming-cell col-title">
							<label for="description">Active From</label>
					</div>
					<div class="clr-col-8 content-cell active-from-cell">
						<input id="active_from"
						size="8"
						type="text"
						[value]="itemForm.controls.active_from.value"
						[formControl]="itemForm.controls.active_from"
						mode="time"
						[theme]="'dp-material'"
						[dpDayPicker]="timeIntervalConfig"
						(ngModelChange)="checkDirty('active_from')"
						>
					</div>
				</div> <!-- active from row ends -->

				<div class="clr-row form-group row form-row active-to-row"> <!-- Active To row starts -->
					<div class="clr-col-4 naming-cell">
						<label for="description">Active To</label>
					</div>
					<div class="clr-col-8 content-cell">
						<input id="active_to"
						size="8"
						type="text"
						[value]="itemForm.controls.active_to.value"
						[formControl]="itemForm.controls.active_to"
						mode="time"
						[theme]="'dp-material'"
						[dpDayPicker]="timeIntervalConfig"
						(ngModelChange)="checkDirty('active_to')"/>
					</div>
				</div> <!-- active from row ends -->


				<div class="clr-row form-group row form-row"> <!-- Active Days to row starts -->
					<div class="clr-col-4 naming-cell">
							<label for="description">Active Days</label>
					</div>
					<div class="clr-col-6 alarm-box">
                           <div class="daysCheckBox checkbox-inline">
								<clr-checkbox-wrapper>
									<input class="checkbox-inline"  clrCheckbox type="checkbox" id="Monday" [formControl]="itemForm.controls.active_monday" name="active_monday" ngDefaultControl />
									<label for="Monday">Monday</label>
								</clr-checkbox-wrapper>
                           </div>
                           <div class="daysCheckBox checkbox-inline">
								<clr-checkbox-wrapper>
									<input clrCheckbox  type="checkbox" id="Tuesday" [formControl]="itemForm.controls.active_tuesday" name="active_tuesday" ngDefaultControl />
									<label for="Tuesday">Tuesday</label>
								</clr-checkbox-wrapper>
                           </div>
                           <div class="daysCheckBox checkbox-inline">
								<clr-checkbox-wrapper>
									<input clrCheckbox  type="checkbox" id="Wednesday" [formControl]="itemForm.controls.active_wednesday" name="active_wednesday" ngDefaultControl />
									<label for="Wednesday">Wednesday</label>
								</clr-checkbox-wrapper>
                           </div>
                           <div class="daysCheckBox checkbox-inline">
								<clr-checkbox-wrapper >
									<input clrCheckbox type="checkbox" id="Thursday" [formControl]="itemForm.controls.active_thursday" name="active_thursday" ngDefaultControl/>
									<label for="Thursday">Thursday</label>
								</clr-checkbox-wrapper>
                           </div>
                           <div class="daysCheckBox checkbox-inline">
								<clr-checkbox-wrapper>
									<input clrCheckbox type="checkbox" id="Friday" [formControl]="itemForm.controls.active_friday" name="active_friday" ngDefaultControl />
									<label for="Friday">Friday</label>
								</clr-checkbox-wrapper>
                           </div>
                           <div class="daysCheckBox checkbox-inline">
                                <clr-checkbox-wrapper>
                                    <input clrCheckbox type="checkbox" id="Saturday" [formControl]="itemForm.controls.active_saturday" name="active_saturday" ngDefaultControl />
                                    <label for="Saturday">Saturday</label>
                                </clr-checkbox-wrapper>
                           </div>
                           <div class="daysCheckBox checkbox-inline">
								<clr-checkbox-wrapper class="t-4 spacer">
									<input clrCheckbox   type="checkbox" id="Sunday" [formControl]="itemForm.controls.active_sunday" name="active_sunday" ngDefaultControl />
									<label for="Sunday">Sunday</label>
								</clr-checkbox-wrapper>
                           </div>
                           <div class="clear"></div>
						</div>
                         <div class="clr-col-2"></div>
				</div>

			</div>    <!-- left 6 grid ends -->
			<div class="clr-col-sm-12 clr-col-md-6 half-form"> <!-- right 6 grid starts -->
				<div class="clr-row form-group row message-position form-row">
					<div class="clr-col-4 naming-cell">

						<div class="clr-row">&nbsp;<br/></div>
						<div class="clr-row form-group row message">
								<label for="description">Message Content:</label>
						</div>

					</div>
					<div class="clr-col-8">              
						<textarea id="description" [formControl]="itemForm.controls.content" name="content" ngDefaultControl rows="4" cols="34"></textarea>
					</div> <!-- Message -->

				</div> <!-- end row -->


				<div class="clr-row form-group row form-row"> <!-- Low Threshold row starts -->
					<div class="clr-col-4 naming-cell col-title">
						<label for="high-threshold">Low Threshold</label>
					</div>

					<div class="clr-col-8 t-4 spacer number">
						<input id="low-threshold"
						type="number"
						size="7"
                        class="number-input"
						[value]="itemForm.controls.low_threshold.value"
						[formControl]="itemForm.controls.low_threshold"
                        name="low_threshold"
                        ngDefaultControl  
						(ngModelChange)="checkDirty('low_threshold')"
						>
					</div>
				</div> <!-- Low Threshold row ends -->

				<div class="clr-row form-group row form-row"> <!-- High Threshold row starts -->
					<div class="clr-col-4 naming-cell col-title">
						<label for="high-threshold">High Threshold</label>
					</div>
					<div class="clr-col-8 t-4 spacer number">
						<input id="high-threshold"
                        size="7"
                        type="number"
                        class="number-input"
						[value]="itemForm.controls.high_threshold.value"
                        name="high_threshold"
                        ngDefaultControl  
						[formControl]="itemForm.controls.high_threshold"
						(ngModelChange)="checkDirty('high_threshold')"
						>
					</div>
				</div> <!-- Low Threshold row ends -->

				<div class="clr-row form-group row form-row"> <!-- Low Discard Threshold row starts -->
					<div class="clr-col-4 naming-cell col-title">
						<label for="description">Low Discard Threshold</label>
					</div>
					<div class="clr-col-8 t-4 spacer number">
						<input id="low-discard-threshold"
                        size="7"
						type="number"
                        class="number-input"
                        name="low_discard_threshold"
                        ngDefaultControl  
						[value]="itemForm.controls.low_discard_threshold.value"
						[formControl]="itemForm.controls.low_discard_threshold"
						(ngModelChange)="checkDirty('low_discard_threshold')"
						>
					</div>
				</div> <!-- Low Threshold row ends -->


				<div class="clr-row form-group row form-row"> <!-- High Discard Threshold row starts -->
					<div class="clr-col-4 naming-cell col-title">
						<label for="high-threshold">High Discard Threshold</label>
					</div>
					<div class="clr-col-8 t-4 spacer number">
						<input id="high-discard-threshold"
                        size="7"
						type="number"
                        class="number-input"
						[value]="itemForm.controls.high_discard_threshold.value"
                        ngDefaultControl  
                        name="high_discard_threshold"
						[formControl]="itemForm.controls.high_discard_threshold"
						(ngModelChange)="checkDirty('high_discard_threshold')"
						>
					</div>
				</div> <!-- Low Threshold row ends -->

				<div class="clr-row form-group row form-row"> <!-- Null Values row starts -->
					<div class="clr-col-4 naming-cell">
						<label for="high-threshold"></label>
					</div>
					<div class="clr-col-8">

						<clr-checkbox-wrapper class="t-2 spacer">
							<input id="includeNull" clrCheckbox type="checkbox" [formControl]="itemForm.controls.include_null" name="include_null" ngDefaultControl  (click)="toggleIncludeNull()" [value]="include_null" />
							<label for="includeNull">Include Null Values ({{itemForm.controls.include_null.value ? 'True' :
							'False'}})</label>
						</clr-checkbox-wrapper>
					</div>
				</div>   


                <div class="clr-row form-group row form-row">
                            <div class="clr-col-3 clr-offset-8 button-update">
                                <button class="btn btn-primary" type="submit" (click)="update()" [disabled]="newNotPopulated()" *ngIf="item">{{item.id > 0 ? "Update" : "Save"}}</button>
                            </div>
                </div>      
			</div>  <!-- right 6 grid ends -->   
		</div>
	</form>
</div>


