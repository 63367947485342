<div class="flex-outer-container child-container-details align-grid">

    <clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading" [(clrDgSelected)]="selected" class="grid-sizing fl-table">
        <clr-dg-column [clrDgField]="'name'">
           Column Name
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'calculation'">
            Calculation
        </clr-dg-column>
        <clr-dg-column>
           Delete
        </clr-dg-column>
        <clr-dg-row *clrDgItems="let item of items" [clrDgItem]="item" (click)="itemSelected(item)">
            <clr-dg-cell>
                    {{ item.name }}
            </clr-dg-cell>
            <clr-dg-cell>
                    {{ readFormula(item.name) }}
            </clr-dg-cell>
              <clr-dg-cell>
                  <button type="button" class="btn btn-sm config-btn" (click)="deleteItem(item)">Delete</button>
              </clr-dg-cell>
        </clr-dg-row>

        <clr-dg-detail *clrIfDetail="let detail">
            <clr-dg-detail-header></clr-dg-detail-header>
            <app-column-config-detail [item]="detail" [calculation]="readFormula(detail.name)" [data_points]="data_points"></app-column-config-detail>
        </clr-dg-detail>

        <clr-dg-footer>
            <clr-dg-pagination #pagination [clrDgPageSize]="perPage" [clrDgTotalItems]="itemCount">
                <clr-dg-page-size [clrPageSizeOptions]="[1, 2, 5, 10]">
                </clr-dg-page-size>
                {{ pagination.firstItem + 1 }}
                -
                {{ pagination.lastItem + 1 }}
                of
                {{ pagination.totalItems }}
            </clr-dg-pagination>
        </clr-dg-footer>
    </clr-datagrid>
</div>

