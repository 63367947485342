import { ActivatedRoute, Router } from '@angular/router';
import {
  Component,
  ComponentRef,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { NavToggleComponent } from '@shared/components/nav-toggle/nav-toggle.component';
import { EventsService } from '@modules/events/services/events.service';
import { EventsState } from '@modules/events/states/events.state';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

@Component({
  providers: [EventsService],
  selector: 'app-header-sub',
  templateUrl: './header-sub.component.html',
  styleUrls: ['./header-sub.component.scss']
})
export class HeaderSubComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('sideNav') sideNav: ElementRef;
  @ViewChild('navToggle') navToggle: ComponentRef<NavToggleComponent>;
  private eventSubs: Subscription;

  constructor(
    protected element: ElementRef,
    protected eventsService: EventsService,
    protected eventStore: Store<{ nf: EventsState }>,
    protected route: ActivatedRoute,
    protected router: Router
  ) {}

  subscribeEvents() {
    this.eventSubs = this.eventStore.select('nf').subscribe(event => {
      if (event.items.id === 'toggle_navigation') {
        this.adjustToggle(event.items.payload.item.nav);
      }
    });
  }

  ngOnInit(): void {
    this.subscribeEvents();
  }

  ngOnDestroy() {
    if (typeof this.eventSubs !== 'undefined' && this.eventSubs !== null) {
      this.eventSubs.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.fixToggle(this.navToggle);
  }

  fixToggle(elem) {
    this.adjustToggle(elem['navState']['source']['_value']);
  }

  adjustToggle(mode) {
    if (this.navToggle) {
      let el = this.navToggle['element'] as ElementRef;
      var div = el.nativeElement.querySelector('div');
      if (mode) {
        div.style.right = '0';
        div.style.width = '12em';
        div.style.marginLeft = '17.0em';
        div.style.position = 'fixed';
        div.style.marginTop = '-0.9em';
        div.style.marginRight = '1.1em';
      } else {
        div.style.right = '0';
        div.style.width = '12em';
        div.style.marginLeft = '17.0em';
        div.style.marginTop = '-0.9em';
        div.style.position = 'fixed';
        div.style.marginRight = '1.1em';
      }
    }
  }

  toggle() {}
}
