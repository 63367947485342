import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TicketListComponent } from './ticket-list/ticket-list.component';
import { TicketDetailsComponent } from './ticket-details/ticket-details.component';

const routes: Routes = [
  {
    path: '',
    data: { title: 'List' },
    component: TicketListComponent
  },
  {
    path: 'list',
    component: TicketListComponent,
    data: { title: 'List' }
  },
  {
    path: ':id',
    component: TicketDetailsComponent,
    data: { title: 'Details' }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TicketingRoutingModule {}
