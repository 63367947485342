import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { Router } from '@angular/router';
import { BaseComponent } from '@shared/components/base/base.component';
import { UserService } from '@core/services/user/user.service';
import { EventsService } from '@modules/events/services/events.service';
import { IUser } from '@shared/interfaces/users';
import { ITriggerPointProfile } from '@modules/eventmanagement/models/trigger-point-profile.model';
import { TriggerPointService } from '@modules/eventmanagement/services/trigger-point.service';
import { UserSessionState } from '@core/stores/user-session/states/user-session.states';
import { Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';

@Component({
  providers: [TriggerPointService, EventsService, UserService],
  selector: 'app-trigger-point-profile-action-menu',
  templateUrl: './trigger-point-profile-action-menu.component.html',
  styleUrls: ['./trigger-point-profile-action-menu.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({ width: '400px', 'pointer-events': 'all' })),
      state('out', style({ width: '47px', 'pointer-events': 'none' })),
      transition('in => out', animate('250ms ease-in-out')),
      transition('out => in', animate('250ms ease-in-out'))
    ])
  ]
})
export class TriggerPointProfileActionMenuComponent extends BaseComponent
  implements OnInit, OnDestroy {
  public triggerMenuState = 'out';
  public triggerPointProfileActionListItems_paginated: boolean = false;
  private className = 'TriggerPointProfileActionMenuComponent';
  loadingTriggerPointProfileActions = false;
  triggerPointProfileActionListItems: IUser[];
  triggerPointProfileActionListLimit = 8;
  triggerPointProfileActionListCount = 8;
  private filters: any;

  @Input() item: ITriggerPointProfile;
  @Output() onTriggerPointActionUpdated: EventEmitter<any> = new EventEmitter<
    any
  >();
  errorMessage: string;
  private successToast = {
    title: 'Success!',
    text: '',
    type: 'success'
  };
  private errorToast = {
    title: 'Error!',
    text: '',
    type: 'error'
  };
  private notExistingWarning = {
    title: 'Warning!',
    text: 'Not available until new Trigger Point Profile is saved!',
    type: 'warning'
  };

  constructor(
    protected idle: Idle,
    protected keepalive: Keepalive,
    private triggerPointService: TriggerPointService,
    private userService: UserService,
    protected sessionStore: Store<{ uss: UserSessionState }>,
    protected eventsService: EventsService,
    protected router: Router
  ) {
    super(idle, keepalive, eventsService, sessionStore, router);
  }
  ngOnInit() {
    this.subscribeEvents();
  }

  ngOnDestroy(): void {}

  subscribeEvents() {}

  toggleTriggerPointProfileActionMenu() {
    if (+this.item.id === 0) {
      this.eventsService.showToast(this.notExistingWarning);
    } else {
      this.triggerMenuState = this.triggerMenuState === 'out' ? 'in' : 'out';
    }
  }

  addTriggerPointProfileAction(item_id) {
    const payload = { id: this.item.id, trigger_point_action_id: item_id };
    this.triggerPointService.addTriggerPointProfileAction(payload).subscribe(
      item => this.onItemUpdated(item, 'add'),
      error => (this.errorMessage = <any>error)
    );
  }

  removeTriggerPointProfileAction(item_id) {
    const payload = { id: this.item.id, trigger_point_action_id: item_id };
    this.triggerPointService.removeTriggerPointProfileAction(payload).subscribe(
      item => this.onItemUpdated(item, 'remove'),
      error => (this.errorMessage = <any>error)
    );
  }

  onItemUpdated(data: any, type: string): void {
    if (data.is_error) {
      this.errorToast.text = `Failed to ${type} Profile Action!`;
      this.eventsService.showToast(this.errorToast);
    } else {
      this.successToast.text = `Profile Action ${
        type === 'remove' ? 'removed' : 'added'
      } successfully!`;
      this.eventsService.showToast(this.successToast);
    }
    this.triggerPointService.getTriggerPointProfile(this.item.id).subscribe(
      res => {
        this.item = res['item'];
        this.onTriggerPointActionUpdated.emit(this.item);
      },
      error => (this.errorMessage = <any>error)
    );
    this.refreshTriggerPointProfileActionList(this.filters);
  }

  onDrop(event: any) {
    event.preventDefault();
    this.addTriggerPointProfileAction(event.dataTransfer.getData('text/plain'));
  }

  onDragOver(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  onItemDrag(event, id) {
    event.dataTransfer.setData('text/plain', id);
  }

  onTriggerPointProfileActionsRetrieved(data: any) {
    if (data.is_error) {
    } else {
      this.triggerPointProfileActionListItems = data.item_list;
      this.triggerPointProfileActionListCount = data.item_count;
      if (data.item_count > 5) {
        this.triggerPointProfileActionListItems_paginated = true;
      }
      setTimeout(() => {
        this.loadingTriggerPointProfileActions = false;
      }, 200);
    }
  }

  addFilter(fs, _f) {
    if (!('filters' in fs)) {
      fs['filters'] = [_f];
    } else {
      fs['filters'].push(_f);
    }
    return fs;
  }

  refreshTriggerPointProfileActionList(filters) {
    this.filters = filters;

    setTimeout(() => {
      this.loadingTriggerPointProfileActions = true;
    }, 100);

    let fs = _.cloneDeep(filters);
    const _f = { triggerPointProfileIdActionsFilter: this.item.id };
    fs = this.addFilter(fs, _f);
    console.log(fs);
    this.triggerPointService.getTriggerPointProfileActions(fs).subscribe(
      items => this.onTriggerPointProfileActionsRetrieved(items),
      error => (this.errorMessage = <any>error)
    );
  }
}
