import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fromComponents from './components';
import { AdvancedCalculationsRoutingModule } from './advanced-calculations-routing.module';
import { AdvancedCalcService } from '@modules/advanced-calculations/services/advanced-calc.service';
import { EventsService } from '@modules/events/services/events.service';

@NgModule({
  declarations: [...fromComponents.components],
  imports: [CommonModule, AdvancedCalculationsRoutingModule],
  providers: [AdvancedCalcService, EventsService],
  exports: [...fromComponents.components]
})
export class AdvancedCalculationsModule {}
