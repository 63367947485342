<form [formGroup]="viewportForm" novalidate>
            <div class="footer-box form-group">
                 <div class="footer-box-item-left">
                     <div class="controls">
                      <button class="btn btn-primary btn-sm adjust-left-most twod-threed-button" (click)="toggleDimensions()">{{ twodtitle }}</button>&nbsp;
<!--
                   <button class="btn btn-primary btn-sm adjust-middle" (click)="scaleCoord('x')">X Scale</button>&nbsp;

                      <button class="btn btn-primary btn-sm adjust-right" (click)="scaleCoord('y')">Size Scale</button>
-->
                      <button class="btn btn-primary btn-sm adjust-right-most" (click)="resizeChart()">Resize</button>
                    </div>
                </div>
                <div class="footer-box-item-center">

                   

                   <div class="item-center-item" style="margin-right:-1.0em;"><label>Width</label></div>
                   <div class="item-center-item">
                     <div class="input-box">
                      <div class="input-wrapper">
                        <input type="text" class="control-input" formControlName="width" [(ngModel)]="width" name="width" ngDefaultControl/>
                      </div>
                      <div class="error-block">
                               <span *ngIf="empty_width_error" class="error-message">Width is required! </span>
                               <span *ngIf="invalid_width_error" class="error-message">Width is not number! </span>
                       </div>
                      </div>
                   </div>
                   <div class="item-center-item" style="margin-left:0.5em;margin-right:-0.7em;"><label>Height</label></div>
                   <div class="item-center-item">
                     <div class="input-box">
                       <div class="input-wrapper">
                        <input type="text" class="control-input" formControlName="height" [(ngModel)]="height" name="height" ngDefaultControl/>
                      </div>
                      <div class="error-block">
                               <span *ngIf="empty_height_error" class="error-message">Height is required! </span>
                               <span *ngIf="invalid_height_error" class="error-message">Height is not number! </span>
                      </div>
                     </div>
                   
                   </div>
<!--
                   <div class="item-center-item" style="margin-left:0.3em;margin-right:-1.3em;"><label>Scale</label></div>
                   <div class="item-center-item"><input type="text" class="control-input" formControlName="factor" [(ngModel)]="factor" name="factor" ngDefaultControl/> 
                    
                   </div>
-->




                </div>
                <div class="footer-box-item-right">
                    <div class="icon-item unpressed" id="plus" (mousedown)="onMouseDown('in',$event)"  (mouseup)="onMouseUp('in',$event)">
                        <fa-icon [icon]="faPlusIcon" #zoomIn  (click)="processAction('in')"  aria="Zoom In"></fa-icon>&nbsp;
                    </div>
                    <div class="icon-item unpressed" id="minus" (mousedown)="onMouseDown('out',$event)"  (mouseup)="onMouseUp('out',$event)">
                        <fa-icon [icon]="faMinusIcon" #zoomOut (click)="processAction('out')" aria="Zoom Out"></fa-icon>&nbsp;
                    </div>
                    <div class="icon-item unpressed" id="sync"  (mousedown)="onMouseDown('sync',$event)"  (mouseup)="onMouseUp('sync',$event)">
                    <fa-icon [icon]="faAltIcon" (click)="processAction('sync')"  aria="Sync" class="sync-alt"></fa-icon>&nbsp;
                    </div>
<!--
                    <div class="icon-item unpressed" id="compass"  (mousedown)="onMouseDown('compass',$event)"  (mouseup)="onMouseUp('compass',$event)">
                    <fa-icon [icon]="faCompassIcon" (click)="processAction('compass')"  aria="Navigate" class="compass"></fa-icon>&nbsp;
                    </div>
-->
                    <div class="icon-item unpressed" id="left"  (mousedown)="onMouseDown('left',$event)"  (mouseup)="onMouseUp('left',$event)">
                    <fa-icon [icon]="faLeftIcon"  (click)="processAction('left')" aria="Left"></fa-icon>&nbsp;
                    </div>
                    <div class="icon-item unpressed" id="right"  (mousedown)="onMouseDown('right',$event)"  (mouseup)="onMouseUp('right',$event)">
                    <fa-icon [icon]="faRightIcon" (click)="processAction('right')"  aria="Right"></fa-icon>&nbsp;
                    </div>
                    <div class="icon-item unpressed" id="up"  (mousedown)="onMouseDown('up',$event)"  (mouseup)="onMouseUp('up',$event)">
                    <fa-icon [icon]="faUpIcon" (click)="processAction('up')"  aria="Up"></fa-icon>&nbsp;
                    </div>
                    <div class="icon-item unpressed" id="down"  (mousedown)="onMouseDown('down',$event)"  (mouseup)="onMouseUp('down',$event)">
                       <fa-icon [icon]="faDownIcon" (click)="processAction('down')" aria="Down"></fa-icon>
                    </div>
                </div> 
            </div>
</form>

