export const highChartsExport = {
    chartOptions: {
        // specific options for the exported image
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: true
                }
            }
        }
    },
    fallbackToExportServer: false,
    buttons: {
        contextButton: {
            align: 'left'
        }
    },
    scale: 1,
    filename: 'legend-analytics'
};
