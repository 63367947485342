<div class="trigger-point-profile-menu" [@slideInOut]="triggerMenuState">
    <div class="trigger-point-profile-control">
        <button
            class="btn btn-link nb-button trigger-point-profile-button"
            (click)="toggleTriggerPointProfileActionMenu()"
        >
            <clr-icon shape="cog" size="24"></clr-icon>
        </button>
        <span>{{ item?.name }}</span>
    </div>
    <div class="trigger-point-profile-container" *ngIf="item">
        <div class="group-title">Applied Actions</div>
        <div *ngIf="item.trigger_point_actions"
            class="trigger-point-profile-users"
            (drop)="onDrop($event)"
            (dragover)="onDragOver($event)"
        >
            <div
                class="trigger-point-profile-user"
                *ngFor="let action of item.trigger_point_actions"
                id="{{ action.id }}"
            >
                {{ action.name
                }}<button class="btn btn-link remove-button" (click)=removeTriggerPointProfileAction(action.id)><clr-icon shape="times"></clr-icon></button>
            </div>
        </div>
        <div class="group-title">Available Actions</div>
        <div class="row trigger-point-profile-actions">
            <div class="col-md-12">
                <div class="all-items">
                    <clr-datagrid
                        (clrDgRefresh)="refreshTriggerPointProfileActionList($event)"
                        [clrDgLoading]="loadingTriggerPointProfileActions"
                    >
                        <clr-dg-column [clrDgField]="'name'">
                            Name
                            <clr-dg-string-filter
                                [clrDgStringFilter]="'name'"
                            ></clr-dg-string-filter>
                        </clr-dg-column>
                        <clr-dg-placeholder
                            >No Actions...</clr-dg-placeholder
                        >
                        <clr-dg-row class="draggable-row"
                            *ngFor="let item of triggerPointProfileActionListItems"
                            [clrDgItem]="item"
                            draggable="true"
                            (dragstart)="onItemDrag($event, item.id)"
                        >
                            <clr-dg-cell
                                >{{ item.name }}</clr-dg-cell
                            >
                        </clr-dg-row>

                        <clr-dg-footer *ngIf="triggerPointProfileActionListItems_paginated">
                            {{ pagination.firstItem + 1 }} -
                            {{ pagination.lastItem + 1 }} of
                            {{ pagination.totalItems }} items
                            <clr-dg-pagination
                                #pagination
                                [clrDgPageSize]="triggerPointProfileActionListLimit"
                                [clrDgTotalItems]="
                                    triggerPointProfileActionListCount
                                "
                            ></clr-dg-pagination>
                        </clr-dg-footer>
                    </clr-datagrid>
                </div>
                <br />
            </div>
        </div>
    </div>
</div>
