import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-folder',
  templateUrl: './create-folder.component.html',
  styleUrls: ['./create-folder.component.scss']
})
export class CreateFolderComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
