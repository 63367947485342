import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BaseService } from '@core/services/base.service';
import { constants } from '@core/constants';
import { catchError, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { IField } from '@shared/interfaces/fields';
import { HttpClient } from '@angular/common/http';
import { EventsService } from '@modules/events/services/events.service';

const initialFieldData = {} as IField;

@Injectable({
  providedIn: 'root'
})
export class FieldService extends BaseService {
  public fieldDataSource = new BehaviorSubject<IField>(initialFieldData);
  public fields = this.fieldDataSource.asObservable();
  public timezoneDataSource = new BehaviorSubject<any>({});

  public time_zones = this.timezoneDataSource.asObservable();

  constructor(
    protected eventsService: EventsService,
    protected http: HttpClient
  ) {
    super(eventsService, http);
  }

  getFields() {
    return this.getAll(constants.api.field.field_api).pipe(
      map((data: any) => {
        if (typeof data.item_list !== 'undefined' && data.item_list !== null) {
          this.fieldDataSource.next(data.item_list[0]);
          this.timezoneDataSource.next(data.item_list[0]['time_zones']);
          return data.item_list[0];
        } else {
          throw 'No fields to read.';
        }
      }),
      catchError(error => this.handleError(error))
    );
  }

  getTimeZones() {
    return this.getAll(constants.api.field.field_api).pipe(
      map((data: any) => {
        this.timezoneDataSource.next(data.item_list[0]['time_zones']);
        return data.item_list[0]['time_zones'];
      }),
      catchError(error => this.handleError(error))
    );
  }

  getAllFieldsAsOptions() {
    return this.getAll(constants.api.field.field_api).pipe(
      map((data: any) => {
        this.fieldDataSource.next(data.item_list[0]);
        this.timezoneDataSource.next(data.item_list[0]['time_zones']);
        return data.item_list[0];
      }),
      catchError(error => this.handleError(error))
    );
  }

  advanceFields(val) {
    this.fieldDataSource.next(val);
  }
}

@Injectable({
  providedIn: 'root'
})
export class FieldResolverService implements Resolve<any> {
  constructor(private fieldService: FieldService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.fieldService.getFields();
  }
}
