import { StateWithEffects } from 'ngrx-reducer-effects';
import {
  UserSessionActionTypes,
  UserSessionActions
} from '@core/stores/user-session/actions/user-session.actions';
import {
  UserSessionState,
  initialUserSessionState
} from '@core/stores/user-session/states/user-session.states';

export function userSessionReducer(
  state = initialUserSessionState,
  action: UserSessionActions
): StateWithEffects<UserSessionState> {
  switch (action.type) {
    case UserSessionActionTypes.ReadSession: {
      return { ...state, session: action.payload };
    }

    case UserSessionActionTypes.Authenticate: {
      return { ...state, payload: action.payload };
    }

    case UserSessionActionTypes.UpdateSession: {
      return { ...state, session: action.payload };
    }

    case UserSessionActionTypes.CreateSession: {
      return {
        authenticated: action.payload.authenticated,
        username: action.payload.username,
        uuid: action.payload.uuid,
        payload: action.payload,
        session: action.payload
      };
    }

    case UserSessionActionTypes.DeleteSession: {
      return { ...state, session: action.payload };
    }

    case UserSessionActionTypes.RefreshSession: {
      return { ...state, session: action.payload };
    }

    case UserSessionActionTypes.RecoverSession: {
      return { ...state, session: action.payload };
    }

    case UserSessionActionTypes.ExpireSession: {
      return { ...state, session: action.payload };
    }

    case UserSessionActionTypes.StartLogin: {
      return { ...state, session: action.payload };
    }

    case UserSessionActionTypes.EndLogin: {
      return { ...state, session: action.payload };
    }

    case UserSessionActionTypes.FailedLogin: {
      return { ...state, session: action.payload };
    }

    case UserSessionActionTypes.ForcedLogout: {
      return { ...state, session: action.payload };
    }

    default:
      return state;
  }
}
