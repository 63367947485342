import * as highCharts from 'highcharts';
import { highChartsBase } from './highcharts-base';

export const highChartsHeatMap = function(data) {
    return Object.assign(
        {
            title: { text: data.name, style: { fontWeight: 'bold' } },
            boost: {
                 enabled: true,
                 useGPUTranslations: true,
            },
            chart: {
                type: 'heatmap',
                spacingTop: 20,
                zoomType: 'x',
                style: {
                    fontFamily:
                        'Metropolis,"Avenir Next","Helvetica Neue",Arial,sans-serif'
                },
                events: {
                    afterPrint: function() {
                        window.dispatchEvent(new Event('resize'));
                    }
                }
            },
            subtitle: {
                text:
                    document.ontouchstart === undefined
                        ? 'Click and drag in the plot area to zoom in'
                        : 'Pinch the chart to zoom in'
            },
            xAxis: {
                type: 'datetime',
                //min: Date(2020, 5, 1),
                //max: Date(2017, 4, 7)
            },
            yAxis: {
                categories: [],
                title: {
                    text: ''
                }
            },
            colorAxis: {
                stops: [
                    [0, '#3060cf'],
                    [0.5, '#fffbbc'],
                    [0.9, '#c4463a'],
                    [1, '#c4463a']
                ]
            },

            legend: {
                align: 'right',
                layout: 'vertical',
                margin: 0,
                verticalAlign: 'top',
                y: 55,
                symbolHeight: 280
            },
            tooltip: {
                formatter: function() {

                    let date = new Date(this.point.x);
                    const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
                    const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                    let day = weekday[date.getDay()];
                    let mon = month[date.getMonth()];
                    let hour = date.getHours();
                    if (date.getHours() < 10) {
                        hour = `0${date.getHours()}`;
                    }

                    let minute = date.getMinutes();
                    if (date.getMinutes() < 10) {
                        minute = `0${date.getMinutes()}`;
                    }
 
                    let format = `${day}, ${mon} ${date.getDate()}, ${hour}:${minute}`;
                    return (
                        format +
                        '<br>' +
                        this.series.yAxis.categories[this.point.y] + ' :<b>' + this.point.value + '</b>'
                    );
                }
            },
            series: []
        },
        highChartsBase
    );
};
