import { EncryptStorage } from 'encrypt-storage';
import * as _ from 'lodash';

const ENCRYPTION_KEY = '101301230sdfsdfsdfs';
// the constructor takes the secret key as a first parameter and an optional
// second parameter as the storage type
// if none is provided it'll be localStorage by default
export const encryptStorage = EncryptStorage(ENCRYPTION_KEY);
/*** for a string ***/

export function deepCopy(item: any) {
  let copy = _.cloneDeep(item);
  return copy;
}

export function set_item(key, item) {
  //  if (typeof item === 'undefined' || item===null) {
  //     encryptStorage.removeItem(key);
  //  } else {
  encryptStorage.setItem(key, item);
  //  }
}

export function get_item(key) {
  let stored = encryptStorage.getItem(key);
  let item = _.cloneDeep(stored);
  return item;
}

export function remove(key) {
  encryptStorage.removeItem(key);
}
