export enum KeyCode {
  BACKSPACE = 8,
  DELETE = 46,
  DOWN = 40,
  END = 35,
  ENTER = 13,
  ESCAPE = 27,
  HOME = 36,
  INSERT = 45,
  LEFT = 37,
  PAGE_DOWN = 34,
  PAGE_UP = 33,
  RIGHT = 39,
  TAB = 9,
  UP = 38,
  SPACE = 32,
}
