<nav class="clr-row subnav fl-header-sub">
  <div class="left">
    <app-breadcrumb></app-breadcrumb>
  </div>
  <div class="right">
    <app-nav-toggle #navToggle id="nav-toggle" (click)="toggle()" (change)="toggle()"></app-nav-toggle>
  </div>
  <div class="clear"></div>
</nav>

