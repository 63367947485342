<div class="child-container child-container-wrapper">

<div class="flex-outer-container">

    <div class="flex-outer-left-item">
         <div class="flex-inner-left-container">
                   <div class="flex-inner-left-left-item">
                      <h2>{{listHeader}}</h2>
                   </div>
         </div>
    </div>
    <div class="flex-outer-right-item">
         <div class="flex-inner-right-container">
                    <div class="flex-inner-right-right-item">
                                <button class="btn btn-primary add-button" (click)=addItem()>
                                  Add
                                </button>&nbsp;
                                <button class="btn btn-primary delete-button" [disabled]="!(selected.length > 0)" (click)=deleteDialog()>
                                 Delete
                               </button>&nbsp;
                    </div>
         </div>
    </div>
</div>
 
<div class="limit-height"> 
    <clr-datagrid [(clrDgSelected)]="selected"
                  (clrDgRefresh)="refresh($event)"
                  [clrDgLoading]="loading" class="fl-table datagrid-host">

        <clr-dg-column [clrDgField]="'subject'">
            Subject
            <clr-dg-string-filter [clrDgStringFilter]="'name'"></clr-dg-string-filter>
        </clr-dg-column>
        <clr-dg-column>Author</clr-dg-column>
        <clr-dg-column>Created</clr-dg-column>
        <clr-dg-column>Updated</clr-dg-column>
        <clr-dg-column>Priority</clr-dg-column>
        <clr-dg-column>Status</clr-dg-column>
        <clr-dg-column>Type</clr-dg-column>
        <clr-dg-column>Active</clr-dg-column>

        <clr-dg-placeholder>{{noDataText}}</clr-dg-placeholder>
        <!-- Do not use *clrDgItems, use *ngFor. https://github.com/vmware/clarity/issues/928 -->
        <clr-dg-row *ngFor="let item of items" [clrDgItem]="item">
            <clr-dg-cell class="col-top">
                <a [routerLink]="readUrl(item.id)">{{item.subject}}</a>
            </clr-dg-cell>
            <clr-dg-cell class="col-top">{{item.author}}</clr-dg-cell>
            <clr-dg-cell class="col-top">{{item.created}}</clr-dg-cell>
            <clr-dg-cell class="col-top">{{item.updated}}</clr-dg-cell>
            <clr-dg-cell class="col-top">{{item.priority}}</clr-dg-cell>
            <clr-dg-cell class="col-top">{{item.status}}</clr-dg-cell>
            <clr-dg-cell class="col-top">{{item.type}}</clr-dg-cell>
            <clr-dg-cell class="item-active col-top-checkbox">




      <clr-checkbox-wrapper class="t-4 spacer">
       <input id="item-active" type="checkbox" [ngModel]="item.active"
       class="checkbox-inline" checked="checked" clrCheckbox  (click)="toggleItemActive($event, item.id)" value="item.active" />
       <label for="alarm-accumulator">Active</label>
      </clr-checkbox-wrapper>


            </clr-dg-cell>
        </clr-dg-row>

        <clr-dg-footer>
            <clr-dropdown [clrCloseMenuOnItemClick]="true" (clrMenuPosition)="'top-left'">
                <clr-dropdown-menu>
                    <h4 class="dropdown-header">Select Row Count</h4>
                    <button type="button" class="dropdown-item" [ngClass]="{'active': limit == 10}" (click)="limit = 10"
                            clrDropdownItem>10
                    </button>
                    <button type="button" class="dropdown-item" [ngClass]="{'active': limit == 25}" (click)="limit = 25"
                            clrDropdownItem>25
                    </button>
                    <button type="button" class="dropdown-item" [ngClass]="{'active': limit == 50}" (click)="limit = 50"
                            clrDropdownItem>50
                    </button>
                    <button type="button" class="dropdown-item" [ngClass]="{'active': limit == 100}"
                            (click)="limit = 100" clrDropdownItem>100
                    </button>
                </clr-dropdown-menu>
            </clr-dropdown>





            <clr-dg-pagination #pagination [clrDgPageSize]="25" [clrDgTotalItems]="itemCount">
                <clr-dg-page-size [clrPageSizeOptions]="[10, 25, 50, 100]">
                  Tickets per page
                </clr-dg-page-size>
                {{ pagination.firstItem + 1 }}
                -
                {{ pagination.lastItem + 1 }}
                of
                {{ pagination.totalItems }}
                tickets
            </clr-dg-pagination>

        </clr-dg-footer>


    </clr-datagrid>
  </div>
</div>
