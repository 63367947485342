import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MenuState } from '../state/dashboard-menu.state';

@Injectable()
export class DashboardMenuService {
  private _menu: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly menu: Observable<boolean> = this._menu.asObservable();

  constructor(private _store: Store<{ mn: MenuState }>) {}

  getMenu(): BehaviorSubject<boolean> {
    return this._menu;
  }

  updateMenu(menu: boolean): void {
    this._menu.next(menu);
    var map = new Map();
    map['on'] = { type: 'MENU_ON' };
    map['off'] = { type: 'MENU_OFF' };
    if (menu) {
      this._store.dispatch(map['on']);
    } else {
      this._store.dispatch(map['off']);
    }
  }
}
