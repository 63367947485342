<clr-header class="legend-header">
    <div class="hamburger">
        <span class="material-icons-outlined">
        </span>
    </div>
    <div class="branding">

        <a href="/" class="nav-link">
            <div class="fl-top-logo"></div>
        </a>
    </div>
    <div class="header-nav">
        <a href="..." class="nav-link nav-icon" aria-label="dashboard">
            <clr-icon shape="dashboard"></clr-icon>
        </a>
        <a href="..." class="nav-link nav-icon" aria-label="alert">
            <clr-icon shape="bubble-exclamation"></clr-icon>
        </a>
    </div>
    <form class="search">
        <label for="search_input">
            <input id="search_input" type="text" placeholder="Search for Keywords...">
        </label>
    </form>
    <div class="header-actions">
        <app-refresh></app-refresh>
        <app-user-menu></app-user-menu>
    </div>
</clr-header>
