import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseService } from '@core/services/base.service';
import { BehaviorSubject } from 'rxjs';
import { constants } from '@core/constants';
import { catchError, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { ICompanies, ICompany } from '@shared/interfaces/companies';
import { IIdName } from '@shared/interfaces/base';
import { HttpClient } from '@angular/common/http';
import { EventsService } from '@modules/events/services/events.service';

const initialCompaniesData = [] as ICompany[];
const initialCompaniesAsOptionsData = [] as IIdName[];
const initialCompanyData = {} as ICompany;

@Injectable({
  providedIn: 'root'
})
export class CompanyService extends BaseService {
  private companyApiUrl = constants.api.company.company_api;
  private companyRoleApiUrl = constants.api.company.role.api;
  private getCompanyRoleThingAclApiUrl = constants.api.company.role.thing.api;
  private addCompanyRoleThingAclApiUrl =
    constants.api.company.role.thing.add_api;
  private removeCompanyRoleThingAclApiUrl =
    constants.api.company.role.thing.remove_api;
  private updateCompanyRoleThingAclApiUrl =
    constants.api.company.role.thing.update_api;

  private companiesDataSource = new BehaviorSubject<ICompany[]>(
    initialCompaniesData
  );
  private companiesAsOptionsDataSource = new BehaviorSubject<IIdName[]>(
    initialCompaniesAsOptionsData
  );
  private companyCountSource = new BehaviorSubject<number>(null);
  private companyDataSource = new BehaviorSubject<ICompany>(initialCompanyData);

  public companies = this.companiesDataSource.asObservable();
  public companiesAsOptions = this.companiesAsOptionsDataSource.asObservable();
  public companyCount = this.companyCountSource.asObservable();
  public company = this.companyDataSource.asObservable();

  constructor(
    protected eventsService: EventsService,
    protected http: HttpClient
  ) {
    super(eventsService, http);
  }

  getCompanies(filters: any) {
    return this.getMany(constants.api.company.company_api, filters).pipe(
      map((data: ICompanies) => {
        this.companiesDataSource.next(data.item_list);
        this.companyCountSource.next(data.item_count);
        return data;
      }),
      catchError(error => this.handleError(error))
    );
  }

  clearCompanies() {
    this.companiesDataSource.next(null);
    this.companyCountSource.next(null);
  }

  getCompany(id: number) {
    if (id === 0) {
      const data = { id: 0 } as ICompany;
      this.companyDataSource.next(data);
      return data;
    }
    return this.getOne(constants.api.company.company_api, id).pipe(
      map(
        (data: { item: ICompany }) => {
          this.companyDataSource.next(data.item);
          return data;
        },
        catchError(error => this.handleError(error))
      )
    );
  }

  updateCompany(company: ICompany) {
    return this.updateOne(constants.api.company.company_api, company).pipe(
      map(data => this.handleSuccess(data)),
      catchError(error => this.handleError(error))
    );
  }

  saveCompany(company: ICompany) {
    return this.saveOne(constants.api.company.company_api, company).pipe(
      map(data => this.handleSuccess(data)),
      catchError(error => this.handleError(error))
    );
  }

  saveCompanyRole(companyRole) {
    return this.saveOne(constants.api.company.role.api, companyRole);
  }

  createCompanyRole(companyRole) {
    return this.createOne(this.companyRoleApiUrl, companyRole);
  }

  deleteCompanyRole(id: number): Observable<any> {
    return this.deleteOne(constants.api.company.role.api, id);
  }

  deleteCompany(id: number) {
    return this.deleteOne(constants.api.company.company_api, id).pipe(
      map(data => this.handleSuccess(data)),
      catchError(error => this.handleError(error))
    );
  }

  getAllCompanyRoles() {
    return this.getAll(constants.api.company.role.api);
  }

  getCompanyRoles(filters: any) {
    return this.getMany(constants.api.company.role.api, filters);
  }

  getCompanyRole(id: number) {
    return this.getOne(constants.api.company.role.api, id);
  }

  getAllCompanies(asOptions?: boolean) {
    return this.get(constants.api.company.company_api).pipe(
      map((data: ICompanies) => {
        if (asOptions) {
          const itemList = [];
          data.item_list.forEach((company: ICompany) => {
            const id = company.id;
            const name = company.name;
            itemList.push({ id, name });
          });
          this.companiesAsOptionsDataSource.next(itemList);
        } else {
          this.companiesDataSource.next(data.item_list);
          this.companyCountSource.next(data.item_count);
        }
        return data;
      }),
      catchError(error => this.handleError(error))
    );
  }

  addCompanyRoleThingAcl(payload): Observable<Response> {
    return this.saveOne(constants.api.company.role.thing.add_api, payload);
  }

  removeCompanyRoleThingAcl(payload): Observable<Response> {
    return this.saveOne(constants.api.company.role.thing.remove_api, payload);
  }

  updateCompanyRoleThingAcl(payload): Observable<Response> {
    return this.saveOne(constants.api.company.role.thing.update_api, payload);
  }

  getCompanyRoleThings(companyFilter): Observable<any[]> {
    return this.getMany(constants.api.company.role.thing.api, companyFilter);
  }
}

@Injectable({
  providedIn: 'root'
})
export class CompaniesAsOptionsResolverService implements Resolve<any> {
  constructor(private companyService: CompanyService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.companyService.getAllCompanies(true);
  }
}

@Injectable({
  providedIn: 'root'
})
export class CompanyResolverService implements Resolve<any> {
  constructor(private companyService: CompanyService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.companyService.getCompany(+route.params.id);
  }
}
