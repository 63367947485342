import { ActivatedRoute, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { BaseService } from '@core/services/base.service';
import { UserSessionService } from '@core/services/user-session/user-session.service';
import { constants } from '@core/constants';
import { HttpClient } from '@angular/common/http';
import { IUser, IUsers } from '@shared/interfaces/users';
import { EventsService } from '@modules/events/services/events.service';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Store } from '@ngrx/store';
import { UserSessionState } from '@core/stores/user-session/states/user-session.states';
import { UserSessionActionTypes } from '@core/stores/user-session/actions/user-session.actions';
import { BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { set_item } from '@shared/utils/storage/encrypted_storage';

const initialUsersData = [] as IUser[];
const initialUserData = {} as IUser;

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {
  private usersDataSource = new BehaviorSubject<IUser[]>(initialUsersData);
  private userCountSource = new BehaviorSubject<number>(null);
  private userDataSource = new BehaviorSubject<IUser>(initialUserData);
  public users = this.usersDataSource.asObservable();
  public userCount = this.userCountSource.asObservable();
  public user = this.userDataSource.asObservable();

  constructor(
    protected eventsService: EventsService,
    private router: Router,
    private route: ActivatedRoute,
    protected sessionStore: Store<{ uss: UserSessionState }>,
    protected userSessionService: UserSessionService,
    protected http: HttpClient
  ) {
    super(eventsService, http);
  }

  getUsers(filters: any) {
    return this.getMany(constants.api.user.user_api, filters).pipe(
      map((data: IUsers) => {
        this.usersDataSource.next(data.item_list);
        this.userCountSource.next(data.item_count);
        return data;
      }),
      catchError(error => this.handleError(error))
    );
  }

  clearUsers() {
    this.usersDataSource.next(null);
    this.userCountSource.next(null);
  }

  getAllUsers() {
    return this.getAll(constants.api.user.user_api).pipe(
      map((data: IUsers) => {
        this.usersDataSource.next(data.item_list);
        this.userCountSource.next(data.item_count);
        return data;
      }),
      catchError(error => this.handleError(error))
    );
  }

  getUser(id: number) {
    if (id === 0) {
      const data = { id: 0 } as IUser;
      this.userDataSource.next(data);
      return data;
    }
    return this.getOne(constants.api.user.user_api, id).pipe(
      map(
        (data: { item: IUser }) => {
          this.userDataSource.next(data.item);
          return data;
        },
        catchError(error => this.handleError(error))
      )
    );
  }

  updateUser(user: IUser) {
    return this.updateOne(constants.api.user.user_api, user).pipe(
      map(data => this.handleSuccess(data)),
      catchError(error => this.handleError(error))
    );
  }

  deleteUser(id: number) {
    return this.deleteOne(constants.api.user.user_api, id).pipe(
      map(data => this.handleSuccess(data)),
      catchError(error => this.handleError(error))
    );
  }

  saveUser(user) {
    return this.saveOne(constants.api.user.user_api, user);
  }

  createUser(user) {
    return this.createOne(constants.api.user.user_api, user);
  }

  onLoginAs(data: any): void {
    if (data.is_error) {
      console.log(`Login error: ${data.message}`);
    } else {
      let username = data.username;
      let password = data.token;
      this.post(constants.api.auth.authenticate, { username, password })
        .pipe(
          mergeMap((res: IAuthResponse) =>
            this.get(constants.api.user_session.v2_url)
          )
        )
        .subscribe(response => {
          console.log(`User login as response is  ${JSON.stringify(response)}`);
          if (response.is_authenticated) {
            console.log(`Successfully authenticated user`);
            response['action'] = 'user_session';
            response['last_route'] = localStorage.getItem('backto');
            this.userSessionService._userSession.next(response);
            this.sessionStore.dispatch({
              type: UserSessionActionTypes.CreateSession,
              payload: response
            });
            set_item('session', response);

            if (response['last_route']) {
              this.router.navigate([response['last_route']]);
            } else {
              this.router.navigate(['/admin/users']);
            }

            this.eventsService.publish('updateFullName', 'updateFullName', {
              module: 'shared',
              action: 'updatel',
              full_name: response.name,
              additionalInfo: ''
            });

            let allowed_components = response.settings.components;
            set_item('allowed_modules', allowed_components);
            this.eventsService.publish('refreshSideMenu', 'refreshSideMenu', {
              module: 'users',
              action: 'refresh',
              user_id: response.id,
              modules: allowed_components
            });

            let id = 'uncheckUsersMenu';
            this.eventsService.publish(id, 'uncheckUsersMenu', {
              module: 'admin',
              action: 'open'
            });
          } else {
            console.log(
              `In login as - Failed validating user ${username} - ${JSON.stringify(
                response
              )}`
            );
            let payload = {
              authenticated: false,
              session: response,
              action: 'failed_login'
            };
            this.userSessionService._userSession.next(response);
            this.sessionStore.dispatch({
              type: UserSessionActionTypes.FailedLogin,
              payload: payload
            });
            this.router.navigate(['/login']);
          }
        });
    }
  }

  loginAs(id: number) {
    return this.http
      .post(constants.api.user.login_as, JSON.stringify({ login_as_id: id }))
      .pipe(
        map(
          data => {
            this.onLoginAs(data);

            return data;
          },
          catchError(error => this.handleError(error))
        )
      );
  }

  getAllUsersWithCompanyNameAsOptions() {
    return this.getAll(constants.api.user.user_api).pipe(
      map((data: IUsers) => {
        this.usersDataSource.next(data.item_list);
        this.userCountSource.next(data.item_count);
        return data;
      }),
      catchError(error => this.handleError(error))
    );
  }
}

@Injectable({
  providedIn: 'root'
})
export class UserResolverService implements Resolve<any> {
  constructor(private userService: UserService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.userService.getUser(+route.params.id);
  }
}
export interface IAuth {
  authenticated: boolean;
}

export interface IAuthResponse {
  is_error: boolean;
}
