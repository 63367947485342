import { Router } from '@angular/router';
import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  Input
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { EventsState } from '@modules/events/states/events.state';
import { EventsService } from '@modules/events/services/events.service';
import { constants } from '@core/constants';
import { DataPointService } from '@core/services/data-point/data-point.service';
import { BaseComponent } from '@shared/components/base/base.component';
import { UserSessionState } from '@core/stores/user-session/states/user-session.states';
import { Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { deepCopy } from '@shared/utils/storage/encrypted_storage';
import { IConfig, IConfigs } from '@shared/models/config.model';

@Component({
  selector: 'app-column-config',
  templateUrl: './column-config.component.html',
  styleUrls: ['./column-config.component.scss']
})
export class ColumnConfigComponent extends BaseComponent
  implements OnInit, OnDestroy, AfterViewInit {
  @Input('items') items: any[];
  @Input('data_points') data_points: any[];
  public updated_items: any = [];
  public calculations: any = {};
  public minId: number = 0;
  public orig_items: any[] = [];
  public eventSubs: Subscription;
  public detailState: boolean = false;
  public selected: any[];
  public loading: boolean = false;
  public itemCount: number = 0;
  public filters: any;
  public baseUrl = '/admin/data-analysis/data-points';
  public perPage: number = 10;
  public currentConfig: IConfigs;

  constructor(
    protected idle: Idle,
    protected keepalive: Keepalive,
    protected sessionStore: Store<{ uss: UserSessionState }>,
    protected router: Router,
    protected eventsService: EventsService,
    protected eventStore: Store<{ nf: EventsState }>,
    protected dataPointService: DataPointService
  ) {
    super(idle, keepalive, eventsService, sessionStore, router);
  }

  refresh(filters) {
    setTimeout(() => {
      this.loading = false;
    }, 90);
    let start: number = filters['page']['from'];
    let end: number = filters['page']['to'];
    let items = this.orig_items; //.slice(start, end);
    if (start > 0) {
      this.itemCount = this.orig_items.length;
      this.items = items;
    }
    this.filters = filters;
  }

  ngOnInit(): void {
    this.orig_items = this.items;
    this.items = this.orig_items.slice(0, this.perPage);
    this.itemCount = this.orig_items.length;
    this.updated_items = deepCopy(this.orig_items);
    for (let i = 0; i < this.orig_items.length; i++) {
      this.calculations[this.orig_items[i].name] = this.orig_items[
        i
      ].calculation;
    }
    this.subscribeEvents();
  }

  ngOnDestroy(): void {
    if (typeof this.eventSubs !== 'undefined' && this.eventSubs !== null) {
      this.eventSubs.unsubscribe();
    }
  }

  ngAfterViewInit(): void {}

  subscribeEvents() {
    this.eventSubs = this.eventStore.select('nf').subscribe(event => {
      if (event.items.id === 'deleteChartItem') {
      }

      if (event.items.id === 'rereadColumnNames') {
        let items = deepCopy(event.items.payload.items);
        this.items = items;
        this.updated_items = deepCopy(items);
        this.loading = false;
      }
      if (event.items.id === 'postFormula') {
        this.calculations[event.items.payload.name] =
          event.items.payload.calculation;
        let item = {
          id: event.items.payload.id,
          name: event.items.payload.name,
          calculation: event.items.payload.calculation,
          data_points: deepCopy(event.items.payload.data_points)
        };
        this.calculations[item.name] = item.calculation;
        let items: any[] = [];
        for (let i = 0; i < this.items.length; i++) {
          if (item.id === this.items[i].id) {
            items.push(item);
          } else {
            let itm = deepCopy(this.items[i]);
            items.push(itm);
          }
        }
        for (let i = 0; i < this.updated_items.length; i++) {
          if (item.id == this.updated_items[i].id) {
            this.updated_items[i] = item;
          }
        }
      }
      if (event.items.id === 'refreshFormula') {
        let item = deepCopy(event.items.payload.item);
        this.refreshItem(item);
        for (let i = 0; i < this.updated_items.length; i++) {
          if (item.id == this.updated_items[i].id) {
            this.updated_items[i] = item;
          }
        }
      }
    });
  }

  toggleItemActive(e: any) {}

  onDetailOpen(e: any) {
    this.detailState = !this.detailState;
  }

  currDetail(): void {}

  refreshItem(item: any): void {
    let items: any[] = [];

    for (let i = 0; i < this.items.length; i++) {
      if (typeof this.items[i] !== 'undefined' && this.items[i] !== null) {
        if (item.id == this.items[i].id) {
          this.calculations[item.name] = item.calculation;
          items.push(item);
        } else {
          items.push(this.items[i]);
        }
      }
    }

    //if (items.length === 0) {
    //   items.push(item);
    //}
    this.items = items;
    this.minId = 0;
  }

  deleteItem(item: any): void {
    let items: any[] = [];

    for (let i = 0; i < this.items.length; i++) {
      if (typeof this.items[i] !== 'undefined' && this.items[i] !== null) {
        if (item.id != this.items[i].id) {
          items.push(this.items[i]);
        }
      }
    }
    let updated_items: any[] = this.updated_items.filter(x => x.id !== item.id);
    this.updated_items = updated_items;
    this.items = items;
    this.minId = 0;
    let id = 'updateChartItems';
    this.eventsService.publish('updateChartItems', 'updateChartItems', {
      items: this.items
    });
  }

  editItem(item: any): void {}

  readFormula(name: string) {
    if (
      typeof this.calculations !== 'undefined' &&
      this.calculations !== null
    ) {
      if (
        typeof this.calculations[name] !== 'undefined' &&
        this.calculations !== null
      ) {
        return this.calculations[name];
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  itemSelected(item: any) {
    let itm = {
      id: item.id,
      name: item.name,
      calculation: this.readFormula(item.name),
      data_grid: item.data_grid
    };
    this.eventsService.publish('populateFormula', 'populateFormula', {
      item: itm
    });
  }
}
