<div class="child-container child-container-wrapper company-details-container">

    <form [formGroup]="companyForm" class="pad-r24" novalidate>
    <h4>{{ header }}</h4>

 <div class="clr-row form-group row-span">    
    <div class="clr-col-1 col-title">
        <label>Name</label>
    </div>
    <div class="clr-col-5">
        <clr-input-container>
        <input clrInput type="text" [formControl]="companyForm.controls.name" name="name" size="71" />
        <clr-control-error *clrIfError="'minlength'">Must be at least 3 characters</clr-control-error>
        <clr-control-error *clrIfError="'required'">Company Name Is Required</clr-control-error>
        </clr-input-container>
    </div>
 </div>

 <div class="clr-row form-group row-span">
    <div class="clr-col-1 col-title">
        <label>Address</label>
    </div>
    <div class="clr-col-5">
        <clr-input-container>
        <input clrInput type="text" [formControl]="companyForm.controls.address" name="address" size="71" ngDefaultControl />
        </clr-input-container>
    </div>
 </div> 

 <div class="clr-row form-group row-span">
    <div class="clr-col-1 col-title">
        <label>Address 2</label>
    </div>
    <div class="clr-col-5">
        <clr-input-container>
          <input clrInput type="text" [formControl]="companyForm.controls.address_2" name="address2" size="71" ngDefaultControl />
        </clr-input-container>
    </div>
 </div> 



 <div class="clr-row form-group row-span">
    <div class="clr-col-1 col-title">
        <label>City</label>
    </div>
    <div class="clr-col-2">
        <clr-input-container>
          <input clrInput type="text" formControlName="city" name="city" size="20" ngDefaultControl />
        </clr-input-container>
    </div>
    <div class="clr-col-1 col-title">
        <label>State</label>
    </div>
    <div class="clr-col-2">
        <app-search-select id="states" #statesCompanyDropdown [tag]="'states'" name="state" [width]="'12em'" [records]="states" (click)="reReadStates()" [(ngModel)]="state" ngDefaultControl [formControl]="companyForm.controls.state"></app-search-select>
    </div>
 </div>



 <div class="clr-row form-group row-span">
    <div class="clr-col-1 col-title">
        <label>Postal Code</label>
    </div>
    <div class="clr-col-2">
        <clr-input-container>
        <input clrInput type="text" formControlName="postal_code" name="postal_code" ngDefaultControl   size="20"  />
        </clr-input-container>
    </div>


    <div class="clr-col-1 col-title">
        <label>Active</label>
    </div>
    <div class="clr-col-2">
        <clr-input-container>
          <input clrInput clrCheckbox type="checkbox" formControlName="active" name="active" ngDefaultControl />
        </clr-input-container>
    </div>
 </div>


 <div class="clr-row form-group row-span">
    <div class="clr-col-1 col-title">
        <label>Time Zone</label>
    </div>
    <div class="clr-col-5">
                <div id="company-timezones-wrapper" *ngIf="time_zones">
                        <app-search-select id="timezones" #timezonesCompanyDropdown [tag]="'time_zones'" [width]="'21em'" name="timezone" [records]="time_zones"  [(ngModel)]="timezone" (click)="reReadTimezones()"  ngDefaultControl [formControl]="companyForm.controls.timezone"></app-search-select>
                </div>
    </div>
 </div>

 <div class="clr-row form-group row-span">
    <div class="clr-col-1 col-title">
        <label>Parent Company</label>
    </div>
    <div class="clr-col-5">
                            <clr-select-container>
                            <select id="company-select" clrSelect formControlName="parent_id" name="parent_id" class="company-select">
                            <option *ngFor="let company of companies" [value]="company.id">{{company.name}}</option>
                            </select>
                           </clr-select-container>
    </div>
 </div>

 <div class="clr-row form-group row-span">
    <div class="clr-col-1 col-title">
        <label>Company Type</label>
    </div>
    <div class="clr-col-5">
        <select class="company-select" clrSelect formControlName="company_type_id" name="company_type_id">
          <option *ngFor="let companyType of companyTypes" [value]="companyType.id">{{companyType.name}}</option>
        </select>
    </div>
 </div>


 <div class="clr-row form-group button-spacing">
    <div class="clr-col-5 col-title">
    </div>
    <div class="clr-col-1 submit-button">
       <button class="btn btn-primary" type="submit" (click)="submit()" [disabled]="!companyForm.dirty || companyForm.invalid">{{ buttonValue(item) }}</button>
    </div>
 </div>

</form>
</div>
