import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BaseComponent } from '@shared/components/base/base.component';
import { UserSessionService } from '@core/services/user-session/user-session.service';
import { CustomPeriodService } from '@core/services/custom-period/custom-period.service';
import { UserService } from '@core/services/user/user.service';
import { UserProfileService } from '@modules/users/services/user-profile.service';
import { CustomPeriod } from '@modules/companies/models/data-interval/data-inteval.model';
import { Store } from '@ngrx/store';
import { EventsState } from '@modules/events/states/events.state';
import { EventsService } from '@modules/events/services/events.service';
import { UserSessionState } from '@core/stores/user-session/states/user-session.states';
import { ICustomPeriod } from '@modules/companies/custom-periods/models/custom-period.model';
import { get_item } from '@shared/utils/storage/encrypted_storage';
import { Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import * as _ from 'lodash';

@Component({
  providers: [
    EventsService,
    CustomPeriodService,
    UserSessionService,
    UserProfileService,
    UserService
  ],
  selector: 'app-custom-period-list',
  templateUrl: './custom-period-list.component.html',
  styleUrls: ['./custom-period-list.component.scss']
})
export class CustomPeriodListComponent extends BaseComponent
  implements OnInit, OnDestroy, AfterViewInit {
  private className = 'CustomPeriodListComponent';
  listHeader = 'Custom Periods';
  private eventSubs: Subscription;
  detailUrl = '';
  baseRoute = '';
  baseUrl = '/admin/companies/custom-periods';
  items: CustomPeriod[];
  loading = true;
  selected = [];
  itemIds = [];
  private deleteModalTitle = 'Delete Custom Period';
  private deleteEvent = 'deleteCustomPeriodItems';
  noDataText = 'No data intervals to display.';

  itemCount = 0;
  limit;
  rowLimitLocalStorageKey = this.className + '-row-limit';
  userCustomPeriodsOnlyStorageKey =
    this.className + '-user-custom-periods-only';
  errorMessage: string;
  showUser = true;
  showCompany = true;
  private eefreshListSubscription: Subscription;
  private deleteItemsSubscription: Subscription;
  private filters: any;
  private successToast = {
    title: 'Success!',
    text: '',
    type: 'success'
  };
  private errorToast = {
    title: 'Error!',
    text: '',
    type: 'error'
  };
  private itemNameOne = 'Custom Period';
  private itemNameMany = 'Custom Periods';
  constructor(
    protected idle: Idle,
    protected keepalive: Keepalive,
    protected eventStore: Store<{ nf: EventsState }>,
    protected sessionStore: Store<{ uss: UserSessionState }>,
    protected eventsService: EventsService,
    private customPeriodService: CustomPeriodService,
    protected userProfileService: UserProfileService,
    protected userSessionService: UserSessionService,
    protected router: Router
  ) {
    super(idle, keepalive, eventsService, sessionStore, router);
    this.subscribeEvents();
    this.limit = this.userProfileService.getStorageVal(
      this.rowLimitLocalStorageKey
    );
    if (!this.limit) {
      this.limit = 25;
    }
  }

  ngOnInit(): void {
    this.subscribeEvents();
  }

  ngOnDestroy(): void {
    if (typeof this.eventSubs !== 'undefined' && this.eventSubs !== null) {
      this.eventSubs.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    this.saveRoute();
  }

  subscribeEvents() {
    this.eventSubs = this.eventStore.select('nf').subscribe(event => {
      if (event.items.id === 'deleteCustomPeriods') {
        this.deleteItems();
      }
    });
  }

  addItem() {
    this.router.navigate([`${this.baseUrl}/0`]);
  }

  deleteDialog() {
    const itemNames = this.selected.map(function(d) {
      return d['name'];
    });
    let id = 'openCustomPeriodDeleteModal';
    this.eventsService.publish(id, 'openCustomPeriodDeleteModal', {
      module: 'companies',
      action: 'open',
      deleteModalTitle: this.deleteModalTitle,
      itemNames: itemNames,
      event: this.deleteEvent,
      additionalInfo: '',
      name: ''
    });
  }

  addFilter(fs, _f) {
    if (!('filters' in fs)) {
      fs['filters'] = [_f];
    } else {
      fs['filters'].push(_f);
    }
    return fs;
  }

  refresh(filters) {
    // this.loading = true;
    this.userProfileService.setStorageVal(
      this.rowLimitLocalStorageKey,
      this.limit
    );
    this.filters = filters;
    // get around clarity 'expression has changed after it was checked' bug.
    setTimeout(() => {
      this.loading = true;
    }, 100);
    this.selected = [];

    let fs = _.cloneDeep(filters);

    this.customPeriodService.getCustomPeriods(fs).subscribe(
      items => this.onItemsRetrieved(items),
      error => (this.errorMessage = <any>error)
    );
  }

  onItemsRetrieved(data: any): void {
    if (data.is_error) {
      this.router.navigate([this.detailUrl]);
    } else {
      this.items = data.item_list;
      this.itemCount = data.item_count;
      // get around clarity 'expression has changed after it was checked' bug.
      setTimeout(() => {
        this.loading = false;
      }, 200);
    }
  }

  deleteItems() {
    this.itemIds = this.selected.map(function(d) {
      return d['id'];
    });
    for (const itemId of this.itemIds) {
      this.customPeriodService.deleteCustomPeriod(itemId).subscribe(
        item => this.onItemDeleted(item, itemId),
        error => (this.errorMessage = <any>error)
      );
    }
  }

  onItemDeleted(data: any, itemId): void {
    this.itemIds = this.itemIds.filter(function(e) {
      return e !== itemId;
    });
    if (data.is_error) {
      this.errorToast.text = `Failed to delete ${
        this.selected.length > 1 ? this.itemNameMany : this.itemNameOne
      }!`;
      this.eventsService.showToast(this.errorToast);
    } else {
      if (this.itemIds.length === 0) {
        this.successToast.text = `${
          this.selected.length > 1 ? this.itemNameMany : this.itemNameOne
        } deleted successfully!`;
        this.eventsService.showToast(this.successToast);
        this.refresh(this.filters);
      }
    }
  }

  toggleItemActive(period: ICustomPeriod) {
    this.customPeriodService.saveCustomPeriod(period).subscribe(
      item => this.onItemUpdated(item),
      error => (this.errorMessage = <any>error)
    );
  }

  onItemUpdated(data: any): void {
    if (data.is_error) {
      this.errorToast.text = data.message;
      this.eventsService.showToast(this.errorToast);
    } else {
      // console.log(`${this.className} onItemUpdated`);
      this.successToast.text = `${this.itemNameOne} updated successfully!`;
      this.eventsService.showToast(this.successToast);
    }
  }

  readUrl(id) {
    return `${this.baseUrl}/${id}`;
  }
}
