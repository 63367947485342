import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EventsService } from '@modules/events/services/events.service';
import { UserProfileService } from '@modules/users/services/user-profile.service';
import { UserService } from '@core/services/user/user.service';
import { UserSessionService } from '@core/services/user-session/user-session.service';
import { Subscription } from 'rxjs';
import { BaseComponent } from '@shared/components/base/base.component';
import { constants } from '@core/constants';
import { Store } from '@ngrx/store';
import { EventsState } from '@modules/events/states/events.state';
import { UserSessionState } from '@core/stores/user-session/states/user-session.states';
import { Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

import { passwordsMatchValidator } from '@shared/directives/passwords-match.directive';
import { nameCheckValidator } from '@shared/directives/name-check.directive';
import { IUser } from '@shared/interfaces/users';
import { NameCheckService } from '@core/services/name-check.service';

@Component({
  providers: [
    UserProfileService,
    UserSessionService,
    EventsService,
    UserService
  ],
  selector: 'app-user-preferences',
  templateUrl: './user-preferences.component.html',
  styleUrls: ['./user-preferences.component.scss']
})
export class UserPreferencesComponent extends BaseComponent
  implements OnInit, OnDestroy {
  openUserPreferences: boolean;
  private item: IUser;
  public initial_username: string;

  public username: string = null;
  public first_name: string;
  public last_name: string;
  private eventSubs: Subscription;
  public itemForm: FormGroup;
  private successToast = {
    title: 'Success!',
    text: 'Settings updated successfully!',
    type: 'success'
  };
  private errorToast = {
    title: 'Error!',
    text: 'Failed to update Settings!',
    type: 'error'
  };

  constructor(
    protected idle: Idle,
    protected keepalive: Keepalive,
    protected eventsService: EventsService,
    protected userProfileService: UserProfileService,
    protected userService: UserService,
    protected nameCheckService: NameCheckService,
    protected userSessionService: UserSessionService,
    protected eventStore: Store<{ nf: EventsState }>,
    protected sessionStore: Store<{ uss: UserSessionState }>,
    protected router: Router
  ) {
    super(idle, keepalive, eventsService, sessionStore, router);
  }

  readUser(id) {
    this.userService.getUser(id).subscribe(
      items => this.onUserRetrieved(items),
      error => this.onError(error)
    );
  }

  onError(error: any): void {}

  subscribeEvents() {
    this.eventSubs = this.eventStore.select('nf').subscribe(event => {
      if (event.items.id === 'openUserPreferencesModal') {
        this.openUserPreferences = true;
        this.readUser(event.items.payload.userSession.id);
      }
    });
  }

  ngOnInit() {
    this.initializeForm();
    this.subscribeEvents();
  }

  initializeForm() {
    this.itemForm = new FormGroup({
      first_name: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50)
      ]),
      last_name: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50)
      ]),
      username: new FormControl(
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
          Validators.pattern(constants.app.email_regex)
        ],

        nameCheckValidator.createValidator(
          this.nameCheckService,
          'user',
          this.initial_username
        )
      ),
      password: new FormControl('', [
        Validators.minLength(8),
        Validators.pattern(constants.app.user_password_regex)
      ]),
      password_confirm: new FormControl('', [
        passwordsMatchValidator(this, {
          formName: 'itemForm',
          field: 'password'
        })
      ])
    });
  }

  onUserRetrieved(data: any): void {
    if (data.is_error) {
      console.log(`Error in onUserRetrieved ${data.message}`);
      // this.router.navigate([`${this.baseRoute}${this.errorReturnUrl}`]);
    } else {
      this.item = data.item;
      this.initial_username = this.item.username;

      this.itemForm.controls[
        'username'
      ].asyncValidator = nameCheckValidator.createValidator(
        this.nameCheckService,
        'user',
        this.initial_username
      );
      this.patchForm();
    }
  }

  patchForm() {
    this.itemForm.patchValue(this.item);
    this.username = this.item.username;
    this.last_name = this.item.last_name;
    this.first_name = this.item.first_name;
    this.itemForm.controls['last_name'].setValue(this.item.last_name);
    this.itemForm.controls['first_name'].setValue(this.item.first_name);
    this.itemForm.controls['username'].setValue(this.item.username);
  }

  update() {
    const updateUser = Object.assign({}, this.item, this.itemForm.value);
    if (!this.itemForm.get('password').value) {
      delete updateUser.password;
      delete updateUser.password_confirm;
    }
    // console.log(updateUser);
    this.userService.saveUser(updateUser).subscribe(
      item => this.onItemUpdated(item),
      error => this.onError(error)
    );
  }

  onItemUpdated(data: any): void {
    if (data.is_error) {
      console.log(`Error in onItemUpdated - ${data.message}`);
      this.errorToast.text = data.message;
      this.eventsService.showToast(this.errorToast);
    } else {
      this.eventsService.showToast(this.successToast);
      this.eventsService.publish('updateFullName', 'updateFullName', {
        module: 'shared',
        action: 'updatel',
        full_name: data.item.full_name,
        additionalInfo: ''
      });
      this.close();
    }
  }

  cancel() {
    this.close();
    // console.log('UserPreferenceComponent cancel');
  }

  close() {
    this.openUserPreferences = false;
    this.itemForm.reset();
    let id = 'closeUserPreferencesModal';
    this.eventsService.publish(id, 'closeUserPreferencesModal', {
      module: 'shared',
      action: 'closeUserPreferencesModal',
      additionalInfo: ''
    });
  }

  ngOnDestroy(): void {
    if (typeof this.eventSubs !== 'undefined' && this.eventSubs !== null) {
      this.eventSubs.unsubscribe();
    }
  }

  updateUsername(e) {
    this.username = e;
  }

  original() {
    if (this.initial_username === this.username) {
      return true;
    }
    return false;
  }
}
