import { ActivatedRoute, Router } from '@angular/router';
import {
  Input,
  Output,
  Component,
  ElementRef,
  AfterViewInit,
  EventEmitter,
  OnDestroy,
  OnInit,
  ComponentRef
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { EventsState } from '@modules/events/states/events.state';
import { EventsService } from '@modules/events/services/events.service';
import { Store } from '@ngrx/store';
import { Subscription, Observable } from 'rxjs';
import { NgxSelectDropdownComponent } from 'ngx-select-dropdown';

@Component({
  providers: [EventsService],
  selector: 'app-search-select',
  templateUrl: './searchable-select.component.html',
  styleUrls: ['./searchable-select.component.scss']
})
export class SearchSelectComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input('records') records: any[];
  @Input('formControl') formControl: FormControl;
  @Input('searchedTag') searchedTag?: ComponentRef<NgxSelectDropdownComponent>;
  @Input('width') width?: string;
  @Input('tag') tag?: string;
  @Input('disabled') disabled?: boolean;
  @Output('selected') selected = new EventEmitter<string>();
  @Input() itemSelected: Observable<any>;

  public record?: any;
  private eventSubs: Subscription;
  private eventsSubs: Subscription;

  public config = {
    displayFn: (item: any) => {
      return item.name;
    },
    search: true, //true/false for the search functionlity defaults to false,
    height: 'auto', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Select', // text to be displayed when no item is selected defaults to Select,
    moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search', // label thats displayed in search input,
    searchOnKey: 'name', // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    clearOnSelection: false, // clears search criteria when an option is selected if set to true, default is false
    inputDirection: 'ltr' // the direction of the search input can be rtl or ltr(default)
  };
  options = []; //{id: 1, name:['option 1']}, {id: 2, name:['option 2']}, {id: 1, name:['option 3']}];

  constructor(
    protected eventsService: EventsService,
    protected eventStore: Store<{ nf: EventsState }>,
    protected element: ElementRef,
    protected route: ActivatedRoute,
    protected router: Router
  ) {}

  subscribeEvents(): void {
    this.eventsSubs = this.eventStore.select('nf').subscribe(event => {
      if (event.items.id === 'readDashboards') {
        //this.records = names;// event.items.payload.dashboards;
        this.records = [];

        for (let i = 0; i < event.items.payload.dashboards.length; i++) {
          let item: any = {
            id: event.items.payload.dashboards[i].id,
            name: event.items.payload.dashboards[i].name
          };
          this.records.push(item);
        }
      }
    });

    if (typeof this.disabled === 'undefined' || this.disabled === null) {
      this.disabled = false;
    }
    if (
      typeof this.itemSelected !== 'undefined' &&
      this.itemSelected !== null
    ) {
      this.itemSelected.subscribe(item => {
        if (typeof item !== 'undefined' && item !== null) {
          this.formControl.setValue(item.name);
        }
      });
    }
  }

  ngOnInit(): void {
    this.subscribeEvents();
  }

  ngOnDestroy(): void {
    if (typeof this.eventSubs !== 'undefined' && this.eventSubs !== null) {
      this.eventSubs.unsubscribe();
    }
    if (typeof this.eventsSubs !== 'undefined' && this.eventsSubs !== null) {
      this.eventsSubs.unsubscribe();
    }
  }

  ngAfterViewInit() {
    if (typeof this.records !== 'undefined' && this.records !== null) {
      this.records.forEach(item =>
        this.options.push({ id: item.id, name: item.name })
      );
      this.fixDropdownButton();
    }
    //        this.fixDropDown(this.searchSelect);
  }

  fixDropdownButton() {
    let el = this['element'] as ElementRef;

    var button = el.nativeElement.querySelector('button');
    button.style.background = 'transparent';
    button.style.borderRadius = 'none';
    button.style.borderTop = 'none';
    button.style.borderLeft = 'none';
    button.style.borderRight = 'none';
    button.style.borderBottomRightRadius = '0';
    button.style.borderBottomLeftRadius = '0';
    button.style.marginTop = '1.4em';
    if (this.width) {
      button.style.width = this.width;
    } else {
      button.style.width = '21em';
    }
    button.style.zIndex = '-1';

    var span = el.nativeElement.querySelectorAll('span');
    span[1].classList.remove('sdicon-angle-down');
    span[1].classList.remove(...span[1].classList);
    span[1].innerHTML =
      '<clr-icon shape="caret" style="transform: rotate(180deg); width: 13px; height: 13px;margin-left:4px;"></clr-icon>';
    span[1].style.color = '#b3b3b3';
    span[1].style.width = '8px';
    span[1].style.marginLeft = '0px';
    span[1].style.height = '8px';

    return false;
  }

  fixDropDown(elem) {
    if (elem && elem[`searchSelect`]) {
      let el = elem[`searchSelect`]['_elementRef'] as ElementRef;

      var button = el.nativeElement.querySelector('button');
      button.style.borderRadius = 'none';
      button.style.background = 'transparent';
      button.style.borderBottomRightRadius = '0';
      button.style.borderBottomLeftRadius = '0';
      button.style.borderTop = 'none';
      button.style.borderLeft = 'none';
      button.style.borderRight = 'none';
      button.style.borderBottomRightRadius = '0';
      button.style.borderBottomLeftRadius = '0';
      button.style.marginTop = '1.4em';
      if (this.width) {
        button.style.width = this.width;
      } else {
        button.style.width = '21em';
      }
      button.style.zIndex = '-1';

      var span = el.nativeElement.querySelectorAll('span');
      span[1].classList.remove('sdicon-angle-down');
      span[1].classList.remove(...span[1].classList);
      span[1].innerHTML =
        '<clr-icon shape="caret" style="transform: rotate(180deg); width: 13x; height: 13px;margin-left:4px;"></clr-icon>';
      span[1].style.color = '#b3b3b3';
      span[1].style.width = '8px';
      span[1].style.marginLeft = '0px';
      span[1].style.height = '8px';
      span[1].style.float = 'right';
    }
  }

  beautify() {
    let selected = document.querySelectorAll<HTMLElement>('.selected-items');
    for (let i = 0; i < selected.length; i++) {
      selected[i].style.display = 'none';
    }

    let elem = document.querySelectorAll<HTMLElement>('.nsdicon-angle-down');

    for (let i = 0; i < elem.length; i++) {
      elem[i].style.color = '#b3b3b3';
    }

    let elements = document.querySelectorAll<HTMLElement>(
      '.ngx-dropdown-list-container'
    )!;
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.borderRadius = '0px';
      elements[i].style.boxShadow = 'none';
      elements[i].style.width = '21em';
      if (this.width) {
        elements[i].style.width = this.width;
      } else {
        elements[i].style.width = '21em';
      }
      elements[i].style.background = '#FAFAFA';
      elements[i].style.border = 'none';
      elements[i].style.zIndex = '1051';
      //elements[i].style.marginLeft = '-0.7em';
      elements[i].style.marginTop = '-0.45em';
    }
  }

  onChange(e: any) {
    this.selected.emit(e);
    console.log(`Component changed` + JSON.stringify(e));
  }
}
