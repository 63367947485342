<form *ngIf="item" [formGroup]="itemForm">
<div class="flex-outer-container child-container-details config-detail">

    <clr-datagrid (clrDgRefresh)="refresh($event)" [(clrDgSelected)]="selected" class="grid-sizing fl-table" (change)="onItemSelected($event)">
        <clr-dg-column [clrDgField]="'name'">
           Data Point Name
        </clr-dg-column>
        <clr-dg-column>
           Short Name
        </clr-dg-column>
        <clr-dg-row *clrDgItems="let item of items" [clrDgItem]="item" (click)="itemSelected(item)">
            <clr-dg-cell>
                    {{ item.name }}
            </clr-dg-cell>
            <clr-dg-cell>
                    {{ shortName(item.name) }}
            </clr-dg-cell>
        </clr-dg-row>
<!--
        <clr-dg-detail *clrIfDetail="let detail">
          <app-grid-config-details [item]="detail"></app-grid-config-details>
        </clr-dg-detail>
-->
        <clr-dg-footer>
            <clr-dg-pagination #pagination [clrDgPageSize]="perPage" [clrDgTotalItems]="itemCount">
                <clr-dg-page-size [clrPageSizeOptions]="[1, 2, 5, 10]">
                </clr-dg-page-size>
                {{ pagination.firstItem + 1 }}
                -
                {{ pagination.lastItem + 1 }}
                of
                {{ pagination.totalItems }}
            </clr-dg-pagination>
        </clr-dg-footer>
    </clr-datagrid>

<div class="clr-row last-row">
   <div class="clr-col-6 adjust-field">
     <clr-input-container>
       <input clrInput type="text" [(ngModel)]="name" formControlName="name" name="name" size="12" (ngModelChange)="updateName($event)"/>
       <clr-control-error *clrIfError="'minlength'">Must be at least 3 characters</clr-control-error>
       <clr-control-error *clrIfError="'required'">Name Is Required</clr-control-error>
     </clr-input-container>
   </div>
   <div class="adjust-update">
       <button type="button" class="btn btn-primary btn-sm config-last-btn" (click)="updateItem()">Update</button>
   </div>
</div>
</div>
</form>
