import * as highCharts from 'highcharts';
import { highChartsBase } from './highcharts-base';

export const highChartsScatter = function(name, series, labels, xAxis, yAxis) {
    let threshold_guide_items = [];
    let event_period = null;
    let start_yaxis_on_min = 0;
    let last_calculated_yaxis_min = null;
    let first_reset_triggered = false;

    return Object.assign(
        {
     chart: {
            type: 'scatter',
            zoomType: 'xy'
        },
        credits:{enabled:false},
        exporting:{enabled:false},
        title:{text: name},

    title: { text: name, style: { fontWeight: 'bold' } },
    boost: {
         enabled: true,
         useGPUTranslations: true,
    },
    xAxis: xAxis,
    yAxis: yAxis,
    legend: {
        layout: 'vertical',
        align: 'left',
        verticalAlign: 'top',
        x: 100,
        y: 70,
        floating: true,
        backgroundColor: highCharts.defaultOptions.chart.backgroundColor || 'rgba(255,255,255,0.25)',
        borderWidth: 1
    },

    plotOptions: {
        scatter: {
            marker: {
                radius: 5,
                states: {
                    hover: {
                        enabled: true,
                        lineColor: 'rgb(100,100,100)'
                    }
                }
            },
            states: {
                hover: {
                    marker: {
                        enabled: false
                    }
                }
            },
            tooltip: {
                headerFormat: '<b>{series.name}</b><br>',
                pointFormat: '{point.x}, {point.y}'
            }
        }
    },

    series: series
    },
        highChartsBase
    );
};


