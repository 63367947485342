import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { UserSessionService } from '@core/services/user-session/user-session.service';
import { UserSessionState } from '@core/stores/user-session/states/user-session.states';
import { UserSessionActionTypes } from '@core/stores/user-session/actions/user-session.actions';
import { set_item, get_item } from '@shared/utils/storage/encrypted_storage';
import { AuthService } from '@core/services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  private loginSubs: Subscription;
  private sessionSubs: Subscription;
  public payload: any;
  public userSession: any;
  private is_authenticated: boolean = false;
  constructor(
    private router: Router,
    private authService: AuthService,
    private userSessionService: UserSessionService,
    protected sessionStore: Store<{ uss: UserSessionState }>
  ) {
    /*
    let session = JSON.parse(get_item('cached_session'));
    if (session && session.is_authenticated) {
        this.is_authenticated = session.is_authenticated;
        this.userSession = session;
    } else {
  */

    let session = get_item('session');
    if (session && session.is_authenticated) {
      this.is_authenticated = session.is_authenticated;
      this.userSession = session;
    } else {
      this.sessionStore.select('uss').subscribe(event => {
        if (event['payload']['action'] === 'authenticate') {
          this.payload = event['payload'];
          this.is_authenticated = event['payload']['authenticated'];
        }
      });
      this.userSessionService.activeUserSession.subscribe(session => {
        var payload = JSON.parse(JSON.stringify(session));
        payload['action'] = 'user_session';
        this.sessionStore.dispatch({
          type: UserSessionActionTypes.CreateSession,
          payload: payload
        });
        this.userSession = session;
      });
    }
  }

  checkComponentACL(route, userSession, parentComponent) {
    if (userSession.settings) {
      if (parentComponent) {
        if (!userSession.settings['components'].includes(parentComponent)) {
          this.router.navigate([
            `${userSession.settings.route}/${userSession.settings.default_component}`
          ]);
          return false;
        }
      }
      //this.router.navigate(['/admin/event-management/asset-customers/list/1']);
      return true;
    } else {
      let _queryParams = null;

      if (window.location.search === '?fromLegacy=1') {
        _queryParams = { fromLegacy: 1 };
      } else {
        // _queryParams = { ...route };
      }
      if (!window.location.href.includes('-stage-')) {
        this.router.navigate(['/login'], { queryParams: _queryParams });
      }
      return false;
    }
    //this.router.navigate(['/admin/event-management/asset-customers/list/1']);
    return true;
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean>
    | Promise<boolean>
    | boolean
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | UrlTree {
    // todo get(next.data, 'module', null) is set in app-routing.module
    // canActivate: [AuthGuard], data: {module: 'MainModule'} if coming from
    // main always cache bust... revisit later...
    //   let cachedSession = JSON.parse(get_item('cached_session'));

    let freshSession = this.userSessionService._userSession.value;

    let cachedSession = get_item('session');

    if (cachedSession && cachedSession.is_authenticated) {
      cachedSession['action'] = 'user_session';
      this.sessionStore.dispatch({
        type: UserSessionActionTypes.CreateSession,
        payload: cachedSession
      });
      return this.checkComponentACL(
        { redirectTo: state.url },
        cachedSession,
        next.data.parentComponent
      );
    }

    if (freshSession) {
      this.userSession = freshSession;
    }

    if (this.userSession && this.userSession.is_authenticated) {
      set_item('session', JSON.stringify(this.userSession));
      this.sessionStore.dispatch({
        type: UserSessionActionTypes.CreateSession,
        payload: this.userSession
      });
      return this.checkComponentACL(
        { redirectTo: state.url },
        this.userSession,
        next.data.parentComponent
      );
    }
    if (this.is_authenticated) {
      if (this.payload) {
        if (this.userSession) {
          set_item('session', JSON.stringify(this.userSession));
          return this.checkComponentACL(
            { redirectTo: state.url },
            this.userSession,
            next.data.parentComponent
          );
        } else {
          return this.router.parseUrl('login');
        }
      } else {
        return this.router.parseUrl('login');
      }
    } else {
      return this.router.parseUrl('login');
    }
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.canActivate(next, state);
  }
}
