import { ControlCenterComponent } from '@modules/data-analysis/control-center/control-center.component';
import { ChartModule } from 'angular-highcharts';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';
import { TabsModule } from 'ngx-bootstrap/tabs';
import {
  StoreModule,
  ActionReducerMap,
  ActionReducer,
  MetaReducer
} from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { EffectsModule, Actions } from '@ngrx/effects';
import { UserSessionState } from '@core/stores/user-session/states/user-session.states';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ClarityModule } from '@clr/angular';
import { ClrDialogModule } from 'clr-dialog';
import { ColorPickerModule } from 'ngx-color-picker';
import { MomentModule } from 'ngx-moment';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from '@shared/shared.module';
import { GridsterModule } from 'angular-gridster2';
import { TagInputModule } from '@vpetrusevici/ngx-chips';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { Idle } from '@ng-idle/core';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { HighchartsChartModule } from 'highcharts-angular';
import { HttpClientModule } from '@angular/common/http';
import { NouisliderModule } from 'ng2-nouislider';
import { ClrIconModule } from '@clr/angular';
import { DragulaModule, DragulaService } from 'ng2-dragula';
import { ToastrModule } from 'ngx-toastr';
import { ReportWizardComponent } from '@modules/reports/report-wizard/report-wizard.component';
import { CompanyWizardComponent } from '@modules//companies/company-wizard/company-wizard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomPeriodService } from '@core/services/custom-period/custom-period.service';
import { UserSessionService } from '@core/services/user-session/user-session.service';
import { ThingService } from '@core/services/thing/thing.service';
import { NavService } from './store/services/nav.service';
import { DashboardMenuService } from './store/services/dashboard-menu.service';
import { appReducer, appMenuReducer } from './app.reducer';
import { eventsReducer } from '@modules/events/reducers/events.reducer';
import { LocalService } from './store/services/local.service';
import { CompanyModule } from '@modules/companies/company.module';
import { EventsService } from '@modules/events/services/events.service';
import { EventsModule } from '@modules/events/events.module';
import { TicketingModule } from '@modules/ticketing/ticketing.module';
import { AdvancedCalculationsModule } from '@modules/advanced-calculations/advanced-calculations.module';
import { ToastrService } from 'ngx-toastr';
import { userSessionReducer } from '@core/stores/user-session/reducers/user-session.reducers';
import { UserSessionActions } from '@core/stores/user-session/actions/user-session.actions';
import { UserSessionEffects } from '@core/stores/user-session/effects/user-session.effects';
import { AngularSlickgridModule } from 'angular-slickgrid';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

export const reducers: ActionReducerMap<UserSessionState> = {
  session: userSessionReducer
};

export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys: [
      {
        product: {
          encrypt: state => btoa(state),
          decrypt: state => atob(state)
        }
      }
    ],
    rehydrate: true,
    removeOnUndefined: true
  })(reducer);
}

const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    TagInputModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({ preventDuplicates: true }),
    NgxMaterialTimepickerModule,
    FontAwesomeModule,
    SelectDropDownModule,
    CommonModule,
    ChartModule,
    CompanyModule,
    HighchartsChartModule,
    DragulaModule.forRoot(),
    ClarityModule,
    ClrDialogModule,
    ClrIconModule,
    ColorPickerModule,
    DragulaModule,
    HttpClientModule,
    EventsModule,
    TicketingModule,
    MomentModule,
    GridsterModule,
    LeafletModule,
    MomentModule,
    NgIdleKeepaliveModule.forRoot(),
    NouisliderModule,
    SharedModule,
    FormsModule,
    AdvancedCalculationsModule,
    ReactiveFormsModule,
    TabsModule.forRoot(),
    AngularSlickgridModule.forRoot({
      // add any Global Grid Options/Config you might want
      // to avoid passing the same options over and over in each grids of your App
      enableExcelExport: true, // Excel Export is the new default,
      enableExport: false, // CSV/Text with Tab Delimited
      enableAutoResize: true,
      autoResize: {
        container: '#demo-container',
        rightPadding: 10
      } as any
    }),
    EffectsModule.forRoot([UserSessionEffects]),
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreModule.forFeature('session', userSessionReducer),
    StoreModule.forFeature('events', eventsReducer),
    StoreModule.forRoot({ nf: eventsReducer }),
    StoreModule.forRoot({
      ui: appReducer,
      nf: eventsReducer,
      mn: appMenuReducer,
      uss: userSessionReducer,
      router: routerReducer
    }),
    StoreRouterConnectingModule.forRoot(),
    NgxMapboxGLModule.withConfig({
      accessToken:
        'pk.eyJ1IjoicmVtbG92IiwiYSI6ImNqaTY2OW4xOTByZzIzdm1qbWk4Y3loeTYifQ.x_hgN-lKM6BhZQAn2AI0GQ'
    })
  ],
  providers: [
    Actions,
    UserSessionService,
    EventsService,
    ToastrService,
    Idle,
    NavService,
    LocalService,
    ThingService,
    DragulaService,
    DashboardMenuService,
    CustomPeriodService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
