import {
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { LaChartService } from '@shared/components/la-chart/la-chart.service';
import { DataViewDataService } from '@core/services/data-view-data/data-view-data.service';
import { DataViewService } from '@core/services/data-view/data-view.service';
import { EventsService } from '@modules/events/services/events.service';

@Component({
  selector: 'app-data-commander-chart',
  templateUrl: './data-commander-chart.component.html',
  styleUrls: ['./data-commander-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [LaChartService, EventsService]
})
export class DataCommanderChartComponent implements OnInit, OnDestroy {
  private chart: any;
  private errorMessage: string;
  public time_domain_id;
  public time_domain_interval_id;
  public start_yaxis_on_min;
  private dataViewData: any;
  private loading?: boolean = true;
  private lastChartData;
  public totalDataViewType = false;
  public totalDataViewTypeTitle = '';
  public totalDataViewTypeTotal: any;
  public wiggleMe?: boolean = false;
  public bounceMe?: boolean = false;
  public blinkMe?: boolean = false;
  public visualCueColor?: any = '#565656';
  private colIntervalLength?: any = null;

  @Input() dataviewID: number;
  @ViewChild('dataCommanderChart') private laChart: ElementRef;

  constructor(
    private laChartService: LaChartService,
    private _dataViewDataService: DataViewDataService,
    private _dataViewService: DataViewService,
    private _zone: NgZone
  ) {}

  onDataRetrieved(data: any) {
    this.dataViewData = data.item;
    if (!data.is_error && data.item.collection.length > 0) {
      this.time_domain_id = data.item.time_domain_id;
      this.time_domain_interval_id = data.item.time_domain_interval_id;
      this.start_yaxis_on_min = data.item.start_yaxis_on_min;

      if ([1, 2, 4].includes(data.item.data_view_type_id)) {
        // 1 time series line
        // 2 pie chart
        // 4 heat map
        this._zone.runOutsideAngular(() => {
          this.chart = this.laChartService.makeChart(
            data,
            this.laChart.nativeElement
          );
          if (data.item.data_view_type_id === 1) {
            let seriesDataRes: object;
            seriesDataRes = this.laChartService.getSeriesData(data.item);
            this.lastChartData = seriesDataRes['seriesData'];
            const yAxisMin = seriesDataRes['yAxisMin'];
            // this.start_yaxis_on_min = true;
            if (this.start_yaxis_on_min && yAxisMin) {
              this.chart.update({
                yAxis: {
                  min: yAxisMin,
                  startOnTick: false
                }
              });
            }
          }
          if (data.item.data_view_type_id === 2) {
            this.lastChartData = this.laChartService.getPieSeriesData(
              data.item
            );
          }
          if (data.item.data_view_type_id === 4) {
            const {
              seriesData,
              categories
            } = this.laChartService.getHeatMapSeriesData(data.item);
            this.lastChartData = seriesData;
            this.chart.yAxis[0].setCategories(categories, false);
          }
          this.lastChartData.forEach(series => {
            this.chart.addSeries(series, false);
          });
          this.chart.redraw();
          this.chart.hideLoading();
        });
      } else if (data.item.data_view_type_id === 3) {
        // text labels
        this.totalDataViewType = true;
        this.totalDataViewTypeTitle = data.item.name;
        this.totalDataViewTypeTotal = data.item.collection
          .map(function(val) {
            return val.data;
          })
          .reduce(function(a, b) {
            return a + b;
          }, 0);

        this.setAnimations(data);

        if (
          'unit' in data.item.collection[0] &&
          data.item.collection[0].unit === '%'
        ) {
          this.totalDataViewTypeTotal += '%';
        } else {
          this.totalDataViewTypeTotal = this.totalDataViewTypeTotal
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
      }
    }
    this.loading = false;
  }

  getDataViewData() {
    this.loading = true;
    this._dataViewDataService.getData(this.dataviewID).subscribe(
      (data: any) => this.onDataRetrieved(data),
      (error: any) => (this.errorMessage = <any>error)
    );
  }

  getDateRangeData(id: number, fromPeriod: any, toPeriod: any) {
    this.colIntervalLength =
      new Date(toPeriod).getTime() - new Date(fromPeriod).getTime();
    this.loading = true;
    this._dataViewService.getDateRangeData(id, fromPeriod, toPeriod).subscribe(
      (data: any) => this.onDataRetrieved(data),
      (error: any) => (this.errorMessage = <any>error)
    );
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  }

  setAnimations(data: any) {
    if ('visual_cue_items' in data.item.settings) {
      for (const visual_cue_item of data.item.settings['visual_cue_items']) {
        if (
          this.totalDataViewTypeTotal <= visual_cue_item['high_threshold'] &&
          this.totalDataViewTypeTotal >= visual_cue_item['low_threshold']
        ) {
          if (parseInt(visual_cue_item['animation_type']) === 1) {
            this.wiggleMe = true;
            this.bounceMe = false;
            this.blinkMe = false;
          } else if (parseInt(visual_cue_item['animation_type']) === 2) {
            this.wiggleMe = false;
            this.bounceMe = true;
            this.blinkMe = false;
          } else if (parseInt(visual_cue_item['animation_type']) === 3) {
            this.wiggleMe = false;
            this.bounceMe = false;
            this.blinkMe = true;
          } else {
            this.wiggleMe = false;
            this.bounceMe = false;
            this.blinkMe = false;
          }
          this.visualCueColor = visual_cue_item['color'];
          break;
        } else {
          this.visualCueColor = '#565656';
          this.wiggleMe = false;
          this.bounceMe = false;
          this.blinkMe = false;
        }
      }
    }
  }
}
