<div id="deleteModal" class="modal" *ngIf="openDeleteModal" [(hidden)]="!openDeleteModal">
    <div #deleteItemModal class="modal-dialog" role="dialog" aria-hidden="true">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">{{deleteModalTitle}}</h3>
            </div>
            <div class="modal-body">
                <h6>Delete the following selected {{itemNames && itemNames.length > 1 ? 'Items' : 'Item'}}?</h6>
                <div *ngFor="let name of itemNames" class="name">{{name}}</div>
                <div *ngIf="additionalInfo">{{additionalInfo}}</div>
                <br />
            </div>
            <div class="modal-footer">
                <button class="btn btn-outline" type="button" (click)="close()">Cancel</button>
                <button class="btn btn-primary" type="button" (click)="deleteItems()">Ok</button>
            </div>
        </div>
    </div>
</div>
<div id="deleteModalBackdrop" *ngIf="openDeleteModal" class="modal-backdrop" aria-hidden="true"></div>


