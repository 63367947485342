import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BaseService } from '@core/services/base.service';
import { constants } from '@core/constants';
import { catchError, map } from 'rxjs/operators';
import {
  IDataPoint,
  IDataPoints
} from '@modules/data-analysis/data-points/models/data-point.model';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { EventsService } from '@modules/events/services/events.service';

const initialDataPointsData = [] as IDataPoint[];
const initialDataPointData = {} as IDataPoint;

@Injectable({
  providedIn: 'root'
})
export class DataPointService extends BaseService {
  private dataPointsDataSource = new BehaviorSubject<IDataPoint[]>(
    initialDataPointsData
  );
  private dataPointCountSource = new BehaviorSubject<number>(null);
  private dataPointDataSource = new BehaviorSubject<IDataPoint>(
    initialDataPointData
  );
  public dataPoints = this.dataPointsDataSource.asObservable();
  public dataPointCount = this.dataPointCountSource.asObservable();
  public dataPoint = this.dataPointDataSource.asObservable();

  constructor(
    protected eventsService: EventsService,
    protected http: HttpClient
  ) {
    super(eventsService, http);
  }

  getDataPoints(filters: any) {
    return this.getMany(constants.api.dataPoint.data_point_api, filters).pipe(
      map((data: IDataPoints) => {
        this.dataPointsDataSource.next(data.item_list);
        this.dataPointCountSource.next(data.item_count);
        return data;
      }),
      catchError(error => this.handleError(error))
    );
  }

  getAllDataPoints() {
    return this.getAll(constants.api.dataPoint.data_point_api).pipe(
      map((data: IDataPoints) => {
        this.dataPointsDataSource.next(data.item_list);
        this.dataPointCountSource.next(data.item_count);
        return data;
      }),
      catchError(error => this.handleError(error))
    );
  }

  clearDataPoints() {
    this.dataPointsDataSource.next(null);
    this.dataPointCountSource.next(null);
  }

  getDataPoint(id: number) {
    if (id === 0) {
      const data = { id: 0 } as IDataPoint;
      this.dataPointDataSource.next(data);
      return data;
    }
    return this.getOne(constants.api.dataPoint.data_point_api, id).pipe(
      map(
        (data: { item: IDataPoint }) => {
          this.dataPointDataSource.next(data.item);
          return data;
        },
        catchError(error => this.handleError(error))
      )
    );
  }

  saveDataPoint(dataPoint) {
    return this.saveOne(constants.api.dataPoint.data_point_api, dataPoint).pipe(
      map(data => {
        this.handleSuccess(data);
        return data;
      }),
      catchError(error => this.handleError(error))
    );
    //    return this.saveOne(constants.api.dataPoint.data_point_api, dataPoint);
  }

  updateDataPoint(dataPoint: IDataPoint) {
    return this.updateOne(
      constants.api.dataPoint.data_point_api,
      dataPoint
    ).pipe(
      map(data => {
        this.handleSuccess(data);
        return data;
      }),
      catchError(error => this.handleError(error))
    );
  }

  deleteDataPoint(id: number) {
    return this.deleteOne(constants.api.dataPoint.data_point_api, id).pipe(
      map(data => {
        this.handleSuccess(data);
        return data;
      }),
      catchError(error => this.handleError(error))
    );
  }

  saveThingToDataPoint(thing) {
    return this.saveOne(constants.api.dataPoint.add_thing_api, thing);
  }

  updateDataPointComputedThing(thing) {
    return this.saveOne(
      constants.api.dataPoint.update_computed_thing_api,
      thing
    );
  }

  deleteThingFromDataPoint(thing) {
    return this.saveOne(constants.api.dataPoint.remove_thing_api, thing);
  }

  saveComputedThingToDataPoint(thing) {
    return this.saveOne(constants.api.dataPoint.add_computed_thing_api, thing);
  }

  deleteComputedThingFromDataPoint(thing) {
    return this.saveOne(
      constants.api.dataPoint.remove_computed_thing_api,
      thing
    );
  }

  resetComputedDatapoint(id: number) {
    let apiUrl = `${constants.api.data.data_point.reset_computed_datapoint}/${id}`;
    return this.http.get(apiUrl).pipe(
      map(data => {
        return data;
      }),
      catchError(error => this.handleError(error))
    );
  }

  getDataPointThingOrder(id: number) {
    let apiUrl = `${constants.api.data.data_point.data_point_thing_order}/${id}`;
    return this.http.get(apiUrl).pipe(
      map(data => {
        return data;
      }),
      catchError(error => this.handleError(error))
    );
  }

  getAllDataPointsAsOptions() {
    return this.getAllAsOptions(
      `${constants.api.dataPoint.data_point_api}?as_options`,
      {
        name: 'name',
        id: 'id',
        user_name: 'user_name',
        user_id: 'user_id',
        company_name: 'company_name',
        company_id: 'company_id'
      }
    );
  }

  copyDataPointToUser(payload) {
    return this.saveOne(
      constants.api.data.data_point.copy_to_user_api_url,
      payload
    );
  }
}

@Injectable({
  providedIn: 'root'
})
export class DataPointResolverService implements Resolve<any> {
  constructor(private dataPointService: DataPointService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.dataPointService.getDataPoint(+route.params.id);
  }
}
