import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { BaseService } from '@core/services/base.service';
import { constants } from '@core/constants';
import { HttpClient } from '@angular/common/http';
import { EventsService } from '@modules/events/services/events.service';
import { UserSessionState } from '@core/stores/user-session/states/user-session.states';
import { UserSessionActionTypes } from '@core/stores/user-session/actions/user-session.actions';
import { set_item, get_item } from '@shared/utils/storage/encrypted_storage';

export interface IUserSession {
  username: string;
  login_url: string;
  name: string;
  company_name: string;
  is_authenticated: boolean;
  company: object;
  avatarUrl: string;
  ws_token: string;
  role: string;
  settings: IUserSessionSettings;
  id: number;
  timeout?: number;
  last_route?: string;
}

export interface IUserSessionSettings {
  default_component: string;
  route: string;
  company_specific: string;
  dashboard_folders: [];
  default_module_component?: string;
  default_module?: string;
  components: string[];
}

const initialData = {} as IUserSession;

const localStorageDefaults = { showNav: true };

@Injectable({
  providedIn: 'root'
})
export class UserSessionService extends BaseService {
  public _userSession = new BehaviorSubject<any>(initialData);
  public activeUserSession = this._userSession.asObservable();

  private userSessionSource = new BehaviorSubject<IUserSession>(initialData);
  private userLocalStorageSource = new BehaviorSubject<any>(
    localStorageDefaults
  );
  private cachedUserSession: Observable<IUserSession>;
  private lastCachedTime;
  public data = this.userSessionSource.asObservable();
  public sessionStorage = this.userLocalStorageSource.asObservable();

  constructor(
    protected eventsService: EventsService,
    protected http: HttpClient,
    protected sessionStore: Store<{ uss: UserSessionState }>
  ) {
    super(eventsService, http);
    /*
    this.sessionStore.select('uss').subscribe(event => {
          if (event['payload']['action']==='authenticate') {
                this.get(constants.api.user_session.v2_url).pipe(
                    map((res) => {
                              res['last_route'] = localStorage.getItem('backto');
                              //this._userSession.next(res);
                              res['authenticated'] = event['payload']['authenticated']; 
                              res['action'] = 'user_session';
                              this.sessionStore.dispatch({type: UserSessionActionTypes.CreateSession, payload:res});
                     }
                ));
          }            
    });  
*/
  }

  public get userSession() {
    return this.userSessionSource.value;
  }

  cacheExpired() {
    const newTime = new Date();
    if (
      !this.lastCachedTime ||
      newTime.getTime() - this.lastCachedTime.getTime() >
        constants.api.user_session.cache_busting_interval
    ) {
      this.lastCachedTime = new Date();
      return true;
    }
    return false;
  }

  getUserSession(bustCache?: boolean) {
    //    if (this.cacheExpired() || (this.cachedUserSession && bustCache)) {
    //      console.log(`Cache expired`);
    /*
      if (!this.cachedUserSession) {
 
      this.cachedUserSession = this.get(constants.api.user_session.v2_url).pipe(
        map((res: IUserSession) => {

          this._userSession.next(res);
          console.log(`Session call result ${res}`);

          const uls = JSON.parse(localStorage.getItem(res.username));
          if (!uls) {
            set_item(res.username, JSON.stringify(localStorageDefaults));
          }
          this._userSession.next(res); //uls || localStorageDefaults);
          set_item(res.username, JSON.stringify(res));
          this._userSession.next(res);
          //return this.userSessionSource.value;
        }),
        publishReplay(1), // this tells Rx to cache the latest emitted
        refCount() // and this tells Rx to keep the Observable alive as long as there are any Subscribers
      );
     }
*/
    let decryptedSession = get_item('session');
    if (decryptedSession && decryptedSession.is_authenticated) {
      decryptedSession['action'] = 'user_session';
      decryptedSession['last_route'] = localStorage.getItem('backto');
      this.sessionStore.dispatch({
        type: UserSessionActionTypes.CreateSession,
        payload: decryptedSession
      });
      this._userSession.next(decryptedSession);
      return decryptedSession;
    }
    let session = this.get(constants.api.user_session.v2_url).subscribe(res => {
      res['action'] = 'user_session';
      res['last_route'] = localStorage.getItem('backto');
      this._userSession.next(res);
      set_item('session', res);
      return res;
    });
    return session;
  }

  setLocalStorage(key: string, value: any) {
    const uls = this.userLocalStorageSource.value;
    uls[key] = value;
    localStorage.setItem(
      this.userSessionSource.value.username,
      JSON.stringify(uls)
    );
    this.userLocalStorageSource.next(uls);
  }

  advanceSession(val) {
    this._userSession.next(val);
  }

  accessSession() {
    return this._userSession.value;
  }

  isAuthenticated(uuid) {
    let userData = localStorage.getItem(uuid);
    if (userData['authenticated']) {
      return userData['authenticated'];
    }
    return false;
  }

  initial_session() {
    return this.get(constants.api.user_session.v2_url);
  }
}
