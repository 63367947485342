<div class="slick-pagination">
  <div class="slick-pagination-nav">
    <nav aria-label="Page navigation">
      <ul class="pagination">
        <li class="page-item" [ngClass]="isLeftPaginationDisabled ? 'disabled' : ''">
          <a class="page-link icon-seek-first fa fa-angle-double-left" aria-label="First"
             (click)="changeToFirstPage($event)">
          </a>
        </li>
        <li class="page-item" [ngClass]="isLeftPaginationDisabled ? 'disabled' : ''">
          <a class="page-link icon-seek-prev fa fa-angle-left" aria-label="Previous"
             (click)="changeToPreviousPage($event)">
          </a>
        </li>
      </ul>
    </nav>

    <div class="slick-page-number">
      <span>{{textPage}}</span>
      <input type="text" class="form-control" data-test="page-number-input" [value]="pageNumber" size="1"
             [readOnly]="totalItems === 0" (change)="changeToCurrentPage($event)">
      <span>{{textOf}}</span><span data-test="page-count"> {{pageCount}}</span>
    </div>

    <nav aria-label="Page navigation">
      <ul class="pagination">
        <li class="page-item" [ngClass]="isRightPaginationDisabled ? 'disabled' : ''">
          <a class="page-link icon-seek-next text-center fa fa-lg fa-angle-right" aria-label="Next"
             (click)="changeToNextPage($event)">
          </a>
        </li>
        <li class="page-item" [ngClass]="isRightPaginationDisabled ? 'disabled' : ''">
          <a class="page-link icon-seek-end fa fa-lg fa-angle-double-right" aria-label="Last"
             (click)="changeToLastPage($event)">
          </a>
        </li>
      </ul>
    </nav>
  </div>
  <span class="slick-pagination-settings">
    <select id="items-per-page-label" (change)="changeItemPerPage($event)">
      <option value="{{pageSize}}" [selected]="pageSize === itemsPerPage" *ngFor="let pageSize of availablePageSizes">{{pageSize}}</option>
    </select>
    <span>{{textItemsPerPage}}</span>,
    <span class="slick-pagination-count">
      <span *ngIf="totalItems">
        <span class="page-info-from-to">
          <span data-test="item-from">{{dataFrom}}</span>-<span data-test="item-to">{{dataTo}}</span>
          {{textOf}}
        </span>
      </span>
      <span class="page-info-total-items">
        <span data-test="total-items">{{totalItems}}</span> {{textItems}}
      </span>
    </span>
  </span>
</div>
