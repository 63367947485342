import { Actions } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserSessionActionTypes } from '@core/stores/user-session/actions/user-session.actions';

@Injectable({
  providedIn: 'root'
})
export class UserSessionEffects {
  public _actions$: any;

  constructor(private actions$: Actions) {
    let subj = new BehaviorSubject(actions$);
    this._actions$ = subj.asObservable();
    if (this._actions$) {
      this._actions$.subscribe(action => {
        if (action.type == UserSessionActionTypes.Authenticate) {
          subj.next(action);
        }
      });
    }
    //  this.actions.subscribe(action => {

    // });
  }

  /*
    authenticate$ = createEffect(() => this._actions$.pipe(
              ofType(UserSessionActionTypes.Authenticate),
              map(() => {
                   console.log('A side effect for authentication was called');
              })
         )
    );
*/
}
