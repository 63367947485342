import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '@core/services/base.service';
import { constants } from '@core/constants';
import { EventsService } from '@modules/events/services/events.service';

@Injectable()
export class AssetService extends BaseService {
  private serviceApiUrl = constants.api.asset;

  constructor(
    protected eventsService: EventsService,
    protected http: HttpClient
  ) {
    super(eventsService, http);
  }

  getAllAssets() {
    return this.getAll(this.serviceApiUrl.url);
  }

  getAsset(id: number) {
    return this.getOne(this.serviceApiUrl.url, id);
  }

  getAssets(filters) {
    return this.getMany(this.serviceApiUrl.url, filters);
  }

  saveAsset(asset) {
    return this.saveOne(this.serviceApiUrl.url, asset);
  }

  deleteAsset(id: number) {
    return this.deleteOne(this.serviceApiUrl.url, id);
  }

  // Service with custom transform
  getAllAssetTypesAsOptions() {
    return this.getAllAsOptions(this.serviceApiUrl.type_url, {
      name: 'name',
      id: 'id'
    });
  }

  getAllAssetCustomers() {
    return this.getAll(this.serviceApiUrl.customer.url);
  }

  getAssetCustomer(id: number) {
    return this.getOne(this.serviceApiUrl.customer.url, id);
  }

  getAssetCustomers(filters) {
    return this.getMany(this.serviceApiUrl.customer.url, filters);
  }

  saveAssetCustomer(assetCustomer) {
    return this.saveOne(this.serviceApiUrl.customer.url, assetCustomer);
  }

  deleteAssetCustomer(id: number) {
    return this.deleteOne(this.serviceApiUrl.customer.url, id);
  }

  // Service with custom transform
  getAllAssetCustomersAsOptions() {
    return this.getAllAsOptions(this.serviceApiUrl.customer.url, {
      name: 'name',
      id: 'id'
    });
  }

  addAssetCustomerAssetCustomerContact(payload) {
    return this.saveOne(
      this.serviceApiUrl.customer.asset_customer_contact.add_api_url,
      payload
    );
  }

  deleteAssetCustomerAssetCustomerContact(payload) {
    return this.saveOne(
      this.serviceApiUrl.customer.asset_customer_contact.remove_api_url,
      payload
    );
  }

  addAssetDataPoint(payload) {
    return this.saveOne(this.serviceApiUrl.data_point.add_api_url, payload);
  }

  deleteAssetDataPoint(payload) {
    return this.saveOne(this.serviceApiUrl.data_point.remove_api_url, payload);
  }

  addAssetTriggerPoint(payload) {
    return this.saveOne(this.serviceApiUrl.trigger_point.add_api_url, payload);
  }

  deleteAssetTriggerPoint(payload) {
    return this.saveOne(
      this.serviceApiUrl.trigger_point.remove_api_url,
      payload
    );
  }

  getAllAssetsAsMapItems() {
    return this.getAllAsOptions(this.serviceApiUrl.url, {
      name: 'name',
      id: 'id',
      lat: 'lat',
      lon: 'lon',
      active: 'active'
    });
  }
}
