import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { BaseComponent } from '@shared/components/base/base.component';
import { EventsService } from '@modules/events/services/events.service';
import { EventsState } from '@modules/events/states/events.state';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { UserSessionState } from '@core/stores/user-session/states/user-session.states';
import { Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Router } from '@angular/router';
import { UserSessionService } from '@core/services/user-session/user-session.service';

@Component({
  providers: [EventsService, UserSessionService],
  selector: 'app-item-delete',
  templateUrl: './item-delete.component.html',
  styleUrls: ['./item-delete.component.scss']
})
export class ItemDeleteComponent extends BaseComponent
  implements OnInit, OnDestroy {
  @ViewChild('deleteItemModal') deleteItemModal: ElementRef;
  protected eventSubs: Subscription;
  public updPayload: any;
  public payload: any;
  public itemNames = [];
  public deleteModalTitle: string;
  public openDeleteModal: boolean = false;
  public additionalInfo;
  public action: string;
  public id: string;
  private deleteEvent;
  private doButton = 'Delete';

  constructor(
    protected idle: Idle,
    protected keepalive: Keepalive,
    protected eventsService: EventsService,
    protected sessionStore: Store<{ uss: UserSessionState }>,
    protected eventStore: Store<{ nf: EventsState }>,
    protected router: Router,
    protected userSessionService: UserSessionService
  ) {
    super(idle, keepalive, eventsService, sessionStore, router);
  }

  ngOnInit(): void {
    let events = [
      'openDeleteChartItemModal',
      'openFolderDeleteModal',
      'openItemDeleteModal',
      'openAssetDeleteModal',
      'openTriggerPointDeleteModal',
      'openTriggerPointProfileDeleteModal',
      'openAlarmDeleteModal',
      'openAlarmContactDeleteModal',
      'openEventContactDeleteModal',
      'openDeleteDashboardModal',
      'openDashboardDeleteModal',
      'openDataViewDeleteModal',
      'openDataPointDeleteModal',
      'openCustomPeriodDeleteModal',
      'openAppliedThingDeleteModal',
      'openThingsDeleteModal',
      'openEventTypeDeleteModal',
      'openUserRoleDeleteModal',
      'openCompanyDeleteModal',
      'openScheduledEventDeleteModal',
      'openAssetCustomerDeleteModal',
      'openTicketDeleteModal',
      'openAlarmHistoryDeleteModal',
      'openContactReportDeleteModal',
      'openReportDeleteModal',
      'openDataCommanderLiteModal',
      'openUserDeleteModal',
      'openUserRoleDeleteModal',
      'openEventDeleteModal',
      'openDeleteAppliedDataPointModal',
      'openLocationDeleteModal'
    ];

    let actionMap = new Map();
    actionMap.set('openDeleteChartItemModal', {
      action: 'deleteChartItem',
      id: 'deleteChartItem'
    });
    actionMap.set('openFolderDeleteModal', {
      action: 'delete_folders',
      id: 'deleteFolders'
    });
    actionMap.set('openItemDeleteModal', {
      action: 'delete_items',
      id: 'deleteItems'
    });
    actionMap.set('openAssetDeleteModal', {
      action: 'delete_assets',
      id: 'deleteAssets'
    });
    actionMap.set('openLocationDeleteModal', {
      action: 'delete_locations',
      id: 'deleteLocations'
    });
    actionMap.set('openAlarmDeleteModal', {
      action: 'delete_alarms',
      id: 'deleteAlarms'
    });
    actionMap.set('openTriggerPointDeleteModal', {
      action: 'delete_trigger_points',
      id: 'deleteTriggerPoints'
    });
    actionMap.set('openTriggerPointProfileDeleteModal', {
      action: 'delete_trigger_point_profiles',
      id: 'deleteTriggerPointProfiles'
    });
    actionMap.set('openAlarmContactDeleteModal', {
      action: 'delete_alarm_contacts',
      id: 'deleteAlarmContacts'
    });
    actionMap.set('openEventContactDeleteModal', {
      action: 'delete_event_contacts',
      id: 'deleteEventContacts'
    });
    actionMap.set('openDashboardDeleteModal', {
      action: 'deleteDashboard',
      id: 'deleteDashboard'
    });
    actionMap.set('openDataPointDeleteModal', {
      action: 'delete_data_points',
      id: 'deleteDataPoints'
    });
    actionMap.set('openDataViewDeleteModal', {
      action: 'delete_data_views',
      id: 'deleteDataViews'
    });
    actionMap.set('openCustomPeriodDeleteModal', {
      action: 'delete_custom_periods',
      id: 'deleteCustomPeriods'
    });
    actionMap.set('openAppliedThingDeleteModal', {
      action: 'delete_applied_thing',
      id: 'deleteAppliedThing'
    });
    actionMap.set('openThingsDeleteModal', {
      action: 'delete_things',
      id: 'deleteThings'
    });
    actionMap.set('openEventTypeDeleteModal', {
      action: 'delete_event_types',
      id: 'deleteEventTypes'
    });
    actionMap.set('openCompanyDeleteModal', {
      action: 'delete_companies',
      id: 'deleteCompanies'
    });
    actionMap.set('openUserRoleDeleteModal', {
      action: 'delete_user_roles',
      id: 'deleteUserRoles'
    });
    actionMap.set('openScheduledEventDeleteModal', {
      action: 'delete_scheduled_events',
      id: 'deleteScheduledEvents'
    });
    actionMap.set('openAssetCustomerDeleteModal', {
      action: 'delete_asset_customers',
      id: 'deleteAssetCustomers'
    });
    actionMap.set('openTicketDeleteModal', {
      action: 'delete_tickets',
      id: 'deleteTickets'
    });
    actionMap.set('openAlarmHistoryDeleteModal', {
      action: 'delete_alarm_histories',
      id: 'deleteAlarmHistories'
    });
    actionMap.set('openContactReportDeleteModal', {
      action: 'deleteReportContacts',
      id: 'deleteReportContacts'
    });
    actionMap.set('openReportDeleteModal', {
      action: 'delete_reports',
      id: 'deleteReports'
    });
    actionMap.set('openDeleteDashboardModal', {
      action: 'delete_dashboard',
      id: 'deleteDashboard'
    });
    actionMap.set('openUserDeleteModal', {
      action: 'delete_users',
      id: 'deleteUsers'
    });
    actionMap.set('openUserRoleDeleteModal', {
      action: 'delete_user_roles',
      id: 'deleteUserRoles'
    });
    actionMap.set('openEventDeleteModal', {
      action: 'delete_events',
      id: 'deleteEvents'
    });
    actionMap.set('openDeleteAppliedDataPointModal', {
      action: 'delete_applied_data_point',
      id: 'deleteAppliedDataPoint'
    });

    this.eventSubs = this.eventStore.select('nf').subscribe(payload => {
      if (payload.items.id === 'openDataCommanderLiteModal') {
      } else if (events.indexOf(payload.items.id) !== -1) {
        this.updPayload = payload.items.payload;
        if (actionMap.get(payload.items.id)) {
          this.action = actionMap.get(payload.items.id).action;
          this.id = actionMap.get(payload.items.id).id;
        }
        this.payload = payload.items.payload;
        this.openDeleteModal = true;
        this.itemNames = payload.items.payload.itemNames;
        this.deleteModalTitle = payload.items.payload.deleteModalTitle;
        this.deleteEvent = payload.items.payload.event;

        if ('doButton' in payload.items.payload) {
          this.doButton = payload.items.payload.doButton;
        } else {
          this.doButton = 'Delete';
        }

        if ('additionalInfo' in payload.items.payload) {
          this.additionalInfo = payload.additionalInfo;
        } else {
          this.additionalInfo = null;
        }
      }
    });
  }

  ngOnDestroy() {
    this.openDeleteModal = false;
  }

  close() {
    this.openDeleteModal = false;
    let elem = document.getElementById('deleteModal');
    let elem1 = document.getElementById('deleteModalBackdrop');
    elem.style.display = 'none';
    elem1.style.display = 'none';
  }

  deleteItems() {
    this.openDeleteModal = false;
    this.eventsService.publish(this.id, this.action, {
      module: 'data-analysis',
      action: this.action,
      event: 'confirmed',
      updPayload: this.updPayload
    });
  }
}
