import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserProfileService } from '@modules/users/services/user-profile.service';
import { BaseComponent } from '@shared/components/base/base.component';
import { EventsService } from '@modules/events/services/events.service';
import { TriggerPointService } from '@modules/eventmanagement/services/trigger-point.service';
import { ITriggerPointProfile } from '@modules/eventmanagement/models/trigger-point-profile.model';
import { EventManagementService } from '@modules/eventmanagement/services/event-management.service';
import { ITicket } from '@modules/ticketing/models/ticket.model';
import { UserSessionState } from '@core/stores/user-session/states/user-session.states';
import { Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

@Component({
  providers: [EventsService, TriggerPointService, EventsService],
  selector: 'app-trigger-point-profile-details',
  templateUrl: './trigger-point-profile-details.component.html',
  styleUrls: ['./trigger-point-profile-details.component.scss']
})
export class TriggerPointProfileDetailsComponent extends BaseComponent
  implements OnInit, OnDestroy, AfterViewInit {
  header = 'Trigger Point Profile Detail - Edit';
  private routeSub: Subscription;
  private item_id: number = 0;
  private baseRoute;
  private returnUrl = '/event-management/profiles/list';
  private newItemUrl = '/event-management/profiles/';
  private baseUrl = '/admin/event-management/profiles';
  public item: ITriggerPointProfile;
  public name: string;

  @ViewChild('app-trigger-point-profile-action-menu') actionMenu;

  public itemForm = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(50)
    ])
  });
  private errorMessage: string;
  private successToast = {
    title: 'Success!',
    text: 'Trigger Point Profile updated successfully!',
    type: 'success'
  };
  private errorToast = {
    title: 'Error!',
    text: 'Failed to update Trigger Point Profile!',
    type: 'error'
  };
  constructor(
    protected idle: Idle,
    protected keepalive: Keepalive,
    protected sessionStore: Store<{ uss: UserSessionState }>,
    private eventManagementService: EventManagementService,
    private triggerPointService: TriggerPointService,
    protected eventsService: EventsService,
    protected userProfileService: UserProfileService,
    protected router: Router,
    protected route: ActivatedRoute
  ) {
    super(idle, keepalive, eventsService, sessionStore, router);
  }
  ngOnInit() {
    this.routeSub = this.route.params.subscribe(params => {
      this.item_id = params['id'];
      if (typeof this.item_id === 'undefined' || this.item_id === null) {
        this.item_id = 0;
      }

      if (this.item_id < 1) {
        this.item = this.getBlankItem();
        this.header = 'Trigger Point Profile - New';
      } else {
        this.triggerPointService
          .getTriggerPointProfile(this.item_id)
          .subscribe(item => {
            this.item = item['item'];
            if (typeof this.item !== 'undefined' && this.item !== null) {
              if (typeof this.item.trigger_point_actions === 'undefined') {
                this.item.trigger_point_actions = [];
              }
              this.header = 'Trigger Point Profile Details - Edit';
            } else {
              this.item = this.getBlankItem();
              this.header = 'Trigger Point Profile - New';
            }
            this.itemForm.controls['name'].setValue(this.item.name);
            this.itemForm.patchValue(this.item);
          });
      }
    });
  }

  ngOnDestroy(): void {
    if (typeof this.routeSub !== 'undefined' && this.routeSub !== null) {
      this.routeSub.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    this.saveRoute();
  }

  toggleUserMenu() {}

  update() {
    const updateItem = Object.assign({}, this.item, this.itemForm.value);
    this.triggerPointService.saveTriggerPointProfile(updateItem).subscribe(
      item => this.onItemUpdated(item),
      error => (this.errorMessage = <any>error)
    );
  }

  onItemUpdated(data: any): void {
    if (data.is_error) {
      this.errorToast.text = data.message;
      this.eventsService.showToast(this.errorToast);
    } else {
      this.eventsService.showToast(this.successToast);
      if (this.item.id === 0) {
        this.header = 'Trigger Point Profile Detail - Edit';
        this.item = data.item;
        this.router.navigate([`${this.baseUrl}/${data.item.id}`]).then(r => {});
      } else {
        this.router.navigate([`${this.baseUrl}`]);
      }
    }
  }

  updateItem(item: ITicket) {
    this.item = item;
  }

  getBlankItem() {
    return {
      id: 0,
      name: '',
      settings: [],
      trigger_point_actions: [
        { id: 1, name: 'SMS' },
        { id: 2, name: 'email' }
      ],
      trigger_point_action_names: 'SMS'
    };
  }
}
