export enum DelimiterType {
  colon = ':',
  comma = ',',
  equalSign = '=',
  pipe = '|',
  semicolon = ';',
  space = ' ',
  tab = '\t',

  doubleColon = '::',
  doublePipe = '||',
  doubleSemicolon = ';;',
}

