export interface UserSessionState {
  username?: string;
  session?: any;
  alive?: boolean;
  verifying?: boolean;
  authenticated?: boolean;
  payload?: any;
  uuid?: string;
  timeout?: number;
  last_route?: string;
}

export const initialUserSessionState: UserSessionState = {
  username: '',
  session: {},
  verifying: false,
  authenticated: false,
  alive: true,
  uuid: '',
  timeout: 300,
  payload: {},
  last_route: ''
};
