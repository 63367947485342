import { NgModule } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TicketService } from '@modules/ticketing/services/ticket.service';
import { CommonModule } from '@angular/common';
import { ClarityModule } from '@clr/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserProfileService } from '@modules/users/services/user-profile.service';
import { LocalService } from '../../store/services/local.service';
import { TicketListComponent } from '@modules/ticketing/ticket-list/ticket-list.component';
import { TicketDetailsComponent } from '@modules/ticketing/ticket-details/ticket-details.component';
import { TicketUserMenuComponent } from '@modules/ticketing/ticket-user-menu/ticket-user-menu.component';
import { TicketingRoutingModule } from './ticketing-routing.module';

@NgModule({
  declarations: [
    TicketListComponent,
    TicketDetailsComponent,
    TicketUserMenuComponent
  ],
  imports: [
    CommonModule,
    ClarityModule,
    FormsModule,
    ReactiveFormsModule,
    TicketingRoutingModule
  ],
  providers: [LocalService, ToastrService, TicketService, UserProfileService],
  exports: []
})
export class TicketingModule {}
