import { AbstractControl } from '@angular/forms';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';

function handleError(error) {
  return throwError(error);
}

export class nameCheckValidator {
  static createValidator(nameCheckService, model, originalName) {
    return (control: AbstractControl) => {
      return nameCheckService.checkNameNotTaken(control.value, model).pipe(
        map(res => {
          if (originalName === control.value) {
            return null;
          } else {
            console.log(`Name Exists => ${res['exists']}`);
            return res['exists'] ? { nameExists: true } : null;
          }
        }),
        catchError(error => handleError(error))
      );
    };
  }
}
