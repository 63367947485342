<app-user-preferences></app-user-preferences>
<div class="header-actions">
    <clr-dropdown>
        <button clrDropdownTrigger>
            <span class="user-profile-text">{{ full_name }}</span>
            <clr-icon *ngIf="avatarImage; else elseBlock" id="avatar" [style]="avatarImage"></clr-icon>
            <ng-template #elseBlock>
                <clr-icon shape="user" size="24"></clr-icon>
            </ng-template>
            <clr-icon shape="caret down"></clr-icon>
        </button>
        <clr-dropdown-menu clrPosition="bottom-right" *clrIfOpen class="adjust-user-menu">
            <button (click)="showUserPreferences()" clrDropdownItem>Preferences</button>
            <button (click)="logout()" clrDropdownItem>Log out</button>
        </clr-dropdown-menu>
    </clr-dropdown>
</div>
