import { Router } from '@angular/router';
import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  Input
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { EventsState } from '@modules/events/states/events.state';
import { EventsService } from '@modules/events/services/events.service';
import { constants } from '@core/constants';
import { DataPointService } from '@core/services/data-point/data-point.service';
import { BaseComponent } from '@shared/components/base/base.component';
import { UserSessionState } from '@core/stores/user-session/states/user-session.states';
import { Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { deepCopy } from '@shared/utils/storage/encrypted_storage';
import { IConfig, IConfigs } from '@shared/models/config.model';

@Component({
  selector: 'app-grid-chart-config',
  templateUrl: './grid-chart-config.component.html',
  styleUrls: ['./grid-chart-config.component.scss']
})
export class GridChartConfigComponent extends BaseComponent
  implements OnInit, OnDestroy, AfterViewInit {
  @Input('item') item: any;
  public detailState: boolean = false;
  public selected: any[];
  public items: any[] = [];
  public loading: boolean = false;
  public itemCount: number = 0;
  public filters: any;
  public baseUrl = '/admin/data-analysis/data-points';
  public perPage: number = 10;
  public currentConfig: IConfigs;

  constructor(
    protected idle: Idle,
    protected keepalive: Keepalive,
    protected sessionStore: Store<{ uss: UserSessionState }>,
    protected router: Router,
    protected eventsService: EventsService,
    protected eventStore: Store<{ nf: EventsState }>,
    protected dataPointService: DataPointService
  ) {
    super(idle, keepalive, eventsService, sessionStore, router);
  }

  refresh(filters) {
    let start: number = filters['page']['from'];
    let end: number = filters['page']['to'];
    let items = this.item.data_points; //.slice(start, end);
    if (start > 0) {
      this.itemCount = this.item.data_points.length;
      this.items = items;
    }
  }

  ngOnInit(): void {
    this.items = this.item.data_points.slice(0, this.perPage);
    this.itemCount = this.item.data_points.length;
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {}

  toggleItemActive(e: any) {}

  onDetailOpen(e: any) {
    this.detailState = !this.detailState;
  }

  currDetail(): void {}
}
