export interface ITicketNote {
  id?: number;
  ticket_id?: number;
  created?: string;
  user_id?: number;
  description?: string;
  subject?: string;
  user?: string;
  user_email?: string;
  sent_users?: string;
  sent_at?: string;
  sent_user_ids?: any[];
}

export class TicketNote implements ITicketNote {
  constructor(id: number) {}
}

export const TicketNoteDefault = {
  item: {
    id: 0
  }
};
