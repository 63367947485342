import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ElementRef,
  ViewChild,
  Renderer2
} from '@angular/core';
import { FieldService } from '@core/services/field/field.service';
import { BaseComponent } from '@shared/components/base/base.component';
import { UserProfileService } from '@modules/users/services/user-profile.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IAssetCustomer } from '@modules/eventmanagement/models/asset-customer.model';
import { AssetService } from '@modules/eventmanagement/services/asset.service';
import { EventManagementService } from '@modules/eventmanagement/services/event-management.service';
import { constants } from '@core/constants';
import { Store } from '@ngrx/store';
import { EventsService } from '@modules/events/services/events.service';
import { UserSessionState } from '@core/stores/user-session/states/user-session.states';
import { Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { get_item, set_item } from '@shared/utils/storage/encrypted_storage';

@Component({
  providers: [EventManagementService, AssetService, FieldService],
  selector: 'app-asset-customer-details',
  templateUrl: './asset-customer-details.component.html',
  styleUrls: ['./asset-customer-details.component.scss']
})
export class AssetCustomerDetailsComponent extends BaseComponent
  implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('stateDropdown', { static: true }) stateDropdown: ElementRef;
  header = 'Asset Customer Detail - Edit';
  public submit;
  public states;
  public state;
  public item: IAssetCustomer;
  private baseRoute;
  private baseUrl = '/admin/event-management/assets/asset-customers';
  private returnUrl = '/admin/event-management/assets/asset-customers/list';
  private newItemUrl = '/admin/event-management/assets/asset-customers/';
  public config = {
    displayFn: (item: any) => {
      return item.name;
    },
    search: true, //true/false for the search functionlity defaults to false,
    height: 'auto', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Select', // text to be displayed when no item is selected defaults to Select,
    moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search', // label thats displayed in search input,
    searchOnKey: 'name', // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    clearOnSelection: false, // clears search criteria when an option is selected if set to true, default is false
    inputDirection: 'ltr' // the direction of the search input can be rtl or ltr(default)
  };
  options = [
    { id: 1, name: ['option 1'] },
    { id: 2, name: ['option 2'] },
    { id: 1, name: ['option 3'] }
  ];

  public itemForm = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(50)
    ]),
    address: new FormControl('', [
      Validators.required,
      Validators.minLength(3)
    ]),
    address_2: new FormControl(''),
    city: new FormControl('', [Validators.required, Validators.minLength(3)]),
    state: new FormControl(''),
    postal_code: new FormControl('', [
      Validators.required,
      Validators.minLength(3)
    ])
  });

  private errorMessage: string;

  private successToast = {
    title: 'Success!',
    text: 'Asset Customer updated successfully!',
    type: 'success'
  };
  private errorToast = {
    title: 'Error!',
    text: 'Failed to update Asset Customer!',
    type: 'error'
  };

  constructor(
    protected idle: Idle,
    protected keepalive: Keepalive,
    protected sessionStore: Store<{ uss: UserSessionState }>,
    protected route: ActivatedRoute,
    protected assetService: AssetService,
    protected eventsService: EventsService,
    protected eventManagementService: EventManagementService,
    protected userProfileService: UserProfileService,
    protected element: ElementRef,
    protected router: Router,
    private renderer: Renderer2
  ) {
    super(idle, keepalive, eventsService, sessionStore, router);
    this.fixDropdownButton();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      let id = params['id'];
      this.states = constants.states;

      if (id < 1) {
        this.submit = 'Save';
        this.header = 'Asset Customer - New';
        this.item = this.getBlankItem();
        this.item['id'] = 0;
        this.itemForm.patchValue(this.item);
      } else {
        this.eventManagementService.getAssetCustomer(id).subscribe(data => {
          this.item = data['item'];
          this.state = this.item.state;
          this.header = 'Asset Customer - Edit';
          this.submit = 'Update';
          this.itemForm.patchValue(this.item);
        });
      }
      this.baseRoute = '/admin/event-management/assets/asset-customers';
    });

    this.fixDropdownButton();
  }

  ngAfterViewInit() {
    let elements = document.querySelectorAll<HTMLElement>(
      'ngx-dropdown-button'
    )!;
    this.fixDropdownButton();
    set_item('backto', this.router.url);
    this.saveRoute();
  }

  ngOnDesroy() {}

  update() {
    var updateItem = Object.assign({}, this.item, this.itemForm.value);
    updateItem['state'] = updateItem['state']['name'];
    this.state = updateItem['state'];
    this.item['state'] = updateItem['state'];
    this.assetService.saveAssetCustomer(updateItem).subscribe(
      item => this.onItemUpdated(item),
      error => (this.errorMessage = <any>error)
    );
  }

  onItemUpdated(data: any): void {
    if (data.is_error) {
      this.errorToast.text = data.message;
      this.eventsService.showToast(this.errorToast);
    } else {
      this.eventsService.showToast(this.successToast);
      if (this.item.id === 0) {
        this.header = 'Asset Customer Detail - Edit';
        this.item = data.item;
        this.router.navigate([`${this.baseUrl}/${data.item.id}`]);
      } else {
        this.router.navigate([this.baseUrl]);
      }
    }
  }
  getBlankItem() {
    return {
      id: 0,
      name: '',
      address: '',
      address_2: '',
      city: '',
      state: '',
      postal_code: ''
    };
  }

  onChangeState(e) {}

  fixDropdownButton() {
    let elements = document.querySelectorAll<HTMLElement>(
      '.ngx-dropdown-button'
    )!;
    if (elements && elements[0]) {
      elements[0].style.background = 'transparent';
      elements[0].style.borderRadius = 'none';
      elements[0].style.borderTop = 'none';
      elements[0].style.borderLeft = 'none';
      elements[0].style.borderRight = 'none';
      elements[0].style.borderBottomRightRadius = '0';
      elements[0].style.borderBottomLeftRadius = '0';
      elements[0].style.marginTop = '1.4em';
      elements[0].style.marginLeft = '-0.7em';
      elements[0].style.width = '12em';
      elements[0].style.zIndex = '-1';
    }
  }

  beautify() {
    let elem = document.querySelectorAll<HTMLElement>('.nsdicon-angle-down');
    if (elem && elem[0]) {
      elem[0].style.color = '#b3b3b3';
    }

    let elements = document.querySelectorAll<HTMLElement>(
      '.ngx-dropdown-list-container'
    )!;
    if (elements && elements[0]) {
      elements[0].style.borderRadius = '0px';
      elements[0].style.boxShadow = 'none';
      elements[0].style.width = '12em';
      elements[0].style.background = '#FAFAFA';
      elements[0].style.border = 'none';
      elements[0].style.zIndex = '100';
      elements[0].style.marginLeft = '-0.7em';
      elements[0].style.marginTop = '-0.45em';
    }
  }

  notEmpty(e) {
    if (e === null) {
      return false;
    }

    if (typeof e === 'undefined') {
      return false;
    }

    return true;
  }

  reReadStates(): void {
    this.states = constants.states;
  }
}
