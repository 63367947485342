import { FormControl, FormGroup, Validators } from '@angular/forms';

export const CompanyForm = () => {
  return new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(50)
    ]),
    address: new FormControl(''),
    address_2: new FormControl(''),
    city: new FormControl(''),
    state: new FormControl(''),
    postal_code: new FormControl(''),
    active: new FormControl(),
    parent_id: new FormControl('', [Validators.required]),
    company_type_id: new FormControl('', [Validators.required]),
    timezone: new FormControl('', Validators.required)
  });
};
