import { finalize } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { EventsState } from '../states/events.state';
import { EventsActionTypes } from '../actions/events.actions';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { set_item, remove } from '@shared/utils/storage/encrypted_storage';
import { constants } from '@core/constants';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  private _item: BehaviorSubject<any> = new BehaviorSubject<any>('');
  public item: Observable<any> = this._item.asObservable();

  private _preferences_modal: BehaviorSubject<boolean> = new BehaviorSubject<
    any
  >(false);
  public preferences_modal: Observable<
    any
  > = this._preferences_modal.asObservable();

  channels: [];

  constructor(
    protected _store?: Store<{ nf: EventsState }>,
    protected http?: HttpClient,
    protected toastrService?: ToastrService,
    protected router?: Router
  ) {}

  publish(id, event, payload): void {
    /* Event mappings for different types of events to listen */
    var m = new Map();
    m.set('redirectToDefault', EventsActionTypes.RedirectToDefault);
    m.set('open_modal', EventsActionTypes.OpenModal);
    m.set('new_dataview', EventsActionTypes.NewDataView);
    m.set('new_dashboard', EventsActionTypes.NewDashboard);
    m.set('delete_folders', EventsActionTypes.DeleteFolders);
    m.set('delete_items', EventsActionTypes.DeleteItems);
    m.set('delete_assets', EventsActionTypes.DeleteAssets);
    m.set('delete_locations', EventsActionTypes.DeleteLocations);
    m.set('delete_alarms', EventsActionTypes.DeleteAlarms);
    m.set('delete_alarm_contacts', EventsActionTypes.DeleteAlarmContacts);
    m.set('delete_alarm_histories', EventsActionTypes.DeleteAlarmHistories);
    m.set('delete_event_contacts', EventsActionTypes.DeleteEventContacts);
    m.set('delete_dashboards', EventsActionTypes.DeleteDashboards);
    m.set('delete_data_points', EventsActionTypes.DeleteDataPoints);
    m.set('delete_data_views', EventsActionTypes.DeleteDataViews);
    m.set('delete_applied_thing', EventsActionTypes.DeleteAppliedThing);
    m.set('delete_asset_customers', EventsActionTypes.DeleteAssetCustomers);
    m.set('delete_custom_periods', EventsActionTypes.DeleteCustomPeriods);
    m.set('delete_things', EventsActionTypes.DeleteThings);
    m.set('delete_tickets', EventsActionTypes.DeleteTickets);
    m.set('delete_companies', EventsActionTypes.DeleteCompanies);
    m.set('delete_scheduled_events', EventsActionTypes.DeleteScheduledEvents);
    m.set('delete_user_roles', EventsActionTypes.DeleteUserRoles);
    m.set('delete_event_types', EventsActionTypes.DeleteEventTypes);
    m.set('delete_trigger_points', EventsActionTypes.DeleteTriggerPoints);
    m.set(
      'delete_trigger_point_profiles',
      EventsActionTypes.DeleteTriggerPointProfiles
    );
    m.set(
      'deleteTriggerPointProfiles',
      EventsActionTypes.DeleteTriggerPointProfiles
    );
    m.set('copy_data_points', EventsActionTypes.CopyDataPoints);
    m.set('copy_data_views', EventsActionTypes.CopyDataViews);
    m.set('copy_dashboards', EventsActionTypes.CopyDashboards);
    m.set(
      'open_applied_thing_delete_modal',
      EventsActionTypes.OpenAppliedThingDeleteModal
    );
    m.set('refresh_assets', EventsActionTypes.RefreshAssets);
    m.set('refresh_component', EventsActionTypes.RefreshComponent);
    m.set('refreshComponent', EventsActionTypes.RefreshComponent);
    m.set('refresh_trigger_points', EventsActionTypes.RefreshTriggerPoints);
    m.set(
      'refresh_trigger_point_profiles',
      EventsActionTypes.RefreshTriggerPointProfiles
    );
    m.set('openItemDeleteModal', EventsActionTypes.OpenItemDeleteModal);
    m.set('openTicketUserMenu', EventsActionTypes.OpenTicketUserMenu);
    m.set('openAssetDeleteModal', EventsActionTypes.OpenAssetDeleteModal);
    m.set(
      'openTriggerPointProfileDeleteModal',
      EventsActionTypes.OpenTriggerPointProfileDeleteModal
    );
    m.set(
      'openTriggerPointDeleteModal',
      EventsActionTypes.OpenTriggerPointDeleteModal
    );
    m.set('openCopyDashboardModal', EventsActionTypes.OpenCopyDashboardModal);
    m.set('openCopyDataViewModal', EventsActionTypes.OpenCopyDataViewModal);
    m.set('openCopyDataPointModal', EventsActionTypes.OpenCopyDataPointModal);
    m.set('openAlarmDeleteModal', EventsActionTypes.OpenAlarmDeleteModal);
    m.set('openDashboardMenu', EventsActionTypes.OpenDashboardMenu);
    m.set('openDataViewMenu', EventsActionTypes.OpenDataViewMenu);
    m.set('openDataPointMenu', EventsActionTypes.OpenDataPointMenu);
    m.set('openAlarmContactMenu', EventsActionTypes.OpenAlarmContactMenu);
    m.set(
      'openAlarmContactDeleteModal',
      EventsActionTypes.OpenAlarmContactDeleteModal
    );
    m.set('openFolderDeleteModal', EventsActionTypes.OpenFolderDeleteModal);
    m.set(
      'openEventContactDeleteModal',
      EventsActionTypes.OpenEventContactDeleteModal
    );
    m.set(
      'openEventTypeDeleteModal',
      EventsActionTypes.OpenEventTypeDeleteModal
    );
    m.set(
      'openDashboardDeleteModal',
      EventsActionTypes.OpenDashboardDeleteModal
    );
    m.set(
      'openDataPointDeleteModal',
      EventsActionTypes.OpenDataPointDeleteModal
    );
    m.set('openDataViewDeleteModal', EventsActionTypes.OpenDataViewDeleteModal);
    m.set('openCompanyDeleteModal', EventsActionTypes.OpenCompanyDeleteModal);
    m.set(
      'openScheduledEventDeleteModal',
      EventsActionTypes.OpenScheduledEventDeleteModal
    );
    m.set('openUserRoleDeleteModal', EventsActionTypes.OpenUserRoleDeleteModal);
    m.set('openThingsDeleteModal', EventsActionTypes.OpenThingDeleteModal);
    m.set('openTicketDeleteModal', EventsActionTypes.OpenTicketDeleteModal);
    m.set(
      'openCustomPeriodDeleteModal',
      EventsActionTypes.OpenCustomPeriodDeleteModal
    );
    m.set('openReportContactMenu', EventsActionTypes.OpenReportContactMenu);
    m.set('closeAlarmContactMenu', EventsActionTypes.CloseAlarmContactMenu);
    m.set(
      'closeUserPreferencesModal',
      EventsActionTypes.CloseUserPreferencesModal
    );
    m.set('refreshSideMenu', EventsActionTypes.RefreshSideMenu);
    m.set(
      'openUserPreferencesModal',
      EventsActionTypes.OpenUserPreferencesModal
    );
    m.set(
      'openAppliedThingDeleteModal',
      EventsActionTypes.OpenAppliedThingDeleteModal
    );
    m.set(
      'openAssetCustomerDeleteModal',
      EventsActionTypes.OpenAssetCustomerDeleteModal
    );
    m.set(
      'openAlarmHistoryDeleteModal',
      EventsActionTypes.OpenAlarmHistoryDeleteModal
    );
    m.set('openReportWizard', EventsActionTypes.OpenReportWizard);
    m.set('toggle_navigation', EventsActionTypes.ToggleNavigation);
    m.set('deleteAppliedThing', EventsActionTypes.DeleteAppliedThing);
    m.set('notifyDrawer', EventsActionTypes.NotifyDrawer);
    m.set('event', EventsActionTypes.PrimeOne);
    m.set('hideHeaderLabels', EventsActionTypes.HideHeaderLabels);
    m.set('dataViewPrimed', EventsActionTypes.DataViewPrimed);
    m.set('updateBreadCrumb', EventsActionTypes.DashboardNameBreadcrumb);
    m.set(
      'openDataCommanderLiteModal',
      EventsActionTypes.OpenDataCommanderLiteModal
    );
    m.set(
      'openContactReportDeleteModal',
      EventsActionTypes.OpenContactReportDeleteModal
    );
    m.set('openReportDeleteModal', EventsActionTypes.OpenReportDeleteModal);
    m.set('deleteReportContacts', EventsActionTypes.DeleteReportContacts);
    m.set('delete_reports', EventsActionTypes.DeleteReports);
    m.set('delete_users', EventsActionTypes.DeleteUsers);
    m.set('delete_events', EventsActionTypes.DeleteEvents);
    m.set('delete_user_roles', EventsActionTypes.DeleteUserRoles);
    m.set('openUserDeleteModal', EventsActionTypes.OpenUserDeleteModal);
    m.set('openEventDeleteModal', EventsActionTypes.OpenEventDeleteModal);
    m.set('openUserRoleDeleteModal', EventsActionTypes.OpenUserRoleDeleteModal);
    m.set('openDataUploadModal', EventsActionTypes.OpenDataUploadModal);
    m.set(
      'openDeleteAppliedDataPointModal',
      EventsActionTypes.OpenDeleteAppliedDataPointModal
    );
    m.set(
      'delete_applied_data_point',
      EventsActionTypes.DeleteAppliedDataPoint
    );
    m.set('postAxisValues', EventsActionTypes.PostAxisValues);
    m.set('postAxes', EventsActionTypes.PostAxes);
    m.set('redrawChart', EventsActionTypes.RedrawChart);
    m.set('redrawSingleChart', EventsActionTypes.RedrawSingleChart);
    m.set('gridsterInitialized', EventsActionTypes.GridsterInitialized);
    m.set('gridsterItemChanged', EventsActionTypes.GridsterItemChanged);
    m.set('gridsterItemResized', EventsActionTypes.GridsterItemResized);
    m.set('gridsterGridSizeChanged', EventsActionTypes.GridsterGridSizeChanged);
    m.set('gridsterItemInitialized', EventsActionTypes.GridsterItemInitialized);
    m.set('gridsterItemDragStopped', EventsActionTypes.GridsterItemDragStopped);
    m.set('gridsterItemDragStarted', EventsActionTypes.GridsterItemDragStarted);
    m.set(
      'gridsterItemResizeStopped',
      EventsActionTypes.GridsterItemResizeStopped
    );
    m.set(
      'gridsterItemResizeStarted',
      EventsActionTypes.GridsterItemResizeStarted
    );
    m.set(
      'readEventContactsMenuData',
      EventsActionTypes.ReadEventContactsMenuData
    );
    m.set('openEventContactMenu', EventsActionTypes.OpenEventContactMenu);
    m.set('closeEventContactMenu', EventsActionTypes.CloseEventContactMenu);
    m.set('configFetched', EventsActionTypes.ConfigFetched);
    m.set('updateFullName', EventsActionTypes.UpdateFullName);
    m.set('uncheckUsersMenu', EventsActionTypes.UncheckUsersMenu);
    m.set('refreshViewsGrid', EventsActionTypes.RefreshViewsGrid);
    m.set('filterValueChanged', EventsActionTypes.FilterValueChanged);
    m.set('openLocationDeleteModal', EventsActionTypes.OpenLocationDeleteModal);
    m.set('parentUserSelected', EventsActionTypes.ParentUserSelected);
    m.set('closeDataPointMenu', EventsActionTypes.CloseDataPointMenu);
    m.set('unsubscribeFromInterval', EventsActionTypes.UnsubscribeFromInterval);
    m.set('updateDataPointActive', EventsActionTypes.UpdateDataPointActive);
    m.set('allowAxisSelection', EventsActionTypes.AllowAxisSelection);
    m.set('readDashboards', EventsActionTypes.ReadDashboards);
    m.set('disallowAxisSelection', EventsActionTypes.DisallowAxisSelection);
    m.set('initializeEvent', EventsActionTypes.InitializeEvent);
    m.set('scheduledMenuOpened', EventsActionTypes.ScheduledMenuOpened);
    m.set('eventContactAdded', EventsActionTypes.EventContactAdded);
    m.set('use3DChart', EventsActionTypes.Use3DChart);
    m.set('use2DChart', EventsActionTypes.Use2DChart);
    m.set('sliceChart', EventsActionTypes.SliceChart);
    m.set('rotateChart', EventsActionTypes.RotateChart);
    m.set('repositionChart', EventsActionTypes.RepositionChart);
    m.set('resetChart', EventsActionTypes.ResetChart);
    m.set('zoomChart', EventsActionTypes.ZoomChart);
    m.set('transformTo2DChart', EventsActionTypes.TransformTo2DChart);
    m.set('transformTo3DChart', EventsActionTypes.TransformTo3DChart);
    m.set(
      'resizeCanvasOnResizeStop',
      EventsActionTypes.ResizeCanvasOnResizeStop
    );
    m.set('useGridChart', EventsActionTypes.UseGridChart);
    m.set('resizeCanvasOnDragStop', EventsActionTypes.ResizeCanvasOnDragStop);
    m.set('resizeChart', EventsActionTypes.ResizeChart);
    m.set('xScaleChart', EventsActionTypes.XScaleChart);
    m.set('sizeScaleChart', EventsActionTypes.SizeScaleChart);
    m.set('zScaleChart', EventsActionTypes.ZScaleChart);
    m.set('chartChanged', EventsActionTypes.ChartChanged);
    m.set('open3DMenu', EventsActionTypes.Open3DMenu);
    m.set('exportAsPNG', EventsActionTypes.ExportAsPNG);
    m.set('exportAsPDF', EventsActionTypes.ExportAsPDF);
    m.set('refresh3DPeriod', EventsActionTypes.Refresh3DPeriod);
    m.set('useDualChart', EventsActionTypes.UseDualChart);
    m.set('notifyDashboard', EventsActionTypes.NotifyDashboard);
    m.set('useAdvancedExpression', EventsActionTypes.UseAdvancedExpression);
    m.set('updateDashboardMenuItem', EventsActionTypes.UpdateDashboardMenuItem);
    m.set('initComputedActive', EventsActionTypes.InitComputedActive);
    m.set('readDataPointConfigs', EventsActionTypes.ReadDataPointConfigs);
    m.set('rereadColumnNames', EventsActionTypes.RereadColumnNames);
    m.set('postFormula', EventsActionTypes.PostFormula);
    m.set('populateFormula', EventsActionTypes.PopulateFormula);
    m.set('refreshFormula', EventsActionTypes.RefreshFormula);
    m.set('refreshGridChart', EventsActionTypes.RefreshGridChart);
    m.set('refreshGridChartHeader', EventsActionTypes.RefreshGridChartHeader);
    m.set('reloadPage', EventsActionTypes.ReloadPage);
    m.set('updateChartItems', EventsActionTypes.UpdateChartItems);
    m.set('stopGridSpinner', EventsActionTypes.StopGridSpinner);
    m.set('startGridSpinner', EventsActionTypes.StartGridSpinner);
    m.set('deleteChartItem', EventsActionTypes.DeleteChartItem);
    m.set('refreshGridChart', EventsActionTypes.RefreshGridChart);
    m.set('updateGridChart', EventsActionTypes.UpdateGridChart);
    m.set(
      'refreshGridChartFinished',
      EventsActionTypes.RefreshGridChartFinished
    );
    m.set(
      'openDeleteChartItemModal',
      EventsActionTypes.OpenDeleteChartItemModal
    );
    m.set(
      'doNotUseAdvancedExpression',
      EventsActionTypes.DoNotUseAdvancedExpression
    );
    m.set(
      'addLetterToAdvancedExpression',
      EventsActionTypes.AddLetterToAdvancedExpression
    );
    m.set('userChangedFromSuper', EventsActionTypes.UserChangedFromSuper);
    m.set('hideAlignOnThreeD', EventsActionTypes.HideAlignOnThreeD);
    m.set('useScatterChart', EventsActionTypes.UseScatterChart);
    m.set('delete_dashboard', EventsActionTypes.DeleteDashboard);
    m.set('deleteDashboard', EventsActionTypes.DeleteDashboard);
    m.set(
      'openDeleteDashboardModal',
      EventsActionTypes.OpenDeleteDashboardModal
    );
    m.set(
      'updateDataPointIsComputed',
      EventsActionTypes.UpdateDataPointIsComputed
    );
    m.set(
      'gridsterWindowResizeStopped',
      EventsActionTypes.GridsterWindowResizeStopped
    );
    if (id !== 'chartChanged') {
      console.log(`Dispatching ${m.get(event)} whth payload`);
    }
    if (this._store) {
      this._store.dispatch({
        type: m.get(event),
        payload: { id: id, event: event, payload: payload }
      });
    }
  }

  showToast(toast: any) {
    if (toast.type === 'success') {
      this.toastrService.success(toast.text, 'Success!');
    } else if (toast.type === 'warning') {
      this.toastrService.warning(toast.text, 'Warning!');
    } else if (toast.type === 'error') {
      this.toastrService.error(toast.text, 'Error!');
    } else if (toast.type === 'info') {
      if (typeof toast.title !== 'undefined' && toast.title !== null) {
        this.toastrService.info(toast.text, toast.title);
      } else {
        this.toastrService.info(toast.text, 'To your information!');
      }
    }
  }

  logout() {
    return this.http.get(constants.api.auth.logout).pipe(
      finalize(() => {
        set_item('backto', null);
        set_item('session', null);

        remove('session');
        remove('backto');
        remove('last_visited');
      })
    );
  }

  forceLogout() {
    if (
      !this.router.url.includes('login') &&
      !this.router.url.includes('welcome') &&
      !this.router.url.includes('recover') &&
      !this.router.url.includes('reset')
    ) {
      set_item('backto', this.router.url);
    }
    this.logout().subscribe(_ => {
      remove('session');
      this.router.navigate(['/login']);
    });
  }

  consume(channel: any) {}

  advance_modal(val) {
    this._preferences_modal.next(val);
  }

  consume_modal() {
    return this._preferences_modal;
  }
}
