import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ClrDatagridStateInterface } from '@clr/angular/data/datagrid/interfaces/state.interface';
import { DatagridStateInterface } from '@core/interfaces/datagridstate.interface';
import { get as _get } from 'lodash-es';
import { throwError, Observable } from 'rxjs';
import { IToastPayload } from '@shared/interfaces/toast-payload';
import { EventsService } from '@modules/events/services/events.service';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  constructor(
    protected eventsService: EventsService,
    protected http: HttpClient
  ) {}

  public setHttp(http: HttpClient) {
    this.http = http;
  }

  private static filterToQuery(filters: DatagridStateInterface) {
    const result = [];
    const fp = filters.page;
    const fs = filters.sort;
    const pf = _get(filters, 'filters', null);
    const fv = filters.filtervalues;

    if (filters.filters != null) {
      const fvs = [];
      let customFilters = [];
      for (const f of filters.filters) {
        if ('filterFn' in f) {
          fvs.push({
            filterFn: f['filterFn'],
            _rawValue: f['_rawValue']
          });
        } else {
          const key = Object.keys(f)[0];
          if (typeof key !== 'undefined' && key !== null) {
            customFilters.push([key, f[key]]);
          }
        }
      }
      if (fvs.length) {
        result.push(['filtervalues', encodeURIComponent(JSON.stringify(fvs))]);
      }
      customFilters.forEach(filter => {
        if (typeof filter !== 'undefined' && filter !== null) {
          result.push(filter);
        }
      });
    }

    if (typeof fp !== 'undefined' && fp !== null) {
      if (fp.from < 0) {
        fp.from = 0;
      }
      result.push(['page', fp.from / fp.size + 1]);
      result.push(['limit', filters.page.size]);
    }

    if (typeof fs !== 'undefined' && fp !== null) {
      result.push(['order', (fs.reverse ? '' : '-') + filters.sort.by]);
    }
    let filterQuery = result.map(param => param.join('=')).join('&');
    if (typeof pf !== 'undefined' && pf !== null) {
      // like maps to SQLAlchemy ilike filter.

      filterQuery +=
        '&' +
        pf
          .map(function(param) {
            if (
              typeof param.property !== 'undefined' &&
              typeof param.value !== 'undefined'
            ) {
              return param.property + '=like:' + param.value;
            }
          })
          .join('&');
    }
    if (
      filterQuery.toString().charAt(filterQuery.toString().length - 1) === '&'
    ) {
      filterQuery = filterQuery.slice(0, -1);
    }
    return filterQuery;
  }

  getMany(apiUrl: string, filters: ClrDatagridStateInterface) {
    return this.http.get<any>(
      `${apiUrl}?${BaseService.filterToQuery(filters)}`
    );
  }

  getAllAsOptions(apiUrl: string, kv: Object, nestedObjects?) {
    return this.getAll(apiUrl).pipe(
      map((data: any) => {
        return this._getAsOptions(data, kv, nestedObjects);
      }),
      catchError(error => this.handleError(error))
    );
  }

  getAll(apiUrl: string) {
    return this.http.get<any>(apiUrl);
  }

  getOne(apiUrl: string, id: number) {
    return this.http.get<any>(`${apiUrl}/${id}`);
  }

  deleteOne(apiUrl: string, id: number) {
    return this.http.delete<any>(`${apiUrl}/${id}`);
  }

  createOne(apiUrl: string, item: any) {
    delete item.id;
    return this.post(apiUrl, item);
  }

  updateOne(apiUrl: string, item: any) {
    if (item.id === 0) {
      delete item.id;
      return this.post(apiUrl, item);
    } else {
      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Max-Age': '86400',
        'Access-Control-Allow-Headers':
          'Access-Control-Allow-Headers, X-PINGOTHER, Origin, X-Requested-With, Content-Type, Accept, Authorization',
        'Access-Control-Allow-Methods': 'PUT, GET, POST, DELETE, OPTIONS'
      });
      let options = { headers: headers };
      return this.http.put<any>(`${apiUrl}/${item.id}`, item, options);
    }
  }

  saveOne(apiUrl: string, item: any) {
    return this.updateOne(apiUrl, item);
  }

  post(apiUrl: string, payload: object) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Max-Age': '86400',
      'Access-Control-Allow-Headers':
        'Access-Control-Allow-Headers, X-PINGOTHER, Origin, X-Requested-With, Content-Type, Accept, Authorization',
      'Access-Control-Allow-Methods': 'PUT, GET, POST, DELETE, OPTIONS'
    });
    let options = { headers: headers };

    return this.http.post<any>(apiUrl, payload, options);
  }

  get(apiUrl: string) {
    return this.http.get<any>(apiUrl);
  }

  getWithParams(apiUrl: string, params: string) {
    return this.http.get<any>(`${apiUrl}${params}`);
  }

  handleSuccess(data) {
    const toastPayload = {} as IToastPayload;
    toastPayload.title = 'Success!';
    toastPayload.text = data.message;
    toastPayload.type = 'success';
    //this.eventsService.showToast(toastPayload);
  }

  handleError(error) {
    console.log('error catched', error);
    const toastPayload = {} as IToastPayload;
    toastPayload.title = 'Error!';
    if (typeof error !== 'undefined' && error !== null) {
      if (typeof error.error !== 'undefined' && error.error !== null) {
        if (
          typeof error.error.message !== 'undefined' &&
          error.error.message !== null
        ) {
          toastPayload.text = error.error.message;
        } else {
          toastPayload.text = 'Failed calling service!';
        }
      }
      if (typeof error.message !== 'undefined' && error.message !== null) {
        toastPayload.text = error.message;
      }
    }
    toastPayload.type = 'error';
    //this.eventsService.showToast(toastPayload);
    if (error.status === 401) {
      setTimeout(() => {
        window.location.href = '/login';
      }, 2500);
    }
    return throwError(error);
  }

  private _getAsOptions(data, kv, nestedObjects) {
    const all = [];
    let subItems = [];
    if (nestedObjects) {
      for (const obj of nestedObjects) {
        subItems = [];
        for (const one of data.item_list[0][obj.objName]) {
          const subItemObj = {};
          subItemObj[obj.kv.name] = one[obj.kv.name];
          subItemObj[obj.kv.id] = one[obj.kv.id];
          // if you want to include keys other than name and id....
          const _keys = Object.keys(obj.kv);
          for (const key in _keys) {
            if (_keys[key] !== 'name' && _keys[key] !== 'id') {
              subItemObj[obj.kv[_keys[key]]] = one[obj.kv[_keys[key]]];
            }
          }
          subItems.push(subItemObj);
        }
        all[obj.objName] = subItems;
        subItems = [];
      }
    } else {
      for (const one of data.item_list) {
        const _keys = Object.keys(kv);
        const options = {};
        for (const key of _keys) {
          options[kv[key]] = one[key];
        }
        all.push(options);
      }
    }
    return all;
  }

  private _get(apiUrl, apiType, errorReturnUrl) {
    return this.http.get(apiUrl).pipe(
      map(data => {
        this._extractData(data as Response, apiType, errorReturnUrl);
        return data;
      }),
      catchError(error => this.handleError(error))
    );
  }

  private _base(call, apiType, errorReturnUrl?) {}

  // Support methods
  private _extractData(
    response: Response,
    callingApi,
    errorReturnUrl?: string
  ) {
    const body = response.json() as any;
    // console.log(`${this.constructor.name} ${callingApi}:${JSON.stringify(body)}`);
    if (body['is_error'] && body['is_error'] === true) {
      if (errorReturnUrl) {
        //  this.router.navigate([errorReturnUrl]);
      }
      if (body.message === 'Not authenticated.') {
        //    this.router.navigate(['/login']);
      }
      // TODO throw backend errors in the notification area for debug
      // TODO in ng6 error needs to include more than just message such as user_override etc...
      return Observable.throw(body.message);
    }
    return body || {};
  }

  private _handleError(error) {
    // console.error(error.toString());
    // session probably expired...
    if (error.status === 502 || error.status === 401) {
      //  this.router.navigate(['/login']);
    }
    return Observable.throw(error || 'Server error');
  }
}
