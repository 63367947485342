import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  Input,
  Output,
  HostListener,
  ViewChild,
  ElementRef
} from '@angular/core';
import {
  faPlus,
  faMinus,
  faSyncAlt,
  faCompass,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowDown
} from '@fortawesome/free-solid-svg-icons';
import { EventsService } from '@modules/events/services/events.service';
import { Store } from '@ngrx/store';
import { EventsState } from '@modules/events/states/events.state';

@Component({
  selector: 'app-threed-controls',
  templateUrl: './threed-controls.component.html',
  styleUrls: ['./threed-controls.component.scss']
})
export class ThreedControlsComponent
  implements OnInit, OnDestroy, AfterViewInit {
  public faMinusIcon: any;
  public faPlusIcon: any;
  public faAltIcon: any;
  public faLeftIcon: any;
  public faRightIcon: any;
  public faUpIcon: any;
  public faDownIcon: any;
  public faCompassIcon: any;
  public toggle2D: boolean = false;
  public twodtitle: string = '2D';
  public width?: number;
  public height?: number;
  public factor?: number;
  public empty_height_error: boolean = false;
  public invalid_height_error: boolean = false;
  public empty_width_error: boolean = false;
  public invalid_width_error: boolean = false;
  public empty_factor_error: boolean = false;
  public invalid_factor_error: boolean = false;
  public data_view_id: number = 0;

  @Input('item') item?: any;
  @ViewChild('zoomIn', { static: false }) zoomIn: ElementRef;
  @ViewChild('zoomOut', { static: false }) zoomOut: ElementRef;

  public viewportForm = new FormGroup({
    width: new FormControl('', []),
    height: new FormControl('', []),
    factor: new FormControl('', [])
  });

  @HostListener('document:click', ['$event'])
  handleClick(event) {
    let elem = document.getElementById('plus');
    let elemminus = document.getElementById('minus');
    if (typeof elem !== 'undefined' && elem !== null) {
      if (elem.contains(event.target)) {
        console.log(`Zooming in`);
      }
    }

    if (typeof elemminus !== 'undefined' && elemminus !== null) {
      if (elemminus.contains(event.target)) {
        console.log(`Zooming out`);
      }
    }
  }

  constructor(
    protected eventsService: EventsService,
    public eventStore: Store<{ nf: EventsState }>
  ) {
    this.faUpIcon = faArrowUp;
    this.faDownIcon = faArrowDown;
    this.faLeftIcon = faArrowLeft;
    this.faRightIcon = faArrowRight;
    this.faMinusIcon = faMinus;
    this.faPlusIcon = faPlus;
    this.faAltIcon = faSyncAlt;
    this.faCompassIcon = faCompass;
  }

  ngOnInit(): void {
    if (typeof this.item !== 'undefined' && this.item !== null) {
      this.data_view_id = this.item.data_view_id;
    }
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {}

  processAction(action: any): void {
    if (action === 'in') {
      this.eventsService.publish('zoomChart', 'zoomChart', {
        direction: 'in',
        factor: 1.1,
        data_view_id: this.data_view_id
      });
    }
    if (action === 'out') {
      this.eventsService.publish('zoomChart', 'zoomChart', {
        direction: 'out',
        factor: 0.9,
        data_view_id: this.data_view_id
      });
    }
    if (action === 'sync') {
      this.eventsService.publish('resetChart', 'resetChart', {
        data_view_id: this.data_view_id
      });
    }
    if (action === 'compass') {
      this.eventsService.publish('rotateChart', 'rotateChart', {
        data_view_id: this.data_view_id
      });
    }
    if (action === 'left') {
      this.eventsService.publish('repositionChart', 'repositionChart', {
        direction: 'left',
        ratio: 1.0,
        data_view_id: this.data_view_id
      });
    }
    if (action === 'right') {
      this.eventsService.publish('repositionChart', 'repositionChart', {
        direction: 'right',
        ratio: 1.0,
        data_view_id: this.data_view_id
      });
    }
    if (action === 'up') {
      this.eventsService.publish('repositionChart', 'repositionChart', {
        direction: 'up',
        ratio: 1.0,
        data_view_id: this.data_view_id
      });
    }
    if (action === 'down') {
      this.eventsService.publish('repositionChart', 'repositionChart', {
        direction: 'down',
        ratio: 1.0,
        data_view_id: this.data_view_id
      });
    }
  }

  scaleCoord(c: any): void {
    if (c === 'x') {
      this.eventsService.publish('xScaleSize', 'xScaleSize', {
        data_view_id: this.data_view_id
      });
    } else if (c === 'y') {
      this.eventsService.publish('sizeScaleChart', 'sizeScaleChart', {
        data_view_id: this.data_view_id
      });
    } else if (c === 'z') {
      this.eventsService.publish('zScaleSize', 'zScaleSize', {
        data_view_id: this.data_view_id
      });
    }
  }

  resizeChart(): void {
    if (this.validateSizes()) {
      this.eventsService.publish('resizeChart', 'resizeChart', {
        width: this.width,
        height: this.height,
        factor: this.factor,
        data_view_id: this.data_view_id
      });
    }
  }

  toggleDimensions(): void {
    this.toggle2D = !this.toggle2D;
    if (this.toggle2D) {
      this.twodtitle = '3D';

      this.eventsService.publish('transformTo2DChart', 'transformTo2DChart', {
        data_view_id: this.data_view_id
      });
    } else {
      this.twodtitle = '2D';

      this.eventsService.publish('transformTo3DChart', 'transformTo3DChart', {
        data_view_id: this.data_view_id
      });
    }
  }

  onMouseDown(t: any, event: any) {
    if (t === 'in') {
      let elem = document.getElementById('plus');
      elem.classList.add('pressed');
      elem.classList.remove('unpressed');
    } else if (t === 'out') {
      let elem = document.getElementById('minus');
      elem.classList.remove('unpressed');
      elem.classList.add('pressed');
    } else if (t === 'compass') {
      let elem = document.getElementById('compass');
      elem.classList.remove('unpressed');
      elem.classList.add('pressed');
    } else if (t === 'sync') {
      let elem = document.getElementById('sync');
      elem.classList.remove('unpressed');
      elem.classList.add('pressed');
    } else if (t === 'left') {
      let elem = document.getElementById('left');
      elem.classList.remove('unpressed');
      elem.classList.add('pressed');
    } else if (t === 'right') {
      let elem = document.getElementById('right');
      elem.classList.remove('unpressed');
      elem.classList.add('pressed');
    } else if (t === 'up') {
      let elem = document.getElementById('up');
      elem.classList.remove('unpressed');
      elem.classList.add('pressed');
    } else if (t === 'down') {
      let elem = document.getElementById('down');
      elem.classList.remove('unpressed');
      elem.classList.add('pressed');
    }
  }

  validateSizes(): boolean {
    let valid: boolean = true;
    if (typeof this.width === 'undefined' || this.width === null) {
      this.empty_width_error = true;
      valid = false;
    } else {
      this.empty_width_error = false;
    }

    if (typeof this.height === 'undefined' || this.height === null) {
      this.empty_height_error = true;
      valid = false;
    } else {
      this.empty_height_error = false;
    }

    if (typeof this.factor === 'undefined' || this.factor === null) {
    }

    if (typeof this.width !== 'undefined' && this.width !== null) {
      if (!this.isNumber(this.width)) {
        this.invalid_width_error = true;
        valid = false;
      } else {
        this.invalid_width_error = false;
      }
    }

    if (typeof this.height !== 'undefined' && this.height !== null) {
      if (!this.isNumber(this.height)) {
        this.invalid_height_error = true;
        valid = false;
      } else {
        this.invalid_height_error = false;
      }
    }

    return valid;
  }

  onMouseUp(t: any, event: any) {
    if (t === 'in') {
      let elem = document.getElementById('plus');
      elem.classList.remove('pressed');
      elem.classList.add('unpressed');
    } else if (t === 'out') {
      let elem = document.getElementById('minus');
      elem.classList.remove('pressed');
      elem.classList.add('unpressed');
    } else if (t === 'compass') {
      let elem = document.getElementById('compass');
      elem.classList.remove('pressed');
      elem.classList.add('unpressed');
    } else if (t === 'sync') {
      let elem = document.getElementById('sync');
      elem.classList.remove('pressed');
      elem.classList.add('unpressed');
    } else if (t === 'left') {
      let elem = document.getElementById('left');
      elem.classList.remove('pressed');
      elem.classList.add('unpressed');
    } else if (t === 'right') {
      let elem = document.getElementById('right');
      elem.classList.remove('pressed');
      elem.classList.add('unpressed');
    } else if (t === 'up') {
      let elem = document.getElementById('up');
      elem.classList.remove('pressed');
      elem.classList.add('unpressed');
    } else if (t === 'down') {
      let elem = document.getElementById('down');
      elem.classList.remove('pressed');
      elem.classList.add('unpressed');
    }
  }

  isNumber(num: any): boolean {
    const isNumeric = (num: any) => /^-?[0-9]+(?:\.[0-9]+)?$/.test(num + '');
    return isNumeric(num);
  }

  readId(id: number): string {
    let hash: any = Math.random()
      .toString(36)
      .slice(2, 7);
    return `${id}-${hash}`;
  }
}
