import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '@shared/components/base/base.component';
import { UserSessionService } from '@core/services/user-session/user-session.service';
import { EventsService } from '@modules/events/services/events.service';
import { Store } from '@ngrx/store';
import { UserSessionState } from '@core/stores/user-session/states/user-session.states';
import { Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Router } from '@angular/router';

@Component({
  providers: [UserSessionService, EventsService],
  selector: 'app-refresh',
  templateUrl: './refresh.component.html',
  styleUrls: ['./refresh.component.scss']
})
export class RefreshComponent extends BaseComponent
  implements OnInit, OnDestroy {
  public showNav;
  constructor(
    protected idle: Idle,
    protected keepalive: Keepalive,
    protected eventsService: EventsService,
    protected sessionStore: Store<{ uss: UserSessionState }>,
    protected router: Router
  ) {
    super(idle, keepalive, eventsService, sessionStore, router);
  }

  ngOnInit() {}

  refresh(): void {}

  ngOnDestroy(): void {}
}
