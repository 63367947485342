import { ActivatedRoute, Router } from '@angular/router';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ComponentRef,
  ElementRef,
  ViewChild,
  Renderer2
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder
} from '@angular/forms';
import {
  trigger,
  state,
  style,
  transition,
  animate
} from '@angular/animations';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { FieldService } from '@core/services/field/field.service';
import { ThingService } from '@core/services/thing/thing.service';
import { IAlarm } from '@modules/alarms/models/alarm.model';
import { AlarmService } from '@core/services/alarm/alarm.service';
import { CompanyService } from '@core/services/company/company.service';
import { UserProfileService } from '@modules/users/services/user-profile.service';
import { UserSessionService } from '@core/services/user-session/user-session.service';
import { BaseComponent } from '@shared/components/base/base.component';
import {
  TimeIntervalConfig,
  TimeIntervalConfigAmPm
} from '@shared/utils/date-picker-options';
import { EventsService } from '@modules/events/services/events.service';
import { UserSessionState } from '@core/stores/user-session/states/user-session.states';
import { Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Store } from '@ngrx/store';
import { SearchSelectComponent } from '@shared/components/searchable-select/searchable-select.component';
import { AlarmContactMenuComponent } from '@modules/alarms/alarm-contact-menu/alarm-contact-menu.component';
import { get_item, set_item } from '@shared/utils/storage/encrypted_storage';
import { empty } from '@shared/utils/check';

export interface MenuData {
  alarmId?: number;
  alarmName?: any;
  alarmContacts: any[];
  allAlarmContacts: [];
}

@Component({
  providers: [
    EventsService,
    FieldService,
    UserProfileService,
    UserSessionService
  ],
  selector: 'app-alarm-details',
  templateUrl: './alarm-details.component.html',
  styleUrls: ['./alarm-details.component.scss'],
  animations: [
    trigger('slideInOut', [
      state(
        'in',
        style({
          width: '67em',
          'pointer-events': 'all',
          overflow: 'hidden',
          display: 'flex'
        })
      ),
      state('out', style({ 'pointer-events': 'all' })),
      transition('in => out', animate('250ms ease-in-out')),
      transition('out => in', animate('250ms ease-in-out'))
    ])
  ]
})
export class AlarmDetailsComponent extends BaseComponent
  implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  @ViewChild('alarm_contact_menu', { static: false })
  public alarmContactMenu: ComponentRef<AlarmContactMenuComponent>; // {alarmId?:number, alarmName?:any, alarmContacts: any[]; allAlarmContacts: []};
  public alarmContactMenuData: MenuData;
  @ViewChild('thingsDropdown')
  thingsDropdown: ComponentRef<SearchSelectComponent>;
  @ViewChild('alarmTimeZonesDropdown')
  alarmTimeZonesDropdown: ComponentRef<SearchSelectComponent>;
  public notice_interval: any;
  public period: any;
  public initial_thing;
  public dataModel: any;
  public alarmFormState = 'out';
  public config = {
    displayFn: (item: any) => {
      return item.name;
    },
    search: true, //true/false for the search functionlity defaults to false,
    height: 'auto', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Select', // text to be displayed when no item is selected defaults to Select,
    moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search', // label thats displayed in search input,
    searchOnKey: 'name', // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    clearOnSelection: false, // clears search criteria when an option is selected if set to true, default is false
    inputDirection: 'ltr' // the direction of the search input can be rtl or ltr(default)
  };
  options = [
    { id: 1, name: ['option 1'] },
    { id: 2, name: ['option 2'] },
    { id: 1, name: ['option 3'] }
  ];
  header = 'Alarm Detail - Edit';
  name: string = '';
  loading = true;
  company_specific;
  private baseUrl = '/admin/alarms';
  private baseRoute;
  private errorReturnUrl = '/alarms/list';
  public returnUrl = '/alarms/list';
  private newItemUrl = '/alarms/';
  public timezone;
  item_id: number;
  public active_from = ['000', '000', '000', '000'];
  public is_accumulator: boolean = false;
  alarm_id: number;
  public low_discard_threshold;
  public high_discard_threshold;
  public low_threshold;
  public high_threshold;
  public thing_id: number;
  public alarm_type_id: number;
  companies: [];
  alarmTypes: [];
  public active: boolean;
  public include_null: boolean;
  alarmTemplates;
  public things = [];
  public initialized: boolean = false;
  fields;
  activeDaysMap = {
    0: 'active_monday',
    1: 'active_tuesday',
    2: 'active_wednesday',
    3: 'active_thursday',
    4: 'active_friday',
    5: 'active_saturday',
    6: 'active_sunday'
  };
  private className = 'AlarmDetailsComponent';
  public item: IAlarm;
  private _item: IAlarm;
  private patching = true;
  private allAlarmContacts: [];
  private alarmContacts: [];
  timeIntervalConfig = TimeIntervalConfig;
  timeIntervalConfigAmPm = TimeIntervalConfigAmPm;
  public time_zones = [];
  public thing;
  /** control for the selected tz */
  //    public timezoneCtrl: FormControl = new FormControl();

  /** control for the MatSelect filter keyword */
  //  public timezoneFilterCtrl: FormControl = new FormControl();

  /** list of banks filtered by search keyword */
  public filteredTimezones: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  public itemForm = new FormGroup({
    timezoneCtrl: new FormControl(),
    timezoneFilterCtrl: new FormControl(),
    name: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(50)
    ]),
    thing: new FormControl(''),
    thing_id: new FormControl(), //,  Validators.required),
    period: new FormControl(),
    active: new FormControl(),
    notice_interval: new FormControl(),
    active_from: new FormControl(),
    active_to: new FormControl(),
    timezone: new FormControl(), //'',  Validators.required),
    low_threshold: new FormControl(''), //, Validators.required),
    high_threshold: new FormControl(''), //Validators.required),
    alarm_type_id: new FormControl(''), //,  Validators.required),
    content: new FormControl(),
    low_discard_threshold: new FormControl(),
    high_discard_threshold: new FormControl(),
    include_null: new FormControl(),
    active_monday: new FormControl(),
    active_tuesday: new FormControl(),
    active_wednesday: new FormControl(),
    active_thursday: new FormControl(),
    active_friday: new FormControl(),
    active_saturday: new FormControl(),
    active_sunday: new FormControl(),
    is_accumulator: new FormControl()
  });
  private routeSubs: Subscription;
  private errorMessage: string;
  private successToast = {
    title: 'Success!',
    text: 'Alarm updated successfully!',
    type: 'success'
  };
  private errorToast = {
    title: 'Error!',
    text: 'Failed to update Alarm!',
    type: 'error'
  };
  constructor(
    protected idle: Idle,
    protected keepalive: Keepalive,
    protected eventsService: EventsService,
    protected sessionStore: Store<{ uss: UserSessionState }>,
    protected router: Router,
    private renderer: Renderer2,
    private _formBuilder: FormBuilder,
    private alarmService: AlarmService,
    private userProfileService: UserProfileService,
    protected companyService: CompanyService,
    protected fieldService: FieldService,
    protected thingService: ThingService,
    protected userSessionService: UserSessionService,
    protected route: ActivatedRoute
  ) {
    super(idle, keepalive, eventsService, sessionStore, router);
    this.alarmContactMenuData = {
      alarmId: 0,
      alarmName: 'new alarm',
      alarmContacts: [],
      allAlarmContacts: []
    };

    this.company_specific = userProfileService.settings['company_specific'];
    this.item_id = 0;
  }

  readThings() {
    this.thingService.getAllThingsAsOptions().subscribe(data => {
      data.forEach(thing => {
        this.things.push(thing);
      });
      this.initial_thing = this.things.filter(
        thing => thing.id === this.item.thing_id
      );
      this.readAlarmTypes();
    });
  }

  readFields() {
    this.fieldService.getFields().subscribe(data => {
      this.fields = data;
      this.time_zones = this.fields['time_zones'];
      this.readThings();
    });
  }

  readAlarmTypes() {
    this.alarmService.getAllAlarmTypesAsOptions().subscribe(data => {
      this.alarmTypes = data;
      this.readAlarmContacts();
    });
  }

  readAlarmContacts() {
    this.alarmService.getAllAlarmContactsAsOptions().subscribe(data => {
      this.allAlarmContacts = data;
      this.alarmContacts = this.item['alarm_contacts'];
      this.initialized = true;
      this.patchForm();
      this.alarmContactMenuData.allAlarmContacts = this.alarmContacts;
      this.updateAlarmContactMenu();
      /*
      this.eventsService.publish(
        'closeAlarmContactMenu',
        'closeAlarmContactMenu',
        { module: 'alarms', action: 'close',       alarmId: this.item.id, }
      );

*/
      this.eventsService.publish('notifyDrawer', 'notifyDrawer', {
        module: 'alarms',
        applied: this.alarmContacts,
        all: this.allAlarmContacts,
        alarmId: this.item.id,
        action: 'notifyDrawer'
      });
    });
  }

  readParams() {
    this.routeSubs = this.route.params.subscribe(params => {
      this.alarm_id = params['id'];
      if (this.alarm_id < 1) {
        this.item = this.getBlankAlarm() as IAlarm;
        this.alarmContactMenuData.alarmId = 0;
        this.header = 'Add Alarm';
        this.readFields();
      } else {
        this.alarmService.getAlarm(this.alarm_id).subscribe(d => {
          this.header = 'Alarm Detail - Edit';
          this.item = d['item'];
          this.name = d['name'];
          this.alarmContactMenuData.alarmId = this.item.id;
          this.readFields();
        });
      }
    });
  }

  onDataRetrieved(data: any, dataType: string): void {
    if (data.is_error) {
      console.log(`Error retrieving data ${data.message}`);
      this.router.navigate([`${this.baseRoute}${this.errorReturnUrl}`]);
    } else {
      switch (dataType) {
        case 'things': {
          this.things = data;
          break;
        }
        case 'alarmTypes': {
          this.alarmTypes = data;
          break;
        }
        case 'alarmTemplates': {
          this.alarmTemplates = data;
          break;
        }
      }
    }
  }
  subscribeEvents() {}

  ngOnInit() {
    this.reloadPage();
    this.readParams();
  }

  ngOnDestroy(): void {
    if (typeof this._onDestroy !== 'undefined' && this._onDestroy !== null) {
      this._onDestroy.next();
      this._onDestroy.complete();
    }
    if (typeof this.routeSubs !== 'undefined' && this.routeSubs !== null) {
      this.routeSubs.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    this.saveRoute();
  }

  patchForm() {
    console.log('patchForm');
    this._item = Object.assign({}, this.item);
    let _period = '00:00:00';
    if (this.item.period) {
      this.item.period = this.item.period.split('T')[1];
      this._item.period = this.item.period;
    } else {
      this.item.period = _period;
      this._item.period = _period;
    }
    let _notice_interval = '00:00:00';
    if (this.item.notice_interval) {
      this.item.notice_interval = this.item.notice_interval.split('T')[1];
      this._item.notice_interval = this.item.notice_interval;
    } else {
      this.item.notice_interval = _notice_interval;
      this._item.notice_interval = _notice_interval;
    }
    let _active_from = '00:00:00';
    if (this.item.active_from) {
      this.item.active_from = this.item.active_from.split('T')[1];
      this._item.active_from = this.item.active_from;
    } else {
      this.item.active_from = _active_from;
      this._item.active_from = _active_from;
    }
    let _active_to = '00:00:00';
    if (this.item.active_to) {
      this.item.active_to = this.item.active_to.split('T')[1];
      this._item.active_to = this.item.active_to;
    } else {
      this.item.active_to = _active_to;
      this._item.active_to = _active_to;
    }
    this.name = this.item.name;
    this.itemForm.controls['name'].setValue(this.item.name);
    this.itemForm.controls['timezone'].setValue(this.item.timezone);
    this.itemForm.patchValue(this.item);
    this.itemForm.controls['is_accumulator'].setValue(this.item.is_accumulator);
    this.itemForm.controls['include_null'].setValue(this.item.include_null);
    if (this.initial_thing) {
      if (this.initial_thing.length > 0) {
        this.itemForm.controls['thing'].setValue(this.initial_thing[0].name);
      }
    }
    this.thing_id = parseInt(this.item.thing_id);
    this.itemForm.controls['thing_id'].setValue(this.item.thing_id);
    this.itemForm.controls['notice_interval'].setValue(
      this._item.notice_interval
    );

    this.itemForm.controls['period'].setValue(this.item.period);
    this.itemForm.controls['active_from'].setValue(this.item.active_from);
    this.itemForm.controls['active_to'].setValue(this._item.active_to);
    this.itemForm.controls['low_threshold'].setValue(this.item.low_threshold);
    this.itemForm.controls['low_discard_threshold'].setValue(
      this.item.low_discard_threshold
    );
    this.itemForm.controls['high_threshold'].setValue(this.item.high_threshold);
    this.itemForm.controls['high_discard_threshold'].setValue(
      this.item.high_discard_threshold
    );
    // patch days of week
    for (let day of this.item.day_of_week) {
      this.itemForm.controls[this.activeDaysMap[day]].setValue(true);
    }
    this.patching = false;
  }

  updateAlarmContactMenu(event?) {
    const _alarm_contacts = this.alarmContacts ? this.alarmContacts : [];
    this.alarmContactMenuData = {
      alarmName: event ? event : this.item.name,
      alarmId: this.item.id,
      alarmContacts: _alarm_contacts,
      allAlarmContacts: this.allAlarmContacts
    };
  }

  checkDirty(control) {
    if (!this.patching) {
      if (this.itemForm.controls[control].value !== this._item[control]) {
        this.itemForm.controls[control].markAsDirty();
      } else {
        // this.itemForm.controls[control].markAsPristine();
      }
    }
  }

  update() {
    /* Read item, parse timezone then save alarm */
    const updateItem = Object.assign({}, this.item, this.itemForm.value);
    updateItem['thing_id'] = this.thing_id;
    // create day of weeks array...
    delete updateItem['thing'];
    updateItem['timezone'] = updateItem['timezone']['name'];
    updateItem['day_of_week'] = [];
    for (let day in this.activeDaysMap) {
      if (updateItem[this.activeDaysMap[day]]) {
        updateItem['day_of_week'].push(+day);
      }
    }
    let item_id = this.item.id;

    this.alarmService.saveAlarm(updateItem).subscribe(
      item => this.onItemUpdateSuccess(item, item_id),
      error => (this.errorMessage = <any>error)
    );
  }

  alarmTypeChanged(event) {}

  onItemUpdateSuccess(item, item_id) {
    this.alarmService.alarm.subscribe(val => {
      this.item = val.item;
      this.alarm_id = this.item.id;
      this.onItemUpdated(this.item, item_id);
    });
  }
  onItemUpdateFailure(error) {
    this.errorMessage = <any>error;
    this.eventsService.showToast(this.errorToast);
  }
  onItemUpdated(item: any, item_id: number): void {
    if (item && !empty(item)) {
      this.eventsService.showToast(this.successToast);
    }
    if (item_id === 0 && item) {
      this.header = 'Alarm Detail - Edit';
      this.router
        .navigate([`${this.baseUrl}/${item.id}`])
        .then(() => this.openAlarmContactMenu(item));
    } else if (item) {
      this.router.navigate([this.baseUrl]);
    }
  }

  openAlarmContactMenu(item) {
    let id = 'openAlarmContactMenu';
    this.eventsService.publish(id, 'openAlarmContactMenu', {
      module: 'alarm',
      item: item,
      alarmId: item.id,
      alarmContacts: this.alarmContacts,
      action: 'open'
    });
  }

  setInitialValue() {}

  fixDropDown(elem) {
    if (elem && elem['_elementRef']) {
      var keys = Object.keys(elem);
      let el = elem['_elementRef'] as ElementRef;

      var button = el.nativeElement.querySelector('button');
      button.style.borderRadius = 'none';
      button.style.background = 'transparent';
      button.style.borderBottomRightRadius = '0';
      button.style.borderBottomLeftRadius = '0';
      button.style.borderTop = 'none';
      button.style.borderLeft = 'none';
      button.style.borderRight = 'none';
      button.style.borderBottomRightRadius = '0';
      button.style.borderBottomLeftRadius = '0';
      button.style.marginTop = '0.5em';
      button.style.marginRight = '1.5em';
      button.style.width = '20em';
      button.style.zIndex = '-1';
      button.style.minWidth = '284px !important';

      var span = el.nativeElement.querySelectorAll('span');
      span[1].classList.remove('sdicon-angle-down');
      span[1].classList.remove(...span[1].classList);
      span[1].innerHTML =
        '<clr-icon shape="caret" style="margin-left:-4.24em; transform: rotate(180deg); width: 13px; height: 13px;"></clr-icon>';
      span[1].style.color = '#b3b3b3';
      span[1].style.width = '8px';
      span[1].style.height = '8px';
      span[1].style.marginLeft = '4.74em';
    }
  }

  toggleActive(): void {
    this.active = !this.active;
    this.itemForm.controls['active'].setValue(this.active);
  }

  toggleIncludeNull(): void {
    this.include_null = !this.include_null;
    this.itemForm.controls['include_null'].setValue(this.include_null);
  }

  toggleAccumulator(): void {
    this.is_accumulator = !this.is_accumulator;
    this.itemForm.controls['is_accumulator'].setValue(this.is_accumulator);
  }

  openChange(e) {}

  protected filterTimezones() {
    if (!this.time_zones) {
      return;
    }
    // get the search keyword
    let search = this.itemForm.controls['timezoneFilterCtrl'].value;
    if (!search) {
      this.filteredTimezones.next(this.time_zones.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredTimezones.next(
      this.time_zones.filter(
        timezone => timezone.name.toLowerCase().indexOf(search) > -1
      )
    );
  }

  tzNames() {
    var tz = new Array();
    for (let i = 0; i < this.fields.time_zones.length; i++) {
      tz.push(this.fields.time_zones[i].name);
    }
    return tz;
  }

  zoneSelectionChanged(e) {}

  getBlankAlarm() {
    return {
      id: 0,
      name: 'New Alarm',
      period: '1900-01-01T02:00:00',
      notice_interval: '1900-01-01T02:00:00',
      active_from: '1900-01-01T07:00:00',
      active_to: '1900-01-01T23:00:00',
      alarm_contacts: [],
      low_discard_threshold: 0,
      high_discard_threshold: 300000,
      include_null: true,
      low_threshold: 0,
      high_threshold: 1,
      day_of_week: [0, 1, 2, 3, 4, 5, 6],
      timezone: 'US/Central'
    };
  }

  setThing(event) {
    this.thing_id = event.value.id;
    this.thing = event.value;
  }

  beautify() {
    let elem = document.querySelectorAll<HTMLElement>('.nsdicon-angle-down');
    if (elem && elem[0]) {
      elem[0].style.color = '#b3b3b3';
    }

    let elements = document.querySelectorAll<HTMLElement>(
      '.ngx-dropdown-list-container'
    )!;
    if (elements && elements[0]) {
      elements[0].style.borderRadius = '0px';
      elements[0].style.boxShadow = 'none';
      elements[0].style.width = '20em';
      elements[0].style.minWidth = '284px !important';
      elements[0].style.background = '#FAFAFA';
      elements[0].style.border = 'none';
    }
  }

  reReadTimezones(): void {
    this.fieldService.getFields().subscribe(data => {
      let time_zones = [];
      this.time_zones = [];
      if (typeof data.time_zones !== 'undefined') {
        for (let i = 0; i < data['time_zones'].length; i++) {
          time_zones.push({
            name: data['time_zones'][i].name.toString(),
            id: data['time_zones'][i].id.toString()
          });
        }
        this.time_zones = time_zones;
      }
    });
  }

  reReadThings(): void {
    let things = [];
    this.thingService.getAllThingsAsOptions().subscribe(data => {
      data.forEach(thing => {
        things.push(thing);
      });
      this.initial_thing = things.filter(
        thing => thing.id === this.item.thing_id
      );
      this.things = things;
    });
  }

  noAlarmTypeId(): boolean {
    if (typeof this.itemForm.controls['alarm_type_id'].value === 'undefined') {
      return true;
    }
    if (this.itemForm.controls['alarm_type_id'].value === null) {
      return true;
    }
    if (!this.itemForm.controls['alarm_type_id'].value) {
      return true;
    }
    return false;
  }

  noThing(): boolean {
    if (typeof this.thing_id === 'undefined' || this.thing_id === null) {
      return true;
    }

    return false;
  }

  newNotPopulated(): boolean {
    if (typeof this.alarm_id !== 'undefined' && this.alarm_id !== null) {
      if (this.noThing() || this.noAlarmTypeId()) {
        return true;
      }
      return false;
    }
    return false;
  }

  toggleChanged(event: any) {
    if (event === true) {
      this.alarmFormState = 'in';
    } else {
      this.alarmFormState = 'out';
    }
  }

  reloadPage(): void {
    let visited3d = get_item('visited3d');
    if (typeof visited3d !== 'undefined' && visited3d !== null) {
      window.location.reload(true);
      set_item('visited3d', null);
    }
  }
}
