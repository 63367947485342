import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { constants } from '@core/constants';

@Injectable()
export class NameCheckService {
  constructor(private http: HttpClient) {}

  checkNameNotTaken(name: string, model: string) {
    return this.http.get(
      constants.api.user.user_api_check_name + '/' + name + '?model=' + model
    );
  }
}
