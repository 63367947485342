import { ActivatedRoute, Router } from '@angular/router';
import { Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { EventsService } from '@modules/events/services/events.service';
import { IToastPayload } from '@shared/interfaces/toast-payload';
import { UserSessionState } from '@core/stores/user-session/states/user-session.states';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private idleState = 'Not started.';
  private timedOut = false;
  private lastPing?: Date = null;
  public title = 'angular-idle-timeout';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private eventsService: EventsService,
    private toastr: ToastrService,
    private idle: Idle,
    private keepalive: Keepalive,
    protected sessionStore: Store<{ uss: UserSessionState }>
  ) {}

  ngOnInit() {
    this.subscribeEvents();
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  subscribeEvents() {}

  ngOnDestroy(): void {}
}
