import { finalize } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { set_item } from '@shared/utils/storage/encrypted_storage';
import { constants } from '@core/constants';
import { BaseService } from '@core/services/base.service';
import { EventsService } from '@modules/events/services/events.service';
import { zip } from '@shared/utils/zip';

@Injectable({
  providedIn: 'root'
})
export class ThreeChartService extends BaseService {
  constructor(
    protected eventsService: EventsService,
    protected http: HttpClient
  ) {
    super(eventsService, http);
  }

  readView(id: number) {
    return this.http.get(`${constants.api.base_api}/data/` + id).pipe(
      map((data: any) => {
        let d = this.getThreeDimData(data);
        return d;
      }),
      catchError(error => this.handleError(error))
    );
  }

  getThreeDimData(data: any) {
    if (typeof data.item !== 'undefined' && data.item !== null) {
      if (data.item.collection.length === 0) {
        return data;
      }
    }

    //let collection = data.item.collection.slice(data.item.collection.length-4, data.item.collection.length-1);

    // data.item.collection = collection;
    let indexes: any = {};

    for (let i = 0; i < data.item.collection.length; i++) {
      indexes[data.item.collection[i].name] = i;
      for (let j = 0; j < data.item.collection[i].data.length; j++) {
        if (data.item.collection[i].data[j] === null) {
          data.item.collection[i].data[j] = 0;
        }
      }
    }

    let col = data.item.collection.slice(
      data.item.collection.length - 3,
      data.item.collection.length
    );
    data.item.collection = col;
    let x = this.extractDates(col[0].data);
    let y = col[1].data;
    let z = col[2].data;
    let converted: any[] = [];
    let result: any[] = [];
    let i = 0;
    let j = 1;
    let k = 2;
    let ln = col[0].data.length;

    let x_name = col[0].name.toLowerCase();
    let y_name = col[1].name.toLowerCase();
    let z_name = col[2].name.toLowerCase();

    let unitX = col[0].unit;
    let unitY = col[1].unit;
    let unitZ = col[2].unit;

    x.forEach((item: any) => {
      item = `${item} ${unitX}`;
    });

    y.forEach((item: any) => {
      item = `${item} ${unitY}`;
    });

    for (let jndex = 0; jndex < y.length; jndex++) {
      for (let index = 0; index < x.length; index++) {
        let item = [];
        item.push(y[jndex]);
        item.push(x[index]);
        converted.push(item);
      }
    }

    for (let index = 0; index < z.length; index++) {
      converted[index].push(z[index]);
    }

    let unit_names: any = {};

    for (let i = 0; i < col[1].data.length; i++) {
      unit_names[col[1].data[i]] = col[1].unit[i];
    }

    result = [];

    for (let index = 0; index < converted.length; index++) {
      let item: any = {};
      item[`${x_name}`] = `${converted[index][1]}`;
      item[`${y_name}`] = `${converted[index][0]}`;
      item[`${z_name}`] = converted[index][2] | 0;
      result.push(item);
    }

    let response = {
      data: data,
      keys: [`${x_name}`, `${y_name}`, `${z_name}`],
      indexes: indexes,
      unit_names: unit_names,
      units: [`${col[0].unit}`, `${col[2].unit}`, ''],
      x: x.reverse(),
      y: y,
      z: z,
      unit_x: unitX,
      unit_y: unitY,
      xIndex: i,
      yIndex: j,
      zIndex: k,
      name_x: x_name,
      name_y: y_name,
      name_z: z_name,
      unit_z: unitZ,
      result: result
    };
    return response;
  }

  extractDates(data: any) {
    const dates = [];
    let extractedDates = JSON.parse(JSON.stringify(data));
    let month = new Array();
    month[0] = 'Jan';
    month[1] = 'Feb';
    month[2] = 'Mar';
    month[3] = 'Apr';
    month[4] = 'May';
    month[5] = 'Jun';
    month[6] = 'Jul';
    month[7] = 'Aug';
    month[8] = 'Sep';
    month[9] = 'Oct';
    month[10] = 'Nov';
    month[11] = 'Dec';

    for (const date of extractedDates) {
      let d = new Date(date);

      let hours = d.getHours();
      let minutes = d.getMinutes();
      let ampm = hours >= 12 ? 'pm' : 'am';
      let minuts = minutes < 10 ? '0' + minutes : minutes;
      let strTime = hours + ':' + minuts;
      let v =
        d.getDate() +
        ' ' +
        month[d.getMonth()] +
        ' ' +
        d.getFullYear() +
        ' ' +
        strTime;
      dates.push(v);
    }
    return dates;
  }
}
