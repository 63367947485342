import { FormControl, FormGroup } from '@angular/forms';
import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ComponentRef,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { BaseComponent } from '@shared/components/base/base.component';
import { EventsState } from '@modules/events/states/events.state';
import { EventsService } from '@modules/events/services/events.service';
import { UserSessionState } from '@core/stores/user-session/states/user-session.states';
import { SearchSelectComponent } from '@shared/components/searchable-select/searchable-select.component';
import { Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

@Component({
  providers: [EventsService],
  selector: 'app-user-filter',
  templateUrl: './user-filter.component.html',
  styleUrls: ['./user-filter.component.scss']
})
export class UserFilterComponent extends BaseComponent
  implements OnInit, OnDestroy {
  private userDataSource = new BehaviorSubject<any>(null);
  private puserSelected = this.userDataSource.asObservable();

  @Input() users: any[];
  @Input() width?: any;
  @Input() disabled: boolean;
  @Input() parentUserSelected?: Observable<any>;
  @Output('selected') selected = new EventEmitter<number>();
  public parentUser?: any;
  public items: any[] = [];
  public user?: any;
  public user_id?: number;
  public user_name?: any;
  eventSubs?: Subscription;
  @ViewChild('usersDropdown')
  usersDropdown: ComponentRef<SearchSelectComponent>;

  public userForm = new FormGroup({
    user: new FormControl('', [])
  });
  constructor(
    protected idle: Idle,
    protected keepalive: Keepalive,
    protected sessionStore: Store<{ uss: UserSessionState }>,
    protected eventsService: EventsService,
    protected eventStore: Store<{ nf: EventsState }>,
    protected router: Router
  ) {
    super(idle, keepalive, eventsService, sessionStore, router);
  }

  getUserName(id: number): string {
    for (let i = 0; i < this.users.length; i++) {
      if (this.users[i].id === id) {
        return this.users[i].name;
      }
    }
    return null;
  }

  ngOnInit(): void {
    this.subscribeEvents();
    if (typeof this.width === 'undefined' || this.width === 'null') {
      this.width = '18em';
    }
    if (typeof this.users !== 'undefined' && this.users !== null) {
      for (let i = 0; i < this.users.length; i++) {
        this.items.push({ id: this.users[i].id, name: this.users[i].name });
      }
      this.items.reverse();
    }
    if (
      typeof this.parentUserSelected !== 'undefined' &&
      this.parentUserSelected !== null
    ) {
      this.parentUserSelected.subscribe(item => {
        if (typeof item !== 'undefined' && item !== null) {
          if (typeof item.name !== 'undefined' && item.name !== null) {
            this.user_name = item.name;
          } else {
            let name = this.getUserName(item.id);
            if (name !== null) {
              this.user_name = name;
            }
          }

          let id = 'filterValueChanged';
          this.eventsService.publish(id, 'filterValueChanged', {
            user: item,
            module: 'shared',
            action: 'open'
          });
          this.user_id = item.id;
          this.setUser(item.id);
          this.selected.emit(item.id);
        }
      });
    }
  }

  subscribeEvents(): void {
    this.eventSubs = this.eventStore.select('nf').subscribe(event => {
      if (event.items.id === 'parentUserSelected') {
        this.parentUser = event.items.payload.user;
        this.userDataSource.next(event.items.payload.user);
        this.user = event.items.payload.user;
      }
    });
  }

  ngOnDestroy(): void {
    if (typeof this.eventSubs !== 'undefined' && this.eventSubs !== null) {
      this.eventSubs.unsubscribe();
    }
  }

  reReadUsers(): void {}

  setUser(e: any) {
    this.selected.emit(e);
  }

  userSelected(e: any) {
    this.selected.emit(e.value.id);
  }

  parentUserSent() {
    if (
      typeof this.parentUserSelected !== 'undefined' &&
      this.parentUserSelected !== null
    ) {
      return this.parentUserSelected;
    } else {
      return this.puserSelected;
    }
  }
}
