import { BaseComponent } from '@shared/components/base/base.component';
import {
  Component,
  Input,
  Output,
  OnInit,
  HostListener,
  EventEmitter
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  trigger,
  state,
  style,
  transition,
  animate
} from '@angular/animations';
import { CompanyService } from '@core/services/company/company.service';
import { Subscription } from 'rxjs';
import { AlarmService } from '@core/services/alarm/alarm.service';
import { UserSessionService } from '@core/services/user-session/user-session.service';
import { EventsService } from '@modules/events/services/events.service';
import { EventsState } from '@modules/events/states/events.state';
import { UserSessionState } from '@core/stores/user-session/states/user-session.states';
import { Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Store } from '@ngrx/store';
import { deepCopy } from '@shared/utils/storage/encrypted_storage';

@Component({
  providers: [EventsService, AlarmService, CompanyService, UserSessionService],
  selector: 'app-alarm-contact-menu',
  templateUrl: './alarm-contact-menu.component.html',
  styleUrls: ['./alarm-contact-menu.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({ width: '350px', 'pointer-events': 'all' })),
      state('out', style({ width: '47px', 'pointer-events': 'none' })),
      transition('in => out', animate('250ms ease-in-out')),
      transition('out => in', animate('250ms ease-in-out'))
    ])
  ]
})
export class AlarmContactMenuComponent extends BaseComponent implements OnInit {
  private subs: Subscription;
  private eventSubs: Subscription;
  private source_indexes: any = {};
  private dest_indexes: any = {};
  public alarmContactMenuState = 'out';
  private className = 'AlarmContactMenuComponent';
  public searchText: string = '';
  public alarmContacts: any[];
  public allContacts = [];
  public appliedAlarmContacts = [];
  public allAlarmContacts: any[];
  public allAlarmUnfilteredContacts: any[] = [];

  alarmId: number = 0;
  @Input() alarmContactMenuData: {
    alarmName: string;
    alarmId: string;
    alarmContacts: Array<object>;
    allAlarmContacts: Array<object>;
  };
  @Output('toggle') toggle: any = new EventEmitter(false);

  errorMessage: string;
  private successToast = {
    title: 'Success!',
    text: '',
    type: 'success'
  };
  private errorToast = {
    title: 'Error!',
    text: '',
    type: 'error'
  };
  private notExistingWarning = {
    title: 'Warning!',
    text: 'Not available until new Alarm is saved!',
    type: 'warning'
  };

  constructor(
    protected idle: Idle,
    protected keepalive: Keepalive,
    protected eventsService: EventsService,
    protected eventStore: Store<{ nf: EventsState }>,
    protected sessionStore: Store<{ uss: UserSessionState }>,
    public companyService: CompanyService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected alarmService: AlarmService,
    protected userSessionService: UserSessionService
  ) {
    super(idle, keepalive, eventsService, sessionStore, router);
  }

  ngOnInit() {
    this.subscribeEvents();
  }

  removeAlarmContactsFromAllAlarmContacts() {
    let contacts = [];

    for (const alarmContact of this.allContacts) {
      if (
        this.appliedAlarmContacts
          .map(a => a.id)
          .indexOf(+alarmContact['id']) === -1
      ) {
        contacts.push(alarmContact);
      }
    }
    this.allContacts = contacts;
    this.allAlarmUnfilteredContacts = contacts;
  }

  ngOnDestroy(): void {
    if (typeof this.eventSubs !== 'undefined' && this.eventSubs !== null) {
      this.eventSubs.unsubscribe();
    }
  }

  subscribeEvents() {
    this.eventSubs = this.eventStore.select('nf').subscribe(event => {
      if (event.items.id === 'openAlarmContactMenu') {
        this.alarmId = parseInt(event.items.payload.alarmId);
        this.alarmContactMenuData.alarmId = event.items.payload.item.id;
        this.alarmContactMenuData.alarmContacts = deepCopy(
          event.items.payload.alarmContacts
        );
        this.allContacts = deepCopy(event.items.payload.allAlarmContacts);
        this.allAlarmUnfilteredContacts = deepCopy(
          event.items.payload.allAlarmContacts
        );
        this.appliedAlarmContacts = deepCopy(event.items.payload.alarmContacts);
        this.alarmContactMenuState = 'in';
        //this.toggleAlarmContactMenu();
      } else if (event.items.id === 'closeAlarmContactMenu') {
        this.alarmId = parseInt(event.items.payload.alarmId);
        this.alarmContactMenuState = 'out';
      } else if (event.items.id === 'notifyDrawer') {
        this.alarmId = parseInt(event.items.payload.alarmId);
        this.allContacts = deepCopy(event.items.payload.all);
        this.allAlarmUnfilteredContacts = this.allContacts;
        this.appliedAlarmContacts = deepCopy(event.items.payload.applied);
      }
    });
  }

  toggleAlarmContactMenu() {
    if (this.alarmId == 0) {
      this.eventsService.showToast(this.notExistingWarning);
    } else {
      this.removeAlarmContactsFromAllAlarmContacts();
      // this.allAlarmContacts = this.alarmContactMenuData.allAlarmContacts;
      this.alarmContactMenuState =
        this.alarmContactMenuState === 'out' ? 'in' : 'out';
      let toggle = false;
      if (this.alarmContactMenuState === 'in') {
        toggle = true;
      } else {
        toggle = false;
      }
      this.toggle.emit(toggle);
    }
  }

  addAlarmSelectedContact(item_id) {
    const payload = { alarm_id: this.alarmId, alarm_contact_id: item_id };
    this.alarmService.addAlarmSelectedContact(payload).subscribe(
      item => this.onItemUpdated(item, 'add'),
      error => (this.errorMessage = <any>error)
    );
  }

  moveFromSelectedToAll(item_id) {}

  moveFromAllToSelected(item_id) {
    const payload = { alarm_id: this.alarmId, alarm_contact_id: item_id };
    this.alarmService.addAlarmSelectedContact(payload).subscribe(
      item => this.onItemUpdated(item, 'add'),
      error => (this.errorMessage = <any>error)
    );
  }

  removeAlarmContact(item_id) {
    const payload = { alarm_id: this.alarmId, alarm_contact_id: item_id };
    // console.log('removeAlarm');
    // console.log(payload);
    this.alarmService.deleteAlarmSelectedContact(payload).subscribe(
      item => this.onItemUpdated(item, 'remove'),
      error => (this.errorMessage = <any>error)
    );
  }

  onItemUpdated(data: any, type: string): void {
    if (data.is_error) {
      console.log(`Error updating item ${data.message}`);
      this.errorToast.text = `Failed to ${type} Alarm Contact!`;
      this.eventsService.showToast(this.errorToast);
    } else {
      this.successToast.text = `Alarm Contact ${
        type === 'remove' ? 'removed' : 'added'
      } successfully!`;
      // console.log(`${this.className} onItemUpdated`);
      this.eventsService.showToast(this.successToast);
      this.checkAlarm();
    }
    this.allAlarmUnfilteredContacts = this.allContacts;
  }

  refreshLists() {
    this.alarmService.getAllAlarmContactsAsOptions().subscribe(
      items => this.onAllAlarmContactsRetrieved(items),
      error => (this.errorMessage = <any>error)
    );
  }

  checkAlarm() {
    //this.alarmContactMenuData.allAlarmContacts = items;
    this.alarmService.getAlarm(+this.alarmId).subscribe(
      data => this.onAlarmContactsRetrieved(data['item']),
      error => (this.errorMessage = <any>error)
    );
  }

  onAllAlarmContactsRetrieved(items) {
    this.alarmContactMenuData.allAlarmContacts = items;
    this.allAlarmUnfilteredContacts = items;
    this.alarmService.getAlarm(+this.alarmContactMenuData.alarmId).subscribe(
      data => this.onAlarmContactsRetrieved(data['item']),
      error => (this.errorMessage = <any>error)
    );
  }

  onAlarmContactsRetrieved(item) {}

  searchContacts(e: any) {
    if (this.searchText.toString().length < 1) {
      this.allContacts = this.allAlarmUnfilteredContacts;
    }
    let preSearch: any[] = this.allContacts;
    let selected: any[] = [];
    e = e.toLowerCase();

    for (const contact of this.allContacts) {
      if (contact.name.toLowerCase().indexOf(e) !== -1) {
        selected.push(contact);
      }
    }

    if (selected.length > 0) {
      this.allContacts = selected;
    } else {
      this.allContacts = [];
    }
  }

  getId(item): number {
    if (this.notEmpty(item)) {
      return item.id;
    } else {
      return Math.floor(Math.random() * 100);
    }
  }

  notEmpty(e) {
    if (e === null) {
      return false;
    }

    if (typeof e === 'undefined') {
      return false;
    }

    return true;
  }

  onDrag(item: any) {}

  onDragCanceled(item: any) {}

  onDragStarted(item: any) {}

  onDragEnded(item: any, mode: any) {
    if (mode === 'all') {
      if (this.allContacts.map(a => a.id).indexOf(item.id) == -1) {
        this.moveFromAllToSelected(item.id);
      }
    } else {
      if (this.appliedAlarmContacts.map(a => a.id).indexOf(item.id) == -1) {
        this.removeAlarmContact(item.id);
      }
    }
  }

  public removeOneItem(item: any, list: any[]): void {
    list.splice(list.indexOf(item), 1);
  }
}
