
<!--
<div *ngIf="!loaded">
      <div class="fl-chart">
              <span class="spinner spinner-lg loading">
                Refreshing...
              </span>
      </div>
</div>
-->
<div *ngIf="showTitle" class="grid-header">{{ data_view_name }} - {{ readTimeDomain(time_domain_id) }}</div> 
<div #wrapper class="grid-wrapper wrapper-{{ currentClass }}"  id="wrapper-{{ currentClass }}" (click)="setDataView()" (change)="setDataView()"> 
            <div *ngIf="showTitle" class="slick-custom" #dropdown (click)="dropDownToggle()">
                <clr-dropdown>
                    <button clrDropdownTrigger id="hamburger">
                        <fa-icon [icon]="faBarsIcon"  aria="Menu"></fa-icon>
                    </button>
                    <clr-dropdown-menu clrPosition="bottom-right" style="padding-left: 0.5em !important; padding-right: 0.5em !important;margin-left: -0.8em !important;" *clrIfOpen>

                        <div class="slick-menu-command-list slick-context-menu-command-list">


                           <div class="slick-command-header with-title with-close menu-header"><span class="slick-menu-title">Commands</span>
                                <button type="button" class="close menu-close" (click)="closeMenu()" data-dismiss="slick-grid-menu" aria-label="Close">×</button></div>

                           <div class="slick-context-menu-item slick-menu-item" (click)="clearFilters()" data-command="clear-filter"><div class="slick-menu-icon fa fa-filter text-danger"></div>&nbsp;<span class="slick-menu-content">Clear all Filters</span></div>

                           

                           <div class="slick-context-menu-item slick-menu-item" (click)="clearSorting()" data-command="clear-sorting"><div class="slick-menu-icon fa fa-unsorted text-danger"></div>&nbsp;<span class="slick-menu-content">Clear all Sorting</span></div>


                           <div class="slick-context-menu-item slick-menu-item" (click)="toggleFilterRow()" data-command="toggle-filter"><div class="slick-menu-icon fa fa-random"></div>&nbsp;<span class="slick-menu-content">Toggle Filter Row</span></div>

                           <div class="slick-context-menu-item slick-menu-item" (click)="resetColumns()" data-command="toggle-filter"><div class="slick-menu-icon fa fa-random"></div>&nbsp;<span class="slick-menu-content">Reset Columns</span></div>


                           <div class="slick-context-menu-item" (click)="exportToExcel()"><div class="slick-context-menu-icon fa fa-file-excel-o text-success"></div>&nbsp;<span class="slick-context-menu-content">Export to Excel</span></div>


                           <div class="slick-command-header with-title with-close menu-header"><span class="slick-menu-title">Columns</span>


                                 <div class="slick-column-picker-list">
                                             <div class="list-item " *ngFor="let column of columns">
                                                    <li class="slick-menu-item"><fa-icon [icon]="faCheckIcon"  (click)="toggleColumn(column)" [ngClass]="{'active': columnOn(column) == true, 'inactive': columnOn(column) == false }"></fa-icon>
                                                    <label for="slickgrid_622336-gridmenu-colpicker-{{ column.id }}">{{ column.name }}</label></li>
                                             </div>
                                 </div>
                           </div>

                        </div>
                    </clr-dropdown-menu>
                </clr-dropdown>
            </div>





  <div class="slick-wrapper-{{ currentClass }}" id="smaller-container {{ grid_id }}" style="width:99.5%;">
    <angular-slickgrid gridId="{{ grid_id }}" id="item-{{ grid_id }}" 
                       [columnDefinitions]="columnDefinitions"
                       (onBeforeExportToExcel)="processing = true"
                       (onAfterExportToExcel)="processing = false"
                       [gridOptions]="gridOptions"
                       (onSelectedRowsChanged)="onSelectedRowsChanged($event.detail.eventData, $event.detail.args)"
                       (onClick)="onCellClicked($event.detail.eventData, $event.detail.args)"
                       (onDrag)="onDrag($event.detail.eventData, $event.detail.args)"
                       (onDragEnd)="onDragEnd($event.detail.eventData, $event.detail.args)"
                       (onHeaderClick)="onHeaderClick($event.detail.eventData, $event.detail.args)"
                       [(dataset)]="dataset"
                       (onColumnsReordered)="onColumnsReordered($event.detail.eventData, $event.detail.args)"
                       (sgOnColumnsReordered)="onColumnsReordered($event.detail.eventData, $event.detail.args)"
                       (onAngularGridCreated)="angularGridReady($event)">
    </angular-slickgrid>
  </div>
</div>
