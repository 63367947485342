import { Injectable } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { constants } from '@core/constants';
import { BaseService } from '@core/services/base.service';
import { EventsService } from '@modules/events/services/events.service';
import { ExcelExportService } from '@slickgrid-universal/excel-export';
import { get_item, set_item } from '@shared/utils/storage/encrypted_storage';
import {
  AngularGridInstance,
  Column,
  Editors,
  FieldType,
  FileType,
  Formatters,
  GridOption,
  GridService,
  OperatorType,
  OnEventArgs,
  SlickDataView,
  SlickGrid,
  Aggregators,
  deepCopy,
  Filters,
  Formatter,
  GroupTotalFormatters
} from 'angular-slickgrid';

const initialDataViewData = {} as any;

@Injectable({
  providedIn: 'root'
})
export class GridChartService extends BaseService {
  public dataViewDataSource = new BehaviorSubject<any>(initialDataViewData);
  public dataViewObj = this.dataViewDataSource.asObservable();
  public excelExportService: any;
  private pageRatio: number = 1;
  private data_view_id: number;
  public angularGrid!: AngularGridInstance;
  public uniqueId: string = '';
  grid!: SlickGrid;
  gridService!: GridService;
  dataView!: SlickDataView;
  columnDefinitions: Column[] = [];
  gridOptions!: GridOption;
  dataset: any[];
  updatedObject: any;
  gridId: number = 0;
  formatters: any = {};
  types: any = {
    string: FieldType.string,
    boolean: FieldType.boolean,
    number: FieldType.number,
    text: FieldType.text,
    integer: {},
    long_text: {},
    date: {}
  };

  editors: any = {
    date: '',
    string: {
      model: Editors.longText
    },
    number: {}
  };

  constructor(
    protected eventsService: EventsService,
    protected http: HttpClient
  ) {
    super(eventsService, http);
    this.excelExportService = new ExcelExportService();
  }

  readView(id: number) {
    return this.http.get(`${constants.api.base_api}/data/` + id).pipe(
      map((data: any) => {
        let d = this.getGridData(data);
        return d;
      }),
      catchError(error => this.handleError(error))
    );
  }

  getGridData(data: any) {
    let template = {
      columnDefinitions: this.generateColumnDefinitions(data.item.data_points),
      gridOptions: this.readGridOptions(data),
      data: this.postProcessCollection(
        data.item.collection,
        data.item.data_points
      ) //this.mockDataset(100)
    };
    return {
      time_domain_id: data.item.time_domain_id,
      columnDefinitions: this.generateColumnDefinitions(data.item.data_points),
      gridOptions: this.readGridOptions(data),
      data: this.postProcessCollection(
        data.item.collection,
        data.item.data_points
      ) //this.mockDataset(100)
    };
  }

  postProcessCollection(collection: any, data_points: any): any {
    let items: any[] = [];
    for (let i = 0; i < collection.length; i++) {
      let item: any = {};
      item['id'] = i;
      item['data_point'] = collection[i]['row']['data_point_name'];
      item['value'] = collection[i]['row']['value'];

      items.push(item);
    }
    return items;
  }

  getDataPointName(data_points: any, dp_id: number): any {
    for (let i = 0; i < data_points.length; i++) {
      if (data_points[i].id === dp_id) {
        return data_points[i].name;
      }
    }
    return '';
  }

  mockDataset(itemCount: number) {
    // mock a dataset
    const mockedDataset = [];
    for (let i = 0; i < itemCount; i++) {
      const randomYear = 2000 + Math.floor(Math.random() * 10);
      const randomMonth = Math.floor(Math.random() * 11);
      const randomDay = Math.floor(Math.random() * 29);
      const randomPercent = Math.round(Math.random() * 100);
      mockedDataset[i] = {
        id: i,
        title: 'Task ' + i,
        duration: Math.round(Math.random() * 100) + '',
        percentComplete: randomPercent,
        percentCompleteNumber: randomPercent,
        start: new Date(randomYear, randomMonth, randomDay),
        finish: new Date(randomYear, randomMonth + 1, randomDay),
        effortDriven: i % 5 === 0
      };
    }
    return mockedDataset;
  }

  preProcessColumns(data: any): any {
    let columns = this.readMockColumns();
    let processedColumns: any = [];

    columns.forEach((column: any) => {
      processedColumns.push(column);
    });
    return processedColumns;
  }

  readMockColumns(): any {
    let columns: any = [
      {
        id: 'Col One (B B1A B1A)',
        sortable: true,
        type: 'string',
        formatter: 'delete_icon',
        excludeFromHeaderMenu: true,
        editor: 'text',
        minWidth: 30,
        maxWidth: 30,
        useForChange: true
      },
      {
        id: 'Col Two (B1A B1A B)',
        sortable: true,
        type: 'string',
        formatter: 'delete_icon',
        excludeFromHeaderMenu: true,
        editor: 'long_text',
        minWidth: 30,
        maxWidth: 30,
        useForChange: false
      },
      {
        id: 'Col Three (B1A B1A B)',
        sortable: true,
        type: 'string',
        formatter: 'delete_icon',
        excludeFromHeaderMenu: true,
        editor: 'text',
        minWidth: 30,
        maxWidth: 30,
        useForChange: false
      },
      {
        id: 'Col Four (B1A B1A B)',
        sortable: true,
        type: 'string',
        formatter: 'delete_icon',
        excludeFromHeaderMenu: true,
        editor: 'integer',
        minWidth: 30,
        maxWidth: 30,
        useForChange: false
      },
      {
        id: 'Col Four (B1A B1A B)',
        sortable: true,
        type: 'string',
        formatter: 'delete_icon',
        excludeFromHeaderMenu: true,
        editor: 'date',
        minWidth: 30,
        maxWidth: 30,
        useForChange: false
      }
    ];
    return columns;
  }

  readMockData(): any {}

  deleteDialog(item, fullItem) {
    const itemNames = [item];
    let id = 'openDeleteChartItemModal';
    let data_view_id: any = get_item('data_view_id');
    this.eventsService.publish(id, 'openDeleteChartItemModal', {
      module: 'data-analysis',
      action: 'open',
      deleteModalTitle: 'Delete Item',
      itemNames: itemNames,
      event: 'Delete Item',
      additionalInfo: '',
      name: '',
      dataViewId: data_view_id,
      fullItem: fullItem
    });
  }

  generateColumnDefinitions(data: any) {
    let columnDefinitions: any = [
      {
        id: 'delete',
        sortable: true,
        field: 'id',
        excludeFromHeaderMenu: true,
        minWidth: 15,
        maxWidth: 15
      }
    ];
    let data_point = {
      id: 'data_point',
      name: 'Data Point',
      field: 'data_point',
      sortable: true,
      filterable: true,
      type: FieldType.string
    };
    let time_domain = {
      id: 'value',
      name: 'Value',
      field: 'value',
      sortable: true,
      filterable: true,
      minWidth: 70,
      width: 70,
      filter: { model: Filters.compoundInputNumber },
      sortDesc: true,
      type: FieldType.number,
      //editor: {
      //   model: Editors.float
      //},
      decimal: 2,
      minValue: 0,
      maxValue: 500000000000,
      placeholder: '0',
      errorMessage: 'Invalid aggregate value'
    };
    columnDefinitions.push(data_point);
    columnDefinitions.push(time_domain);

    return columnDefinitions;
  }

  readGridOptions(data: any) {
    let gridOptions: any = {
      asyncEditorLoading: true,
      autoHeight: true,
      gridWidth: 400,
      rowHeight: 33,
      autoResize: {
        container: `#gridster-item-${this.uniqueId}`,
        rightPadding: 10,
        delay: 0,
        sidePadding: 4,
        bottomPadding: 4
      } as any,
      enableFiltering: true,
      //    showHeaderRow: false,
      preHeaderPanelHeight: 40,
      showCustomFooter: true,
      enableGridMenu: true,
      enableExcelExport: true,
      enableAutoResize: true,
      enableHeaderButton: true,
      enableHeaderMenu: true,
      editable: true,
      enableColumnPicker: true,
      enableRowSelection: true,
      forceFitColumns: true,
      topPanelHeight: 25,
      enableAddRow: true,
      enableCellNavigation: true,
      autoEdit: true,
      enableCheckboxSelector: true,
      enablePagination: true,
      createFooterRow: true,
      showFooterRow: true,
      footerRowHeight: 21,
      headerButton: {
        // when floating to left, you might want to inverse the icon orders
        onCommand: (_e, args) => this.handleOnCommand(_e, args, 2)
      },
      formatterOptions: {
        displayNegativeNumberWithParentheses: true,
        thousandSeparator: ','
      },
      draggableGrouping: {
        dropPlaceHolderText: 'Some ',
        deleteIconCssClass: 'fa fa-times'
      },
      enableDraggableGrouping: true,
      createPreHeaderPanel: true,
      showPreHeaderPanel: true,

      pagination: {
        pageSizes: [5, 10, 15, 20, 25, 50, 75, 100],
        pageSize: 20 //this.pageRatio
      },
      /* 
      excelExportOptions: {
        sanitizeDataExport: true
       } as any,
  
*/

      headerMenu: {
        onCommand: (e, args) => {
          this.eventsService.publish(
            'refreshGridChartHeader',
            'refreshGridChartHeader',
            {}
          );
        }
      },
      excelExportOptions: {
        enableExcelExport: true,
        // optionally pass a custom header to the Excel Sheet
        // a lot of the info can be found on Web Archive of Excel-Builder
        // http://web.archive.org/web/20160907052007/http://excelbuilderjs.com/cookbook/fontsAndColors.html
        customExcelHeader: (workbook, sheet) => {
          const customTitle = '';
          const stylesheet = workbook.getStyleSheet();
          const aFormatDefn = {
            font: {
              size: 12,
              fontName: 'Calibri',
              bold: true,
              color: 'FF0000FF'
            }, // every color starts with FF, then regular HTML color
            alignment: { wrapText: true }
          };
          const formatterId = stylesheet.createFormat(aFormatDefn);
          sheet.setRowInstructions(0, { height: 30 }); // change height of row 0

          // excel cells start with A1 which is upper left corner
          sheet.mergeCells('B1', 'D1');
          const cols = [];
          // push empty data on A1
          cols.push({ value: '' });
          // push data in B1 cell with metadata formatter
          cols.push({
            value: customTitle,
            metadata: { style: formatterId.id }
          });
          sheet.data.push(cols);
        }
      } as any,
      gridMenu: {
        hideExportExcelCommand: false // false by default, so it's optional
      },
      registerExternalResources: [new ExcelExportService()]
    } as any;
    return gridOptions;
  }
  setUniqueId(uniqueId: any) {
    this.uniqueId = uniqueId;
  }
  setDataViewId(dataViewId: number) {
    this.data_view_id = dataViewId;
  }

  getDataViewId(): number {
    return this.data_view_id;
  }

  handleOnCommand(e: any, a: any, s: any) {}

  postProcessDataPoints(data: any): any {}

  getDataPointById(id: number): any {}
}
