<div class="flex-outer-container child-container-details">

    <clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading" [(clrDgSelected)]="selected" class="grid-sizing fl-table">
        <clr-dg-column [clrDgField]="'name'">
           Data Point Name
        </clr-dg-column>

        <clr-dg-row *clrDgItems="let item of items" [clrDgItem]="item">
            <clr-dg-cell>
                    {{ item.name }}
            </clr-dg-cell>
        </clr-dg-row>

        <clr-dg-detail *clrIfDetail="let detail">
          <app-grid-config-details [item]="detail"></app-grid-config-details>
        </clr-dg-detail>

        <clr-dg-footer>
            <clr-dg-pagination #pagination [clrDgPageSize]="perPage" [clrDgTotalItems]="itemCount">
                <clr-dg-page-size [clrPageSizeOptions]="[1, 2, 5, 10]">
                </clr-dg-page-size>
                {{ pagination.firstItem + 1 }}
                -
                {{ pagination.lastItem + 1 }}
                of
                {{ pagination.totalItems }}
            </clr-dg-pagination>
        </clr-dg-footer>
    </clr-datagrid>
</div>

