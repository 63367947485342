<app-ticket-user-menu [item]="item" (onTicketUserUpdated)="updateItemViaAux()"></app-ticket-user-menu>
<div class="child-container top-align ticket-details-container">
    <form [formGroup]="itemForm" class="pad-r24">

        <h4>{{header}}</h4>
        <br/>
        <div class="row" *ngIf="item">
            <div class="clr-row form-group" *ngIf="item.id !== 0 && item.event_id">
                <div class="form-group row">
                    <div class="clr-col-md-1 col-title ">
                        <label for="event">Event:</label>
                    </div>
                    <div class="clr-col-md-5">
                        <span id="event">{{item.event}}</span>
                    </div>
                </div>
            </div>



            <div class="clr-row form-group">
                <div class="clr-col-1 col-title">

                         <label>Created:</label>

                </div>
            
                <div class="clr-col-2 non-editable">
                        <span id="created">{{ item.created }}</span>
                </div>
    
                <div class="clr-col-1 col-title">
                         <label>Author:</label>
                </div>

                <div class="clr-col-2 non-editable">
                        <span>{{ author }}</span>

                </div>
            </div>


            <div *ngIf="item.id > 0" class="clr-row form-group">

                <div class="clr-col-1 col-title">
                         <label>Updated:</label>
                </div>
                <div class="clr-col-2">
                    <clr-input-container>
                        <span id="updated">{{ item.updated }}</span>
                    </clr-input-container>
                </div>

            </div>

            <div class="clr-row form-group">
                <div class="clr-col-1 col-title">
                         <label for="subject">Subject</label>
                </div>
                <div class="clr-col-2">

                    <label for="subject"
                           aria-haspopup="true"
                           role="tooltip"
                           class="tooltip tooltip-bottom-right tooltip-validation tooltip-sm"
                           [class.invalid]="itemForm.get('subject').invalid
                    && (itemForm.get('subject').dirty || itemForm.get('subject').touched)">

                             <clr-input-container>

                             <span class="tooltip-content">Subject is required.</span>
                             <input id="subject"  clrInput type="text" [formControl]="itemForm.controls.subject" name="subject"  size="30"/>
                             <clr-control-error *clrIfError="'minlength'">Subject must be at least 3 characters</clr-control-error>
                             <clr-control-error *clrIfError="'required'">Subject Is Required</clr-control-error>
                             </clr-input-container>
                    </label>


                </div>
            </div>

            <div class="clr-row form-group">
                <div class="clr-col-1 col-title">
                    <label for="description">Description</label>
                </div>
                <div class="clr-col-7"> 
                    <clr-textarea-container>
                    <textarea clrTextarea [(ngModel)]="description"  
                     [formControl]="itemForm.controls.description"  
                     name="description" required rows="4" cols="80"></textarea>
                    </clr-textarea-container>
                </div>
            </div>



            <div class="clr-row form-group">
                <div class="clr-col-1 col-title">
                         <label>Status</label>
                </div>
                <div class="clr-col-2">

                        <clr-select-container>
                            <select clrSelect id="status_id" class="ticket-select" *ngIf="ticketStatuses" [formControl]="itemForm.controls.status_id">
                                <option *ngFor="let ticketStatus of ticketStatuses" [value]="ticketStatus.id">
                                 {{ticketStatus.name}}
                                </option>
                            </select>
                        </clr-select-container>

                </div>
            </div>


            <div class="clr-row form-group">
                <div class="clr-col-1 col-title">
                         <label>Priority</label>
                </div>
                <div class="clr-col-2">
                        <clr-select-container>
                            <select clrSelect id="priority_id" class="ticket-select" *ngIf="ticketPriorities" [formControl]="itemForm.controls.priority_id">
                                <option *ngFor="let ticketPriority of ticketPriorities" [value]="ticketPriority.id">
                                 {{ticketPriority.name}}
                                </option>
                            </select>
                        </clr-select-container>
                </div>
            </div>

            <div class="clr-row form-group">
                <div class="clr-col-1 col-title">
                         <label>Type</label>
                </div>
                <div class="clr-col-2">
                        <clr-select-container>
                            <select clrSelect id="type_id" class="ticket-select" *ngIf="ticketTypes" [formControl]="itemForm.controls.type_id">
                                <option *ngFor="let ticketType of ticketTypes" [value]="ticketType.id">
                                 {{ticketType.name}}
                                </option>
                            </select>
                        </clr-select-container>
                </div>
            </div>

            <div class="clr-row form-group row">
                <div class="clr-col-6">
                </div>
                <div class="clr-col-1">
                     <button class="btn btn-primary update-button" [disabled]="!itemForm.dirty || itemForm.invalid"
                            (click)="update()">{{(item.id > 0) ? 'Update' : 'Save'}}
                    </button>
                </div>
            </div>
        </div>
        <div class="spacer">&nbsp;</div>
        <div *ngIf="item">
            <h5>Notes</h5>
            <br/>
            <div class="row">
                <div class="col-md-12">
                    <div *ngFor="let ticket_note of item.notes; index as i" class="form-group row note-container">
                               

                           <div class="clr-row form-group">
                                <div class="clr-col-2 adjust-left col-title">

                                     <label>From:</label>

                                </div>

                                <div class="clr-col-10 non-editable">
                                     <span>{{ticket_note.user}} ({{ticket_note.user_email}})</span>
                                </div>

                           </div>
                          

                           <div class="clr-row form-group">
                                <div class="clr-col-2 adjust-left col-title">

                                     <label>Sent to:</label>

                                </div>

                                <div class="clr-col-10 non-editable">
                                     <span><b>{{ticket_note.sent_users.toString()}}</b> on <b>{{ticket_note.sent_at}}</b></span>
                                </div>
                           </div>

                           <div class="clr-row form-group">
                                <div class="clr-col-2 adjust-left col-title">

                                     <label>Subject:</label>

                                </div>

                                <div class="clr-col-10 non-editable">
                                     <span>{{ticket_note.subject}}</span>
                                </div>

                           </div>

                           <div class="clr-row form-group">
                                <div class="clr-col-2 adjust-left col-title">

                                     <label>Note:</label>

                                </div>

                                <div class="clr-col-10 non-editable adjust-bottom-description">

                                     <clr-textarea-container>
                                       <textarea clrTextarea disabled 
                                           name="description" required rows="4" cols="80">{{ticket_note.description}} </textarea>
                                     </clr-textarea-container>


                                </div>

                           </div>

                    </div>


                    <div>
                          <form [formGroup]="noteForm" class="form-group row note-container note-new">
                                <div class="clr-row form-group">
                                    <div class="clr-col-2 adjust-left col-title">
                                            <label>From:</label>
                                    </div>
                                    <div class="clr-col-10 non-editable">
                                          <span> {{ username }}</span>
                                    </div>
                                </div>
                                <div class="clr-row form-group row">
                                    <div class="clr-col-2 adjust-left col-title">
                                            <label>Send to:</label>
                                    </div>
                                    <div class="clr-col-10 non-editable">
                                        <b>{{ assignedUsers() }}</b>
                                    </div>
                                </div>
                                <div class="clr-row form-group row"> 
                                    <div class="clr-col-2 adjust-left col-title">
                                            <label>Subject:</label>
                                    </div>
                                    <div class="clr-col-10 non-editable subject-input">

                                        <clr-input-container>
                                             <input clrInput id="send-subject" type="text" [formControl]="noteForm.controls.subject" name="subject"  size="30">
                                             <clr-control-error *clrIfError="'minlength'">Subject must be at least 3 characters</clr-control-error>
                                             <clr-control-error *clrIfError="'required'">Subject Is Required</clr-control-error>
                                       </clr-input-container>
                                    </div>
                                </div>



                               <div class="clr-row form-group row">
                                    <div class="clr-col-2 adjust-left col-title">

                                            <label for="description">Description:</label>
                                    </div>
                                    <div class="clr-col-10">
                                        <clr-textarea-container>
                                          <textarea clrTextarea [(ngModel)]="description"  
                                             [formControl]="noteForm.controls.description"  
                                             name="description" required rows="4" cols="80"></textarea>
                                        </clr-textarea-container>
                                    </div>
                                </div>



                                <div class="clr-row form-group row">
                                    <div class="clr-col-9">
                                       &nbsp;
                                    </div>
                                    <div class="clr-col-1 move-left-send">
                                        <button class="btn btn-primary update-button" [disabled]="noteForm.invalid"
                                           (click)=sendNote()>Send
                                        </button>
                                    </div> 
                                </div>
                          </form>

                    </div>

                </div>
            </div>
        </div>
    </form>
</div>

