import * as hightCharts from 'highcharts';
import { highChartsBase } from './highcharts-base';

export const highChartsHistogramChart = function(name, records, labels) {
    let threshold_guide_items = [];
    let start_yaxis_on_min = 0;
    let last_calculated_yaxis_min = null;
    let first_reset_triggered = false;
    return Object.assign({
            title: { text: name, style: { fontWeight: 'bold' } },

    chart: {
            renderTo:'container',
            defaultSeriesType:'column',
            borderWidth:0,
            backgroundColor:'#FFF',
            borderWidth:1,
            borderColor:'#FFF',
            plotBackgroundColor:'#fff',
            plotBorderWidth:1,
            plotBorderColor:'#FFF'
        },
        credits:{enabled:false},
        exporting:{enabled:false},
        title:{text: name},
        boost: {
            enabled: true,
            useGPUTranslations: true,
        },
        legend:{
            //enabled:false
        },
        accessibility: {
                point: {
                    valueSuffix: ''
                }
        },
        tooltip:{
            borderWidth:1,
            formatter:function() {
                return '<b>Range:</b><br/> '+ this.x +'<br/>'+
                '<b>Count:</b> '+ this.y;
            }
        },
        plotOptions:{
            column:{
                shadow:false,
                borderWidth:.5,
                borderColor:'#FFFFFF',
                pointPadding:0,
                groupPadding:0,
                color: '#7BB4EC'
            },
            spline:{
                shadow:false,
                marker:{
                    radius:1
                }
            },
            areaspline:{
                color:'#7BB4EC',
          //      fillColor:'#7BB4EC',
                shadow:false,
                marker:{
                    radius:1
                }
            },
            series: {
                 // general options for all series
                    animation: false,
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        style: { fontWeight: 'normal' },
                        enabled: true,
                    },
                    colors: [
                        '#2b908f',
                        '#90ee7e',
                        '#f45b5b',
                        '#7798BF',
                        '#aaeeee',
                        '#ff0066',
                        '#eeaaee',
                        '#55BF3B',
                        '#DF5353',
                        '#7798BF',
                        '#aaeeee'
                    ],
                    showInLegend: true
            },
            histogram: {
                    animation: false,
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        style: { fontWeight: 'normal' },
                        enabled: true,
                    },
                    colors: [
                        '#2b908f',
                        '#90ee7e',
                        '#f45b5b',
                        '#7798BF',
                        '#aaeeee',
                        '#ff0066',
                        '#eeaaee',
                        '#55BF3B',
                        '#DF5353',
                        '#7798BF',
                        '#aaeeee'
                    ],
                    showInLegend: true                
            }
        },
        xAxis:{
            categories: labels,
            labels:{
                rotation:-90,
                y:40,
                style: {
                    fontSize:'8px',
                    fontWeight:'normal',
                    color:'#333'
                },
            },
            lineWidth:0,
            lineColor:'#999',
            tickLength:70,
            tickColor:'#ccc',
        },
        yAxis:{
            title:{text:''},
            //maxPadding:0,
            gridLineColor:'#e9e9e9',
            tickWidth:1,
            tickLength:3,
            tickColor:'#ccc',
            lineColor:'#ccc',
            tickInterval:25,
            //endOnTick:false,
        },
        series: [{
            name:`${name} - Histogram`,
            data: records,
        },{
            name:`${name} - Curve`,
            type:'spline',
            visible:false,
            data: records,
            //color: 'rgba(204,204,255,.85)'
        },{
            name:`${name} - Filled Curve`,
            type:'areaspline',
            visible:false,
            data: records,
            //color: 'rgba(204,204,255,.85)'
        }]


            },
            highChartsBase
        );
};
