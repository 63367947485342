<clr-modal [(clrModalOpen)]="openUserPreferences" [clrModalSize]="'md'">
<h3 class="modal-title">User Preferences</h3>
    <div class="modal-body">
        <form [formGroup]="itemForm">
    <section class="form-block">



            <div class="clr-row form-group row">
                     <div class="clr-col-3">
                         <clr-input-container>
                             <label>First Name</label>
                         </clr-input-container>

                     </div>

                     <div class="clr-col-4">

                             <clr-input-container>


                             <input clrInput id="first_name" type="text" [(ngModel)]="first_name"   [formControl]="itemForm.controls.first_name" name="first_name"  size="41"/>


                             <clr-control-error *clrIfError="'minlength'">First Name must be at least 3 characters</clr-control-error>
                             <clr-control-error *clrIfError="'required'">First Name Is Required</clr-control-error>
                             </clr-input-container>
 
                      </div>

             </div>

            <div class="clr-row form-group row">
                     <div class="clr-col-3">
                         <clr-input-container>
                             <label>Last Name</label>
                         </clr-input-container>

                     </div>

                     <div class="clr-col-4">
                             <clr-input-container>
                             <input clrInput id="last_name" type="text" [(ngModel)]="last_name"   [formControl]="itemForm.controls.last_name" name="last_name"  size="41"/>
                             <clr-control-error *clrIfError="'minlength'">First Name must be at least 3 characters</clr-control-error>
                             <clr-control-error *clrIfError="'required'">First Name Is Required</clr-control-error>
                             </clr-input-container>
                      </div>
             </div>

            <div class="clr-row form-group row">
                     <div class="clr-col-3">
                         <clr-input-container>
                             <label>Username</label>
                         </clr-input-container>

                     </div>

                     <div class="clr-col-4">
                     <clr-input-container>
                        <input clrInput type="email" [(ngModel)]="username" [email]="true" formControlName="username" name="username" size="61" (ngModelChange)="updateUsername($event)"  [formControl]="itemForm.controls.username"  />
                        <clr-control-error *clrIfError="'minlength'">Username Must be at least 3 characters</clr-control-error>
                        <clr-control-error *clrIfError="'required'">Username Is Required</clr-control-error>
                        <clr-control-error *clrIfError="'pattern'">Invalid Email Format</clr-control-error>
                        <clr-control-error *clrIfError="'nameExists'">This Username Is Already Used</clr-control-error>
                     </clr-input-container>
                    </div>
             </div>

            <div class="clr-row form-group row">
                     <div class="clr-col-3">
                         <clr-input-container>
                             <label>Password</label>
                         </clr-input-container>

                     </div>

                     <div class="clr-col-4">
                             <clr-password-container>
                             <input clrPassword id="password" type="text" [formControl]="itemForm.controls.password" name="password"  size="41"/>
                             <clr-control-error *clrIfError="'minlength'">Password must contain at least 8 characters and one number</clr-control-error>
                             <clr-control-error *clrIfError="'required'">Password is required!</clr-control-error>
                             </clr-password-container>
                      </div>
             </div>

            <div class="clr-row form-group row">
                     <div class="clr-col-3">
                         <clr-input-container>
                             <label>Confirm</label>
                         </clr-input-container>

                     </div>

                     <div class="clr-col-4">
                             <clr-password-container>
                             <input clrPassword id="password_confirm" type="text" [formControl]="itemForm.controls.password_confirm" name="password_confirm"  size="41"/>
                             <clr-control-error *clrIfError="'minlength'">Password must contain at least 8 characters and one number</clr-control-error>
                             <clr-control-error *clrIfError="'required'">Password Is Required</clr-control-error>
                             <clr-control-error *clrIfError="'passwordMismatch'">Passwords must match!</clr-control-error>
                             </clr-password-container>
                      </div>
             </div>


    </section>

</form>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)=close()>Close</button>
        <button type="button" class="btn btn-primary" [disabled]="!itemForm.dirty || !itemForm.valid" (click)=update()>Update</button>
    </div>
</clr-modal>

