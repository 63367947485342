import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../state/nav.state';

@Injectable()
export class NavService {
  private _nav: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly nav: Observable<boolean> = this._nav.asObservable();
  public current: BehaviorSubject<boolean>;

  constructor(private _store: Store<{ ui: State }>) {}

  getNav(): BehaviorSubject<boolean> {
    return this._nav;
  }

  updateNav(nav: boolean): void {
    this._nav.next(nav);
    var map = new Map();
    map['on'] = { type: 'SIDEBAR_ON' };
    map['off'] = { type: 'SIDEBAR_OFF' };
    if (nav) {
      this._store.dispatch(map['on']);
    } else {
      this._store.dispatch(map['off']);
    }
  }

  getCurrent(): BehaviorSubject<boolean> {
    return this.current;
  }
}
