import { Injectable } from '@angular/core';
import { BaseService } from '@core/services/base.service';
import { BehaviorSubject } from 'rxjs';
import { constants } from '@core/constants';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { IDataView, IDataViews } from '@shared/interfaces/data-views';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { EventsService } from '@modules/events/services/events.service';

const initialDataViewsData = [] as IDataView[];
const initialDataViewData = {} as IDataView;

@Injectable({
  providedIn: 'root'
})
export class DataViewService extends BaseService {
  private dataViewsDataSource = new BehaviorSubject<IDataView[]>(
    initialDataViewsData
  );
  private dataViewsURL = constants.api.dataView.data_view_api;
  private dataViewCountSource = new BehaviorSubject<number>(null);
  public dataViews = this.dataViewsDataSource.asObservable();
  public dataViewCount = this.dataViewCountSource.asObservable();
  private dataViewDataSource = new BehaviorSubject<IDataView>(
    initialDataViewData
  );
  public dataView = this.dataViewDataSource.asObservable();

  constructor(
    protected eventsService: EventsService,
    protected http: HttpClient
  ) {
    super(eventsService, http);
  }

  getDataViews(filters: any) {
    return this.getMany(constants.api.dataView.data_view_api, filters).pipe(
      map((data: IDataViews) => {
        this.dataViewsDataSource.next(data.item_list);
        this.dataViewCountSource.next(data.item_count);
        return data;
      }),
      catchError(error => this.handleError(error))
    );
  }

  getUserDataViews(filters: any) {
    return this.getMany(constants.api.dataView.data_view_api, filters).pipe(
      map((data: IDataViews) => {
        this.dataViewsDataSource.next(data.item_list);
        this.dataViewCountSource.next(data.item_count);
        return data;
      }),
      catchError(error => this.handleError(error))
    );
  }

  resetDataViews(v: any) {
    this.dataViewsDataSource.next(v);
    this.dataViewCountSource.next(v.length);
  }

  getAllDataViews() {
    return this.getAll(constants.api.dataView.data_view_api).pipe(
      map((data: IDataViews) => {
        this.dataViewsDataSource.next(data.item_list);
        this.dataViewCountSource.next(data.item_count);
        return data;
      }),
      catchError(error => this.handleError(error))
    );
  }
  clearDataViews() {
    this.dataViewsDataSource.next(null);
    this.dataViewCountSource.next(null);
  }

  getDataView(id: number) {
    if (id === 0) {
      const data = { id: 0 } as IDataView;
      this.dataViewDataSource.next(data);
      return data;
    }
    return this.getOne(constants.api.dataView.data_view_api, id).pipe(
      map(
        (data: { item: IDataView }) => {
          return data;
          //this.dataViewDataSource.next(data.item);
        },
        catchError(error => this.handleError(error))
      )
    );
  }

  getDataViewFull(id: number) {
    return this.getOne(constants.api.dataView.data_view_full_api, id).pipe(
      map(
        (data: { item: IDataView }) => {
          return data;
          //this.dataViewDataSource.next(data.item);
        },
        catchError(error => this.handleError(error))
      )
    );
  }
  // Service with custom transform
  getAllDataViewsAsOptions() {
    return this.getAllAsOptions(
      `${constants.api.dataView.data_view_api}?as_options`,
      {
        name: 'name',
        id: 'id',
        user_name: 'user_name',
        user_id: 'user_id',
        company_name: 'company_name',
        company_id: 'company_id'
      }
    );
  }

  updateDataView(dataView: IDataView) {
    return this.updateOne(constants.api.dataView.data_view_api, dataView).pipe(
      map(data => {
        this.handleSuccess(data);
        return data;
      }),
      catchError(error => this.handleError(error))
    );
  }

  deleteDataView(id: number) {
    return this.deleteOne(constants.api.dataView.data_view_api, id).pipe(
      map(data => {
        this.handleSuccess(data);
        return data;
      }),
      catchError(error => this.handleError(error))
    );
  }

  saveDataView(dataView: IDataView) {
    return this.saveOne(constants.api.dataView.data_view_api, dataView).pipe(
      map(data => {
        this.handleSuccess(data);
        return data;
      }),
      catchError(error => this.handleError(error))
    );
  }

  saveDataPointToDataView(data_point) {
    return this.saveOne(
      constants.api.data.data_view.data_point.add_api_url,
      data_point
    );
  }

  deleteDataPointFromDataView(data_point) {
    return this.saveOne(
      constants.api.data.data_view.data_point.remove_api_url,
      data_point
    );
  }

  copyDataViewToUser(payload) {
    return this.saveOne(
      constants.api.data.data_view.copy_to_user_api_url,
      payload
    );
  }

  copyDataPointToUser(payload) {
    return this.saveOne(constants.api.dataPoint.copy_to_user_api, payload);
  }

  getDateRangeData(id: number, from: any, to: any) {
    let data_url = 'data/' + id + '/' + from + '/' + to;
    return this.http.get(data_url).pipe(
      map(data => {
        this.handleSuccess(data);
        return data;
      }),
      catchError(error => this.handleError(error))
    );
  }
}

@Injectable({
  providedIn: 'root'
})
export class DataViewResolverService implements Resolve<any> {
  constructor(private dataViewService: DataViewService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.dataViewService.getDataView(+route.params.id);
  }
}
