import {
  AfterViewInit,
  Component,
  ViewEncapsulation,
  Input,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  Renderer2,
  HostListener
} from '@angular/core';
import { Subscription } from 'rxjs';
import { D3Service } from '@shared/services/d3/d3.service';
import { ScriptService } from '@shared/services/script/script.service';
import { Router } from '@angular/router';
import * as d3 from 'd3';
import * as d3Scale from 'd3-scale';
import * as d3Array from 'd3-array';
import * as d3Axis from 'd3-axis';
import * as THREE from 'three';
import * as TWEEN from '@tweenjs/tween.js';
import * as _ from 'lodash';
import { InteractionManager } from 'three.interactive';
import { Chart } from './utils/chart';
import { ClusteredChart } from './utils/clustered-chart';
import { EventsService } from '@modules/events/services/events.service';
import { ThreeChartService } from '@shared/services/three-chart/three-chart.service';
import { DataViewService } from '@core/services/data-view/data-view.service';
import { Store } from '@ngrx/store';
import { EventsState } from '@modules/events/states/events.state';
import { deepCopy } from '@shared/utils/storage/encrypted_storage';
import { jsPDF } from 'jspdf';
import { get_item, set_item } from '@shared/utils/storage/encrypted_storage';

@Component({
  selector: 'app-three-chart',
  templateUrl: './three-chart.component.html',
  styleUrls: ['./three-chart.component.scss']
})
export class ThreeChartComponent implements OnInit, OnDestroy {
  protected eventSubs: Subscription;
  public hideLabels: boolean = false;
  private clustered: ClusteredChart = null;
  private errorMessage: string;
  public currentClass: string = '';
  public currentId: string = '';
  public hash: any;
  public user_id: number = 0;
  public indexes: any[] = [];
  public keys: any[] = [];
  public units: any[] = [];
  public zIndex: number = 0;
  public yIndex: number = 0;
  public xIndex: number = 0;
  public x: number = 0;
  public y: number = 0;
  public z: number = 0;
  public clusterCharts: any = {};
  public zAxis: number[] = [];
  public origX: any[] = [];
  public origY: any[] = [];
  public origZ: any[] = [];
  public labels: any = {};
  public xStart: number = 0;
  public xEnd: number = 0;
  public yStart: number = 0;
  public yEnd: number = 0;
  public zStart: number = 0;
  public zEnd: number = 0;
  public width: number = 0;
  public height: number = 0;
  public xLabel: string = '';
  public yLabel: string = '';
  public zLabel: string = '';
  public raycaster: any;
  public camera: any;
  public scene: any;
  public renderer: any;
  public result: any;
  public orig_result: any;
  public unit_names: any = {};
  public data: any;
  public orig_data: any;
  public dimensions: any;
  public options: any;
  public coordinates: any = {};
  public colors = [
    '#313695',
    '#4575b4',
    '#74add1',
    '#abd9e9',
    '#e0f3f8',
    '#ffffbf',
    '#fee090',
    '#fdae61',
    '#f46d43',
    '#d73027',
    '#a50026'
  ];

  @Input('canvasDimensions') canvasDimensions?: any;
  @Input('data_view_id') data_view_id: number;
  @Input('item') item: any;

  constructor(
    protected threeChartService: ThreeChartService,
    protected eventsService: EventsService,
    protected dataViewService: DataViewService,
    public eventStore: Store<{ nf: EventsState }>,
    protected router: Router
  ) {
    this.hash = this.genRand();
    if (typeof this.dimensions === 'undefined' || this.dimensions === null) {
      this.dimensions = {
        minY: 0,
        maxY: 9,
        minX: 0,
        maxX: 14,
        minZ: 0,
        maxZ: 9
      };
    }
    if (typeof this.options === 'undefined' || this.options === null) {
      this.options = {
        yGrid: 10,
        gridSize: 3,
        axis: {
          x: {
            from: this.xStart,
            to: this.xEnd,
            gridValue: 1,
            interval: 0
          },
          y: {
            from: this.yStart,
            to: this.yEnd,
            gridValue: 1,
            interval: 1
          },
          z: {
            from: this.zStart,
            to: this.yEnd,
            gridValue: 1,
            interval: 1
          }
        },
        colors: this.colors
      };
    }
  }

  ngOnInit(): void {
    let width = 1000;
    let height = 500;
    // this.zScaleSize();
    set_item('visited3d', true);

    if (
      typeof this.canvasDimensions !== 'undefined' &&
      this.canvasDimensions !== null
    ) {
      width = this.canvasDimensions.width;
      height = this.canvasDimensions.height;
      this.width = width;
      this.height = height;
    }
    if (this.canvasDimensions.width < 500) {
      this.hideLabels = true;
      this.eventsService.publish('hideHeaderLabels', 'hideHeaderLabels', {
        hideHeaderLabels: true,
        data_view_id: this.data_view_id
      });
    }
    this.currentId = this.getId();
    this.readData();
    this.subscribeEvents();
  }

  readData(): void {
    this.threeChartService.readView(this.data_view_id).subscribe(
      (data: any) => {
        this.onDataRead(data);
      },
      (error: any) => {}
    );
  }

  onDataRead(d: any): void {
    this.data = d.data;
    this.orig_data = d.data;
    this.keys = d.keys;
    this.units = d.units;
    this.origX = d.x;
    this.origY = d.y;
    this.origZ = d.z;
    this.zAxis = d.z;
    this.unit_names = d.unit_names;
    this.xIndex = d.xIndex;
    this.yIndex = d.yIndex;
    this.zIndex = d.zIndex;
    this.result = d.result;
    this.orig_result = d.result;
    this.yLabel = d.name_y;
    this.xLabel = d.name_x;
    this.zLabel = d.name_z;
    this.labels = {
      xLabel: this.xLabel,
      yLabel: this.yLabel,
      zLabel: this.zLabel
    };
    this.x = d.x.reverse();
    this.y = d.y;
    this.z = d.z;
    this.drawChart();
  }

  redrawChart(): void {
    this.labels = {
      xLabel: this.xLabel,
      yLabel: this.yLabel,
      zLabel: this.zLabel
    };
    let cl: any = new ClusteredChart(
      `clustered${this.data_view_id}${this.hash}`,
      this.keys,
      this.units,
      this.unit_names,
      this.result,
      this.zAxis,
      this.width,
      this.height - 50,
      this.eventsService,
      this.labels,
      this.currentId
    );
    this.clustered = cl;
    this.clustered._camera.position.set(
      -6.058788142688726,
      3.0186596630514573,
      -0.010744810851141382
    );
    this.clustered._render();
    this.zoomInFunction();
    this.zoomInFunction();
    this.moveDown();
    this.moveDown();
    this.moveRight();
    this.moveDown();
    this.moveRight();
    let payload: any = {};
    payload[this.xLabel] = this.x;
    payload[this.yLabel] = this.y;
    payload[this.zLabel] = this.z;
    this.xLabel =
      this.xLabel[0].toUpperCase() + this.xLabel.slice(1, this.xLabel.length);
    this.yLabel =
      this.yLabel[0].toUpperCase() + this.yLabel.slice(1, this.yLabel.length);
    this.zLabel =
      this.zLabel[0].toUpperCase() + this.zLabel.slice(1, this.zLabel.length);
    let axisX = { id: 1, name: this.xLabel };
    let axisY = { id: 2, name: this.yLabel };
    let axisZ = { id: 3, name: this.zLabel };

    let items = [axisX, axisY, axisZ];
    payload['items'] = items; //.slice(1, items.length);
    payload['data_view_id'] = this.data_view_id;
    this.eventsService.publish('postAxes', 'postAxes', payload);
    this.eventsService.publish('postAxisValues', 'postAxisValues', {
      data_view_id: this.data_view_id
    });
  }

  drawChart(): void {
    let width = 1000;
    let height = 470;
    // this.result = [];
    this.clustered = new ClusteredChart(
      `clustered${this.data_view_id}${this.hash}`,
      this.keys,
      this.units,
      this.unit_names,
      this.result,
      this.zAxis,
      this.width,
      this.height - 50,
      this.eventsService,
      this.labels,
      this.currentId
    );
    this.eventsService.publish('postAxisValues', 'postAxisValues', {
      data_view_id: this.data_view_id
    });
    this.clustered._camera.position.set(
      -6.058788142688726,
      3.0186596630514573,
      -0.010744810851141382
    );
    this.clustered._render();
    this.zoomInFunction();
    this.zoomInFunction();
    this.moveDown();

    if (this.item.settings.cols <= 3) {
      this.zoomOutFunction();
      this.zoomOutFunction();
      this.zoomOutFunction();
      this.zoomOutFunction();
      this.zoomOutFunction();
      this.zoomOutFunction();
    }
    /*
    this.moveDown();
    this.moveRight();  
    this.moveDown();
    this.moveRight();
*/

    let payload: any = {};
    payload[this.xLabel] = this.origX.reverse();
    payload[this.yLabel] = this.y;
    payload[this.zLabel] = this.z;
    this.xLabel =
      this.xLabel[0].toUpperCase() + this.xLabel.slice(1, this.xLabel.length);
    this.yLabel =
      this.yLabel[0].toUpperCase() + this.yLabel.slice(1, this.yLabel.length);
    this.zLabel =
      this.zLabel[0].toUpperCase() + this.zLabel.slice(1, this.zLabel.length);
    let axisX = { id: 1, name: this.xLabel };
    let axisY = { id: 2, name: this.yLabel };
    let axisZ = { id: 3, name: this.zLabel };
    let items = [axisX, axisY, axisZ];

    payload['items'] = items;
    payload['data_view_id'] = this.data_view_id;
    this.eventsService.publish('postAxes', 'postAxes', payload);
  }

  ngOnDestroy(): void {
    this.clearChart();
    if (typeof this.clustered !== 'undefined' && this.clustered !== null) {
      if (
        typeof this.clustered._renderer !== 'undefined' &&
        this.clustered._renderer !== null
      ) {
        this.clustered._renderer.dispose();

        const cleanMaterial = material => {
          console.log('dispose material!');
          material.dispose();
          this.clustered._renderer.renderLists.dispose();

          // dispose textures
          for (const key of Object.keys(material)) {
            const value = material[key];
            if (value && typeof value === 'object' && 'minFilter' in value) {
              console.log('dispose texture!');
              value.dispose();
            }
          }
        };

        this.clustered._scene.traverse(object => {
          if (!object.isMesh) return;

          console.log('dispose geometry!');
          object.geometry.dispose();

          if (object.material.isMaterial) {
            cleanMaterial(object.material);
          } else {
            // an array of materials
            for (const material of object.material) cleanMaterial(material);
          }
        });
        this.clustered._controls.reset();
        this.clustered.three.Cache.clear();
        //  this.clustered.three.clear();
      }
    }
    if (typeof this.eventSubs !== 'undefined' && this.eventSubs === null) {
      this.eventSubs.unsubscribe();
    }
    this.reloadPage();
  }

  readDataView(payload: any): void {
    this.dataViewService
      .getDataView(payload.item.data_view_id)
      .subscribe((item: any) => {
        this.updateDataView(
          item.item,
          payload.time_domain_id,
          payload.time_domain_interval_id,
          payload.user_id
        );
      });
  }

  subscribeEvents(): void {
    this.eventSubs = this.eventStore.select('nf').subscribe(event => {
      if (event.items.id === 'resizeCanvasOnResizeStop') {
        let data_view_id = event.items.payload.item.data_view_id;
        if (data_view_id === this.data_view_id) {
          if (
            typeof event.items.payload.item.settings.cols !== 'undefined' &&
            typeof event.items.payload.item.settings.rows !== 'undefined'
          ) {
            this.canvasDimensions = {
              width: 220 * parseInt(event.items.payload.item.settings.cols),
              height: 120 * parseInt(event.items.payload.item.settings.rows)
            };
            if (
              typeof this.clustered !== 'undefined' &&
              this.clustered !== null
            ) {
              this.clustered.width =
                220 * parseInt(event.items.payload.item.settings.cols);
              this.clustered.height =
                140 * parseInt(event.items.payload.item.settings.rows);
            }
            let width = this.canvasDimensions.width;
            let height = this.canvasDimensions.height;
            this.resizeCanvas(width, height);
          }
        }
      }
      if (event.items.id === 'refresh3DPeriod') {
        if (event.items.payload.data_view_id === this.data_view_id) {
          let data_view_id = event.items.payload.data_view_id;
          this.readDataView(event.items.payload);
        }
      }
      if (event.items.id === 'xScaleChart') {
        if (event.items.payload.data_view_id === this.data_view_id) {
          console.log(`X Scale`);
          this.zScaleX();
        }
      }
      if (event.items.id === 'sizeScaleChart') {
        if (event.items.payload.data_view_id === this.data_view_id) {
          console.log(`Size Scale`);
          this.zScaleSize();
        }
      }
      if (event.items.id === 'zScaleChart') {
        if (event.items.payload.data_view_id === this.data_view_id) {
          console.log(`Z Scale`);
          this.zScaleZ();
        }
      }
      if (event.items.id === 'resizeChart') {
        if (event.items.payload.data_view_id === this.data_view_id) {
          let data_view_id = event.items.payload.data_view_id;

          console.log(`Resize Chart ${JSON.stringify(event.items)}`);
          this.resizeCanvas(
            event.items.payload.width,
            event.items.payload.height
          );
        }
      }
      if (event.items.id === 'sliceChart') {
        if (event.items.payload.data_view_id === this.data_view_id) {
          this.result = this.orig_result;
          this.slice(
            event.items.payload.axis.id,
            event.items.payload.axis.start.index,
            event.items.payload.axis.end.index
          );
        }
      }
      if (event.items.id === 'transformTo2DChart') {
        if (event.items.payload.data_view_id === this.data_view_id) {
          this.toTwoD();
        }
      }
      if (event.items.id === 'transformTo3DChart') {
        if (event.items.payload.data_view_id === this.data_view_id) {
          this.toThreeD();
        }
      }
      if (event.items.id === 'zoomChart') {
        if (event.items.payload.data_view_id === this.data_view_id) {
          console.log(`Zoom chart ${JSON.stringify(event.items)}`);
          if (event.items.payload.direction === 'in') {
            console.log(`Will zoom in`);
            this.zoomInFunction();
          }
          if (event.items.payload.direction === 'out') {
            console.log(`Will zoom out`);
            this.zoomOutFunction();
          }
        }
      }
      if (event.items.id === 'repositionChart') {
        if (event.items.payload.data_view_id === this.data_view_id) {
          console.log(`Reposition chart ${JSON.stringify(event.items)}`);
          if (event.items.payload.direction === 'left') {
            console.log(`Will move left`);
            this.moveLeft();
          }
          if (event.items.payload.direction === 'right') {
            console.log(`Will move right`);
            this.moveRight();
          }
          if (event.items.payload.direction === 'up') {
            console.log(`Will move up`);
            this.moveUp();
          }
          if (event.items.payload.direction === 'down') {
            console.log(`Will move down`);
            this.moveDown();
          }
        }
      }
      if (event.items.id === 'chartChanged') {
        this.onChartChanged();
      }
      if (event.items.id === 'resetChart') {
        if (event.items.payload.data_view_id === this.data_view_id) {
          console.log(`Reset chart ${JSON.stringify(event.items)}`);
          this.resetChart();
        }
      }

      if (event.items.id === 'exportAsPNG') {
        this.exportAsPNG();
      }

      if (event.items.id === 'exportAsPDF') {
        this.exportAsPDF();
      }
      /*
      if (event.items.id === 'rotateChart') {
          console.log(`Rotate chart ${JSON.stringify(event.items)}`);
      }
    
*/
    });
  }

  clearChart(): void {
    this.data = this.orig_data;
    if (typeof this.data !== 'undefined') {
      if (typeof this.data.item !== 'undefined') {
        if (typeof this.data.item.collection !== 'undefined') {
          this.data.item.collection[0].data = this.origX;
          this.data.item.collection[1].data = this.origY;
          this.data.item.collection[2].data = this.origZ;

          let elem = document.getElementById(
            `clustered${this.data_view_id}${this.hash}`
          );
          this.result = this.getThreeDimData(this.data).result;
          if (
            typeof this.clustered !== 'undefined' &&
            this.clustered !== null
          ) {
            this.clustered.disposeNode(this.clustered._scene);
          }
          if (typeof elem !== 'undefined' && elem !== null) {
            elem.innerHTML = '';
          }
        }
      }
    }
  }

  resetChart(): void {
    // this.data = this.orig_data;
    //  this.data.item.collection[0].data = this.origX;
    //  this.data.item.collection[1].data = this.origY;
    //  this.data.item.collection[2].data = this.origZ;
    //this.result = this.orig_result;

    let elem = document.getElementById(
      `clustered${this.data_view_id}${this.hash}`
    );
    this.result = this.getThreeDimData(this.data).result;
    if (typeof this.clustered !== 'undefined' && this.clustered !== null) {
      this.clustered.disposeNode(this.clustered._scene);
    }
    elem.innerHTML = '';
    //    this.redrawChart();
    this.readData();
  }

  toTwoD(): void {
    let dat: any[] = [];
    if (typeof this.data === 'undefined') {
      //      this.data = this.orig_data;
    }
    if (typeof this.clustered !== 'undefined' && this.clustered !== null) {
      this.clustered.move2D();
      this.clustered.camera.updateProjectionMatrix();
      this.clustered._render();
    }
  }

  toThreeD(): void {
    if (typeof this.clustered !== 'undefined' && this.clustered !== null) {
      this.clustered.move3D();
      this.clustered.camera.updateProjectionMatrix();
      this.clustered._render();
    }
  }

  moveUp(): void {
    if (typeof this.clustered !== 'undefined' && this.clustered !== null) {
      this.clustered.moveUp(1);
      this.clustered.camera.updateProjectionMatrix();
      this.clustered._render();
    }
  }

  moveDown(): void {
    if (typeof this.clustered !== 'undefined' && this.clustered !== null) {
      this.clustered.moveDown(1);
      this.clustered.camera.updateProjectionMatrix();
      this.clustered._render();
    }
  }

  moveLeft(): void {
    if (typeof this.clustered !== 'undefined' && this.clustered !== null) {
      this.clustered.moveLeft(1);
      this.clustered.camera.updateProjectionMatrix();
      this.clustered._render();
    }
  }

  moveRight(): void {
    if (typeof this.clustered !== 'undefined' && this.clustered !== null) {
      this.clustered.moveRight(1);
      this.clustered.camera.updateProjectionMatrix();
      this.clustered._render();
    }
  }

  resizeCanvas(width, height): void {
    if (typeof this.clustered !== 'undefined' && this.clustered !== null) {
      this.clustered.camera.updateProjectionMatrix();
      this.clustered.renderer.setSize(width, height);
    }
  }

  getFov(): any {
    return Math.floor(
      (2 *
        Math.atan(
          this.clustered.camera.getFilmHeight() /
            2 /
            this.clustered.camera.getFocalLength()
        ) *
        180) /
        Math.PI
    );
  }

  zoomInFunction() {
    const fov = this.getFov();
    if (typeof this.clustered !== 'undefined' && this.clustered !== null) {
      this.clustered.camera.fov = this.clickZoom(fov, 'zoomIn');
      this.clustered.camera.updateProjectionMatrix();
      this.clustered._render();
    }
  }

  zoomOutFunction() {
    const fov = this.getFov();
    if (typeof this.clustered !== 'undefined' && this.clustered !== null) {
      this.clustered.camera.fov = this.clickZoom(fov, 'zoomOut');
      this.clustered.camera.updateProjectionMatrix();
      this.clustered._render();
    }
  }

  clickZoom(value: any, zoomType: any): any {
    if (value >= 20 && zoomType === 'zoomIn') {
      return value - 5;
    } else if (zoomType === 'zoomOut') {
      return value + 5;
    } else {
      return value;
    }
  }

  onChartChanged(): void {}

  zScaleX(): void {}

  zScaleSize(): void {
    this.data = this.data.sort(function(a, b) {
      return a.quarter - b.quarter;
    });
    console.log(JSON.stringify(this.data));
    //   this.clustered._render();
  }

  zScaleZ(): void {}

  slice(id: number, start_index: number, end_index: number): void {
    if (id === 1) {
      let origX = this.origX;
      end_index++;
      let result_axis = [];

      for (let i = start_index; i <= end_index; i++) {
        result_axis.push(this.origX[i]);
      }

      let elem = document.getElementById(
        `clustered${this.data_view_id}${this.hash}`
      );
      //   this.result = this.orig_result;

      let total_chunks = result_axis.length * this.origY.length;

      this.result = this.readChunkedData(
        id,
        total_chunks,
        start_index,
        end_index
      );

      this.clustered.disposeNode(this.clustered._scene);
      elem.innerHTML = '';
      this.redrawChart();
    } else if (id === 2) {
      let elem = document.getElementById(
        `clustered${this.data_view_id}${this.hash}`
      );
      let origY = this.origY;
      let result_axis = [];

      for (let i = start_index; i <= end_index; i++) {
        result_axis.push(this.origY[i]);
      }
      let total_chunks = result_axis.length * this.origX.length;
      if (start_index == 0 && end_index == 1) {
        this.result = this.readChunkedData(id, total_chunks, 0, 1);
      }
      if (start_index == 1 && end_index == 1) {
        this.result = this.readChunkedData(id, total_chunks, 1, 1);
      } else {
        this.result = this.readChunkedData(
          id,
          total_chunks,
          start_index,
          end_index
        );
      }

      this.clustered.disposeNode(this.clustered._scene);
      elem.innerHTML = '';
      this.redrawChart();
    } else {
      let origZ = this.origZ;
      let result_axis = origZ.slice(start_index, end_index);
      this.data = this.orig_data;
      this.data.item.collection[this.zIndex].data = result_axis;
      let elem = document.getElementById(
        `clustered${this.data_view_id}${this.hash}`
      );
      this.result = this.orig_result;

      this.clustered.disposeNode(this.clustered._scene);
      elem.innerHTML = '';
      this.redrawChart();
    }
  }

  exportAsPNG(): void {
    let name = this.data.item.name;
    name = name.replace(/ /g, '_');
    name = name.replace(/-/g, '');
    var img = new Image();
    this.clustered._renderer.render(
      this.clustered._scene,
      this.clustered._camera
    );
    img.src = this.clustered._renderer.domElement.toDataURL();
    let strMime = 'image/jpeg';
    let strDownloadMime = 'image/octet-stream';
    this.saveFile(
      img.src.replace(strMime, strDownloadMime),
      `Chart_Screenshot_${name}.png`
    );
  }

  saveFile(strData: any, filename): void {
    var link = document.createElement('a');
    if (typeof link.download === 'string') {
      document.body.appendChild(link); //Firefox requires the link to be in the body
      link.download = filename;
      link.href = strData;
      link.click();
    } else {
      let uri = this.router.url;
      location.replace(uri);
    }
  }
  exportAsPDF(): void {
    console.log(`Export as PDF`);
    let name = this.data.item.name;
    name = name.replace(/ /g, '_');
    name = name.replace(/-/g, '');

    var img = new Image();
    this.clustered._renderer.render(
      this.clustered._scene,
      this.clustered._camera
    );
    let imgPath = this.clustered._renderer.domElement.toDataURL();
    var doc = new jsPDF('p', 'mm', 'a4', true);
    var imgData = 'data:image/jpeg;base64,' + imgPath;
    doc.addImage(imgPath, 'JPEG', -70, 1, 320, 160, '', 'FAST', 0);
    doc.save(`Chart_Screenshot_${name}.pdf`);
  }

  generateChunks(arr: any[], bulkSize: number) {
    const bulks = [];
    for (let i = 0; i < Math.ceil(arr.length / bulkSize); i++) {
      bulks.push(arr.slice(i * bulkSize, (i + 1) * bulkSize));
    }
    return bulks;
  }

  unchunk(arr: any[]): any[] {
    let result: any[] = [];
    arr.forEach((item: any) => {
      item.forEach((itm: any) => {
        result.push(itm);
      });
    });
    return result;
  }

  readChunkedData(
    id: number,
    numChunks: number,
    startIndex: number,
    endIndex: number
  ) {
    if (id === 1) {
      let chunks = this.generateChunks(
        this.result,
        this.origX.length
      ).reverse();
      let totalChunks: any[] = [];
      chunks.forEach((chunk: any) => {
        chunk = chunk.reverse();
        let chnk = chunk.slice(startIndex, endIndex);
        totalChunks.push(chnk);
      });
      let result = this.unchunk(totalChunks);
      return result.reverse();
      // X chunks
    } else if (id === 2) {
      let totalChunks: any[] = [];
      let chunks = this.generateChunks(this.result, this.origX.length);
      chunks.forEach((chunk: any) => {
        //  chunk = chunk.reverse();
        totalChunks.push(chunk);
      });

      if (startIndex === endIndex) {
        totalChunks = totalChunks.slice(startIndex, endIndex + 1);
        //      } else if (startIndex === 0 && endIndex === 1) {
        //      totalChunks = totalChunks.slice(startIndex, endIndex + 1);
      } else {
        totalChunks = totalChunks.slice(startIndex, endIndex + 1);
      }

      let result = totalChunks;
      // if (startIndex != endIndex) {
      result = this.unchunk(totalChunks);
      //  }
      return result; //.reverse();
      // Y chunks
    } else {
      // Z chunks
      return [];
    }
  }
  chunkX(): any {}

  chunkY(): any {}

  extractDates(data: any) {
    const dates = [];
    let extractedDates = JSON.parse(JSON.stringify(data));
    let month = new Array();
    month[0] = 'Jan';
    month[1] = 'Feb';
    month[2] = 'Mar';
    month[3] = 'Apr';
    month[4] = 'May';
    month[5] = 'Jun';
    month[6] = 'Jul';
    month[7] = 'Aug';
    month[8] = 'Sep';
    month[9] = 'Oct';
    month[10] = 'Nov';
    month[11] = 'Dec';

    for (const date of extractedDates) {
      let d = new Date(date);

      let hours = d.getHours();
      let minutes = d.getMinutes();
      let ampm = hours >= 12 ? 'pm' : 'am';
      let minuts = minutes < 10 ? '0' + minutes : minutes;
      let strTime = hours + ':' + minuts;
      let v =
        d.getDate() +
        ' ' +
        month[d.getMonth()] +
        ' ' +
        d.getFullYear() +
        ' ' +
        strTime;
      dates.push(v);
    }
    return dates;
  }

  getThreeDimData(data: any) {
    if (typeof data.item !== 'undefined' && data.item !== null) {
      if (data.item.collection.length === 0) {
        return data;
      }
    }

    //let collection = data.item.collection.slice(data.item.collection.length-4, data.item.collection.length-1);

    // data.item.collection = collection;

    for (let i = 0; i < data.item.collection.length; i++) {
      for (let j = 0; j < data.item.collection[i].data.length; j++) {
        if (data.item.collection[i].data[j] === null) {
          data.item.collection[i].data[j] = 0;
        }
      }
    }

    let col = data.item.collection.slice(
      data.item.collection.length - 3,
      data.item.collection.length
    );
    data.item.collection = col;
    let x = this.extractDates(col[0].data);
    let y = col[1].data;
    let z = col[2].data;
    let converted: any[] = [];
    let result: any[] = [];
    let i = 0;
    let j = 1;
    let k = 2;
    let ln = col[0].data.length;

    let x_name = col[0].name.toLowerCase();
    let y_name = col[1].name.toLowerCase();
    let z_name = col[2].name.toLowerCase();

    let unitX = col[0].unit;
    let unitY = col[1].unit;
    let unitZ = col[2].unit;

    x.forEach((item: any) => {
      item = `${item} ${unitX}`;
    });

    y.forEach((item: any) => {
      item = `${item} ${unitY}`;
    });

    for (let jndex = 0; jndex < y.length; jndex++) {
      for (let index = 0; index < x.length; index++) {
        let item = [];
        item.push(y[jndex]);
        item.push(x[index]);
        converted.push(item);
      }
    }

    // for (let index = 0; index < z.length; index++) {
    //    converted[index].push(z[index]);
    //  }

    let unit_names: any = {};

    for (let i = 0; i < col[1].data.length; i++) {
      unit_names[col[1].data[i]] = col[1].unit[i];
    }

    result = [];

    for (let index = 0; index < converted.length; index++) {
      let item: any = {};
      item[`${x_name}`] = `${converted[index][0]}`;
      item[`${y_name}`] = `${converted[index][2]}`;
      item[`${z_name}`] = converted[index][1];
      result.push(item);
    }

    let response = {
      data: data,
      keys: [`${x_name}`, `${y_name}`, `${z_name}`],
      unit_names: unit_names,
      units: [`${col[0].unit}`, `${col[2].unit}`, ''],
      x: x.reverse(),
      y: y,
      z: z,
      unit_x: unitX,
      unit_y: unitY,
      xIndex: i,
      yIndex: j,
      zIndex: k,
      name_x: x_name,
      name_y: y_name,
      name_z: z_name,
      unit_z: unitZ,
      result: result
    };
    return response;
  }

  itemNotEmpty(data): boolean {
    if (typeof data !== 'undefined') {
      if (typeof data.item !== 'undefined') {
        return true;
      }
      return false;
    }
    return false;
  }

  updateDataView(
    item: any,
    time_domain_id: number,
    time_domain_interval_id: number,
    user_id: number
  ): void {
    const updateItem = deepCopy(item);
    updateItem['time_domain_id'] = time_domain_id;
    updateItem['time_domain_interval_id'] = time_domain_interval_id;
    updateItem['user_id'] = user_id;
    this.dataViewService.saveDataView(updateItem).subscribe(
      item => this.onItemUpdated(item),
      error => (this.errorMessage = <any>error)
    );
  }

  onItemUpdated(item: any) {
    this.clearChart();
    this.readData();
  }

  genRand(): any {
    return Math.random()
      .toString(36)
      .slice(2, 7);
  }

  readCol(): any {
    return this.item.settings.cols;
  }

  getId(): any {
    return Math.floor(Math.random() * 100);
  }

  reloadPage(): void {}
}
