import * as highCharts from 'highcharts';
import { highChartsBase } from './highcharts-base';

export const highChartsMultiAxes = function(name, series, labels, yAxis) {
    let threshold_guide_items = [];
    let start_yaxis_on_min = 0;
    let last_calculated_yaxis_min = null;
    let first_reset_triggered = false;
    return Object.assign({

     chart: {
            zoomType: 'xy',
           renderTo:'container',
            defaultSeriesType:'column',
            borderWidth:0,
            backgroundColor:'#FFF',
            borderWidth:1,
            borderColor:'#FFF',
            plotBackgroundColor:'#fff',
            plotBorderWidth:1,
            plotBorderColor:'#FFF'
        },
        credits:{enabled:false},
        exporting:{enabled:false},
        title:{text: name},

    title: { text: name, style: { fontWeight: 'bold' } },
            boost: {
                 enabled: true,
                 useGPUTranslations: true,
            },
    xAxis: [{
        categories: labels,
        crosshair: true
    }],
    yAxis: yAxis,
    tooltip: {
        shared: true
    },
    legend: {
        layout: 'vertical',
        align: 'left',
        x: 80,
        verticalAlign: 'top',
        y: 55,
        floating: true,
        backgroundColor:
            highCharts.defaultOptions.legend.backgroundColor || // theme
            'rgba(255,255,255,0.25)'
    },
    series: series, 
    responsive: {
        rules: [{
            condition: {
                maxWidth: 500
            },
            chartOptions: {
                legend: {
                    floating: false,
                    layout: 'horizontal',
                    align: 'center',
                    verticalAlign: 'bottom',
                    x: 0,
                    y: 0
                },
                yAxis: [{
                    labels: {
                        align: 'right',
                        x: 0,
                        y: -6
                    },
                    showLastLabel: false
                }, {
                    labels: {
                        align: 'left',
                        x: 0,
                        y: -6
                    },
                    showLastLabel: false
                }, {
                    visible: false
                }]
            }
        }]
    }

    },
    highChartsBase
    );
};


