import { State } from './store/state/nav.state';
import { MenuState } from './store/state/dashboard-menu.state';

const initialState: State = {
  sidebar: true
};

const initialMenuState: MenuState = {
  open: false
};

export function appReducer(state, action) {
  switch (action.type) {
    case 'SIDEBAR_ON':
      return { sidebar: true };
    case 'SIDEBAR_OFF':
      return { sidebar: false };
    default:
      return state;
  }

  return state;
}

export function appMenuReducer(state, action) {
  switch (action.type) {
    case 'MENU_ON':
      return { open: true };
    case 'MENU_OFF':
      return { open: false };
    default:
      return state;
  }

  return state;
}
