import * as d3 from 'd3';
import * as d3Scale from 'd3-scale';
import * as d3Array from 'd3-array';
import * as d3Axis from 'd3-axis';
import * as THREE from 'three';
import * as TWEEN from '@tweenjs/tween.js';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { deepCopy } from '@shared/utils/storage/encrypted_storage';
import { EventsService } from '@modules/events/services/events.service';
let global_store: any = {};
//let updated_bars_gl: any = [];
// let back_bars: any = [];
// https://github.com/analyzer2004/clusteredchart
export class ClusteredChart {
  public three: any = THREE;
  public back_bars: any = [];
  public updated_bars_gl: any = [];
  public _maxTick: number = 0;
  public _unit_names: any = {};
  public eventsService: EventsService;
  public _eventsService: EventsService;
  public _scaled_down_bars: any[] = [];
  public _updated_bars: any[] = [];
  public orig_y: any = {};
  public meshes_orig: any = {};
  public meshes_scaled: any = {};
  public provided_data: any;
  public container: string;
  public loader: any;
  public _raycaster: any;
  public _scene: any;
  public _sceneOrtho: any;
  public _camera: any;
  public _cameraOrtho: any;
  public _renderer: any;
  public _controls: any;
  public _font: any;
  public _pool: any;
  public _keysY: any;
  public _yAxisLabel: string = '';
  public _zAxisLabel: string = '';
  // interaction
  public _mouseScene: any;
  public _mouseScreen: any;
  public _hint: any;
  public _focus: any;
  public _request: any;
  public _bars: any;
  public _bar_ids: any[] = [];
  public columns: any;
  // data
  public _data: any;
  public _chartData: any = null;
  public _keysX: any = null;
  public _keysZ: any = null;
  public _keys: any = null;
  public _units: any = null;
  // scalesg
  public _x: any = null;
  n;
  public _y: any = null;
  public _z: any = null;
  public _color: any = null;

  // delegates
  public _docmousemove: any = (e: any) => this._mousemove(e);
  public _controlschange: any = () => this._render();
  public currentId: string = '';
  // events
  public _onhover: any = null;
  public _onclick: any = null;
  public _oncancel: any = null;
  public _container: any;
  public _width: any;
  public _height: any;
  public _dims: any;
  public _tickPadding: any;
  public _options: any;
  public _column: any;
  public _bar: any;
  public bars: any;
  public _wall: any;
  public _floor: any;
  public _tooltip: any;

  constructor(
    container: any,
    keys: any,
    units: any,
    unit_names: any,
    provided_data: any,
    zAxis: any[],
    width: any,
    height: any,
    eventsService: EventsService,
    labels: any,
    currentId: string
  ) {
    this.currentId = currentId;
    this.provided_data = provided_data;
    this._unit_names = unit_names;
    this._units = units;

    if (typeof this._units !== 'undefined' && this._units !== null) {
      this._yAxisLabel = this._units[1];
      this._zAxisLabel = this._units[2];
    }
    this.three.Cache.enabled = true;
    this._maxTick = Math.max(...zAxis) + 10;
    this._keys = keys;
    this.columns = { x: keys[0], y: keys[1], z: keys[2] };
    //this.columns = {x:'territory', y: 'quarter', z: 'profit'};
    this.container = container;
    this._container = document.getElementById(container);
    this._width = width;
    this._height = height;
    this._dims = { width: 5, height: 2, depth: 5 };
    this._tickPadding = 0.1;
    this._eventsService = eventsService;
    this._options = {
      animation: true,
      font: null,
      backgroundColor: 0xffffff, //0xF0239a, //ffffff,
      color: 0xeeeeee,
      textColor: 0x333333,
      lineColor: 0xcccccc
    };

    this.loader = new FontLoader();

    this._column = { x: labels.xLabel, y: labels.yLabel, z: labels.zLabel };

    this._bar = {
      scale: { x: 0.65, z: 0.65 },
      opacity: 0.85,
      isOrdinal: true,
      palette: d3.schemeTableau10
    };

    this._wall = {
      visible: true,
      color: 0xeeeeee,
      opacity: 0.9,
      showTicks: true,
      tickFormat: '~s'
    };

    this._floor = {
      visible: true,
      color: 0xeeeeee,
      opacity: 0.9,
      showTicks: true
    };

    (this._tooltip = {
      textColor: 'black',
      fillColor: 'rgba(255,255,255,0.75)',
      scale: 0.4
    }),
      // three.js objects
      (this._raycaster = null);
    this._scene = null;
    this._sceneOrtho = null;
    this._camera = null;
    this._cameraOrtho = null;
    this._renderer = null;
    this._controls = null;
    this._font = null;
    this._pool = null;

    // interaction
    this._mouseScene = null;
    this._mouseScreen = null;
    this._hint = null;
    this._focus = null;
    this._request = null;
    this._bars = null;

    // data
    this._data = null;
    this._chartData = null;
    this._keysX = null;
    this._keysZ = null;

    // scales
    this._x = null;
    this._y = null;
    this._z = null;
    this._color = null;

    // delegates
    this._docmousemove = e => this._mousemove(e);
    this._controlschange = () => this._render();

    // events
    this._onhover = null;
    this._onclick = null;
    this._oncancel = null;
    this.column({ x: labels.xLabel, y: labels.yLabel, z: labels.zLabel });
    this.bar({
      palette: d3.interpolateYlOrRd,
      isOrdinal: false,
      scale: { x: 1, z: 1 }
    });
    //    this.size([1000, 500]);
    this.options({
      textColor: 0x666666,
      lineColor: 0xcccccc
    });
    //   this.floor({visible: false})
    //   this.wall({visible: false})
    //  this.column({x: "date"})
    //    this.bar({palette: d3.interpolateYlOrRd, isOrdinal: false, scale: {x: 1, z: 1}})
    this.wall({
      visible: true,
      color: 0xeeeeee,
      showTicks: true
    });
    this.floor({
      visible: true,
      color: 0xeeeeee,
      showTicks: true
    });
    this.render();
    this.bars = this.bar;
  }

  size(_) {
    return arguments.length
      ? ((this._width = _[0]), (this._height = _[1]), this)
      : [this._width, this._height];
  }

  dimensions(_) {
    return arguments.length
      ? ((this._dims = Object.assign(this._dims, _)), this)
      : this._dims;
  }

  options(_) {
    return arguments.length
      ? ((this._options = Object.assign(this._options, _)), this)
      : this._options;
  }

  bar(_) {
    return arguments.length
      ? ((this._bar = Object.assign(this._bar, _)), this)
      : this._bar;
  }

  wall(_) {
    return arguments.length
      ? ((this._wall = Object.assign(this._wall, _)), this)
      : this._wall;
  }

  floor(_) {
    return arguments.length
      ? ((this._floor = Object.assign(this._floor, _)), this)
      : this._floor;
  }

  tooltip(_) {
    return arguments.length
      ? ((this._tooltip = Object.assign(this._tooltip, _)), this)
      : this._tooltip;
  }

  column(_) {
    return arguments.length
      ? ((this._column = Object.assign(this._column, _)), this)
      : this._column;
  }

  data(_) {
    return arguments.length ? ((this._data = _), this) : this._data;
  }

  set datum(data: any) {
    this._data = data;
  }

  get datum() {
    return this._data;
  }

  onhover(_) {
    return arguments.length ? ((this._onhover = _), this) : this._onhover;
  }

  onclick(_) {
    return arguments.length ? ((this._onclick = _), this) : this._onclick;
  }

  oncancel(_) {
    return arguments.length ? ((this._oncancel = _), this) : this._oncancel;
  }

  render() {
    this._init();
    this._process();
    const values = this._chartData.flatMap(d =>
      this._keysZ.map(key => +d[key])
    );

    this._initScales(values);
    this._initPool(values);
    this._renderChart();
    return this;
  }

  rerender() {
    this._process();
    const values = this._chartData.flatMap(d =>
      this._keysZ.map(key => +d[key])
    );
    this._initScales(values);
    this._initPool(values);
    this._renderChart();
  }

  dispose() {
    const that = this;

    if (this._request) cancelAnimationFrame(this._request);

    cleanup();
    this._hint.dispose();
    this._detachEvents();

    checkMemory();
    this._renderer.dispose();
    this._controls.dispose();
    //this.three.clear();

    return this;

    function cleanup() {
      const scene: any = that._scene;
      for (let i = scene.children.length - 1; i >= 0; i--) {
        let obj: any = scene.children[i];
        scene.remove(obj);
        if (obj instanceof THREE.Mesh || obj instanceof THREE.Line) {
          obj.geometry.dispose();
          obj.material.dispose();
          if (obj) {
            //  obj.frame.geometry.dispose();
            //  obj.frame.material.dispose();
          }
        }
      }
    }

    function checkMemory() {
      const memory = that._renderer.info.memory;
      if (memory.geometries > 0 || memory.textures > 0) console.log(memory);
    }
  }

  move3D() {
    this._drawChartUp();
  }

  move2D() {
    this._drawChartDown();
  }

  moveRight(step: number) {
    const scene: any = this._scene;
    for (let i = scene.children.length - 1; i >= 0; i--) {
      let obj: any = scene.children[i];
      if (obj instanceof THREE.Mesh || obj instanceof THREE.Line) {
        obj.position.z += step;
      }
    }
    if (
      typeof this._eventsService !== 'undefined' &&
      this._eventsService !== null
    ) {
      this._eventsService.publish('chartChanged', 'chartChanged', {});
    }
  }

  moveLeft(step: number) {
    const scene: any = this._scene;
    for (let i = scene.children.length - 1; i >= 0; i--) {
      let obj: any = scene.children[i];
      if (obj instanceof THREE.Mesh || obj instanceof THREE.Line) {
        obj.position.z -= step;
      }
    }
    if (
      typeof this._eventsService !== 'undefined' &&
      this._eventsService !== null
    ) {
      this._eventsService.publish('chartChanged', 'chartChanged', {});
    }
  }

  moveUp(step: number) {
    const scene: any = this._scene;
    for (let i = scene.children.length - 1; i >= 0; i--) {
      let obj: any = scene.children[i];
      if (obj instanceof THREE.Mesh || obj instanceof THREE.Line) {
        obj.position.y += step;
      }
    }
    if (
      typeof this._eventsService !== 'undefined' &&
      this._eventsService !== null
    ) {
      this._eventsService.publish('chartChanged', 'chartChanged', {});
    }
  }

  moveDown(step: number) {
    const scene: any = this._scene;
    for (let i = scene.children.length - 1; i >= 0; i--) {
      let obj: any = scene.children[i];
      if (obj instanceof THREE.Mesh || obj instanceof THREE.Line) {
        obj.position.y -= step;
      }
    }
    if (
      typeof this._eventsService !== 'undefined' &&
      this._eventsService !== null
    ) {
      this._eventsService.publish('chartChanged', 'chartChanged', {});
    }
  }

  get controls() {
    return this._controls;
  }

  get camera() {
    return this._camera;
  }

  set camera(camera: any) {
    this._camera = camera;
  }

  get renderer() {
    return this._renderer;
  }

  set width(w: number) {
    this._width = w;
  }

  set height(h: number) {
    this._height = h;
  }

  get width(): number {
    return this._width;
  }

  get height(): number {
    return this._height;
  }

  _init() {
    let index = document.getElementsByTagName('canvas').length;
    let canvas = document.getElementsByTagName('canvas')[0];
    if (index > 0) {
      canvas = document.getElementsByTagName('canvas')[index];
    }
    this._raycaster = new THREE.Raycaster();
    this._scene = new THREE.Scene();
    this._sceneOrtho = new THREE.Scene();
    this._renderer = new THREE.WebGLRenderer({
      canvas,
      alpha: true,
      antialias: true
    });
    this._renderer.setSize(this._width, this._height);
    this._renderer.setClearColor(0xffffff, 1);

    this._mouseScene = new THREE.Vector2();
    this._mouseScreen = new THREE.Vector2();

    this._camera = new THREE.PerspectiveCamera(
      75,
      this._width / this._height,
      0.5,
      this._height
    );
    this._cameraOrtho = new THREE.OrthographicCamera(
      -this._width / 2,
      this._width / 2,
      this._height / 2,
      -this._height / 2,
      1,
      10
    );
    this._cameraOrtho.position.z = 10;

    if (typeof this._container !== 'undefined' && this._container !== null) {
      this._container.appendChild(this._renderer.domElement);
    }

    this._renderer.setPixelRatio(window.devicePixelRatio);
    this._renderer.setSize(this._width, this._height);
    this._renderer.autoClear = false;

    this._camera.aspect = this._width / this._height;
    this._camera.updateProjectionMatrix();
    this._camera.position.x = -2.5;
    this._camera.position.y = 5;
    this._camera.position.z = 5;

    this._scene.position.x = -this._dims.width / 2;
    this._scene.position.y = this._dims.height / 2;
    this._scene.position.z = -this._dims.depth / 2;
    this._scene.background = new THREE.Color(this._options.backgroundColor);
    this._render();

    this._hint = this._createHint();
  }

  _process() {
    this._data = this.provided_data;
    this._column = this.columns;
    const column = this.columns;
    if (this._data === null || this._data.length === 0)
      throw 'No data to display.';
    if (column.x === '' && column.y === '' && column.z === '')
      throw 'Please specify the column names.';

    if (typeof this._data[0] !== 'undefined' && this._data[0] !== null) {
      const keys = Object.keys(this._data[0]);
      if (column.x !== '' && column.z !== '') {
        if (
          !keys.includes(column.x) ||
          !keys.includes(column.y) ||
          !keys.includes(column.z)
        )
          throw '1. Please verify if the specified column names are correct.';

        const xmap = new Map();

        this._data.forEach(d => {
          const key = d[column.y];
          let x = xmap.get(key);
          if (!x) {
            x = {};
            x[column.x] = key;
            xmap.set(key, x);
          }
          if (x) {
            //      x[d[column.x]] = `${d[column.x]}`;
            x[d[column.x]] = `${d[column.z]}`;
          }
        });

        this._chartData = [...xmap.values()];
      } else {
        if (!keys.includes(column.x))
          throw '2. Please verify if the specified column.x is correct.';
        this._chartData = this._data;
      }

      this._keysX = this._chartData.map(d => d[column.x]);
      this._keysY = this._chartData.map(d => d[column.y]);
      let max = 0,
        mindex = 0;
      this._chartData.forEach((d, i) => {
        const len = Object.keys(d).length;
        if (len > max) {
          max = len;
          mindex = i;
        }
      });
      this._keysZ = Object.keys(this._chartData[mindex]).filter(
        d => d !== column.x
      );
    }
  }

  _initPool(values) {
    const that = this;
    this._pool = {
      boxGeometry: new THREE.BoxBufferGeometry(1, 1, 1),
      boxMaterials: this._bar.isOrdinal ? generateByKeys() : generateByValues(),
      textMaterial: new THREE.MeshBasicMaterial({
        color: this._options.textColor
      }),
      lineMaterial: new THREE.LineBasicMaterial({
        color: this._options.lineColor
      }),
      dispose: function() {
        this.boxGeometry.dispose();
        this.boxMaterials.forEach(m => m.dispose());
        this.textMaterial.dispose();
        this.lineMaterial.dispose();
      }
    };
    function generateByKeys() {
      return new Map(
        that._keysX.map((d: any) => [
          d,
          new THREE.MeshBasicMaterial({
            color: that._color(d),
            opacity: that._bar.opacity,
            transparent: true
          })
        ])
      );
    }

    function generateByValues() {
      return new Map(
        values
          .filter((value, index, self) => self.indexOf(value) === index)
          .map(v => [
            v,
            new THREE.MeshBasicMaterial({
              color: that._color(v),
              opacity: that._bar.opacity,
              transparent: true
            })
          ])
      );
    }
  }

  _initScales(values) {
    const ext: any = d3.extent(values);
    this._x = d3
      .scaleBand()
      .domain(this._keysX)
      .range([0, this._dims.width]);

    //    this._x = d3
    //     .scaleBand()
    //      .range([0, this._dims.width]);
    this._y = d3.scaleLinear().range([0, this._dims.height / 2]);

    this._z = d3
      .scaleBand()
      .domain(this._keysZ)
      .range([this._dims.depth, 0]);

    this._color = this._bar.isOrdinal
      ? d3
          .scaleOrdinal()
          .domain(this._keysX)
          .range(this._bar.palette)
      : d3.scaleSequential(this._bar.palette).domain(ext);
  }

  _renderChart() {
    const f: any = this._options.font;
    if (typeof f === 'string') this._loadThenDraw();
    else if (typeof f === 'object') {
      this.loader.load(
        'assets/fonts/helvetiker_regular.typeface.json',
        (f: any) => {
          this._font = f; //new Font(f);
          this._options.font = f;
          this._drawChart();
        }
      );
    }
  }

  _loadThenDraw() {
    const manager: any = new THREE.LoadingManager();
    manager.onLoad = () => {
      this._drawChart();
    };
    new FontLoader(manager).load(this._options.font, f => (this._font = f));
  }

  _drawChart() {
    const that = this;

    this._drawElements();

    this._initControls();
    if (this._options.animation) animate(1000);
    this._attachEvents();
    //   that._render();

    function animate(time: any) {
      that._request = requestAnimationFrame(animate);

      if (typeof that._bars !== 'undefined' && that._bars !== null) {
        if (that._bars.length > 0) {
          that._growBars();
        } else {
          // exit the animation loop after finished
          cancelAnimationFrame(that._request);
          TWEEN.update(time);

          that._request = null;
          return;
        }
      }

      that._render();
    }
    let items = [];
    for (let i = 0; i < that._bars.length; i++) {
      let item = that._bars[i];
      items.push(item);
    }
    global_store[that.container] = items;
  }

  _drawChartDown() {
    const that = this;

    if (global_store[that.container].length > 0) {
      that._updated_bars = global_store[that.container];
      global_store[that.container] = [];
    }

    if (this._options.animation) animate(1000);
    function animate(time: any) {
      that._request = requestAnimationFrame(animate);

      if (that._updated_bars.length > 0) {
        //back_bars = that._updated_bars;
        that._scaleDownBars();
      } else {
        // exit the animation loop after finished
        cancelAnimationFrame(that._request);
        TWEEN.update(time);
        //       that._updated_bars = that._bars;
        that._request = null;
        return;
      }

      that._render();
    }
    //that._updated_bars = that._bars;
  }

  _drawChartUp() {
    const that = this;
    this._drawElements();

    if (global_store[that.container].length > 0) {
      that._updated_bars = global_store[that.container];
      global_store[that.container] = [];
    }

    if (this._options.animation) animate(1000);
    this._attachEvents();
    that._updated_bars = [];
    global_store[that.container] = [];

    function animate(time: any) {
      that._request = requestAnimationFrame(animate);

      if (that._bars.length > 0) {
        that._scaleUpBars();
      } else {
        // exit the animation loop after finished
        cancelAnimationFrame(that._request);
        TWEEN.update(time);
        that._request = null;
        return;
      }

      that._render();
    }
  }

  _render() {
    this._renderer.clear();
    this._renderer.render(this._scene, this._camera);
    this._renderer.clearDepth();
    this._renderer.render(this._sceneOrtho, this._cameraOrtho);
    if (
      typeof this._eventsService !== 'undefined' &&
      this._eventsService !== null
    ) {
      this._eventsService.publish('chartChanged', 'chartChanged', {});
    }
  }

  _scaleUpBars() {
    for (let i = this._bars.length - 1; i >= 0; i--) {
      const bar = this._bars[i];
      if (bar.scale.y <= bar.targetHeight) {
        bar.scale.y += 0.05;
        bar.position.y = bar.scale.y / 2;

        if (bar.scale.y >= bar.targetHeight) {
          bar.scale.y = bar.targetHeight;
          bar.position.y = bar.targetHeight / 2;
          global_store[this.container].push(bar);
          this._updated_bars.push(bar);
          this._bars.splice(i, 1);
        }
      }
    }
  }

  _growBars() {
    let _updated_bars: any[] = [];

    for (let i = this._bars.length - 1; i >= 0; i--) {
      const bar = this._bars[i];
      if (bar.scale.y <= bar.targetHeight) {
        bar.scale.y += 0.05;
        bar.position.y = bar.scale.y / 2;

        if (bar.scale.y >= bar.targetHeight) {
          bar.scale.y = bar.targetHeight;
          bar.position.y = bar.targetHeight / 2;
          this._updated_bars.push(bar);
          this.back_bars.push(bar);
          this._bars.splice(i, 1);
        }
      }
    }
    //   this._updated_bars = _updated_bars;
  }

  _scaleDownBars() {
    for (let i = this._updated_bars.length - 1; i >= 0; i--) {
      const bar = this._updated_bars[i];

      if (bar.scale.y > 0.051) {
        bar.scale.y -= 0.05;
        bar.position.y = bar.scale.y / 2;
      } else {
        bar.position.y = 0.025;
      }
      global_store[this.container].push(bar);
      if (bar.scale.y <= 0) {
      }
    }
  }

  _drawElements() {
    // floor margin
    const margin = this._floor.visible
      ? { x: this._dims.width / 20, z: this._dims.depth / 20 }
      : { x: 0, z: 0 };

    this._drawFloorAndWalls(margin);
    this._drawBars(margin);
  }

  inBars(uuid: any) {
    let found: boolean = false;
    this._bar_ids.forEach((id: any) => {
      if (id === uuid) {
        found = true;
      }
    });
    return found;
  }
  _drawBars(margin) {
    const sx = this._x.bandwidth() * this._bar.scale.x,
      sz = this._z.bandwidth() * this._bar.scale.z,
      hsx = sx / 2,
      hsz = sz / 2,
      qsx = sx / 4,
      qsz = sz / 4,
      halfPI = Math.PI / 2;

    this._bars = [];

    this._chartData.forEach((row, i) => {
      const tx = row[this._column.x];
      let x = this._x(tx) + margin.x / 2;

      if (this._floor.showTicks) {
        const t = this._addText(
          tx,
          hsx / 2.4,
          0,
          x + hsx + qsx,
          0,
          0,
          halfPI,
          Math.PI,
          -halfPI
        );
        t.position.z =
          this._calcTextWidth(t) +
          this._dims.depth +
          margin.z +
          this._tickPadding;
      }

      this._keysZ.forEach(key => {
        const value = row[key] | 0,
          h = this._y(value),
          z = this._z(key) + margin.z / 2;

        if (value) {
        }
        if (i === 0 && this._floor.showTicks)
          this._addText(
            key,
            hsz / 2.4,
            0,
            -this._tickPadding,
            0,
            z + qsz,
            halfPI,
            Math.PI,
            0
          );
        let factor: number = this._maxTick / 2;
        const bar = this._addBar(
          sx,
          h / factor,
          sz,
          x,
          0,
          z,
          this._bar.isOrdinal ? tx : value
        );
        bar.info = { keyX: tx, keyZ: key, value };
        this._bars.push(bar);
        if (typeof bar.geometry.uuid !== 'undefined') {
          this._bar_ids.push(bar.geometry.uuid);
        }
      });
    });
  }

  _drawFloorAndWalls(margin) {
    const thickness = 0.025;
    const floor = {
      width: this._dims.width + margin.x,
      depth: this._dims.depth + margin.z,
      x: 0,
      z: 0
    };

    const backWall = { x: floor.x, y: 0, z: 0 },
      sideWall = { x: floor.x + floor.width, y: 0, z: floor.z };

    if (this._floor.visible) {
      this._addWall(
        floor.width,
        thickness,
        floor.depth,
        floor.x,
        0,
        floor.z,
        this._floor.color,
        this._floor.opacity
      );
    }

    if (this._wall.visible) {
      // backwall
      this._addWall(
        floor.width,
        this._dims.height,
        thickness,
        backWall.x,
        backWall.y,
        backWall.z,
        this._wall.color,
        this._wall.opacity
      );
      // sidewall
      this._addWall(
        thickness,
        this._dims.height,
        floor.depth,
        sideWall.x,
        sideWall.y,
        sideWall.z,
        this._wall.color,
        this._wall.opacity
      );
    }

    if (this._wall.showTicks) {
      let ticks, th, fmtr;
      let xticks, zticks;
      if (this._wall.showTicks) {
        ticks = this._y.ticks();
        th = (this._dims.height / ticks.length) * 0.5;
        fmtr = d3.format(this._wall.tickFormat);
      }
      //  xticks = this._x.ticks();
      //zticks = this._z.ticks();
      // backwall ticks (x wall)

      ticks.forEach(tick => {
        const y = this._y(tick);
        const t = this._addText(
          `${tick * this._maxTick} ${this._zAxisLabel}` /*fmtr(tick)*/,
          th,
          0,
          0,
          y * 2,
          backWall.z,
          0,
          0,
          0
        );
        t.position.x = -this._calcTextWidth(t) - this._tickPadding;
        this._addLine(
          new THREE.Vector3(backWall.x, y * 2, backWall.z + thickness),
          new THREE.Vector3(
            sideWall.x - thickness,
            y * 2,
            backWall.z + thickness
          )
        );
      });

      // sidewall ticks (z wall)
      ticks.forEach(tick => {
        const y = this._y(tick);
        const t = this._addText(
          `${tick * this._maxTick} ${this._zAxisLabel}`, //fmtr(tick),
          th,
          0,
          sideWall.x,
          y * 2,
          0,
          0,
          -Math.PI / 2,
          0
        );
        t.position.z = floor.depth + this._tickPadding;
        this._addLine(
          new THREE.Vector3(
            sideWall.x - thickness,
            y * 2,
            backWall.z + thickness
          ),
          new THREE.Vector3(sideWall.x - thickness, y * 2, floor.depth)
        );
      });
    }
  }

  _calcTextWidth(text) {
    if (!text.geometry.boundingBox) text.geometry.computeBoundingBox();
    return text.geometry.boundingBox.max.x;
  }

  _addFrame(target) {
    const geometry = new THREE.EdgesGeometry(target.geometry);
    const material = new THREE.LineBasicMaterial({ color: 0x0, linewidth: 1 });
    const frame = new THREE.LineSegments(geometry, material);
    frame.renderOrder = 1;
    target.frame = frame;
    target.add(frame);
  }

  _addText(text, size, h, x, y, z, rx, ry, rz) {
    const geometry = new TextGeometry(text, {
      font: this._font,
      size: size,
      height: h
    });
    geometry.computeBoundingSphere();
    geometry.computeVertexNormals();

    const mesh = new THREE.Mesh(geometry, this._pool.textMaterial);
    mesh.position.set(x, y, z);
    mesh.rotation.set(rx, ry, rz);
    this._scene.add(mesh);
    return mesh;
  }

  _addBar(w, h, d, x, y, z, key) {
    if (eval(key) >= 0) {
    }

    const mesh: any = this._createMesh(
      this._pool.boxGeometry,
      this._pool.boxMaterials.get(key),
      w,
      h,
      d,
      x,
      y,
      z
    );
    //  this.orig_y[`item_${mesh.geometry.uuid}`]={scale_y:mesh.scale.y, position_y:mesh.position.y};
    if (this._options.animation) {
      mesh.scale.set(w, 0, d);
      mesh.targetHeight = h;
      this._bars.push(mesh);
    } else mesh.scale.set(w, h, d);

    this._scene.add(mesh);
    return mesh;
  }

  _addWall(w, h, d, x, y, z, color, opacity) {
    const geometry = new THREE.BoxBufferGeometry(w, h, d);
    const material = new THREE.MeshBasicMaterial({
      color: color,
      opacity: opacity,
      transparent: true
    });
    return this._createMesh(geometry, material, w, h, d, x, y, z);
  }

  _createMesh(geometry, material, w, h, d, x, y, z) {
    const mesh = new THREE.Mesh(geometry, material);
    mesh.position.set(x + w / 2, y + h / 2, z + d / 2);
    this._scene.add(mesh);
    return mesh;
  }

  _addLine(f, t) {
    const geometry = new THREE.BufferGeometry().setFromPoints([f, t]);
    const line = new THREE.Line(geometry, this._pool.lineMaterial);
    this._scene.add(line);
    return line;
  }

  _attachEvents() {
    document.addEventListener('mousemove', this._docmousemove, false);
    this._controls.addEventListener('change', this._controlschange);
  }

  _detachEvents() {
    document.removeEventListener('mousemove', this._docmousemove);
    this._controls.removeEventListener('change', this._controlschange);
  }

  _mousemove(e) {
    e.preventDefault();
    if (e.target instanceof HTMLCanvasElement) {
      this._mouseScreen.x = e.offsetX;
      this._mouseScreen.y = e.offsetY;
      this._mouseScene.x = (e.offsetX / this._width) * 2 - 1;
      this._mouseScene.y = -(e.offsetY / this._height) * 2 + 1;
      this._intersect();
    }
  }

  _intersect() {
    const that = this;

    this._camera.updateMatrixWorld();
    this._cameraOrtho.updateMatrixWorld();
    this._raycaster.setFromCamera(this._mouseScene, this._camera);

    const intersects = this._raycaster.intersectObjects(this._scene.children);
    if (intersects.length > 0) {
      let target;
      for (let i = 0; i < intersects.length; i++) {
        if (intersects[i].object.info) {
          target = intersects[i].object;
          break;
        }
      }

      if (target) {
        if (this._focus !== target) {
          cancelFocus();
          this._focus = target;
          this._addFrame(this._focus);
          this._hint.update(this._focus);
          this._render();
        }
      } else cancelFocus();
    } else cancelFocus();

    function cancelFocus() {
      const f = that._focus;
      if (f) {
        if (f.frame) {
          f.remove(f.frame);
          f.frame.geometry.dispose();
          f.frame.material.dispose();
          f.frame = null;
        }
        that._focus = null;
        that._hint.clear();
        that._render();
      }
    }
  }

  _initControls() {
    this._controls = new OrbitControls(this._camera, this._renderer.domElement);
    this._controls.screenSpacePanning = false;

    this._controls.maxPolarAngle = Math.PI / 2.5;
    this._controls.minDistance = 1.12;
    this._controls.maxDistance = 10;
  }

  disposeNode(parentObject) {
    parentObject.traverse(function(node) {
      if (node instanceof THREE.Mesh) {
        if (node.geometry) {
          node.geometry.dispose();
        }
        if (node.material) {
          var materialArray;
          if (
            node.material instanceof THREE.MeshBasicMaterial ||
            node.material instanceof THREE.LineBasicMaterial
          ) {
            materialArray = node.material;
          } else if (node.material instanceof Array) {
            materialArray = node.material;
          }
          if (materialArray && Array.isArray(materialArray)) {
            materialArray.forEach(function(mtrl, idx) {
              if (mtrl.map) mtrl.map.dispose();
              if (mtrl.lightMap) mtrl.lightMap.dispose();
              if (mtrl.bumpMap) mtrl.bumpMap.dispose();
              if (mtrl.normalMap) mtrl.normalMap.dispose();
              if (mtrl.specularMap) mtrl.specularMap.dispose();
              if (mtrl.envMap) mtrl.envMap.dispose();
              mtrl.dispose();
            });
          } else {
            if (node.material.map) node.material.map.dispose();
            if (node.material.lightMap) node.material.lightMap.dispose();
            if (node.material.bumpMap) node.material.bumpMap.dispose();
            if (node.material.normalMap) node.material.normalMap.dispose();
            if (node.material.specularMap) node.material.specularMap.dispose();
            if (node.material.envMap) node.material.envMap.dispose();
            node.material.dispose();
          }
        }
      }
    });
  }

  _createHint() {
    console.log(
      `CREATING HINT FOR CONTAINER ${this.container} AND CURRENT ID ${this.currentId}`
    );
    const that = this,
      size = 26,
      canvas = document.createElement('canvas'),
      ctx = canvas.getContext('2d');
    if (typeof ctx !== 'undefined' && ctx !== null) {
      ctx.font = `${size}px tahoma`;
    }
    const texture = new THREE.CanvasTexture(canvas);
    texture.needsUpdate = true;

    const material = new THREE.SpriteMaterial({ map: texture });
    const sprite = new THREE.Sprite(material);
    this._sceneOrtho.add(sprite);

    return {
      sprite,
      update: function(target: any) {
        const texts = [target.info.keyX, target.info.keyZ, target.info.value];

        let label = texts[0];

        let unit = that._unit_names[label];

        texts[2] = `${texts[2]} ${unit}`;

        if (typeof ctx !== 'undefined' && ctx !== null) {
          let w = Math.max(...texts.map((d: any) => ctx.measureText(d).width)),
            dim = { w: w + 90, h: size * 3 + 10 };
          ctx.clearRect(0, 0, 1000, 1000);
          ctx.fillStyle = that._tooltip.fillColor;
          ctx.fillRect(0, 0, dim.w, dim.h);
          ctx.fillStyle = that._tooltip.textColor;
          texts.forEach((t, i) => ctx.fillText(t, 10, size * (i + 1)));
        }

        texture.needsUpdate = true;

        sprite.center.set(0.5, 0.5);
        sprite.scale.set(
          300 * that._tooltip.scale,
          200 * that._tooltip.scale,
          1
        ); // 3:2 scale
        sprite.position.set(
          that._mouseScreen.x - that._width / 2,
          -that._mouseScreen.y + that._height / 2,
          1
        );
      },
      clear: function() {
        if (typeof ctx !== 'undefined' && ctx !== null) {
          ctx.clearRect(0, 0, 1000, 1000);
        }
        texture.needsUpdate = true;
      },
      dispose: function() {
        that._sceneOrtho.remove(this.sprite);
        if (this.sprite !== null) {
          if (this.sprite.geometry !== null) {
            this.sprite.geometry.dispose();
          }
          if (this.sprite.material !== null) {
            if (this.sprite.material.map !== null) {
              this.sprite.material.map.dispose();
            }
            this.sprite.material.dispose();
          }
        }
      }
    };
  }
}
