import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  Input
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  FormControl,
  FormGroup,
  Validators,
  FormArray,
  FormBuilder
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { EventsState } from '@modules/events/states/events.state';
import { EventsService } from '@modules/events/services/events.service';
import { constants } from '@core/constants';
import { BaseComponent } from '@shared/components/base/base.component';
import { UserSessionState } from '@core/stores/user-session/states/user-session.states';
import { Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { IConfig } from '@shared/models/config.model';

@Component({
  selector: 'app-column-config-detail',
  templateUrl: './column-config-detail.component.html',
  styleUrls: ['./column-config-detail.component.scss']
})
export class ColumnConfigDetailComponent extends BaseComponent
  implements OnInit, OnDestroy, AfterViewInit {
  @Input('data_points') data_points: any;
  @Input('item') item: any;
  @Input('calculation') calculation: any;
  public grid_data: any[] = [];
  public detailState: boolean = false;
  public name: string = '';
  public selected: any = '';
  public items: any[] = [];
  public perPage: number = 10;
  public itemCount: number = 0;
  public loading: boolean = false;
  public itemForm: FormGroup;
  public totalName: string = '';

  constructor(
    protected idle: Idle,
    protected keepalive: Keepalive,
    protected sessionStore: Store<{ uss: UserSessionState }>,
    protected eventsService: EventsService,
    protected eventStore: Store<{ nf: EventsState }>,
    protected route: ActivatedRoute,
    protected router: Router,
    private formBuilder: FormBuilder
  ) {
    super(idle, keepalive, eventsService, sessionStore, router);
    this.itemForm = this.formBuilder.group({
      name: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50)
      ])
    });
  }

  refresh(filters) {
    let start: number = filters['page']['from'];
    let end: number = filters['page']['to'];
    let items = this.data_points; //.slice(start, end);
    if (start > 0) {
      this.itemCount = this.data_points.length;
      this.items = items;
    }
    this.loading = false;
  }

  ngOnInit(): void {
    this.items = this.data_points.slice(0, this.perPage);
    this.itemCount = this.data_points.length;
    this.patchForm();
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {}

  toggleItemActive(e: any) {}

  onDetailOpen(e: any) {
    this.detailState = !this.detailState;
  }

  currDetail(): void {}

  onItemSelected(e: any) {}

  patchForm(): void {
    this.name = this.calculation;
    this.itemForm.controls['name'].setValue(this.calculation);
  }

  itemSelected(item: any) {
    if (typeof this.totalName !== 'undefined' && this.totalName.length > 0) {
      this.name = this.totalName + ' ' + this.shortName(item.name);
      this.totalName = this.name;
    } else {
      this.name = this.shortName(item.name);
      this.totalName = this.name;
    }
    //     this.totalName = this.totalName+' '+this.name;
  }

  isNum(val) {
    return !isNaN(val);
  }

  shortName(name: string) {
    let words = name.split(' ');
    let sName = '';
    for (let i = 0; i < words.length; i++) {
      if (this.isNum(words[i])) {
        sName = sName + words[i];
      } else {
        sName = sName + words[i].toUpperCase().slice(0, 1);
      }
    }
    return sName; //name.toUpperCase().slice(0 , 4);
  }

  startsWith(formula: any, item: any) {
    let prefix = formula.slice(0, item.length);
    if (prefix === item) {
      return true;
    }
    return false;
  }

  valueAdded(item: any, formula: any): boolean {
    if (
      formula.includes(item) &&
      (formula.includes(` ${item}`) || this.startsWith(formula, item))
    ) {
      return true;
    }
    return false;
  }

  getDataPoint(abbr: any) {
    for (let i = 0; i < this.items.length; i++) {
      if (this.shortName(this.items[i].name) === abbr) {
        return this.items[i];
      }
    }
    return {};
  }
  updateItem(): void {
    let id = 'postFormula';
    let data_points: any[] = [];
    let calculations: any[] = [];
    let items = this.name.split(' ');
    items.forEach((item: any) => {
      let dp = this.getDataPoint(item.trim());
      if (!calculations.includes(item.trim())) {
        let data = { data_point_id: dp.id, calc_label: item.trim() };
        data_points.push(data);
        calculations.push(this.name);
      }
    });
    data_points = this.dedupGridData(data_points);
    let payload = {
      id: this.item.id,
      name: this.item.name,
      calculation: this.name,
      data_points: data_points
    };

    console.log('=> payload ' + JSON.stringify(payload));

    this.eventsService.publish(id, id, payload);
    var elem1 = document.querySelectorAll('[aria-label="Close"]');
    elem1['0'].click();
    elem1['0'].click();
  }

  updateName(e: any) {
    this.totalName = e;
    this.name = e;
  }

  dedupGridData(items: any[]) {
    let updated_items: any[] = [];
    let dp_id: {} = {};

    for (let i = 0; i < items.length; i++) {
      let key = `${items[i].data_point_id}-${items[i].calc_label}`;
      let v = dp_id[key];
      if (typeof v === 'undefined' || v === null) {
        dp_id[key] = true;
        updated_items.push(items[i]);
      }
    }
    return updated_items;
  }
}
