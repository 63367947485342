import { Injectable } from '@angular/core';
import { IAlarm, IAlarms } from '@shared/interfaces/alarms';
import {
  IAlarmHistory,
  IAlarmHistories
} from '@modules/alarms/models/alarm-history.model';
import {
  IAlarmContact,
  IAlarmContacts
} from '@modules/alarms/models/alarm-contact.model';
import { BehaviorSubject } from 'rxjs';
import { IIdName } from '@shared/interfaces/base';
import { BaseService } from '@core/services/base.service';
import { constants } from '@core/constants';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { EventsService } from '@modules/events/services/events.service';

const initialAlarmsData = [] as IAlarm[];
const initialAlarmsAsOptionsData = [] as IIdName[];
const initialAlarmData = {} as IAlarm;
const initialAlarmHistoryData = {} as IAlarmHistory;
const initialAlarmHistoriesAsOptionsData = [] as IIdName[];
const initialAlarmContactData = {} as IAlarmContact;
const initialAlarmContactsAsOptionsData = [] as IIdName[];

@Injectable({
  providedIn: 'root'
})
export class AlarmService extends BaseService {
  private alarmsDataSource = new BehaviorSubject<IAlarm[]>(initialAlarmsData);
  private alarmsAsOptionsDataSource = new BehaviorSubject<IIdName[]>(
    initialAlarmsAsOptionsData
  );

  private alarmHistoriesAsOptionsDataSource = new BehaviorSubject<IIdName[]>(
    initialAlarmHistoriesAsOptionsData
  );
  private alarmContacstsAsOptionsDataSource = new BehaviorSubject<IIdName[]>(
    initialAlarmContactsAsOptionsData
  );

  private alarmCountSource = new BehaviorSubject<number>(null);
  private alarmDataSource = new BehaviorSubject<IAlarm>(initialAlarmData);

  private alarmHistoryCountSource = new BehaviorSubject<number>(null);
  private alarmHistoryDataSource = new BehaviorSubject<IAlarmHistory>(
    initialAlarmHistoryData
  );

  private alarmContactCountSource = new BehaviorSubject<number>(null);
  private alarmContactDataSource = new BehaviorSubject<IAlarmContact>(
    initialAlarmContactData
  );

  public alarms = this.alarmsDataSource.asObservable();
  public alarmsAsOptions = this.alarmsAsOptionsDataSource.asObservable();
  public alarmCount = this.alarmCountSource.asObservable();
  public alarmContactCount = this.alarmContactCountSource.asObservable();
  public alarmHistoryCount = this.alarmHistoryCountSource.asObservable();
  public alarm = this.alarmDataSource.asObservable();

  constructor(
    protected eventsService: EventsService,
    protected http: HttpClient
  ) {
    super(eventsService, http);
  }

  getAlarms(filters: any) {
    return this.getMany(constants.api.alarm.alarm_api, filters).pipe(
      map((data: IAlarms) => {
        data.item_list.forEach(alarm => {
          alarm.period = alarm.period.split('T')[1];
          alarm.notice_interval = alarm.notice_interval.split('T')[1];
          alarm.active_from = alarm.active_from ? alarm.active_from.split('T')[1] : null;
          alarm.active_to = alarm.active_to ? alarm.active_to.split('T')[1] : null;
        });
        this.alarmsDataSource.next(data.item_list);
        this.alarmCountSource.next(data.item_count);
        return data;
      }),
      catchError(error => this.handleError(error))
    );
  }

  clearAlarms() {
    this.alarmsDataSource.next(null);
    this.alarmCountSource.next(null);
  }

  getAlarm(id: number) {
    if (id === 0) {
      const data = { id: 0 } as IAlarm;
      this.alarmDataSource.next(data);
      return data;
    }
    return this.getOne(constants.api.alarm.alarm_api, id).pipe(
      map(
        (data: { item: IAlarm }) => {
          this.alarmDataSource.next(data.item);
          return data;
        },
        catchError(error => this.handleError(error))
      )
    );
  }

  updateAlarm(alarm: IAlarm) {
    return this.updateOne(constants.api.alarm.alarm_api, alarm).pipe(
      map(data => this.handleSuccess(data)),
      catchError(error => this.handleError(error))
    );
  }
  handleAlarmSuccess(data) {
    this.alarmDataSource.next(data);
    this.handleSuccess(data);
  }
  saveAlarm(alarm: IAlarm) {
    return this.saveOne(constants.api.alarm.alarm_api, alarm).pipe(
      map(data => {
        this.handleAlarmSuccess(data);
      }),
      catchError(error => this.handleError(error))
    );
  }

  deleteAlarm(id: number) {
    return this.deleteOne(constants.api.alarm.alarm_api, id).pipe(
      map(data => this.handleSuccess(data)),
      catchError(error => this.handleError(error))
    );
  }

  getAlarmHistories(filters) {
    return this.getMany(constants.api.alarm.alarm_history_api, filters).pipe(
      map(data => {
        this.handleSuccess(data);
        return data;
      }),

      catchError(error => this.handleError(error))
    );
  }

  getAlarmHistory(id: number) {
    return this.getOne(constants.api.alarm.alarm_history_api, id).pipe(
      map(data => {
        this.handleSuccess(data);
        return data;
      }),

      catchError(error => this.handleError(error))
    );
  }

  deleteAlarmHistory(id: number) {
    return this.deleteOne(constants.api.alarm.alarm_history_api, id);
  }

  saveAlarmHistory(alarmHistory) {
    return this.saveOne(constants.api.alarm.alarm_history_api, alarmHistory);
  }

  getAllAlarms(asOptions?: boolean) {
    return this.get(constants.api.alarm.alarm_api).pipe(
      map((data: IAlarms) => {
        if (asOptions) {
          const itemList = [];
          data.item_list.forEach((alarm: IAlarm) => {
            const id = alarm.id;
            const name = alarm.name;
            itemList.push({ id, name });
          });
          this.alarmsAsOptionsDataSource.next(itemList);
        } else {
          this.alarmsDataSource.next(data.item_list);
          this.alarmCountSource.next(data.item_count);
        }
        return data;
      }),
      catchError(error => this.handleError(error))
    );
  }

  getAllAlarmHistories(asOptions?: boolean) {
    return this.get(constants.api.alarm.alarm_history_api).pipe(
      map((data: IAlarmHistories) => {
        if (asOptions) {
          const itemList = [];
          data.item_list.forEach((alarm: IAlarm) => {
            const id = alarm.id;
            const name = alarm.name;
            itemList.push({ id, name });
          });
          this.alarmHistoriesAsOptionsDataSource.next(itemList);
        } else {
          this.alarmHistoryDataSource.next(data.item_list);
          this.alarmHistoryCountSource.next(data.item_count);
        }
        return data;
      }),
      catchError(error => this.handleError(error))
    );
  }

  getAllAlarmContacts(asOptions?: boolean) {
    return this.get(constants.api.alarm.alarm_contact_api).pipe(
      map((data: IAlarmContacts) => {
        if (asOptions) {
          const itemList = [];
          data.item_list.forEach((alarm: IAlarmContact) => {
            const id = alarm.id;
            const name = alarm.name;
            itemList.push({ id, name });
          });
          this.alarmContacstsAsOptionsDataSource.next(itemList);
        } else {
          this.alarmContactDataSource.next(data.item_list);
          this.alarmContactCountSource.next(data.item_count);
        }
        return data;
      }),
      catchError(error => this.handleError(error))
    );
  }

  getAlarmContacts(filters) {
    // Former ALARM_CONTACT_API_URL 'api/alarm_contacts'
    return this.getMany(constants.api.alarm.alarm_contact_api, filters);
  }

  deleteAlarmContact(id: number) {
    //  Former ALARM_CONTACT_API_URL 'api/alarm_contacts'
    return this.deleteOne(constants.api.alarm.alarm_contact_api, id);
  }

  saveAlarmContact(alarmContact) {
    //  Former ALARM_CONTACT_API_URL 'api/alarm_contacts'
    return this.saveOne(constants.api.alarm.alarm_contact_api, alarmContact);
  }

  createAlarmContact(alarmContact) {
    //  Former ALARM_CONTACT_API_URL 'api/alarm_contacts'
    console.log(
      `ALARM CONTACT ${
        constants.api.alarm.alarm_contact_api
      }  and ${JSON.stringify(alarmContact)}`
    );
    return this.createOne(constants.api.alarm.alarm_contact_api, alarmContact);
  }

  addAlarmSelectedContact(payload) {
    // Former ALARM_ALARM_CONTACT_ADD_API_URL 'api/add_alarm_contact'
    return this.createOne(
      constants.api.alarm.alarm_alarm_contact_add_api,
      payload
    );
  }

  deleteAlarmSelectedContact(payload) {
    // Former ALARM_ALARM_CONTACT_REMOVE_API_URL 'api/remove_alarm_contact'
    return this.saveOne(
      constants.api.alarm.alarm_alarm_contact_remove_api,
      payload
    );
  }

  // Service with custom transform
  getAllAlarmTypesAsOptions() {
    return this.getAllAsOptions(constants.api.alarm.alarm_type_api, {
      name: 'name',
      id: 'id'
    });
  }

  getAllAlarmTemplatesAsOptions() {
    return this.getAllAsOptions(constants.api.alarm.alarm_template_api, {
      name: 'name',
      id: 'id'
    });
  }

  getAllAlarmContactsAsOptions() {
    return this.getAllAsOptions(constants.api.alarm.alarm_contact_api, {
      name: 'name',
      id: 'id'
    });
  }

  getLast() {
    return this.alarmsDataSource.value;
  }
}
