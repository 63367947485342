<div class="child-container child-container-wrapper resizable asset-customer-profile-details-container">
    <form [formGroup]="itemForm" class="pad-r24" novalidate >
        <h4>{{header}}</h4>


 <div class="clr-row form-group row-span">
   <div class="clr-col-1 col-title col-title-left">
     <label>Name</label>
   </div>
   <div class="clr-col-5">
     <clr-input-container>
       <input clrInput type="text" formControlName="name" name="name" size="64" />   
       <clr-control-error *clrIfError="'minlength'">Must be at least 3 characters</clr-control-error>
       <clr-control-error *clrIfError="'required'">Name Is Required</clr-control-error>
       <clr-control-error *clrIfError="'maxlength'">Can't be longer than 50 characters</clr-control-error>
     </clr-input-container>
    </div>
  </div>



<div class="clr-row form-group row-span">
 <div class="clr-col-1 col-title col-title-left">
        <label for="address">Address</label>
</div>
<div class="clr-col-5">
                             <clr-input-container>
                             <input id="address"  clrInput type="text" [formControl]="itemForm.controls.address" name="address"  size="64"/>
                             <clr-control-error *clrIfError="'minlength'">Address must be at least 3 characters</clr-control-error>
                             <clr-control-error *clrIfError="'required'">Address Is Required</clr-control-error>
                             </clr-input-container>
</div>
</div>




<div class="clr-row form-group row-span">
 <div class="clr-col-1 col-title col-title-left">
        <label for="address">Address 2</label>
</div>
<div class="clr-col-5">
                             <clr-input-container>
                             <input id="address_2"  clrInput type="text" [formControl]="itemForm.controls.address_2" name="address_2"  size="64"/>
                             </clr-input-container>
</div>
</div>

<div class="clr-row form-group row-span">
     <div class="clr-col-1 col-title col-title-left">
        <label for="city">City</label>
    </div>
    <div class="clr-col-2">
                             <clr-input-container>
                             <input id="city"  clrInput type="text" [formControl]="itemForm.controls.city" name="city"  size="20"/>
                             <clr-control-error *clrIfError="'minlength'">City must be at least 3 characters</clr-control-error>
                             <clr-control-error *clrIfError="'required'">City Is Required</clr-control-error>
                             </clr-input-container>
    </div>
    <div class="clr-col-1 col-title">
        <label for="state">State</label>
    </div>
    <div class="clr-col-2 position-state">
        <app-search-select id="states" #stateDropdown [tag]="'states'" name="state" [width]="'12em'" [records]="states" (click)="reReadStates()" [(ngModel)]="state" ngDefaultControl [formControl]="itemForm.controls.state"></app-search-select>

    </div>
</div>






 <div class="clr-row form-group row-span">
   <div class="clr-col-1 col-title col-title-left">
     <label>Postal Code</label>
   </div>
   <div class="clr-col-5">
     <clr-input-container>
       <input clrInput type="text" formControlName="postal_code" name="postal_code" size="64" />
     <clr-control-error *clrIfError="'minlength'">Must be at least 3 characters</clr-control-error>
     <clr-control-error *clrIfError="'required'">Postal Code Is Required</clr-control-error>
     </clr-input-container>
    </div>
  </div>


 <div class="clr-row form-group button-spacing">
   <div class="clr-col-4"></div> 
   <div class="clr-col-1">
                        <button *ngIf="notEmpty(item)" class="btn btn-primary update-button position-button" [disabled]="!itemForm.dirty || itemForm.invalid"
                                (click)=update()>{{(item.id > 0) ? 'Update' : 'Save'}}
                        </button>
   </div>
</div>
</form>
</div>
