<div class="child-container child-container-wrapper">

<div class="flex-outer-container">

    <div class="flex-outer-left-item">
         <div class="flex-inner-left-container">
                   <div class="flex-inner-left-left-item">
                      <h2>{{listHeader}}</h2>
                   </div>
         </div>
    </div>
    <div class="flex-outer-right-item">
         <div class="flex-inner-right-container">
                    <div class="flex-inner-right-right-item">
                                <button class="btn btn-primary add-button" (click)=addItem()>
                                  Add
                                </button>&nbsp;
                                <button class="btn btn-primary delete-button" [disabled]="!(selected.length > 0)" (click)=deleteDialog()>
                                 Delete
                               </button>&nbsp;
                    </div>
         </div>
    </div>
</div>
<div class="limit-height">
  <clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading" [(clrDgSelected)]="selected" class="fl-table">
    <clr-dg-column [clrDgField]="'name'">
            Contact Name
            <clr-dg-string-filter [clrDgStringFilter]="'name'"></clr-dg-string-filter>
    </clr-dg-column>
    <clr-dg-column>Start Date</clr-dg-column>
    <clr-dg-column>Active</clr-dg-column>

    <clr-dg-row *ngFor="let company of companyService.companies | async" [clrDgItem]="company">
      <clr-dg-cell class="col-top">
        <a [routerLink]="[company.id]">{{company.name}}</a>
      </clr-dg-cell>
      <clr-dg-cell class="col-top">{{company.start_date}}</clr-dg-cell>

      <clr-dg-cell class="col-top-checkbox item-active">
                          <clr-checkbox-wrapper>
                              <input clrCheckbox type="checkbox" class="item-active-checkbox" [checked]="company.active" [(ngModel)]="company.active" (ngModelChange)="toggleItemActive(company)" />
                          </clr-checkbox-wrapper>
      </clr-dg-cell>

    </clr-dg-row>
    <clr-dg-footer>
      <clr-dg-pagination #pagination [clrDgPageSize]="25" [clrDgTotalItems]="companyService.companyCount | async">
        <clr-dg-page-size [clrPageSizeOptions]="[25,50,100]">
          Companies per page
        </clr-dg-page-size>
        {{pagination.firstItem + 1}}
        -
        {{pagination.lastItem + 1}}
        of
        {{pagination.totalItems}}
        companies
      </clr-dg-pagination>
    </clr-dg-footer>
  </clr-datagrid>
</div>
</div>
