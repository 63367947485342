import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from '@core/services/base.service';
import { constants } from '@core/constants';
import { BehaviorSubject, Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { EventsService } from '@modules/events/services/events.service';

@Injectable({
  providedIn: 'root'
})
export class TicketService extends BaseService {
  private _ticketTypes: BehaviorSubject<any> = new BehaviorSubject<any>('');
  public ticketTypes: Observable<any> = this._ticketTypes.asObservable();

  constructor(
    protected eventsService: EventsService,
    protected http: HttpClient
  ) {
    super(eventsService, http);
  }

  getAllTickets() {
    return this.getAll(constants.api.tickets.ticket_url);
  }

  getTicket(id: number) {
    return this.getOne(constants.api.tickets.ticket_url, id);
  }

  getTickets(filters) {
    return this.getMany(constants.api.tickets.ticket_url, filters);
  }

  saveTicket(ticket) {
    return this.saveOne(constants.api.tickets.ticket_url, ticket);
  }

  deleteTicket(id: number) {
    return this.deleteOne(constants.api.tickets.ticket_url, id);
  }

  // Service with custom transform
  getAllTicketTypesAsOptions() {
    return this.http.get(constants.api.tickets.ticket_type_url).pipe(
      map((res: any) => <any>res),
      catchError(error => this.outputError(error))
    );
  }

  outputError(error): Observable<any> {
    console.log(`Failed calling API - ${JSON.stringify(error)}`);
    return throwError(error);
  }

  getAllTicketPriorities() {
    return this.getAll(constants.api.tickets.ticket_priority_url);
  }

  getAllTicketPrioritiesAsOptions() {
    return this.getAllAsOptions(constants.api.tickets.ticket_priority_url, {
      name: 'name',
      id: 'id'
    });
  }

  getAllTicketStatusesAsOptions() {
    return this.http.get(constants.api.tickets.ticket_status_url).pipe(
      map((res: any) => <any>res),
      catchError(error => this.outputError(error))
    );

    //return this.getAllAsOptions(constants.api.tickets.ticket_status_url, {name: 'name', id: 'id'})
  }

  addTicketTicketUser(payload) {
    return this.saveOne(
      constants.api.tickets.ticket_ticket_user_add_api_url,
      payload
    );
  }

  removeTicketTicketUser(payload) {
    return this.saveOne(
      constants.api.tickets.ticket_ticket_user_remove_api_url,
      payload
    );
  }

  addTicketNote(payload) {
    return this.saveOne(constants.api.tickets.ticket_add_note_url, payload);
  }
}
