import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

interface Event {
  type: string;
  payload: any;
}

type EventCallback = (payload: any) => void;

@Injectable({
  providedIn: 'root'
})
export class EventService {
  private eventHandler = new Subject<Event>();

  validate(v, t): boolean {
    if (t === 'type') {
      if (!v) {
        return null;
      } else {
        return v.type;
      }
    } else {
      if (!v) {
        return null;
      } else {
        return v.payload;
      }
    }
  }
  broadcast(type: string, payload: any) {
    this.eventHandler.next({ type, payload });
  }

  subscribe(type: string, callback: EventCallback): Subscription {
    return this.eventHandler
      .pipe(
        filter(event => this.validate(event, 'type')),
        map(event => this.validate(event, 'payload'))
      )
      .subscribe(callback);
  }
}
