import { IDatePickerConfig } from 'ng2-date-picker';

export const TimeDateConfig: IDatePickerConfig = {
  showTwentyFourHours: false,
  showSeconds: false,
  format: 'YYYY-MM-DD'
};

export const TimeIntervalConfig: IDatePickerConfig = {
  showTwentyFourHours: true,
  showSeconds: false,
  timeSeparator: ':'
  // minutesInterval: 5
};

export const TimeIntervalDateConfig: IDatePickerConfig = {
  showTwentyFourHours: true,
  showSeconds: false,
  timeSeparator: ':',
  format: 'YYYY-MM-DD HH:mm',
  hours24Format: 'HH',
  minutesFormat: 'mm'
};

export const TimeIntervalDateSummaryConfig: IDatePickerConfig = {
  showTwentyFourHours: true,
  showSeconds: true,
  timeSeparator: ':',
  format: 'HH:mm:ss',
  hours24Format: 'HH',
  minutesFormat: 'mm',
  secondsFormat: 'ss'
};

export const TimeIntervalConfigAmPm: IDatePickerConfig = {
  showTwentyFourHours: false,
  showSeconds: false,
  timeSeparator: ':'
  // minutesInterval: 5
};
