import { Component, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { BaseComponent } from '@shared/components/base/base.component';
import { CompanyService } from '@core/services/company/company.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ICompany } from '@shared/interfaces/companies';
import { EventsState } from '@modules/events/states/events.state';
import { UserSessionService } from '@core/services/user-session/user-session.service';
import { Store } from '@ngrx/store';
import { EventsService } from '@modules/events/services/events.service';
import { UserSessionState } from '@core/stores/user-session/states/user-session.states';
import { Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

@Component({
  providers: [EventsService, UserSessionService],
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.scss']
})
export class CompanyListComponent extends BaseComponent
  implements OnInit, OnDestroy, AfterViewInit {
  private eventSubs: Subscription;
  public listHeader = 'Companies';
  private filters: any;
  private deleteModalTitle = 'Delete Companies';
  private deleteEvent = 'deleteCompanyItems';
  private itemsToDelete: any;
  public selected = [];
  constructor(
    protected idle: Idle,
    protected keepalive: Keepalive,
    protected eventStore: Store<{ nf: EventsState }>,
    protected sessionStore: Store<{ uss: UserSessionState }>,
    protected eventsService: EventsService,
    public companyService: CompanyService,
    protected router: Router,
    protected userSessionService: UserSessionService
  ) {
    super(idle, keepalive, eventsService, sessionStore, router);
    this.userSession = this.userSessionService.getUserSession(true);
  }

  ngOnInit(): void {
    // must be cleared or dataGrid selection breaks.
    this.companyService.clearCompanies();
    this.subscribeEvents();
  }

  subscribeEvents() {
    this.eventSubs = this.eventStore.select('nf').subscribe(event => {
      if (event.items.id === 'deleteCompanies') {
        this.deleteItems();
      }
      if (event.items.id === 'refreshComponent') {
        this.refresh(this.filters);
      }
    });
  }

  refresh(filters) {
    this.loading = true;
    this.filters = filters;
    this.companyService.getCompanies(filters).subscribe(_ => {
      this.loading = false;
      this.selected = [];
    });
  }

  toggleItemActive(company: ICompany) {
    console.log(company);
    this.companyService.updateCompany(company).subscribe(
      _ => {},
      _ => {
        company.active = !company.active;
      }
    );
  }

  addItem() {
    this.router.navigate(['/admin/companies/0']).then(null);
  }

  deleteDialog() {
    /**
     * Open delete company dialog to confirm.
     *
     */
    this.itemsToDelete = this.selected.map(selected => ({
      name: selected.name,
      id: selected.id
    }));
    const itemNames = this.selected.map(function(d) {
      return d['name'];
    });
    let id = 'openCompanyDeleteModal';
    this.eventsService.publish(id, 'openCompanyDeleteModal', {
      module: 'companies',
      action: 'open',
      deleteModalTitle: this.deleteModalTitle,
      itemNames: itemNames,
      event: this.deleteEvent,
      additionalInfo: '',
      name: ''
    });
  }

  deleteItems() {
    /**
     * Delete items
     */
    let deleteCount = this.itemsToDelete.length;
    for (const item of this.itemsToDelete) {
      this.companyService.deleteCompany(item.id).subscribe(_ => {
        deleteCount -= 1;
        if (deleteCount === 0) {
          this.refresh(this.filters);
        }
      });
    }
  }

  ngOnDestroy(): void {
    /**
     * Unsubscribe from any resources used
     */
    if (typeof this.eventSubs !== 'undefined' && this.eventSubs !== null) {
      this.eventSubs.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    this.saveRoute();
  }
}
