import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { AdvancedCalcService } from '@modules/advanced-calculations/services/advanced-calc.service';
import { UserSessionState } from '@core/stores/user-session/states/user-session.states';
import { BaseComponent } from '@shared/components/base/base.component';
import { EventsService } from '@modules/events/services/events.service';
import { Store } from '@ngrx/store';
import { EventsState } from '@modules/events/states/events.state';
import { Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

@Component({
  providers: [AdvancedCalcService, EventsService],
  selector: 'app-advanced-calc-details',
  templateUrl: './advanced-calc-details.component.html',
  styleUrls: ['./advanced-calc-details.component.scss']
})
export class AdvancedCalcDetailsComponent extends BaseComponent
  implements OnInit, OnDestroy {
  constructor(
    protected idle: Idle,
    protected keepalive: Keepalive,
    protected advancedCalcService: AdvancedCalcService,
    protected eventsService: EventsService,
    public eventStore: Store<{ nf: EventsState }>,
    protected sessionStore: Store<{ uss: UserSessionState }>,
    protected router: Router
  ) {
    super(idle, keepalive, eventsService, sessionStore, router);
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
