import { AbstractControl, ValidatorFn } from '@angular/forms';

export function passwordsMatchValidator(
  component,
  config: object
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const name = control.value;
    let yes = false;
    if (component[config['formName']]) {
      // console.log('passwordMatchValidator');
      yes = name === component[config['formName']].get(config['field']).value;
    }
    // console.log('passwordMatchValidator');
    // console.log(yes);
    return yes ? null : { passwordMismatch: true };
  };
}
