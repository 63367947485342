import { Router } from '@angular/router';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { BaseComponent } from '@shared/components/base/base.component';
import * as _ from 'lodash';
import { ITicket } from '@modules/ticketing/models/ticket.model';
import { TicketService } from '@modules/ticketing/services/ticket.service';
import { UserService } from '@core/services/user/user.service';
import { IUser } from '@shared/interfaces/users';
import { EventsState } from '@modules/events/states/events.state';
import { EventsService } from '@modules/events/services/events.service';
import { UserSessionState } from '@core/stores/user-session/states/user-session.states';
import { Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

@Component({
  providers: [EventsService],
  selector: 'app-ticket-user-menu',
  templateUrl: './ticket-user-menu.component.html',
  styleUrls: ['./ticket-user-menu.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({ width: '416px', 'pointer-events': 'all' })),
      state('out', style({ width: '47px', 'pointer-events': 'none' })),
      transition('in => out', animate('250ms ease-in-out')),
      transition('out => in', animate('250ms ease-in-out'))
    ])
  ]
})
export class TicketUserMenuComponent extends BaseComponent
  implements OnInit, OnDestroy {
  public ticketUserMenuState = 'out';
  private className = 'TicketUserMenuComponent';
  protected eventSubs: Subscription;
  loadingTicketUserList = false;
  ticketUserListItems: IUser[];
  ticketUserListLimit = 8;
  ticketUserListCount = 8;
  private filters: any;

  @Input() item: ITicket;
  @Output() onTicketUserUpdated: EventEmitter<any> = new EventEmitter<any>();
  errorMessage: string;
  private successToast = {
    title: 'Success!',
    text: '',
    type: 'success'
  };
  private errorToast = {
    title: 'Error!',
    text: '',
    type: 'error'
  };
  private notExistingWarning = {
    title: 'Warning!',
    text: 'Not available until new Ticket is saved!',
    type: 'warning'
  };
  private openMenuSubscription: Subscription;

  constructor(
    protected idle: Idle,
    protected keepalive: Keepalive,
    protected sessionStore: Store<{ uss: UserSessionState }>,
    protected eventStore: Store<{ nf: EventsState }>,
    protected ticketService: TicketService,
    protected eventsService: EventsService,
    protected userService: UserService,
    protected router: Router
  ) {
    super(idle, keepalive, eventsService, sessionStore, router);
  }

  ngOnInit() {
    this.subscribeEvents();
  }

  ngOnDestroy(): void {
    //this.openMenuSubscription.unsubscribe();
  }

  subscribeEvents() {}

  toggleTicketUserMenu() {
    if (+this.item.id === 0) {
      this.eventsService.showToast(this.notExistingWarning);
    } else {
      this.ticketUserMenuState =
        this.ticketUserMenuState === 'out' ? 'in' : 'out';
    }
  }

  addTicketUser(item_id) {
    const payload = { id: this.item.id, user_id: item_id };
    this.ticketService.addTicketTicketUser(payload).subscribe(
      item => this.onItemUpdated(item, 'add'),
      error => (this.errorMessage = <any>error)
    );
  }

  removeTicketUser(item_id) {
    const payload = { id: this.item.id, user_id: item_id };
    this.ticketService.removeTicketTicketUser(payload).subscribe(
      item => this.onItemUpdated(item, 'remove'),
      error => (this.errorMessage = <any>error)
    );
  }

  onItemUpdated(data: any, type: string): void {
    if (data.is_error) {
      this.errorToast.text = `Failed to ${type} Ticket Action!`;
      this.eventsService.showToast(this.errorToast);
    } else {
      this.successToast.text = `Ticket Action ${
        type === 'remove' ? 'removed' : 'added'
      } successfully!`;
      this.eventsService.showToast(this.successToast);
    }
    this.onTicketUserUpdated.emit();
    this.refreshTicketUserList(this.filters);
  }

  onDrop(event: any) {
    event.preventDefault();
    this.addTicketUser(event.dataTransfer.getData('text/plain'));
  }

  onDragOver(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  onItemDrag(event, id) {
    event.dataTransfer.setData('text/plain', id);
  }

  onTicketUsersRetrieved(data: any) {
    if (data.is_error) {
      this.errorToast.text = `Failed to read ticket users!`;
      this.eventsService.showToast(this.errorToast);
    } else {
      this.ticketUserListItems = data.item_list;
      this.ticketUserListCount = data.item_count;

      setTimeout(() => {
        this.loadingTicketUserList = false;
      }, 200);
    }
  }

  addFilter(fs, _f) {
    if (!('filters' in fs)) {
      fs['filters'] = [_f];
    } else {
      fs['filters'].push(_f);
    }
    return fs;
  }

  refreshTicketUserList(filters) {
    this.filters = filters;

    setTimeout(() => {
      this.loadingTicketUserList = true;
    }, 100);

    let fs = _.cloneDeep(filters);
    const _f = { ticketIdUsersFilter: this.item.id };
    fs = this.addFilter(fs, _f);
    console.log(fs);
    this.userService.getUsers(fs).subscribe(
      items => this.onTicketUsersRetrieved(items),
      error => (this.errorMessage = <any>error)
    );
  }

  toggleTicketMenu() {
    this.refreshTicketUserList(this.filters);
    if (this.item.id < 1) {
      this.eventsService.showToast(this.notExistingWarning);
    } else {
      this.ticketUserMenuState =
        this.ticketUserMenuState === 'out' ? 'in' : 'out';
    }
  }
}
