<form [formGroup]="axisForm">
<div class="axis-selector-box" *ngIf="!hideHeaderLabels && !hideHeader">

    <div class="axis-select-item">
                            <select *ngIf="total_items" name="axis" [(ngModel)]="axis" class="axis-dropdown-select" [formControl]="axisForm.controls.axis" (ngModelChange)="updateAxis()"
>
                                <option *ngFor="let item of total_items" [value]="item.id">{{ item.name }}</option>                                
                            </select>
    </div>


    <div class="coordinates-select-item" *ngIf="total_items">
           <div class="axis-label-item">{{ getAxisName(axis) }}</div>
           <div class="axis-start-item clr-row form-group">
                  <div class="clr-col-2">
                          <label>From</label>
                  </div>
                  <div class="clr-col-4"> 
                   <select  name="start" [(ngModel)]="start" class="axis-start-select" [formControl]="axisForm.controls.start" *ngIf="currentId===0">
                       <option *ngFor="let item of x" [value]="item.id" [selected]="item.id===start">{{ item.value }}</option>
                   </select>
                   <select   name="start" [(ngModel)]="start" class="axis-start-select" [formControl]="axisForm.controls.start" *ngIf="currentId===1">
                       <option *ngFor="let item of y" [value]="item.id" [selected]="item.id===start">{{ item.value }}</option>
                   </select>
                   <select   name="start" [(ngModel)]="start" class="axis-start-select" [formControl]="axisForm.controls.start" *ngIf="currentId===2">
                       <option *ngFor="let item of z" [value]="item.id" [selected]="item.id===start">{{ item.value }}</option>
                   </select>
<!--
                         <input type="text" name="start"  formControlName="start" [value]="axis.start" [ngModel]="start" class="axis-input"/>
-->
                  </div>
                  <div class="clr-col-2"> 
                          <label>To</label>
                  </div>
                  <div class="clr-col-4">
                   <select  [(ngModel)]="end" class="axis-end-select" [formControl]="axisForm.controls.end"  *ngIf="currentId==0">
                       <option *ngFor="let item of x" [value]="item.id" [selected]="item.id === end">{{ item.value }}</option>
                   </select>
                   <select   [(ngModel)]="end" class="axis-end-select" [formControl]="axisForm.controls.end"  *ngIf="currentId==1">
                       <option *ngFor="let item of y" [value]="item.id" [selected]="item.id === end">{{ item.value }}</option>
                   </select>
                   <select  [(ngModel)]="end" class="axis-end-select" [formControl]="axisForm.controls.end"  *ngIf="currentId==2">
                       <option *ngFor="let item of z" [value]="item.id" [selected]="item.id === end">{{ item.value }}</option>
                   </select>
<!--
                         <input type="text" name="end"  formControlName="end" [value]="axis.end" [ngModel]="end" class="axis-input"/>
-->
                  </div>
           </div>

        <div class="axis-update-item set-update-button">
                <button class="btn btn-primary btn-sm" (click)="update()"><span class="btn-inside">Update</span></button>
        </div>
    </div>





</div>


<div class="axis-selector-box" *ngIf="hideHeaderLabels && !hideHeader">
    <div class="coordinates-select-item" *ngIf="axis">
           <div class="axis-start-item clr-row form-group">

                  <div class="clr-col-3">
                            <select *ngIf="axis" name="axis" [ngModel]="axis" id="axis-select" class="axis-dropdown-select" [formControl]="axisForm.controls.axis" (ngModelChange)="updateAxis()"
>
                                <option *ngFor="let item of items" [value]="item.id">{{ item.name }}</option>
                            </select>

                  </div>
                  <div class="clr-col-3"> 
                   <select  name="start" [(ngModel)]="start" class="axis-start-select" [formControl]="axisForm.controls.start" *ngIf="currentId==1 && x">
                       <option *ngFor="let item of x" [value]="item.id" [selected]="item.id===start">{{ item.value }}</option>
                   </select>
                   <select   name="start" [(ngModel)]="start" class="axis-start-select" [formControl]="axisForm.controls.start" *ngIf="currentId==2 && y">
                       <option *ngFor="let item of y" [value]="item.id" [selected]="item.id===start">{{ item.value }}</option>
                   </select>
                   <select   name="start" [(ngModel)]="start" class="axis-start-select" [formControl]="axisForm.controls.start" *ngIf="currentId==3 && z">
                       <option *ngFor="let item of z" [value]="item.id" [selected]="item.id===start">{{ item.value }}</option>
                   </select>
<!--
                         <input type="text" name="start"  formControlName="start" [value]="axis.start" [ngModel]="start" class="axis-input"/>
-->
                  </div>
                  <div class="clr-col-3">
                   <select  [(ngModel)]="end" class="axis-end-select" [formControl]="axisForm.controls.end"  *ngIf="currentId==1 && x">
                       <option *ngFor="let item of x" [value]="item.id" [selected]="item.id === end">{{ item.value }}</option>
                   </select>
                   <select   [(ngModel)]="end" class="axis-end-select" [formControl]="axisForm.controls.end"  *ngIf="currentId==2 && y">
                       <option *ngFor="let item of y" [value]="item.id" [selected]="item.id === end">{{ item.value }}</option>
                   </select>
                   <select  [(ngModel)]="end" class="axis-end-select" [formControl]="axisForm.controls.end"  *ngIf="currentId==3 && z">
                       <option *ngFor="let item of z" [value]="item.id" [selected]="item.id === end">{{ item.value }}</option>
                   </select>

<!--
                         <input type="text" name="end"  formControlName="end" [value]="axis.end" [ngModel]="end" class="axis-input"/>
-->
                  </div>
                   <div class="clr-col-1">
                        <button class="btn btn-primary btn-sm" (click)="update()"><span class="btn-inside">Update</span></button>
                   </div>
           </div>

    </div>

</div>
</form>

