<div class="ticket-menu" [@slideInOut]="ticketUserMenuState">
    <div class="ticket-control">
        <button class="btn btn-link nb-button ticket-button" (click)="toggleTicketMenu()">
            <clr-icon shape="cog" size="24"></clr-icon>
        </button>
        <span>{{item?.description}}</span>
    </div>
    <div class="ticket-container" *ngIf="item">

        <div class="group-title">Applied Ticket Users</div>
        <div *ngIf="item.assigned_users"
            class="ticket-users"
            (drop)="onDrop($event)"
            (dragover)="onDragOver($event)"
        >
            <div
                class="ticket-user"
                *ngFor="let user of item.assigned_users"
                id="{{ user.id }}"
            >
                {{ user.name
                }}<button class="btn btn-link remove-button" (click)=removeTicketUser(user.id)><clr-icon shape="times"></clr-icon></button>
            </div>
        </div>



        <div class="group-title">Available Ticket Users</div>
        <div class="row">
            <div class="col-md-12">
                <div class="all-items">
                    <clr-datagrid
                        (clrDgRefresh)="refreshTicketUserList($event)"
                        [clrDgLoading]="loadingTicketUserList"
                    >
                        <clr-dg-column [clrDgField]="'name'">
                            Name
                            <clr-dg-string-filter
                                [clrDgStringFilter]="'name'"
                            ></clr-dg-string-filter>
                        </clr-dg-column>
                        <clr-dg-column>
                            Company
                        </clr-dg-column>
                        <clr-dg-placeholder
                            >No Ticket Users...</clr-dg-placeholder
                        >
                        <clr-dg-row class="draggable-row"
                            *ngFor="let item of ticketUserListItems"
                            [clrDgItem]="item"
                            draggable="true"
                            (dragstart)="onItemDrag($event, item.id)"
                        >
                            <clr-dg-cell
                                >{{ item.name }}</clr-dg-cell
                            >
                            <clr-dg-cell>
                                {{ item.company }}
                            </clr-dg-cell>
                        </clr-dg-row>







        <clr-dg-footer>

            <clr-dg-pagination #pagination [clrDgPageSize]="ticketUserListLimit" [clrDgTotalItems]="ticketUserListCount">
                <clr-dg-page-size [clrPageSizeOptions]="[25,50,100]">
                    Events per page
                </clr-dg-page-size>
                {{pagination.firstItem + 1}}
                -
                {{pagination.lastItem + 1}}
                of
                {{pagination.totalItems}}
                events
            </clr-dg-pagination>
        </clr-dg-footer>

                    </clr-datagrid>
                </div>
                <br />
            </div>
        </div>
    </div>
</div>











