import { Injectable } from '@angular/core';
import { IDView } from '@shared/interfaces/dashboards';
import { forkJoin } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ITriggerPointDataView } from '@shared/interfaces/trigger-point';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '@core/services/base.service';
import { EventsService } from '@modules/events/services/events.service';
import { constants } from '@core/constants';

@Injectable({
  providedIn: 'root'
})
export class DataViewDataService extends BaseService {
  private dataViewsHttpArray = [];

  constructor(
    protected eventsService: EventsService,
    protected http: HttpClient
  ) {
    super(eventsService, http);
  }

  getData(id: number) {
    return this.http.get(`${constants.api.base_api}/data/` + id).pipe(
      map((data: any) => {
        return data;
      }),
      catchError(error => this.handleError(error))
    );
  }

  primeAllData(dataViews: IDView[]) {
    this.dataViewsHttpArray = [];
    console.log(
      'Priming all the data from ' + `${dataViews.length} Data Views`
    );

    for (const dataView of dataViews) {
      this.dataViewsHttpArray.push(
        this.http.get(`${constants.api.base_api}/data/` + dataView.data_view_id)
      );
    }
    forkJoin(this.dataViewsHttpArray).subscribe(results => {
      results.forEach((result, i) => {
        const dataViewId = dataViews[i].data_view_id;
        let id = 'dataViewPrimed' + '_' + dataViewId;
        this.eventsService.publish(id, 'event', result);
      });
    });
  }

  primeAllDateRangeDataByDataPointId(
    dataViews: ITriggerPointDataView[],
    from: string,
    to: string,
    eventId: number
  ) {
    let dataViewObservables = dataViews.map(dataView => {
      return this.get(
        `${constants.api.base_api}/data/${dataView.id}/${from}/${to}?eventId=${eventId}`
      ).pipe(
        map((dataview: any) => {
          this.dataViewsHttpArray.push(dataview);
          return dataview;
        }),
        catchError(error => this.handleError(error))
      );
    });

    return forkJoin(dataViewObservables);
  }
}
