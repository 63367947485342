import {
  Component,
  AfterViewInit,
  AfterContentInit,
  OnDestroy,
  OnInit,
  ElementRef,
  ComponentRef,
  ViewChild
} from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { BaseComponent } from '@shared/components/base/base.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ICompany } from '@shared/interfaces/companies';
import { CompanyService } from '@core/services/company/company.service';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { constants } from '@core/constants';
import { IField } from '@shared/interfaces/fields';
import { CompanyForm } from '@modules/companies/company-forms';
import { IIdName } from '@shared/interfaces/base';
import { sortBy as _sortBy } from 'lodash-es';
import { UserSessionService } from '@core/services/user-session/user-session.service';
import { Store } from '@ngrx/store';
import { FieldService } from '@core/services/field/field.service';
import { EventsService } from '@modules/events/services/events.service';
import { UserSessionState } from '@core/stores/user-session/states/user-session.states';
import { Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { SearchSelectComponent } from '@shared/components/searchable-select/searchable-select.component';

@Component({
  providers: [EventsService, UserSessionService, FieldService],
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss']
})
export class CompanyDetailsComponent extends BaseComponent
  implements AfterViewInit, OnInit, OnDestroy, AfterContentInit {
  @ViewChild('timezonesCompanyDropdown')
  timezonesCompanyWizardDropdown: ComponentRef<SearchSelectComponent>;
  @ViewChild('statesCompanyDropdown')
  statesCompanyWizardDropdown: ComponentRef<SearchSelectComponent>;

  public filteredTimezones: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();
  public state: any;
  public timezone: any;
  public header: string = 'Company Detail - Edit';
  public item: ICompany;
  public postal_code: string;
  public states: any[] = [];
  public parent_id: number;
  public time_zones: any[] = [];
  public all_time_zones: any[] = [];
  public fields: IField;
  public company_id: number = 0;
  public companies: ICompany[];
  public companyTypes: IIdName[];
  public config = {
    displayFn: (item: any) => {
      return item.name;
    },
    search: true, //true/false for the search functionlity defaults to false,
    height: 'auto', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Select', // text to be displayed when no item is selected defaults to Select,
    moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search', // label thats displayed in search input,
    searchOnKey: 'name', // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    clearOnSelection: false, // clears search criteria when an option is selected if set to true, default is false
    inputDirection: 'ltr' // the direction of the search input can be rtl or ltr(default)
  };

  public companyForm = new FormGroup({
    postal_code: new FormControl('', []),
    active: new FormControl(false, []),
    name: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(50)
    ]),
    address: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(50)
    ]),
    timezone: new FormControl(''),
    address_2: new FormControl(''),
    city: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(50)
    ]),
    state: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(50)
    ]),
    company_id: new FormControl(0, []),
    parent_id: new FormControl(0, []),
    company_type_id: new FormControl(0, [])
  });
  options = [
    { id: 1, name: ['option 1'] },
    { id: 2, name: ['option 2'] },
    { id: 1, name: ['option 3'] }
  ];

  constructor(
    protected idle: Idle,
    protected keepalive: Keepalive,
    protected sessionStore: Store<{ uss: UserSessionState }>,
    public fieldService: FieldService,
    protected eventsService: EventsService,
    public companyService: CompanyService,
    protected element: ElementRef,
    protected router: Router,
    protected route: ActivatedRoute,
    protected userSessionService: UserSessionService
  ) {
    super(idle, keepalive, eventsService, sessionStore, router);
  }

  submit() {
    const updateItem = Object.assign({}, this.item, this.companyForm.value);
    // create day of weeks array...
    updateItem['timezone'] = updateItem['timezone']['name'];
    updateItem['state'] = updateItem['state']['name'];
    updateItem['settings'] = updateItem['settings'].toString();
    updateItem['company_type_id'] = parseInt(updateItem['company_type_id']);

    this.companyService

      .updateCompany(updateItem)
      .subscribe(_ => this.router.navigate(['/admin/companies']));
  }

  ngOnInit(): void {
    this.companyForm = CompanyForm();
    this.readParams();
  }

  ngOnDestroy(): void {}

  readParams() {
    this.route.params.subscribe(params => {
      let id = params['id'];
      this.states = constants.states;
      if (eval(params['id']) < 1) {
        this.header = 'Add Company';
        this.item = this.getEmptyCompany();
        this.parent_id = 0;
        this.postal_code = '';
        this.readCompanies();
      } else {
        this.readCompany(id);
      }
    });
  }

  readCompanies() {
    this.companyService.getAllCompanies().subscribe(data => {
      this.companies = data['item_list'];
      this.readFields();
    });
  }

  readCompany(id: number) {
    this.companyService.getCompany(id).subscribe(data => {
      this.item = data.item;
      console.log(this.item);
      this.readCompanies();
    });
  }

  readFields(): void {
    this.states = constants.states;
    this.fieldService.getFields().subscribe(data => {
      this.fields = data;
      this.states = constants.states;
      this.time_zones = data.time_zones;
      if (typeof data.time_zones !== 'undefined') {
        for (let i = 0; i < data.time_zones.length; i++) {
          this.all_time_zones.push({
            id: data.time_zones[i].id.toString(),
            name: data.time_zones[i].name.toString()
          });
        }
      }
      this.companyTypes = _sortBy(this.fields.company_types, 'id');
      this.companyTypes.shift();
      this.patchForm();
    });
  }

  patchForm(): void {
    this.companyForm.controls['parent_id'].setValue(this.item.parent_id);
    this.companyForm.controls['postal_code'].setValue(this.item.postal_code);
    this.companyForm.controls['timezone'].setValue(this.item.timezone);
    this.companyForm.patchValue(this.item);
  }

  ngAfterViewInit(): void {
    let elements = document.querySelectorAll<HTMLElement>(
      'ngx-dropdown-button'
    )!;
    this.saveRoute();
    //    this.fixDropdownButton();
    // this.fixDropDown(this.timezonesCompanyDropdown);
    //    this.fixDropDown(this.statesCompanyDropdown);
  }

  ngAfterContentInit(): void {}

  fixDropDown(elem) {
    if (elem && elem[`searchSelect`]) {
      let keys = Object.keys(elem);
      let el = elem[`searchSelect`]['_elementRef'] as ElementRef;

      let button = el.nativeElement.querySelector('button');
      button.style.borderRadius = 'none';
      button.style.background = 'transparent';
      button.style.borderBottomRightRadius = '0';
      button.style.borderBottomLeftRadius = '0';
      button.style.borderTop = 'none';
      button.style.borderLeft = 'none';
      button.style.borderRight = 'none';
      button.style.borderBottomRightRadius = '0';
      button.style.borderBottomLeftRadius = '0';
      button.style.marginTop = '1.4em';
      button.style.width = '21em';
      button.style.zIndex = '-1';

      let span = el.nativeElement.querySelectorAll('span');
      span[1].classList.remove('sdicon-angle-down');
      span[1].classList.remove(...span[1].classList);
      span[1].innerHTML =
        '<clr-icon shape="caret" style="transform: rotate(180deg); width: 16px; height: 16px;"></clr-icon>';
      span[1].style.color = '#b3b3b3';
      span[1].style.width = '8px';
      span[1].style.height = '8px';
    }
  }

  fixDropdownButton() {
    let elements = document.querySelectorAll<HTMLElement>(
      '.ngx-dropdown-button'
    )!;
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.background = 'transparent';
      elements[i].style.borderRadius = 'none';
      elements[i].style.borderTop = 'none';
      elements[i].style.borderLeft = 'none';
      elements[i].style.borderRight = 'none';
      elements[i].style.borderBottomRightRadius = '0';
      elements[i].style.borderBottomLeftRadius = '0';
      elements[i].style.marginTop = '1.4em';
      //elements[i].style.marginLeft = '-0.7em';
      elements[i].style.width = '21em';
      elements[i].style.zIndex = '-1';
    }
  }

  beautify() {
    let elem = document.querySelectorAll<HTMLElement>('.nsdicon-angle-down');

    for (let i = 0; i < elem.length; i++) {
      elem[i].style.color = '#b3b3b3';
    }

    let elements = document.querySelectorAll<HTMLElement>(
      '.ngx-dropdown-list-container'
    )!;
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.borderRadius = '0px';
      elements[i].style.boxShadow = 'none';
      elements[i].style.width = '21em';
      elements[i].style.background = '#FAFAFA';
      elements[i].style.border = 'none';
      elements[i].style.zIndex = '100';
      //elements[i].style.marginLeft = '-0.7em';
      elements[i].style.marginTop = '-0.45em';
    }
  }

  onChangeState(e) {}

  protected filterTimezones() {
    if (!this.time_zones) {
      return;
    }
    // get the search keyword
    let search = this.companyForm.controls['timezoneFilterCtrl'].value;
    if (!search) {
      this.filteredTimezones.next(this.time_zones.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredTimezones.next(
      this.time_zones.filter(
        timezone => timezone.name.toLowerCase().indexOf(search) > -1
      )
    );
  }

  tzNames() {
    let tz = new Array();
    for (let i = 0; i < this.fields.time_zones.length; i++) {
      tz.push(this.fields.time_zones[i].name);
    }
    return tz;
  }

  getEmptyCompany() {
    return {
      domain: '',
      primary_extension: '',
      postal_code: '',
      timezone: 'US/Central',
      id: 0,
      city: 'Houston',
      logo_file_name: '',
      billing_email: '',
      hostname: '',
      parent_id: 2,
      state: 'Texas',
      secondary_extension: '',
      secondary_telephone: '',
      description: '',
      administrative_email: '',
      address: '',
      active: false,
      primary_telephone: '',
      name: '',
      settings: {},
      url: '',
      technical_email: '',
      address_2: '',
      company_type_id: 1
    };
  }
  zoneSelectionChanged(e) {}

  onSelectChange(e): void {}

  reReadStates(): void {
    let states = [];
    for (let i = 0; i < constants.states.length; i++) {
      states.push({
        name: constants.states[i].name.toString(),
        id: constants.states[i].id.toString()
      });
    }
    this.states = states;
  }

  reReadTimezones(): void {
    this.fieldService.getFields().subscribe(data => {
      let time_zones = [];
      this.time_zones = [];
      if (typeof data.time_zones !== 'undefined') {
        for (let i = 0; i < data['time_zones'].length; i++) {
          time_zones.push({
            name: data['time_zones'][i].name.toString(),
            id: data['time_zones'][i].id.toString()
          });
        }
        this.time_zones = time_zones;
      }
    });
  }

  buttonValue(item: any) {
    if (typeof item === 'undefined' || item === null) {
      return 'Save';
    }

    if (item.id < 1) {
      return 'Save';
    }

    return 'Update';
  }
}
