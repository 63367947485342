<app-trigger-point-profile-action-menu [item]="item" (onTriggerPointActionUpdated)="updateItem($event)"></app-trigger-point-profile-action-menu>
<div class="child-container child-container-wrapper trigger-point-profile-details-container">
<form name="itemForm" [formGroup]="itemForm" novalidate class="form-block pad-r24">
        <h4>{{header}}</h4>
        <br/>



        <div class="clr-row form-group row">
              <div class="clr-col-1 col-title">

                             <label>Name</label>
       
              </div>
              <div class="clr-col-9">
                         <clr-input-container>
                                <input clrInput id="name" type="text" [ngModel]="name" formControlName="name" name="name"  size="42">
                                <clr-control-error *clrIfError="'minlength'">Name must be at least 3 characters</clr-control-error>
                                <clr-control-error *clrIfError="'required'">Name Is Required</clr-control-error>
                         </clr-input-container>
              </div>
           </div>

        <div class="clr-row form-group row" *ngIf="item">
              <div class="clr-col-1 col-title">

                             <label>Actions</label>

              </div>

              <div class="clr-col-9 assigned-actions col-actions" *ngIf="item">
                                <div  *ngIf="item.trigger_point_actions">
                                   <span *ngFor="let action of item.trigger_point_actions; index as i">
                                     <span *ngIf="i < item.trigger_point_actions.length-1">{{action.name}},&nbsp;</span>
                                     <span *ngIf="i == item.trigger_point_actions.length-1">{{action.name}}&nbsp;</span>
                                   </span>
                                   <span id="assigned">
                                     <button class="btn btn-sm btn-link modify-assigned-button" (click)=toggleUserMenu()>
                                     </button>
                                  </span>
                                </div>
              </div>
        </div>

        <div class="clr-row form-group row">
              <div class="clr-col-3">
              </div>
              <div class="clr-col-" *ngIf="item">
                        <button class="btn btn-primary update-button" [disabled]="!itemForm.dirty || itemForm.invalid"
                                (click)=update()>{{(item.id > 0) ? 'Update' : 'Save'}}
                        </button>
              </div>
        </div>
</form>
</div>
