<clr-modal [(clrModalOpen)]="openPitAssociateModal" [clrModalSize]="'md'">
    <h2 class="modal-title">{{title}}</h2>
    <div class="modal-body">
        <h4>{{message}}</h4>
    </div>
    <div class="modal-footer">
        <button *ngIf="!hideCancel" type="button" class="btn btn-outline" (click)=cancel()>Cancel</button>
        <button *ngIf="doButton" type="button" class="btn btn-primary" (click)=associateItem()>{{doButton}}</button>
    </div>
</clr-modal>
